import React, { useState, useEffect } from "react";
import { Grid, Typography, Breadcrumbs, Select, MenuItem, Box, Button, TextField, Modal } from "@mui/material";
import ChecklistForm from "./Checklist/ChecklistForm";
import AssessmetQuestionsForm from "./Assessment/AssessmentQuestionForm";
import CloseIcon from "@mui/icons-material/Close";

export default function ChecklistOverlay(props) {
    const { setShowChecklistOverlay, showChecklistOverlay, candidate } = props;
    const [isChecklist, setIsChecklist] = useState(true);

    return (
        <Modal
          open={showChecklistOverlay}
          onClose={() => setShowChecklistOverlay(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
            <Box className="modal-content checklist-modal responsive-checklist-modal inputcontrasts">
            <Typography className="align-left">
              <Grid container item id="modal-title" className="align-center">
                <Grid item  xs={10}>
                  <Typography className="f-24 font-weight-600 heading-text-color">
                     View Checklist
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    align="right"
                    className="cursor-hover gray7 icon-button"
                  >
                    <CloseIcon onClick={() => setShowChecklistOverlay(false)} aria-label="Close icon" tabIndex={0} onKeyPress={() => setShowChecklistOverlay(false)} />
                  </Typography>
                </Grid>
              </Grid>
              <Grid item id="modal-description" style={{ maxHeight: "500px", overflowY: "overlay" }}>
              {/* <Typography className=" mt-10">
                                {candidate.answers && <Button
                type="submit"
                className={`btn ${!isChecklist?'primary-button':'secondary-button'}`}
                variant="contained"
                onClick={()=>setIsChecklist(false)}
              >
               Assessment
              </Button>} {candidate.candidateSkillChecklistResponse && <Button
                type="submit"
                className={`btn ${isChecklist?'primary-button':'secondary-button'}`}
                variant="contained"
                onClick={()=>setIsChecklist(true)}
              >
               Checklist
              </Button>}</Typography> */}
          {isChecklist && <ChecklistForm selectedCandidateChecklist={candidate.candidateSkillChecklistResponse} />}
          {/* {!isChecklist && <AssessmetQuestionsForm answers={JSON.parse(candidate.answers)}/>} */}
          </Grid>
          </Typography>
          </Box>
        </Modal>
    );
}
