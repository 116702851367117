import React, { useState, useEffect, useRef } from "react";
import { Grid, Typography, Box, TextField, InputAdornment, FormControl, Select, MenuItem, Paper } from "@mui/material";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { ReactComponent as NoProject } from "../../../../assets/images/NoProjectFound.svg";
import { ReactComponent as SearchIcon } from "../../../../assets/images/new-search-gray-icon.svg";
import sortByIcon from "../../../../assets/images/sortby-new-icon.svg";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import ClearIcon from "@mui/icons-material/Clear";
import { useMemo } from "react";
import { handleSpaces, getDurationValue, getDurationPeriod } from "../../../../helpers/helper-data";
import AccessService from "../../../../Services/Enterprise/Access.service";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../../Loader";

const ITEM_HEIGHT = 48;

const columns = [
    { id: "jobTitle", label: "Job Title", minWidth: 50 },
    { id: "jobReference", label: "Job Reference #", minWidth: 50 },
    { id: "companyName", label: "Company Name", minWidth: 50, align: "left" },
    { id: "onboardedDate", label: "Onboarded Date", minWidth: 50 },
    { id: "duration", label: "Duration", minWidth: 50 },
    // { id: "jobEndDate", label: "Job End Date", minWidth: 50, align: "left" },
    { id: "offboardedDate", label: "Offboarded Date", minWidth: 50 },
    { id: "currentStatus", label: "Current Status", minWidth: 50, align: "left" },
];

export default function ProjectLabelEnhancement(props) {
    const [showSearch, setShowSearch] = useState(false);
    const [sort, setSort] = useState("O");
    const [openSortBy, setOpenSortBy] = useState(false);
    const [projectDetails, setProjectDetails] = useState([]);
    const [showCompanyColumn, setShowCompanyColumn] = useState(true);
    const [containerHeight, setContainerHeight] = useState(props.isSideDrawer ? window.innerHeight * 0.4 : window.innerHeight * 0.6);
    const [loading, setIsLoading] = useState(false);
    const [projectLoader, setProjectLoader] = useState(true);
    const inputRef = useRef();
    const divRef = useRef(null);
    const [loadMore, setLoadMore] = useState(true);
    const [pageNumber, setPageNumber] = useState(0);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [previouslySearchKeyword, setPreviouslySearchKeyword] = useState("");
    const projectDetailRef = useRef(null);

    const showSearchBar = (e) => {
        setShowSearch(true);
    };

    const handleEnterKey = (e) => {
        setSearchKeyword(e.target.value);
        console.log(e.target.value);
        if (e.key === "Enter" && e.target.value !== "") {
            e.preventDefault();
            getRecords(0, e.target.value);
        }
        if (e.type === "input" && e.target.value === "") {
            e.preventDefault();
            getRecords(0, e.target.value);
        }
    };

    const clearSearch = () => {
        setSearchKeyword("");
        setPreviouslySearchKeyword("");
        getRecords(0, "");
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                divRef.current &&
                !divRef.current.contains(event.target) &&
                inputRef.current?.value === ""
            ) {
                setShowSearch(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleSortBy = (event) => {
        setSort(event.target.value);
        getRecords(0, searchKeyword, event.target.value);
    };

    const sortOptions = useMemo(() => [
        { label: "Onboarded Date", value: "O" },
        // { label: "Job End Date", value: "E" },
        { label: "Offboarded Date", value: "B" },
        { label: "Current Status", value: "C" },
    ], []);

    // useEffect(() => {
    //     const bodyElement = document.body;
    //     const htmlElement = document.documentElement;
    //     if (props?.isSideDrawer) {
    //         bodyElement.style.overflow = "hidden";
    //         htmlElement.style.overflow = "hidden";
    //     } else {
    //         bodyElement.style.overflow = "";
    //         htmlElement.style.overflow = "";
    //     }
    //     const handleResize = () => {
    //         setContainerHeight(window.innerHeight * 0.8);
    //     };
    //     window.addEventListener('resize', handleResize);
    //     return () => {
    //         window.removeEventListener('resize', handleResize);
    //     };
    // }, [props?.isSideDrawer]);
    useEffect(() => {
        const getProjectData = async () => {
            let query = "sortBy=O&page=0&size=10&";
            await AccessService.getProjectDetails(props.candidateId, query).then((response) => {
                if (response.length < 10) {
                    setLoadMore(false);
                    console.log("anytime coming");
                } else {
                    setLoadMore(true);
                    console.log("anytime coming");
                }
                setProjectDetails(response);
                setProjectLoader(false);
            }).catch(() => {
                setProjectLoader(false);
            });
        };
        getProjectData();
    }, []);

    const getRecords = (pgNo, search, sortValue) => {
        setIsLoading(true);
        setPageNumber(pgNo);
        let query = "";
        if (sortValue) {
            query += `sortBy=${sortValue}&`;
        } else {
            query += `sortBy=${sort}&`;
        }
        if (pgNo) {
            query += `page=${pgNo}&size=10&`;
        } else {
            query += `page=0&size=10&`;
        }
        if (search && searchKeyword !== "") {
            query += `keyword=${search}`;
        }
        if (pgNo == 0) {
            projectDetailRef?.current?.scrollIntoView();
        }
        setPreviouslySearchKeyword(search);
        AccessService.getProjectDetails(props.candidateId, query).then((response) => {
            if (response.length < 10) {
                setLoadMore(false);
                console.log("anytime coming");
            } else {
                setLoadMore(true);
                console.log("anytime coming");
            }
            if (pgNo === 0) {
                setProjectDetails(response);
            } else {
                setProjectDetails((prevDetails) => [...prevDetails, ...response]);
            }
            setIsLoading(false);
        }).catch(() => {
            setIsLoading(false);
        });
    };

    // useEffect(() => {
    //     getRecords(0, searchKeyword);
    // }, [searchKeyword]);

    const fetchMoreData = () => {
        let pgNo = pageNumber + 1;
        getRecords(pgNo, searchKeyword);
    };
    return (
        <>
            {!projectLoader ? (
                <>
                    {loading && <Loader />}
                    <Grid>
                        {projectDetails.length > 0 ? (
                            <Grid>
                                <Grid className="d-flex justify-end mb-20">
                                    <Box
                                        ref={divRef}
                                        className="inputGroup navbarMargin d-flex align-center project-search"
                                    >
                                        {showSearch && (
                                            <TextField
                                                inputRef={inputRef}
                                                sx={{ minWidth: "320px" }}
                                                type="search"
                                                className="inputcontrasts position-issue postJob-search search-id new-search"
                                                variant="outlined"
                                                margin="normal"
                                                id="globalSearch"
                                                value={searchKeyword}
                                                placeholder="Search jobs"
                                                onKeyPress={(e) => {
                                                    handleSpaces(e);
                                                    handleEnterKey(e);
                                                }}
                                                onInput={handleEnterKey}
                                                onKeyDown={handleEnterKey}
                                                InputProps={{
                                                    autoComplete: "off",
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {searchKeyword !== "" && (
                                                                <ClearIcon
                                                                    onClick={() => {
                                                                        inputRef.current.value = "";
                                                                        clearSearch();
                                                                        setShowSearch(false);
                                                                    }}
                                                                    style={{ marginRight: "5px" }}
                                                                    aria-label="Clear icon"
                                                                    className="cursor-hover clear-icon-align"
                                                                />
                                                            )}
                                                            <Typography className="search-byId">
                                                                <Grid
                                                                    className="border-radius-6 d-flex align-center cursor-hover"
                                                                    style={{ padding: "12px" }}
                                                                    onClick={() => {
                                                                        if (
                                                                            searchKeyword !== "" &&
                                                                            searchKeyword !== previouslySearchKeyword
                                                                        ) {
                                                                            getRecords(0, searchKeyword);
                                                                        }
                                                                    }}
                                                                    aria-label="Search icon"
                                                                    disableRipple="true"
                                                                >
                                                                    <SearchIcon width="20px" aria-label="search icon" />
                                                                </Grid>
                                                            </Typography>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        )}
                                        {!showSearch && (
                                            <Typography className="search-byId cursor-hover">
                                                <Grid
                                                    className="bg-gray3 border-radius-6 d-flex align-center create-video-justify"
                                                    style={{ padding: "13px" }}
                                                    onClick={(e) => {
                                                        showSearchBar(e);
                                                    }}
                                                    aria-label="Search icon"
                                                    disableRipple="true"
                                                >
                                                    <SearchIcon width="20px" aria-label="search icon" />
                                                </Grid>
                                            </Typography>
                                        )}
                                    </Box>
                                    <Grid className="d-flex align-center bg-gray3 border-radius-6 pl-10 filter-responsive p-5 job-option-align ml-10">
                                        <Typography
                                            className="f-16 gray7 sortByDropdown matching-dropdown align-center cursor-hover"
                                            style={{ display: "contents" }}
                                            onClick={() => setOpenSortBy(!openSortBy)}
                                        >
                                            <span className="d-flex align-center">
                                                <img
                                                    width="20"
                                                    height="20"
                                                    src={sortByIcon}
                                                    alt="img"
                                                    className="mr-5"
                                                />
                                            </span>
                                            <Box>Sort by:</Box>
                                            <FormControl className="sortby-align relevance-align">
                                                <Select
                                                    open={openSortBy}
                                                    onClose={() => setOpenSortBy(false)}
                                                    inputProps={{
                                                        name: "age",
                                                        id: "uncontrolled-native",
                                                    }}
                                                    value={sort} // this is coming from state
                                                    onChange={handleSortBy} // we can setFruit directly here
                                                    className="pl-0 pr-0 bg-grey sort-responsive dropdownSort"
                                                >
                                                    {sortOptions.map((item) => {
                                                        return (
                                                            <MenuItem
                                                                className="relevance-align"
                                                                value={item.value}
                                                                key={item.value}
                                                            >
                                                                {item.label}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <div style={{ height: containerHeight, overflow: 'auto' }}>
                                    <InfiniteScroll
                                        dataLength={projectDetails.length}
                                        next={() => fetchMoreData()}
                                        hasMore={loadMore}
                                        //   loader={<Loader/>}
                                        inverse={false}
                                        className="hello"
                                        //   scrollableTarget="scrollableDiv"
                                        height={containerHeight}
                                    >
                                        <Grid className="matching-list-view" id="jobs" ref={projectDetailRef}>
                                            <TableContainer component={Paper} className="no-table-border">
                                                <Table
                                                    className="matching-table joblist-table mt-0"
                                                    aria-label="simple table"
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            {columns.map((column) => (
                                                                !(column.label === "Company Name" && projectDetails?.length > 0 && projectDetails[0]?.enterpriseName === null) && (
                                                                    <TableCell
                                                                        key={column.id}
                                                                        align={column.align}
                                                                        style={{ minWidth: column.minWidth }}
                                                                    >
                                                                        <Typography className="py-10 f-12 gray9 font-weight-500">
                                                                            {column.label}
                                                                        </Typography>
                                                                    </TableCell>
                                                                )
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {projectDetails?.map((candidate, index) => (
                                                            <TableRow key={index}>
                                                                {columns.map((column) => (
                                                                    !(column.label === "Company Name" && projectDetails[0]?.enterpriseName === null) && (
                                                                        <TableCell
                                                                            tabIndex={0}
                                                                            key={column.id}
                                                                            align={column.align}
                                                                        >
                                                                            <Grid>
                                                                                {column.id === "jobTitle" && (
                                                                                    <>
                                                                                        <Typography className="gray9 f-14 vertical-align-top">
                                                                                            {candidate?.jobTitle || "-"}
                                                                                        </Typography>
                                                                                        {candidate?.externalJobId &&
                                                                                            <Typography className="f-12 gray7 font-weight-400 d-flex align-center pt-5">
                                                                                                External Job ID: {candidate?.externalJobId}
                                                                                            </Typography>
                                                                                        }
                                                                                    </>
                                                                                )}
                                                                                {column.id === "jobReference" && (
                                                                                    <Typography className="gray9 f-14 vertical-align-top">
                                                                                        {candidate?.jobReferenceNumber || "-"}
                                                                                    </Typography>
                                                                                )}
                                                                                {column.id === "companyName" && (
                                                                                    <Typography className="gray9 f-14 vertical-align-top">
                                                                                        {candidate?.enterpriseName || "-"}
                                                                                    </Typography>
                                                                                )}
                                                                                {column.id === "onboardedDate" && (
                                                                                    <Typography className="gray9 f-14 vertical-align-top">
                                                                                        {candidate?.onboardedDate ? moment.utc(candidate.onboardedDate).format("MMM DD, YYYY") : "-"}
                                                                                    </Typography>
                                                                                )}
                                                                                {column.id === "duration" && (
                                                                                    <Typography className="gray9 f-14 vertical-align-top">
                                                                                        {candidate?.duration ? `${getDurationValue(candidate.duration)} ${getDurationPeriod(candidate.duration)}` : "-"}
                                                                                    </Typography>
                                                                                )}
                                                                                {column.id === "offboardedDate" && (
                                                                                    <Typography className="gray9 f-14 vertical-align-top">
                                                                                        {candidate?.offboardedDate ? moment.utc(candidate.offboardedDate).format("MMM DD, YYYY") : "-"}
                                                                                    </Typography>
                                                                                )}
                                                                                {column.id === "currentStatus" && (
                                                                                    <Typography className="gray9 f-14 vertical-align-top">
                                                                                        {candidate?.currentStatus || "-"}
                                                                                    </Typography>
                                                                                )}
                                                                            </Grid>
                                                                        </TableCell>
                                                                    )
                                                                ))}
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </InfiniteScroll>
                                </div>
                            </Grid>
                        ) : (
                            <Grid>
                                {searchKeyword != "" &&
                                    <Grid className="d-flex justify-end">
                                        <Box
                                            ref={divRef}
                                            className="inputGroup navbarMargin d-flex align-center project-search"
                                        >
                                            {showSearch && (
                                                <TextField
                                                    inputRef={inputRef}
                                                    sx={{ minWidth: "320px" }}
                                                    type="search"
                                                    className="inputcontrasts position-issue postJob-search search-id new-search"
                                                    variant="outlined"
                                                    margin="normal"
                                                    id="globalSearch"
                                                    value={searchKeyword}
                                                    placeholder="Search jobs"
                                                    onKeyPress={(e) => {
                                                        handleSpaces(e);
                                                        handleEnterKey(e);
                                                    }}
                                                    onInput={handleEnterKey}
                                                    onKeyDown={handleEnterKey}
                                                    InputProps={{
                                                        autoComplete: "off",
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                {searchKeyword !== "" && (
                                                                    <ClearIcon
                                                                        onClick={() => {
                                                                            inputRef.current.value = "";
                                                                            clearSearch();
                                                                            setShowSearch(false);
                                                                        }}
                                                                        style={{ marginRight: "5px" }}
                                                                        aria-label="Clear icon"
                                                                        className="cursor-hover clear-icon-align"
                                                                    />
                                                                )}
                                                                <Typography className="search-byId">
                                                                    <Grid
                                                                        className="border-radius-6 d-flex align-center cursor-hover"
                                                                        style={{ padding: "12px" }}
                                                                        onClick={() => {
                                                                            if (
                                                                                searchKeyword !== "" &&
                                                                                searchKeyword !== previouslySearchKeyword
                                                                            ) {
                                                                                getRecords(0, searchKeyword);
                                                                            }
                                                                        }}
                                                                        aria-label="Search icon"
                                                                        disableRipple="true"
                                                                    >
                                                                        <SearchIcon width="20px" aria-label="search icon" />
                                                                    </Grid>
                                                                </Typography>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                            {!showSearch && (
                                                <Typography className="search-byId cursor-hover">
                                                    <Grid
                                                        className="bg-gray3 border-radius-6 d-flex align-center create-video-justify"
                                                        style={{ padding: "13px" }}
                                                        onClick={(e) => {
                                                            showSearchBar(e);
                                                        }}
                                                        aria-label="Search icon"
                                                        disableRipple="true"
                                                    >
                                                        <SearchIcon width="20px" aria-label="search icon" />
                                                    </Grid>
                                                </Typography>
                                            )}
                                        </Box>
                                    </Grid>}
                                <Grid container item spacing={1} lg={12} md={12} xs={12}>
                                    <Grid item lg={6} md={6} xs={12} className="m-auto pt-0">
                                        <Typography className="text-center" sx={{ mt: 4 }}>
                                            <Box>
                                                <NoProject width="250px" height="250px" className="object-fit" alt="img" />
                                            </Box>
                                            <Box className="gray10 font-weight-500 f-16">
                                                {searchKeyword != "" ? "No project details found" : "No project details available"}
                                            </Box>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </>
            ) : (
                <Loader />
            )}
        </>
    );
}
