import React, { lazy, useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { AppContext } from "../../contexts/context";
import { getFromLocalStorage } from "../../helpers/helper";
import LinearProgress from "@mui/material/LinearProgress";
import EnterprisePortalLayout from "../../layout/EnterprisePortalLayout";
import AssessmentQuestions from "./Project/Assessment/AssessmentQuestion";
import SideBar from "../SideMenu/SideBar";
import MicrosoftToken from "./MicrosoftToken";
import DomainRequestApprovedPage from "../PublicPortal/DomainRequestApprovedPage.js";
import { useDispatch, useSelector } from "react-redux";
import { getPageNotFoundRecode } from "../../redux/pageNotFoundReducer.js";
const EditProfile = lazy(() =>
  import("./Project/Profile/EditProfile")
);
const TalentPoolCandidateCampaignList = lazy(() => import("./TalentPool/TalentPoolCandidateCampaignList"));
const ScheduleInterview = lazy(() => import("./JobSearch/ScheduleInterview"));
const MessageList = lazy(() => import("./Messaging/MessageList"));
const CandidateList = lazy(() => import("./Project/CandidateList"));
const CreateProject = lazy(() => import("./Project/CreateProject"));
const CreateProjectPreview = lazy(() =>
  import("./Project/CreateProjectPreview")
);
const SuccessPage = lazy(() => import("./Project/SuccessPage"));
const JobList = lazy(() => import("./JobSearch/JobList"));
const JobDetails = lazy(() => import("./JobSearch/JobDetails"));
const AdminAccess = lazy(() => import("./AdminAccessPage"));
const Access = lazy(() => import("./Access/EnterpriseAccess"));
const MatchingCandidate = lazy(() => import("./Project/MatchingCandidate"));
const MatchingCandidateDescription = lazy(() =>
  import("./Project/MatchingCandidateDescription")
);
const CampaignList = lazy(() => import("./EmailCampaign/CampaignLists"));
const InterviewList = lazy(() => import("./Interviews/InterviewList"));
const InterviewDetails = lazy(() => import("./Interviews/InterviewDetails"));
const EditCampaign = lazy(() => import("./EmailCampaign/CreateCampaignNew"));
const CreateCampaign = lazy(() => import("./EmailCampaign/CreateCampaignNew"));
const CampaignDetail = lazy(() => import("./EmailCampaign/CampaignDetails"));
const NotificationList = lazy(() => import("./Notification/NotificationList"));
const Analytics = lazy(() => import("./Analytics"));
const EnterpriseCandidateDescription = lazy(() =>
  import("./Project/EnterpriseCandidateDescription")
);
const NotFound = React.lazy(() => import("../../PageNotFound.jsx"));

const AddChecklist = React.lazy(() =>
  import("./Project/Checklist/AddCheckList")
);
const MyCandidateList = lazy(() => import("./MyCandidate/MyCandidateList"));

const BlukJobsLog = lazy(() => import("./JobSearch/BlukJobsLog"));
const TalentPoolManagment = lazy(() =>
  import("./TalentPool/TalentPoolManagement")
);
const TalentPoolCampaigning = lazy(() =>
  import("./TalentPool/TalentPoolCampaigning")
);
const CreateTalentPool = lazy(() => import("./TalentPool/CreateTalentPool"));
const TalentPoolCandidateList = lazy(() =>
  import("./TalentPool/TalentPoolCandidateList")
);
const EditTalentPool = lazy(() => import("./TalentPool/EditTalentPool"));
const LeadsTalentPoolList = lazy(() =>
  import("./TalentPool/LeadsTalentPoolList")
);
const TalentConsole = lazy(() =>
  import("./TalentConsole/TalentConsole")
);
const TalentConsoleCandidates = lazy(() =>
  import("./TalentConsole/TalentConsoleCandidates")
);
const JsonPreviewPage = lazy(() => import("./JobSearch/LogsEditPage"));
const SettingMenu = React.lazy(() => import("./Settings/SettingSideMenu"));
const JobWizad = React.lazy(() => import("./JobSearch/CreateJobViaWizard"));
const CandidateTalentPoolRequest = lazy(() => import("./TalentPool/CandidateTalentPoolRequest"));
const QRCode = lazy(() => import("./../PublicPortal/QRCode"));
const MyReferrals = React.lazy(() => import("./Referrals/MyReferrals.js"));
const Menus = require("../../menu-control");

const EnterprisePortal = () => {
  const [profileImage, setProfileImage] = useState(null);
  const dispatch = useDispatch();
  const pageNotFoundError = useSelector((state) => state.pageNotFound.error);

  const profileImageChange = (image) => {
    setProfileImage(image);
  };
  const [enterpriseName, setEnterpriseName] = React.useState("");
  const [fullname, setFullname] = useState(getFromLocalStorage("fullname"));
  const [profileTitle, setProfileTitle] = useState(
    getFromLocalStorage("profileTitle")
  );
  const [superAdminEntityObj, setSuperAdminEntityObj] = useState({});

  // var picture = (profileImage === 'null') ? "https://daas-prog-test.s3.amazonaws.com/default-user.png" : profileImage;
  var picture = profileImage;
  const [profilePicture, setProfilePicture] = useState(picture);
  const [navbarLogo, setNavbarLogo] = useState(
    getFromLocalStorage("navbarLogo")
  );
  useEffect(()=>{
    if (pageNotFoundError) {
      dispatch(getPageNotFoundRecode(false));
    }
  }, [window.location.pathname]);
  return (
    <>
      <AppContext.Provider
        value={{
          fullname,
          setFullname,
          setProfileTitle,
          profilePicture,
          setProfilePicture,
          navbarLogo,
          setNavbarLogo,
          setSuperAdminEntityObj,
          superAdminEntityObj
        }}
      >
        <Routes>
        <Route path="*" element={<NotFound isAuthenticated = {true}/>} />
          <Route
            path="/"
            element={pageNotFoundError ? <NotFound isAuthenticated = {true}/> : <EnterprisePortalLayout profileImage={profileImage} />}
          >
            <Route
              path="/postJob"
              element={
                <React.Suspense fallback="">
                  <CreateProject />
                </React.Suspense>
              }
            />
            <Route
              path="/jobWizard"
              element={
                <React.Suspense fallback="">
                  <JobWizad />
                </React.Suspense>
              }
            />
            <Route
              path="/screeningQuestions"
              element={
                <React.Suspense fallback="">
                  <AssessmentQuestions />
                </React.Suspense>
              }
            />
            <Route
              path="/previewJob"
              element={
                <React.Suspense fallback="">
                  <CreateProjectPreview />
                </React.Suspense>
              }
            />
            <Route
              path="/referrals"
              element={
                <React.Suspense fallback="">
                  <MyReferrals/>
                </React.Suspense>
              }
            />
            <Route
                path="/callback"
                element={
                  <React.Suspense fallback="">
                    <MicrosoftToken />
                  </React.Suspense>
                }
              />
            <Route
              path="/success"
              element={
                <React.Suspense fallback="">
                  <SuccessPage />
                </React.Suspense>
              }
            />

            <Route
              path="/JobList"
              element={
                <React.Suspense fallback={<></>}>
                  <JobList />
                </React.Suspense>
              }
            />
            <Route
              path="/Analytics"
              element={
                <React.Suspense fallback={<></>}>
                  <Analytics />
                </React.Suspense>
              }
            />
            <Route
              path="/CandidateList"
              element={
                <React.Suspense fallback="">
                  <CandidateList />
                </React.Suspense>
              }
            />
            <Route
              path="/jobs/:job_id/matching-candidates"
              element={
                <React.Suspense fallback="">
                  <MatchingCandidate />
                </React.Suspense>
              }
            />
            <Route
              path="/matching-candidate-description/:job_id/:candidate_id/:status"
              element={
                <React.Suspense fallback="">
                  <MatchingCandidateDescription />
                </React.Suspense>
              }
            />
            {/* <Route
              path="/sso-signup"
              element={
                <React.Suspense fallback="">
                  <SsoSignup />
                </React.Suspense>
              }
            /> */}
            <Route
              path="/candidate-detail/:candidate_id"
              element={
                <React.Suspense fallback="">
                  <EnterpriseCandidateDescription />
                </React.Suspense>
              }
            />
            <Route
              path="/my-candidate/candidate-detail/:candidate_id"
              element={
                <React.Suspense fallback="">
                  <EnterpriseCandidateDescription />
                </React.Suspense>
              }
            />
            <Route
              path="/access"
              element={
                <React.Suspense fallback={<></>}>
                  <Access profileImageChange={profileImageChange} />
                </React.Suspense>
              }
            />
            <Route
              path="/admin-access"
              element={
                <React.Suspense fallback={<></>}>
                  <AdminAccess />
                </React.Suspense>
              }
            />
            <Route
              path="/interviewList"
              element={
                <React.Suspense fallback={<></>}>
                  <AuthGuardForInterviews redirectTo={localStorage.getItem("userType") === "A" ? "/enterprise/access" : "/enterprise/jobList"}>
                    <InterviewList />
                  </AuthGuardForInterviews>
                </React.Suspense>
              }
            />
            <Route
              path="/interviewList/interview-details/:id/:roundNumber"
              element={
                <React.Suspense fallback={<></>}>
                  <AuthGuardForInterviews redirectTo={localStorage.getItem("userType") === "A" ? "/enterprise/access" : "/enterprise/jobList"}>
                    <InterviewDetails />
                  </AuthGuardForInterviews>
                </React.Suspense>
              }
            />
            <Route
              path="/jobs/interviews/interview-details/:id/:roundNumber"
              element={
                <React.Suspense fallback={<></>}>
                  <AuthGuardForInterviews redirectTo={localStorage.getItem("userType") === "A" ? "/enterprise/access" : "/enterprise/jobList"}>
                    <InterviewDetails />
                  </AuthGuardForInterviews>
                </React.Suspense>
              }
            />
            <Route
              path="/campaignList"
              element={
                <React.Suspense fallback={<></>}>
                  <CampaignList />
                </React.Suspense>
              }
            />
            <Route
              path="/createCampaign"
              element={
                <React.Suspense fallback={<></>}>
                  <CreateCampaign />
                </React.Suspense>
              }
            />
            <Route
              path="/edit-campaign"
              element={
                <React.Suspense fallback={<></>}>
                  <EditCampaign />
                </React.Suspense>
              }
            />
            <Route
              path="/campaignDetail"
              element={
                <React.Suspense fallback={<></>}>
                  <CampaignDetail />
                </React.Suspense>
              }
            />
            <Route
              path="/notification"
              element={
                <React.Suspense fallback={<></>}>
                  <NotificationList />
                </React.Suspense>
              }
            />
            <Route
              path="/message-list"
              element={
                <React.Suspense fallback={<></>}>
                  <MessageList />
                </React.Suspense>
              }
            />
            <Route
              path="/add-checklist"
              element={
                <React.Suspense fallback="">
                  <AddChecklist />
                </React.Suspense>
              }
            />
            <Route
              path="/sideMenu"
              element={
                <React.Suspense fallback={<></>}>
                  <SideBar />
                </React.Suspense>
              }
            />
            <Route
              path="/jobsLog"
              element={
                <React.Suspense fallback={<></>}>
                  <BlukJobsLog />
                </React.Suspense>
              }
            />
            <Route
              path="my-candidate"
              element={
                <React.Suspense fallback={<></>}>
                  <MyCandidateList />
                </React.Suspense>
              }
            />
            <Route
              path="/talent-pool-management"
              element={
                <React.Suspense fallback={<></>}>
                  <TalentPoolManagment />
                </React.Suspense>
              }
            />
            <Route
              path="/talent-pool-campaign"
              element={
                <React.Suspense fallback={<></>}>
                  <TalentPoolCandidateCampaignList/>
                </React.Suspense>
              }
            />
            <Route
              path="/create-talent-pool"
              element={
                <React.Suspense fallback={<></>}>
                  <CreateTalentPool />
                </React.Suspense>
              }
            />
            <Route
              path="/create-talent-pool/:id"
              element={
                <React.Suspense fallback={<></>}>
                  <CreateTalentPool />
                </React.Suspense>
              }
            />
            <Route
              path="/talent-pool-candidate-list"
              element={
                <React.Suspense fallback={<></>}>
                  <TalentPoolCandidateList />
                </React.Suspense>
              }
            />
            <Route
              path="/talent-pool/:id"
              element={
                <React.Suspense fallback={<></>}>
                  <EditTalentPool />
                </React.Suspense>
              }
            />
            <Route
              path="/talent-pool-request/:id"
              element={
                <React.Suspense fallback={<></>}>
                  <CandidateTalentPoolRequest />
                </React.Suspense>
              }
            />
            <Route
              path="/leads-talent-pool-list/:id"
              element={
                <React.Suspense fallback={<></>}>
                  <LeadsTalentPoolList />
                </React.Suspense>
              }
            />
            <Route
              path="/my-candidate/leads-talent-pool-list/:id"
              element={
                <React.Suspense fallback={<></>}>
                  <LeadsTalentPoolList />
                </React.Suspense>
              }
            />
            <Route
              path="/talent-pool/:id/candidate-detail/:candidate_id"
              element={
                <React.Suspense fallback="">
                  <EnterpriseCandidateDescription />
                </React.Suspense>
              }
            />
            <Route
              path="/talent-pool-candidate-list/candidate-detail/:candidate_id"
              element={
                <React.Suspense fallback="">
                  <EnterpriseCandidateDescription />
                </React.Suspense>
              }
            />
            <Route
              path="/talent-console"
              element={
                <React.Suspense fallback={<></>}>
                  <TalentConsole />
                </React.Suspense>
              }
            />
            <Route
              path="/talent-console-candidates"
              element={
                <React.Suspense fallback={<></>}>
                  <TalentConsoleCandidates />
                </React.Suspense>
              }
            />
            <Route
              path="/edit_logs"
              element={
                <React.Suspense fallback={<></>}>
                  <JsonPreviewPage />
                </React.Suspense>
              }
            />
            <Route
              path="/settings"
              element={
                <React.Suspense fallback={<></>}>
                  <SettingMenu />
                </React.Suspense>
              }
            />
            <Route
              path="/schedule-interviews/:jobId/:candidateId"
              element={
                <React.Suspense fallback="">
                  <AuthGuardForInterviews redirectTo={localStorage.getItem("userType") === "A" ? "/enterprise/access" : "/enterprise/jobList"}>
                    <ScheduleInterview />
                  </AuthGuardForInterviews>
                </React.Suspense>
              }
            />
            <Route
              path="/schedule-interview/:jobId/:candidateId"
              element={
                <React.Suspense fallback="">
                  <AuthGuardForInterviews redirectTo={localStorage.getItem("userType") === "A" ? "/enterprise/access" : "/enterprise/jobList"}>
                    <ScheduleInterview />
                  </AuthGuardForInterviews>
                </React.Suspense>
              }
            />
            <Route
                path="/qr"
                element={
                  <React.Suspense fallback="">
                    <QRCode />
                  </React.Suspense>
                }
            />
            <Route
              path="/domain-approval"
              element={
                <React.Suspense fallback={<></>}>
                  <DomainRequestApprovedPage />
                </React.Suspense>
              }
            />
            <Route
              path="/edit-candidate-details/:candidate_id"
              element={
                <React.Suspense fallback="">
                  <EditProfile />
                </React.Suspense>
              }
            />
          </Route>
        </Routes>
      </AppContext.Provider>
    </>
  );
};

export default EnterprisePortal;

function AuthGuardForInterviews({ children, redirectTo }) {
  const enterpriseDetails = useSelector((state) => state.enterprise.enterprise);
  if (!enterpriseDetails?.interviewConfigurations || (localStorage.getItem("userType") != null && localStorage.getItem("userType") != "" && !Menus?.interviews?.includes(localStorage.getItem("userType")))) {
    return <Navigate to={redirectTo} />;
  } else {
    return children;
  }
}
