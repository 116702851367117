import React, { useState, useEffect } from "react";
import { Grid, Typography, Breadcrumbs, Select, MenuItem, Box, Button, TextField } from "@mui/material";
import cathLabHTMLForm from "../../../../assets/Forms/checklistCathLab.txt";
import MRITechnicianForm from "../../../../assets/Forms/checklistAdministrativeAssistant.txt";
import { getSkillChecklist } from "../../../../helpers/helper-data";

export default function ChecklistForm(props) {
    const { selectedCandidateChecklist } = props;
    const [template, setTemplate] = React.useState('');
    const [skillChecklistSelected, setSkillChecklistSelected] = useState(selectedCandidateChecklist.checkList.id);
console.log(skillChecklistSelected, 'skillChecklistSelected');
    useEffect(()=>{
        if (skillChecklistSelected) {
        // fetch(skillChecklistSelected === 2 ?'https://raw.githubusercontent.com/Roopesh-prog/Test/main/checklistMRITechnicalAssessment.html':'https://raw.githubusercontent.com/Roopesh-prog/Test/main/checklistCathLab.html')
        setTemplate(selectedCandidateChecklist.response);
}
      }, []);

      useEffect(() => {
        if ( template ) {
            let formElements = document.forms[document.forms.length-1]?.elements;
            for (let i = 0; i < formElements?.length; i++) {
                formElements[i].disabled = true;
                formElements[i].style.cursor = 'default';
                if (formElements[i].nextElementSibling) {
                    formElements[i].nextElementSibling.style.cursor = 'default';
                }
            }
        }
      }, [template]);
      function fill(a, editChecklist) {
        if (!editChecklist) {
            let formElements = document.forms[document.forms.length-1].elements;
            for (let i = 0; i < formElements.length; i++) {
                formElements[i].disabled = true;
            }
        }
        for (var k in a) {
            if (a[k]) {
            const formFields = document.getElementsByName(k);
            if (formFields.length === 1) {
                formFields[0].value = a[k];
            } else {
                formFields.forEach((checkbox, i) => {
                if (typeof(a[k]) === "string") {
                    if (a[k] === formFields[i].value) {
                        checkbox.checked = true;
                    }
                } else {
                    a[k].forEach((value) => {
                        if (value === formFields[i].value) {
                            checkbox.checked = true;
                        }
                    });
                }
            });
            }
            }
        }
    }
    return (
        <>
      <div id="form-response" dangerouslySetInnerHTML={{ __html: template }} />
      </>
    );
}
