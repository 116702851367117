import { Tooltip } from "@mui/material";
import moment from "moment/moment";
import React, { useState } from "react";
import { ReactComponent as OccupiedIcon } from "../../assets/images/occupied-calendar.svg";
import { formatDate } from "../../helpers/helper-data";
import { useSelector } from "react-redux";
import MatchingCandidateListingDrawer from "../../Views/EnterprisePortal/Project/MatchingCandidateDescriptionDrawer";
import EnterpriseCandidateDescriptionDrawer from "../../Views/EnterprisePortal/Project/EnterpriseCandidateDescriptionDrawer";
export default function AvailabilityStatusLabels(props) {
  const enterpriseDetail = useSelector((state) => state.enterprise);
  const [canduadteDrawer, setCanduadteDrawer] = useState(false);
  const [optionValue, setOptionValue] = useState("");
  const availabilityStatusLabels = JSON.parse(
    enterpriseDetail?.enterprise?.availabilityStatusLabels
  );
  const { candidate } = props;
  const handleOpenProjectDetails = (e) => {
    if (props.isFromMatchingDrawer) {
      props.goToProjectTab(e);
    } else {
      e.stopPropagation();
      e.preventDefault();
      setOptionValue("ProjectDetails");
      setCanduadteDrawer(true);
    }
  };
  return (
    <>
      {candidate?.availableDate !== null &&
        candidate?.availableDate != undefined && (
          <Tooltip
            placement="top"
            trigger="mouseenter"
            animation="fade"
            arrow
            title={availabilityStatusLabels?.statusO?.tooltip}
          >
            <span
              className="occupied-status word-break cursor-hover min-w-100"
              onClick={(e)=>handleOpenProjectDetails(e)}
              onKeyPress={(e)=>handleOpenProjectDetails(e)}
              tabIndex={0}
              style={{
                backgroundColor: `${availabilityStatusLabels?.statusO?.bgColor}`,
                color: availabilityStatusLabels?.statusO?.textColor,
              }}
            >
              {availabilityStatusLabels?.statusO?.value}
              {/* <OccupiedIcon className="pl-5" width={"18px"} /> */}
            </span>
          </Tooltip>
        )}
      {candidate?.availableDate === null && (
        <>
          {candidate?.inCandidatePool === true &&
          !(
            availabilityStatusLabels?.statusA.tooltip == "" ||
            availabilityStatusLabels?.statusA.tooltip == null ||
            availabilityStatusLabels?.statusA.tooltip.trim().length === 0
          ) ? (
            <Tooltip
              placement="top"
              trigger="mouseenter"
              animation="fade"
              arrow
              title={`${availabilityStatusLabels?.statusA.tooltip}`}
            >
              <span
                className="available-status cursor-hover min-w-content"
                onClick={(e)=>handleOpenProjectDetails(e)}
                onKeyPress={(e)=>handleOpenProjectDetails(e)}
                tabIndex={0}
                style={{
                  backgroundColor: `${availabilityStatusLabels?.statusA?.bgColor}`,
                  color: availabilityStatusLabels?.statusA?.textColor,
                }}
              >
                {availabilityStatusLabels?.statusA?.value}
              </span>
            </Tooltip>
          ) : candidate?.inCandidatePool === true ? (
            <span
              className="available-status cursor-hover min-w-content"
              onClick={(e)=>handleOpenProjectDetails(e)}
              onKeyPress={(e)=>handleOpenProjectDetails(e)}
              tabIndex={0}
              style={{
                backgroundColor: `${availabilityStatusLabels?.statusA?.bgColor}`,
                color: availabilityStatusLabels?.statusA?.textColor,
              }}
            >
              {availabilityStatusLabels?.statusA?.value}
            </span>
          ) : (
            ""
          )}
          {candidate?.inCandidatePool === false &&
          !(
            availabilityStatusLabels?.statusM.tooltip == "" ||
            availabilityStatusLabels?.statusM.tooltip == null ||
            availabilityStatusLabels?.statusM.tooltip.trim().length === 0
          ) ? (
            <Tooltip
              placement="top"
              trigger="mouseenter"
              animation="fade"
              arrow
              title={`${availabilityStatusLabels?.statusM.tooltip}`}
            >
              <span
                className="maybe-available-status cursor-hover min-w-content"
                onClick={(e)=>handleOpenProjectDetails(e)}
                onKeyPress={(e)=>handleOpenProjectDetails(e)}
                tabIndex={0}
                style={{
                  backgroundColor: `${availabilityStatusLabels?.statusM?.bgColor}`,
                  color: availabilityStatusLabels?.statusM?.textColor,
                }}
              >
                {availabilityStatusLabels?.statusM?.value}
              </span>
            </Tooltip>
          ) : candidate?.inCandidatePool === false ? (
            <span
              className="maybe-available-status cursor-hover min-w-content"
              onClick={(e)=>handleOpenProjectDetails(e)}
              onKeyPress={(e)=>handleOpenProjectDetails(e)}
              tabIndex={0}
              style={{
                backgroundColor: `${availabilityStatusLabels?.statusM?.bgColor}`,
                color: availabilityStatusLabels?.statusM?.textColor,
              }}
            >
              {availabilityStatusLabels?.statusM?.value}
            </span>
          ) : (
            ""
          )}
        </>
      )}
      {props.isFromMatchingCandidate ? <>
        {optionValue && (
          <MatchingCandidateListingDrawer
            // candidate={props.candidate}
            setIsLoading={props.setIsLoading}
            canduadteDrawer={canduadteDrawer}
            setCanduadteDrawer={setCanduadteDrawer}
            candidateId={props?.candidate?.id ? props?.candidate?.id : props?.candidate?.candidateId}
            JobId={props.JobId}
            status={props.status}
            getCandidatesByStatus={props.getCandidatesByStatus}
            getMatchingCandidates={props.getMatchingCandidates}
            setJobStatistics={props.setJobStatistics}
            updatedstatus={props.updatedstatus}
            jobStatus={props.jobStatus}
            optionValue={optionValue}
          />
        )}
      </> : <>
        {optionValue && (
          <EnterpriseCandidateDescriptionDrawer
            // candidate={user}
            candidateProfileDrawer={canduadteDrawer}
            setCandidateProfileDrawer={setCanduadteDrawer}
            setCandidateListId={props.setCandidateListId}
            setIsLoading={props.setIsLoading}
            candidate_id={props.candidate.candidateId ? props.candidate.candidateId : props.candidate.id}
            optionValue={optionValue}
            {...(props.isFromMyCandidates !== undefined && props.isFromMyCandidates !== false ?
              { isFromMyCandidates: props.isFromMyCandidates } : {})}
            {...(props.talentPool !== undefined && props.talentPool !== "" && props.talentPool !== false ?
              { talentPool: props.talentPool } : {})}
            {...(props.searchDate !== undefined && props.searchDate !== "" && props.searchDate !== false ?
              { searchDate: props.searchDate } : {})}
          />
        )}
      </>
      }
    </>
  );
}
