import React, { useState } from "react";
import { Typography, Box, Popover, Grid } from "@mui/material";
import { functiondisable, functionenable } from "../../../../helpers/helper";
import CloseIcon from "@mui/icons-material/Close";

const styles = {
  skills: {
    background: "#F2F4F7",
    borderRadius: "20px",
    padding: "4px 12px",
    margin: "7px 4px 4px 0px",
    color: "#363F72",
    fontWeight: "500",
    display: "inline-block",
  },
};

function RenderSkills(props) {
  const { children, value, index, ...other } = props;

  const skills = props.skills;
  const experience = props.experience;
  const [viewMoreSkills, setViewMoreSkills] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleViewAllSkills = (event) => {
    setViewMoreSkills(!viewMoreSkills);
    setAnchorEl(event.currentTarget);
    functiondisable();
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
    setViewMoreSkills(!viewMoreSkills);
    functionenable();
  };

  return (
    <>
      {/*    { skills?.length > 0 &&
                <Typography>
                    <Box className="mt-5">
                        { skills?.map((skill, index) => (

                            <span key={index} className="skills">{skill.skills.keyword}</span>

                        ))}
                    </Box>
                </Typography>
            }*/}

      {/* {viewMoreSkills
        ? (() => {
            if (skills && skills.length > 0) {
              return skills.map((skill, index) => (
                <span className="skills" key={index}>{skill.skills.keyword}</span>
              ));
            } else {
              return "-";
            }
          })() */}
      {(() => {
        if (skills && skills.length > 0) {
          return skills.map((skill, index) => {
            return index < 6 ? (
              <span key={index} className="f-14" style={styles.skills}>
                {skill.skills.keyword}
              </span>
            ) : null;
          });
        } else {
          return "-";
        }
      })()}

      <Typography>
        {skills?.length > 6 && (
          <span
            className="link-color cursor-hover text-decoration-none d-inline-flex f-12 font-weight-600"
            onClick={(e) => handleViewAllSkills(e)}
            tabIndex={0}
            onKeyPress={(e) => handleViewAllSkills(e)}
          >
            {`+${skills?.length - 6} more`}
          </span>
        )}
      </Typography>
      <Popover
        id="simple-popover-list"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <Grid item sx={{ p: 1 }}>
          <Box className="align-between mb-5">
            <Grid item xs={12}>
              <Typography
                id="modal-title"
                className="d-flex align-between p-10"
              >
                <Typography variant="h5" className="f-16 font-weight-600 heading-text-color">
                  Skills
                </Typography>
                <Typography
                  align="right"
                  className="cursor-hover sub-text-color icon-button"
                >
                  <CloseIcon onClick={handleClosePopover} onKeyPress={handleClosePopover} aria-label="Close icon" tabIndex={0} />
                </Typography>
              </Typography>
            </Grid>
          </Box>
          <Grid item
            className="p-10"
            style={{ maxHeight: "250px", overflow: "auto", maxWidth: "300px" }}
          >
            <Box>
              {skills?.map((skill, index) => {
                return (
                  <span key={index} className="f-14" style={styles.skills}>
                    {skill.skills.keyword}
                  </span>
                );
              })}
            </Box>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
}

export default function Skills(props) {
  const skills = props.skills;

  return (
    <>
      <Typography
        className="font-weight-600 f-16 gray9 border-bottom-1 pb-10"
      >
        Skills
      </Typography>
      <Typography className="pt-10">
        <RenderSkills skills={skills} />
      </Typography>
    </>
  );
}
