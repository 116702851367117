import * as React from "react";
import { Box, Button, Checkbox, FormControlLabel, Input, Modal, Radio, RadioGroup, TextField, TextareaAutosize, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState, useEffect } from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ClearIcon from "@mui/icons-material/Clear";
import calenderIcon from "../../assets/images/assessment-calender.svg";
import { handleSpaces } from "../../helpers/helper-data";
import ApiCalls from "../../api/customAxios";
import moment from "moment";

export default function SubmitScreeningQuestionsModal(props) {
    const [questionsAnswers, setQuestionsAnswers] = useState();
    const [isValidQuestions, setIsValidQuestions] = useState(false);
    const [isShowQuesErrMsgs, setIsShowQuesErrMsgs] = useState(false);
    const [highlightQuestion, setHighlightQuestion] = useState(null);
    const [showHighlightQuestion, setShowHighlightQuestion] = useState(false);
    const [hiddenQuesCount, setHiddenQuesCount] = useState(0);
    const [hideDateClearIcon, setHideDateClearIcon] = useState({});
    const [openDatePicker, setOpenDatePicker] = useState({});
    const [jobType, setJobType] = useState();
    const [questionDate, setQuestionDate] = useState({});
    const [shortlistingRemarks, setShortlistingRemarks] = useState("");
    useEffect(async () => {
        if (!props.isFromDrawer) {
            if (props.openScreeningQuestionsModal) {
                document.body.style.overflow = "hidden";
                document.documentElement.style.overflow = "hidden";
            } else {
                document.body.style.overflow = "";
                document.documentElement.style.overflow = "";
            }
        }
        if (props.jobDetails?.externalJobId && props.openScreeningQuestionsModal && props.questionsAnswers && JSON.parse(props.questionsAnswers).length > 0) {
            props.setIsLoading(true);
            let screeningAnswers = [];
            if (props.tabValue != "matching-candidate") {
                await ApiCalls.get(`/enterprises/pool/get/pool/${props.jobDetails.id ? props.jobDetails.id : props.jobDetails.jobId}/${props.candidate?.id ? props.candidate?.id : props.candidate?.candidateId}`)
                .then((response)=>{
                    if (response.answers) {
                        screeningAnswers = JSON.parse(response.answers);
                    }
                }).catch((err)=>{});
            }
            setJobType();
            setQuestionDate({});
            setIsValidQuestions(false);
            setIsShowQuesErrMsgs(false);
            setHighlightQuestion(null);
            setShowHighlightQuestion(false);
            let _hiddenQuesCount = JSON.parse(props.questionsAnswers)?.filter(
                (question) =>
                question.Question === "What days of the week you are available?" ||
                question.Question === "For how many hours are you available per day?"
            )?.length;
            setHiddenQuesCount(_hiddenQuesCount);
            let _questionAnswers = [];
            JSON.parse(props.questionsAnswers)?.map((question, index)=>{
                let currentQuestion = screeningAnswers.filter((obj)=>(obj.key == question.key));
                if (currentQuestion.length && question.Type === "date") {
                    setQuestionDate({ ...questionDate, [index]: currentQuestion[0].Answer });
                }
                _questionAnswers.push({ ...question, answeredTime: (currentQuestion.length ? currentQuestion[0]?.answeredTime : "" ), Answer: (currentQuestion.length ? (question.Type == "checkbox" ? currentQuestion[0].Answer?.split(",") : currentQuestion[0].Answer) : ""), candidateAnswer: (currentQuestion.length ? currentQuestion[0].Answer : "") });
            });
            setQuestionsAnswers(_questionAnswers);
            let ques = JSON.parse(props.questionsAnswers);
            let hide_clear_icon = {};
            let open_date_picker = {};
            for (let i = 0; i < ques.length; i++) {
                hide_clear_icon[ques[i].key] = true;
                open_date_picker[ques[i].key] = false;
            }
            setHideDateClearIcon(hide_clear_icon);
            setOpenDatePicker(open_date_picker);
            props.setIsLoading(false);
        }
    }, [props.openScreeningQuestionsModal]);
    useEffect(() => {
        let _isValid = true;
        for (let index = 0; index < questionsAnswers?.length; index++) {
          if (questionsAnswers[index].required && (questionsAnswers[index].scope == "E" || questionsAnswers[index].scope == "B")) {
            if (
              jobType === "Full Time" &&
              (questionsAnswers[index].Question ===
                "What days of the week you are available?" ||
                questionsAnswers[index].Question ===
                  "For how many hours are you available per day?")
            ) {
              continue;
            }
            if (Array.isArray(questionsAnswers[index].Answer)) {
              if (questionsAnswers[index].Answer.length <= 0) {
                setHighlightQuestion(questionsAnswers[index].key);
                _isValid = false;
                break;
              } else {
                if (questionsAnswers[index].key === highlightQuestion) {
                  setShowHighlightQuestion(false);
                }
              }
            } else {
              if (!questionsAnswers[index].Answer) {
                setHighlightQuestion(questionsAnswers[index].key);
                _isValid = false;
                break;
              } else {
                if (questionsAnswers[index].key === highlightQuestion) {
                  setShowHighlightQuestion(false);
                }
              }
            }
          }
        }
        if (_isValid) {
          setHighlightQuestion(null);
        }
        setIsValidQuestions(_isValid);
    }, [questionsAnswers]);
    const handleCloseScreeningQuestionsModal = () => {
        props.setOpenScreeningQuestionsModal(false);
    };
    const showErrorMsg = (Question, key) => {
        if (
            jobType === "Full Time" &&
            (Question === "What days of the week you are available?" ||
                Question === "For how many hours are you available per day?")
        ) {
            return false;
        }
        let ques = questionsAnswers?.filter(
            (_question) =>
                _question.key == key &&
                (Array.isArray(_question.Answer)
                    ? _question.Answer.length > 0
                    : _question.Answer)
        );
        return ques[0] ? false : true;
    };

    function handleChange(event, question) {
        if (
            question.Question === "Select your availability" ||
            question.Question === "Are you looking for full-time or part-time?"
        ) {
            if (event.target.value === "Part Time") {
                // setQuestions(allQuestions);
                setJobType(event.target.value);
            } else {
                // setQuestions(tempQuestions);
                setJobType(event.target.value);
            }
        }
        setQuestionsAnswers(
            questionsAnswers.map((_question) => {
                return _question.key === question.key
                    ? _question?.key
                        ? {
                            ..._question,
                            Question: _question.Question,
                            Answer:
                                question.Question ===
                                    "For how many hours are you available per day?" &&
                                    event.target.value < 0
                                    ? ""
                                    : event.target.value,
                            required: _question.required,
                            key: _question?.key,
                        }
                        : {
                            ..._question,
                            Question: _question.Question,
                            Answer:
                                question.Question ===
                                    "For how many hours are you available per day?" &&
                                    event.target.value < 0
                                    ? ""
                                    : event.target.value,
                            required: _question.required,
                        }
                    : _question;
            })
        );
    }

    function handleChangeCheckbox(event, question, option) {
        var prevAnswers = questionsAnswers.find(
            (_question) => _question.key === question.key
        );

        var answersArray = [];
        if (event.target.checked === false) {
            if (prevAnswers?.Answer) {
                answersArray = prevAnswers?.Answer;
                answersArray.splice(answersArray.indexOf(option), 1);
            }
        }
        if (event.target.checked === true) {
            if (prevAnswers?.Answer) {
                answersArray = prevAnswers?.Answer;
                answersArray.push(option);
            } else {
                answersArray.push(option);
            }
        }

        setQuestionsAnswers(
            questionsAnswers.map((_question) => {
                return _question.key === question.key
                    ? _question?.key
                        ? {
                            ..._question,
                            Question: _question.Question,
                            Answer: answersArray,
                            required: _question.required,
                            key: _question?.key ? _question?.key : null,
                        }
                        : {
                            ..._question,
                            Question: _question.Question,
                            Answer: answersArray,
                            required: _question.required,
                        }
                    : _question;
            })
        );
    }
    const handleHideClearIcon = (question, value) => {
        let hide_clear_icon = { ...hideDateClearIcon };
        hide_clear_icon[question.key] = value;
        setHideDateClearIcon(hide_clear_icon);
    };
    const handleChangeDate = (date, question, index) => {
        // setQuestionDate({ ...questionDate, [index]: date });
        setQuestionsAnswers(
            questionsAnswers.map((_question) => {
                return _question.key === question.key
                    ? _question?.key
                        ? {
                            ..._question,
                            Question: _question.Question,
                            Answer: date,
                            required: _question.required,
                            key: _question?.key ? _question?.key : null,
                        }
                        : {
                            ..._question,
                            Question: _question.Question,
                            Answer: date,
                            required: _question.required,
                        }
                    : _question;
            })
        );
    };
    const handleChangeShortListRemarks = (event) => {
        setShortlistingRemarks(event.target.value);
    };
    const shortListHandler = () => {
        if (questionsAnswers && !isValidQuestions) {
            setIsShowQuesErrMsgs(true);
            document.getElementById(highlightQuestion)?.scrollIntoView();
            return;
          }
          props.setIsLoading(true);
          let formatedData = [];
          if (questionsAnswers) {
            questionsAnswers.forEach((question) => {
              if (
                question.Question === "What days of the week you are available?" ||
                question.Question === "For how many hours are you available per day?"
              ) {
                if (jobType !== "Full Time") {
                  formatedData.push(
                    question?.key
                      ? {
                          ...question,
                          key: question?.key ? question?.key : null,
                          Question: question.Question,
                          enterpriseResponse: ((question.scope == "E" || question.scope == "B") && question.Answer)
                            ? question.Type == "date" ? moment(question.Answer).format('MMM DD YYYY'):question.Answer.toString().trim()
                            : "",
                          Answer: question.candidateAnswer,
                        }
                      : {
                          ...question,
                          Question: question.Question,
                          enterpriseResponse: ((question.scope == "E" || question.scope == "B") && question.Answer)
                            ? question.Type == "date" ? moment(question.Answer).format('MMM DD YYYY'):question.Answer.toString().trim()
                            : "",
                          Answer: question.candidateAnswer,
                        }
                  );
                }
              } else {
                formatedData.push(
                  question?.key
                    ? {
                        ...question,
                        key: question?.key ? question?.key : null,
                        Question: question.Question,
                        enterpriseResponse: ((question.scope == "E" || question.scope == "B") && question.Answer)
                          ? question.Type == "date" ? moment(question.Answer).format('MMM DD YYYY'):question.Answer.toString().trim()
                          : "",
                        Answer: question.candidateAnswer,
                      }
                    : {
                        ...question,
                        Question: question.Question,
                        enterpriseResponse: ((question.scope == "E" || question.scope == "B") && question.Answer)
                          ? question.Type == "date" ? moment(question.Answer).format('MMM DD YYYY'):question.Answer.toString().trim()
                          : "",
                        Answer: question.candidateAnswer,
                      }
                );
              }
            });
          }
        props.shortListHandler(shortlistingRemarks, formatedData);
    };
    return (
        <Modal
            open={props.openScreeningQuestionsModal}
            onClose={handleCloseScreeningQuestionsModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="modal-content inputcontrasts">
                <Typography className="align-left">
                    <Typography id="modal-title" className="d-flex align-between">
                        <Typography variant="h5" className="f-20 font-weight-600 heading-text-color">
                            Shortlist Candidate
                        </Typography>
                        <Typography
                            align="right"
                            className="cursor-hover gray7 icon-button"
                        >
                            <CloseIcon
                                onClick={handleCloseScreeningQuestionsModal}
                                onKeyPress={handleCloseScreeningQuestionsModal}
                                aria-label="Close icon"
                                tabIndex={0}
                            />
                        </Typography>
                    </Typography>
                    <Typography id="modal-description">
                        <Box>
                            {questionsAnswers &&
                                questionsAnswers.map((question, index) => {
                                    return (
                                        <div key={index}>
                                            {question.Type === "date" && (
                                                <Typography id={question.key}>
                                                    <p className="f-14 gray9 m-b-0 font-weight-400 h-auto">
                                                        Q{index + 1}. {question.Question}{" "}
                                                        {(question.required && (question.scope == "E" || question.scope == "B")) ? (
                                                            <span className="text-black">*</span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </p>
                                                    <Typography
                                                        className={`display-flex justify-between p-10 m-t-5 form-control input-group no-wrap inputcontrasts ${question.required &&
                                                                isShowQuesErrMsgs &&
                                                                showHighlightQuestion &&
                                                                question.key === highlightQuestion
                                                                ? "danger-border"
                                                                : ""
                                                            } ${question.scope != "E" && question.scope != "B" ? "screeningQuesDisabled" : ""}`}
                                                        onMouseEnter={() => handleHideClearIcon(question, false)}
                                                        onMouseLeave={() => handleHideClearIcon(question, true)}
                                                    >
                                                        <MuiPickersUtilsProvider
                                                            utils={DateFnsUtils}
                                                            id="job-detail"
                                                        >
                                                            <DatePicker
                                                                className="sub-text-color f-16 font-weight-400 w-100 d-content select-date certificate-date"
                                                                placeholder="Select date"
                                                                open={openDatePicker[question.key] ? true : false}
                                                                onOpen={() => {
                                                                    let open_date_picker = { ...openDatePicker };
                                                                    open_date_picker[question.key] = true;
                                                                    setOpenDatePicker(open_date_picker);
                                                                }}
                                                                onClose={() => {
                                                                    let open_date_picker = { ...openDatePicker };
                                                                    open_date_picker[question.key] = false;
                                                                    setOpenDatePicker(open_date_picker);
                                                                }}
                                                                value={
                                                                    question.Answer
                                                                        ? question.Answer
                                                                        : null
                                                                }
                                                                onChange={(date) =>
                                                                    handleChangeDate(
                                                                        date,
                                                                        question,
                                                                        index
                                                                    )
                                                                }
                                                                format="MMM dd, yyyy"
                                                                minDate={(question.Question == "From when are you available for this job?") ? new Date() : "01-01-1970"}
                                                                emptyLabel=""
                                                                disabled={question.scope != "E" && question.scope != "B"}
                                                            />
                                                            <Box className="d-flex">
                                                                {question.Answer && !hideDateClearIcon[question.key] && (question.scope == "E" || question.scope == "B") ?
                                                                    <ClearIcon
                                                                        onClick={() => handleChangeDate(null, question, index)}
                                                                        aria-label="Clear icon"
                                                                        className="Clearicon-issue dateScreeningQuesClearIcon cursor-hover"
                                                                    /> : ""}
                                                                <img
                                                                    style={{ width: "15px" }}
                                                                    className={(question.scope == "E" || question.scope == "B") ? "cursor-hover" : ""}
                                                                    src={calenderIcon}
                                                                    alt="img"
                                                                    onClick={() => {
                                                                        if (question.scope == "E" || question.scope == "B") {
                                                                            let open_date_picker = { ...openDatePicker };
                                                                            open_date_picker[question.key] = true;
                                                                            setOpenDatePicker(open_date_picker);
                                                                        }
                                                                    }}
                                                                />
                                                            </Box>
                                                        </MuiPickersUtilsProvider>
                                                    </Typography>
                                                </Typography>
                                            )}
                                            {(jobType !== "Full Time" ||
                                                !jobType ||
                                                question.Question !==
                                                "What days of the week you are available?") &&
                                                question.Type === "checkbox" && (
                                                    <Typography
                                                        id={question.key}
                                                        className="mt-10 assessment-calender"
                                                    >
                                                        <p className="f-14 gray9 font-weight-400 mb-10 h-auto mt-20">
                                                            Q
                                                            {jobType === "Full Time" && index > 1
                                                                ? index + 1 - hiddenQuesCount
                                                                : index + 1}
                                                            . {question.Question}{" "}
                                                            {(question.required && (question.scope == "E" || question.scope == "B")) ? (
                                                                <span className="text-black">*</span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </p>
                                                        <Box
                                                            className={`m-t-5 file-upload question-asses ${question.required &&
                                                                    isShowQuesErrMsgs &&
                                                                    showHighlightQuestion &&
                                                                    question.key === highlightQuestion
                                                                    ? "danger-border"
                                                                    : ""
                                                                }`}
                                                        >
                                                            {question?.Options.map(
                                                                (option, index) => (
                                                                    <FormControlLabel
                                                                        key={index}
                                                                        value={option}
                                                                        onChange={(event) =>
                                                                            handleChangeCheckbox(
                                                                                event,
                                                                                question,
                                                                                option
                                                                            )
                                                                        }
                                                                        control={
                                                                            <Checkbox
                                                                                color="primary"
                                                                                checked={
                                                                                    question.Answer?.includes(
                                                                                        option
                                                                                    )
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                disabled={question.scope != "E" && question.scope != "B"}
                                                                            />
                                                                        }
                                                                        className="sub-text-font file-upload assessmentDetails screeningQuesPadding"
                                                                        label={option}
                                                                    />
                                                                )
                                                            )}
                                                        </Box>
                                                    </Typography>
                                                )}

                                            {question.Type === "radio" && (
                                                <Typography
                                                    id={question.key}
                                                    className="assessment-calender"
                                                >
                                                    <p className="f-14 gray9 font-weight-400 mb-10 h-auto mt-20">
                                                        Q
                                                        {jobType === "Full Time" && index > 1
                                                            ? index + 1 - hiddenQuesCount
                                                            : index + 1}
                                                        . {question.Question}{" "}
                                                        {(question.required && (question.scope == "E" || question.scope == "B")) ? (
                                                            <span className="text-black">*</span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </p>
                                                    <Box
                                                        className={`${question.required &&
                                                                isShowQuesErrMsgs &&
                                                                showHighlightQuestion &&
                                                                question.key === highlightQuestion
                                                                ? "danger-border"
                                                                : ""
                                                            }`}
                                                    >
                                                        <RadioGroup
                                                            row
                                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                                            name="row-radio-buttons-group"
                                                            className="file-upload"
                                                            onChange={(event) =>
                                                                handleChange(event, question)
                                                            }
                                                            value={question.Answer}
                                                        >
                                                            {question?.Options.map(
                                                                (option, index) => (
                                                                    <FormControlLabel
                                                                        className="file-upload assessment-details availability-assess screeningQuesPadding"
                                                                        key={index}
                                                                        value={option}
                                                                        control={<Radio color="primary" />}
                                                                        label={option}
                                                                        disabled={question.scope != "E" && question.scope != "B"}
                                                                    />
                                                                )
                                                            )}
                                                        </RadioGroup>
                                                    </Box>
                                                </Typography>
                                            )}
                                            {jobType !== "Full Time" &&
                                                question.Type === "text" &&
                                                question.Question ===
                                                "For how many hours are you available per day?" && (
                                                    <Typography id={question.key}>
                                                        <p className="f-14 gray9 font-weight-400 m-b-0 h-auto mt-20 align-left">
                                                            Q{index + 1}. {question.Question}{" "}
                                                            {(question.required && (question.scope == "E" || question.scope == "B")) ? (
                                                                <span className="text-black">*</span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </p>
                                                        <Input
                                                            type="number"
                                                            min="0"
                                                            className={`form-control input-group m-b-0 pl-10 inputcontrasts ${question.required &&
                                                                    isShowQuesErrMsgs &&
                                                                    showHighlightQuestion &&
                                                                    question.key === highlightQuestion
                                                                    ? "danger-border"
                                                                    : ""
                                                                } ${question.scope != "E" && question.scope != "B" ? "screeningQuesDisabled" : ""}`}
                                                            margin="normal"
                                                            placeholder="Eg: 8 hours"
                                                            onKeyPress={(e) => {
                                                                if (e.code === "Minus") {
                                                                    e.preventDefault();
                                                                }
                                                            }}
                                                            onChange={(event) =>
                                                                handleChange(event, question)
                                                            }
                                                            value={question.Answer}
                                                            disabled={question.scope != "E" && question.scope != "B"}
                                                        />
                                                    </Typography>
                                                )}
                                            {question.Type === "text" &&
                                                question.Question !==
                                                "For how many hours are you available per day?" && (
                                                    <Typography
                                                        id={question.key}
                                                        className="input-focus-none"
                                                    >
                                                        <p className="f-14 gray9 font-weight-400 m-b-0 h-auto mt-20 align-left">
                                                            Q
                                                            {jobType === "Full Time" && index > 1
                                                                ? index + 1 - hiddenQuesCount
                                                                : index + 1}
                                                            . {question.Question}{" "}
                                                            {(question.required && (question.scope == "E" || question.scope == "B")) ? (
                                                                <span className="text-black">*</span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </p>
                                                        <TextareaAutosize
                                                            minRows={3}
                                                            maxRows={4}
                                                            aria-label="maximum height"
                                                            className={`form-control input-group p-10 inputcontrasts ${question.required &&
                                                                    isShowQuesErrMsgs &&
                                                                    showHighlightQuestion &&
                                                                    question.key === highlightQuestion
                                                                    ? "danger-border"
                                                                    : ""
                                                                } ${question.scope != "E" && question.scope != "B" ? "screeningQuesDisabled" : ""}`}
                                                            placeholder=""
                                                            onKeyPress={handleSpaces}
                                                            onChange={(event) =>
                                                                handleChange(event, question)
                                                            }
                                                            value={question.Answer}
                                                            disabled={question.scope != "E" && question.scope != "B"}
                                                        />
                                                    </Typography>
                                                )}
                                            {question.required &&
                                                (question.scope == "E" || question.scope == "B") &&
                                                isShowQuesErrMsgs &&
                                                showErrorMsg(question.Question, question.key) ? (
                                                <span className="danger-color error-msg m-t-5">
                                                    Please provide the required details
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    );
                                })}
                        </Box>
                        <Box>
                            <p
                            className={props.jobDetails?.externalJobId ? "f-14 gray7 mb-0 font-weight-500 mt-10 gray7" : "f-14 gray7 mb-0 font-weight-500 gray7"}
                            align="left"
                            >
                            Remarks
                            </p>
                            <TextField
                                type="text"
                                variant="outlined"
                                multiline
                                rows={2}
                                rowsmax={6}
                                onChange={handleChangeShortListRemarks}
                                onKeyPress={handleSpaces}
                                className="form-control input-group mb-0 inputcontrasts textarea-input"
                                placeholder="Enter Remarks"
                                autoFocus={!props.jobDetails?.externalJobId}
                            />
                        </Box>
                    </Typography>
                    <Typography className="justify-center d-flex mt-20">
                        <Button
                            onClick={shortListHandler}
                            // onKeyPress={shortListHandler}
                            className="btn primary-button"
                            aria-label="Shortlist"
                            disableRipple="true"
                        >
                            Shortlist
                        </Button>
                    </Typography>
                </Typography>
            </Box>
        </Modal>
    );
}
