import React, { useRef, useEffect } from "react";
import { Grid, Typography, Box, Button } from "@mui/material";
import defaultUser from "./../../assets/images/default-user.svg";
import CandidateServiceClass from "../../Services/Enterprise/Candidate.service";
import { useNavigate } from "react-router-dom";
import ListAltIcon from "@mui/icons-material/ListAlt";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import { lowerCase } from "lodash";
// import WorkSvg from './../../assets/images/profileTitle.svg'
// import SkillSvg from './../../assets/images/Skill.svg'
import { ReactComponent as SkillIcon } from "./../../assets/images/Skill.svg";
import { ReactComponent as WorkIcon } from "./../../assets/images/profileTitle.svg";
import { ReactComponent as DegreeIcon } from "./../../assets/images/degree.svg";
import { ReactComponent as CertificationIcon } from "./../../assets/images/global-search-certification.svg";
import { useSelector } from "react-redux";
import KeycloakService from "./../../keycloak";

export default function SearchFilter(props) {
  const appOrigin = useSelector((state) => state.location.origin);
  let navigate = useNavigate();
  const isAuthenticated = KeycloakService.isLoggedIn();
  const {
    selectedjobTitleFilter,
    setShowSearchFilter,
    handleStateChange,
    isFilterByTalentPool,
    isFilterByTalentPoolLeads,
  } = props;
  const [candidateResponse, setCandidateResponse] = React.useState(undefined);
  React.useEffect(() => {
    if (selectedjobTitleFilter) {
    CandidateServiceClass.searchBar(
      "input=" +
        selectedjobTitleFilter.split(",") +
        (appOrigin === "H" ? "&is-healthcare=true" : ""),
      0,
      3
    ).then((response) => {
      setCandidateResponse(response);
    });
  }
  }, [selectedjobTitleFilter]);
  const goToCanidateList = (search) => {
    if (isFilterByTalentPool || isFilterByTalentPoolLeads) {
      handleStateChange(search);
    } else {
      handleStateChange(search.name);
      localStorage.removeItem("searchId");
      if (isAuthenticated) {
        window.location.href = `/enterprise/CandidateList?keyword=${search.name.split(
          ","
        )}&talentPool=${"A"}&type=${search.type}${
          search.id
            ? search.type === "S"
              ? "&skillid=" + search.id
              : search.type === "C"
              ? "&certifications=" + search.name.split("-")[0].trim()
              : "&checklistid=" + search.id
            : ""
        }`;
        // navigate(
        //   `/enterprise/CandidateList?keyword=${search.name.split(",")}&talentPool=${"A"}&type=${
        //     search.type
        //   }${search.id ? (search.type === "S" ? "&skillid=" + search.id : "&checklistid=" + search.id): ""}`
        // );
      } else {
        navigate(
          `/CandidateList?keyword=${search.name.split(
            ","
          )}&talentPool=${"A"}&type=${search.type}${
            search.id
              ? search.type === "S"
                ? "&skillid=" + search.id
                : search.type === "C"
                ? "&certifications=" + search.name.split("-")[0].trim()
                : "&checklistid=" + search.id
              : ""
          }`
        );
      }
    }
  };
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (
          ref.current &&
          event.target.id !== "globalSearch" &&
          !ref.current.contains(event.target)
        ) {
          setShowSearchFilter(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const getFormattedFilterValue = (name, type) => {
    if (type === "S" || type === "C") {
      let word = "";
      if (name.toUpperCase().startsWith(selectedjobTitleFilter.toUpperCase())) {
        word += selectedjobTitleFilter;
      }
      let skillSplit = name.split("-");
      let splitName = skillSplit[0].toString();
      let firstSplit = splitName.substring(0, word.length);
      let secondSplit = splitName.substring(word.length, splitName.length);
      return (
        <Typography>
          <span className="sub-text-font gray9">{firstSplit}</span>
          <span className="sub-text-font gray9">{secondSplit}</span>{" "}
          <span className="f-13 light-grey">
            [{skillSplit[1].trim()} - {skillSplit[2]}]
          </span>
        </Typography>
      );
    }
    if (type === "M") {
      if (name === "F") {
        return "full time";
      } else if (name === "P") {
        return "part time";
      } else if (name === "D") {
        return "distance education";
      }
    } else if (type === "L") {
      if (name === "B") {
        return "bachelors";
      } else if (name === "P") {
        return "doctorate";
      } else if (name === "M") {
        return "masters";
      } else if (name === "D") {
        return "diploma";
      } else if (name === "S") {
        return "school";
      }
    }
    return (
      <Typography>
        <span className="sub-text-font gray9">{name}</span>
      </Typography>
    );
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  if (!candidateResponse && selectedjobTitleFilter != "") {
    return (
      <Grid
        item
        lg={12}
        md={12}
        xs={12}
        className={`loading-align ${
          !isFilterByTalentPool && !isFilterByTalentPoolLeads
            ? "header-search-bar"
            : !isFilterByTalentPool && isFilterByTalentPoolLeads
            ? "add-pool-dropdown"
            : "talent-pool-search"
        }`}
      >
        <Grid
          container
          item
          spacing={1}
          lg={12}
          md={12}
          xs={12}
          className="m-0"
        >
          <Grid item lg={12} md={12} xs={12} className="m-auto">
            <Typography className="searchFilter">
              <Typography
                className="p-b-15 borderBottom"
                style={{ marginTop: "6px" }}
              >
                <Grid item className="d-flex align-center">
                  <Grid
                    item
                    lg={3}
                    md={3}
                    xs={4}
                    className="align-center d-flex"
                  ></Grid>
                  <Typography>
                    <Box className="font-weight-400 f-12 gray9">
                      Please wait while loading...
                    </Box>
                  </Typography>
                </Grid>
                <Grid item className="mt-10"></Grid>
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }
  if (selectedjobTitleFilter === "") {
    return <></>;
  }
  const noOfCandidates = candidateResponse.length;
  return (
    <>
      <Grid
        item
        ref={wrapperRef}
        lg={12}
        md={12}
        xs={12}
        className={`global-search-align ${
          !isFilterByTalentPool && !isFilterByTalentPoolLeads
            ? "header-search-bar"
            : !isFilterByTalentPool && isFilterByTalentPoolLeads
            ? "add-pool-dropdown"
            : "talent-pool-search"
        }`}
      >
        <Grid
          container
          item
          spacing={1}
          lg={12}
          md={12}
          xs={12}
          className="m-0"
        >
          <Grid item lg={12} md={12} xs={12} className="m-auto">
            <Typography
              className="searchFilter candidateList-align"
              style={{
                padding: "10px 20px 0px 20px",
                // height: '300px',
                // overflow: 'auto',
                // overflowY: 'auto',
                // height: noOfCandidates >= 3? '500px':'unset',
              }}
            >
              {candidateResponse.length > 0 ? (
                candidateResponse.slice(0, 5).map((candidate, index) => {
                  return (
                    <Box
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        // setSelectedText(candidate)
                        setShowSearchFilter(false);
                        goToCanidateList(candidate);
                      }}
                      tabIndex={0}
                      onKeyPress={() => {
                        // setSelectedText(candidate)
                        setShowSearchFilter(false);
                        goToCanidateList(candidate);
                      }}
                    >
                      <Typography className="py-10 ">
                        <Typography className="d-flex align-center">
                          {/*  <Grid item lg={1} className="align-center d-flex">
                                            <img style={{ width: '30px' }} src={defaultUser} alt="img" />
                                        </Grid>*/}
                          <Typography className="font-weight-500 f-14 d-flex">
                            {candidate.type === "T" ? (
                              <Box>
                                <WorkIcon
                                  style={{ width: "20px" }}
                                  aria-label="Work icon"
                                />
                              </Box>
                            ) : candidate.type === "D" ||
                              candidate.type === "L" ||
                              candidate.type === "I" ||
                              candidate.type === "M" ||
                              candidate.type === "U" ? (
                              <Box>
                                <DegreeIcon
                                  style={{ width: "20px", paddingTop: "6px" }}
                                  aria-label="Degree icon"
                                />
                              </Box>
                            ) : candidate.type === "C" ? (
                              <Box>
                                <CertificationIcon
                                  alt="img"
                                  style={{ width: "20px", marginTop: "2px" }}
                                  className="sub-text-color"
                                  aria-label="Certification icon"
                                />
                              </Box>
                            ) : (
                              <Box>
                                <SkillIcon
                                  style={{ width: "20px" }}
                                  aria-label="Skills icon"
                                />
                              </Box>
                            )}

                            <Typography className="pl-10">
                              {getFormattedFilterValue(
                                candidate.name,
                                candidate.type
                              )}
                            </Typography>
                            {candidate.type === "T" ? (
                              <span className="gray7 pl-10 f-12 pt-5">
                                <i>in profile title</i>
                              </span>
                            ) : candidate.type === "C" ? (
                              <span className="sub-text-color pl-10 f-12 pt-5">
                                <i>in certifications</i>
                              </span>
                            ) : candidate.type === "S" ? (
                              <span className="gray7 pl-10 f-12 pt-5">
                                <i>
                                  {appOrigin === "D"
                                    ? "in skills"
                                    : "in specialization"}
                                </i>
                              </span>
                            ) : candidate.type === "CL" ? (
                              <span className="gray7r pl-10 f-12 pt-5">
                                <i>in checklist</i>
                              </span>
                            ) : (
                              <span className="gray7 pl-10 f-12 pt-5">
                                <i>in education</i>
                              </span>
                            )}
                          </Typography>
                        </Typography>
                      </Typography>
                    </Box>
                  );
                })
              ) : (
                <Grid
                  item
                  className="align-center justify-center d-flex p-b-15"
                  style={{ marginTop: "6px" }}
                >
                  <Typography className="font-weight-500 f-14 cursor-normal gray9">
                    No results found
                  </Typography>
                </Grid>
              )}
              {/* {candidateResponse.length > 0 ?candidateResponse.map((candidate) => {
                                const matchedSkills = candidate.skills.filter(o=>(o.skills.keyword).toLowerCase().includes(selectedjobTitleFilter.toLowerCase()));
                                const unMatchedSkills = candidate.skills.filter(o=>!(o.skills.keyword).toLowerCase().includes(selectedjobTitleFilter.toLowerCase()));
                                const allSkills = [...matchedSkills,...unMatchedSkills];
                                const candidateSkills = allSkills.slice(0,4);
                                return (<Typography className="p-b-15 borderBottom" style={{ marginTop: '6px', cursor:'pointer' }}
                            onClick={()=>{
                                setShowSearchFilter(false);
                                    goToCanidateList();
                                // window.open(`${process.env.REACT_APP_AWS_USER_POOL_REDIRECT_SIGN_IN}view-public-profile/${candidate.id}`, '_blank')
                            }}
                            >
                                <Grid item className="d-flex align-center">
                                    <Grid item lg={2} className="align-center d-flex">
                                        <img style={{ width: '65px', height: '65px', borderRadius: '50%' }} src={candidate.mediaFile ? candidate.mediaFile : defaultUser} alt="img" />
                                    </Grid>
                                    <Typography>
                                        <Box className="font-weight-600 f-16">
                                            {candidate.firstName} {candidate.lastName}
                                        </Box>
                                        <Box className="f-14 gray7">
                                            {candidate.profileTitle}
                                        </Box>
                                    </Typography>
                                </Grid>
                                <Grid item className="mt-10">
                                    {candidateSkills.map((skill) => <span className="skills" style={{
                                        padding: '2px 6px',fontSize:'12px'
                                    }}>{skill.skills.keyword}</span>)}
                                </Grid>
                            </Typography>)}) : <Typography className="p-b-15 borderBottom" style={{ marginTop: '6px' }}>

                            <Grid item className="d-flex align-center">
                                    <Grid item lg={3} className="align-center d-flex">
                                    </Grid>
                                    <Typography>
                                        <Box className="font-weight-400 f-12">
                                        No  search results found
                                        </Box>

                                    </Typography>
                                </Grid>
                                <Grid item className="mt-10">
                                </Grid>


                            </Typography>} */}
              {candidateResponse.length > 3 && (
                <Typography
                  className="mt-10 justify-center d-flex"
                  style={{
                    background: "white",
                    borderTop: "1px solid #E4E4E7",
                  }}
                >
                  <Button
                    className="link-color f-14 font-weight-500 text-decoration-none"
                    onClick={() => {
                      setShowSearchFilter(false);
                      goToCanidateList({
                        name: selectedjobTitleFilter,
                        type: "K",
                      });
                    }}
                    style={{ textTransform: "none" }}
                    disableRipple="true"
                  >
                    See All
                  </Button>
                </Typography>
              )}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
