import React, { useState, useCallback, useEffect } from "react";
import { Typography, Grid, Box, Button, Link } from "@mui/material";
import { ReactComponent as Magic } from "../../../assets/images/magic.svg";
import { ReactComponent as MagicWhite } from "../../../assets/images/magic-White.svg";
import { ReactComponent as UploadWhiteIcon } from "../../../assets/images/uploadWhiteIcon.svg";
import { ReactComponent as UploadActiveIcon } from "../../../assets/images/uploadActiveIcon.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/images/download-icon.svg";
import uploadJobIcon from "../../../assets/images/upload-job-icon.svg";
import docIcon from "../../../assets/images/extension-icon/doc-icon.png";
import zipIcon from "../../../assets/images/extension-icon/zip.png";
import Resumepdf from "../../../assets/images/extension-icon/pdf-icon.png";
import deleteIcon from "../../../assets/images/delete-new-icon.svg";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import html2pdf from 'html2pdf.js';
import { useDropzone } from "react-dropzone";
import { danger, success } from "../../../helpers/message-box";
import ConfirmDialog from "../../../Components/shared/ConfirmDialog";
import AccessService from "../../../Services/Enterprise/Access.service";
import axios from "axios";
import CandidateService from "../../../Services/Enterprise/Candidate.service";
import { getCredentialingtoken } from "../../../helpers/credentialingToken";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
const steps = ['Generate', 'Attach Documents'];

const DownloadButton = (props) => {
  return (
    <Grid
    style={{ marginBottom: "auto" }}
      className="d-flex secondary-bg-color ml-10"
    >
      <Box className={props.disabled ? 'offer-disable' : 'offer-download cursor-hover'}>
        <DownloadIcon width="18px" height="18px" className={props.disabled ? 'svg-icons-disabled' : 'svg-icons'} onClick={()=>props.generatePDFDocumentDownload()}/>
      </Box>
    </Grid>
  );
};
const GenerateButton = (props) => {
  return (
    <Button
    style={{ marginLeft: "auto", marginBottom: "auto" }}
      className="btn create-ai-btn" onClick={() => {
        // props.offerButton("AI");
        props.generateOfferLetterData();
      }}
    >
      <MagicWhite width="18px" height="18px" />
      <Box className="ml-5">Generate Using AI</Box>
    </Button>
      );
};
export default function GenerateOfferLetterMatchingCandidate({ generateOffer, jobId, candidateApplicationData, interviewDetails, setViewOfferDrawer, setIsLoading,  resendOfferHeading, jobStartDate, jobCompletionDate, payRate, expiryDate, currency, updatedstatus, candidateCall, status, getCandidatesByStatus, isDetailsPage, sendOfferEmailChecked }) {
  const [sampleText, setSampleText] = useState("");
  const [filesArrays, setfilesArrays] = useState([]);
  const [documentIndex, setdocumentIndex] = useState();
  const [openDocumentIndex, setOpenDocumentIndex] = useState(false);
  const [jobDetails, setJobDetails] = useState();
  const [offerBody, setOfferBody] = useState("");
  const [offerLetterConfirmation, setOfferLetterConfirmation] = useState(false);
  const [invalidError, setInvalidError] = useState({
    offerBody: "",
  });
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  console.log(candidateApplicationData);
  const firstName = candidateApplicationData?.firstName != null ? candidateApplicationData?.firstName: "";
  const lastName = candidateApplicationData?.lastName != null ?candidateApplicationData?.lastName : "";
  const file_name =  firstName + lastName + "_offerletter.pdf";
  const pdfOptions = {
    margin: 10,
    filename: file_name,
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
  };
  const htmlContent = (draftToHtml(convertToRaw(editorState.getCurrentContent())));
  const generatePDFDocumentDownload = async () => {
    setIsLoading(true);
    try {
      html2pdf().from(htmlContent?.replaceAll("<br></p>", "</p><br>").replaceAll("<li>", "<li>&nbsp;").replaceAll('p>', 'div>').replaceAll("<div></div>", "<div><br></div>")).set(pdfOptions).save();
      success("File downloaded successfully.");
      setTimeout(() =>{
        setIsLoading(false);
      }, 1000);
      return;
    } catch (error) {
      console.error("An error occurred:", error);
      setIsLoading(false);
      return null;
    }
  };
  useEffect(() => {
    // const jobId = jobId;
    AccessService.getJobDetails(jobId)
    .then(async (res) => {
      setJobDetails(res);
    })
    .catch((e) => { });
  }, []);
  const callcredentaling = async () => {
    try {
      const data = await getCredentialingtoken();
      const firstName = candidateApplicationData.firstName;
      const lastName = candidateApplicationData.lastName;
      if (data) {
        const payload = {
          "workflowId": parseInt(jobDetails?.workflowUniqueIdentifier),
          "externalRefId": jobDetails?.job_id,
          "candidateDetail": {
            "firstName": firstName,
            "lastName": lastName ? lastName : "",
            "displayName": firstName + " " + (lastName ? lastName : ""),
            "emailId": "",
            "phoneNo": 0,
            "authType": "link",
            "password": "link"
          }
        };
        const response = await axios.post(`${process.env.REACT_APP_CREDENTIALING_MDM_SERVICE_URL}/credentialing/v1/api/assign/candidate/workflow`, payload, {
          headers: {
            Authorization: `Bearer ${data}`,
          },
        });
         createOfferHandlerUpload((response.data?.payload?.invitationCode));
      } else {
        // Handle the case where data is not available or falsy
        console.error("Credentialing token data is empty or falsy.");
        props.setIsLoading(false);
      }
    } catch (error) {
      // Handle errors from getCredentialingtoken
      console.error("Error while fetching credentialing token:", error);
      props.setIsLoading(false);
    }
  };
  const createOfferHandlerAI = async (invitationCode) => {
    setIsLoading(true);
    var formData = new FormData();
    const firstName = candidateApplicationData?.firstName != null ? candidateApplicationData?.firstName: "";
    const lastName = candidateApplicationData?.lastName != null ?candidateApplicationData?.lastName : "";
    const file_name =  firstName + lastName + "_offerletter.pdf";
    const pdfBlob = await html2pdf().from(htmlContent).set(pdfOptions).outputPdf('blob');
    formData.append('offer-document', new Blob([pdfBlob]), file_name, { type: 'application/pdf' });
    if (process.env.REACT_APP_INTEGRATE_CREDENTIALING === "Y") {
      formData.append(
        "request-body",
        new Blob(
          [
            JSON.stringify({
              jobId: jobId,
              candidateId: isDetailsPage ?  candidateApplicationData.candidateId : status === "shortlisted" ? candidateApplicationData.id : candidateApplicationData.candidateId,
              offerExpiryDate: expiryDate,
              jobStartDate: jobStartDate,
              jobCompletionDate: jobCompletionDate,
              payRate: payRate ? parseFloat(payRate) : null,
              currency: currency,
              interviewId: interviewDetails?.interviewHeadersDto?.id,
              workflowUniqueIdentifier: (invitationCode ? invitationCode : null),
            }),
          ],
          {
            type: "application/json",
          }
        )
      );
    } else {
      formData.append(
        "request-body",
        new Blob(
          [
            JSON.stringify({
              jobId: jobId,
              candidateId: isDetailsPage ?  candidateApplicationData.candidateId : status === "shortlisted" ? candidateApplicationData.id : candidateApplicationData.candidateId,
              offerExpiryDate: expiryDate,
              jobStartDate: jobStartDate,
              jobCompletionDate: jobCompletionDate,
              payRate: payRate ? parseFloat(payRate) : null,
              currency: currency,
              interviewId: interviewDetails?.interviewHeadersDto?.id,
            }),
          ],
          {
            type: "application/json",
          }
        )
      );
    }
    await CandidateService.createOffer(formData)
    .then((response) => {
    //   setToUpdateDetails(!toUpdateDetails);
    //   interviewClouser("H");
      setViewOfferDrawer(false);
      success("Offer Letter has been sent successfully.");
      setIsLoading(false);
    })
    .catch(function (error) {
      danger("something went wrong.");
      setIsLoading(false);
    });
  };
  const handleOfferLetterConfirmation =() => {
    if (process.env.REACT_APP_INTEGRATE_CREDENTIALING === "Y" && jobDetails?.workflowUniqueIdentifier) {
      callcredentaling();
    } else {
      createOfferHandlerUpload();
    }
  };
  const createOfferHandlerUpload = async (invitationCode) => {
    setIsLoading(true);
    var formData = new FormData();
    if (filesArrays?.length > 0) {
      for ( const fileObj of filesArrays) {
        formData.append("offer-document", fileObj ? fileObj.file : '');
      }
    };
    if (editorState.getCurrentContent().hasText()) {
        const firstName = candidateApplicationData?.firstName != null ? candidateApplicationData?.firstName: "";
      const lastName = candidateApplicationData?.lastName != null ?candidateApplicationData?.lastName : "";
      const file_name =  firstName + lastName + "_offerletter.pdf";
      const pdfBlob = await html2pdf().from(htmlContent).set(pdfOptions).outputPdf('blob');
      formData.append('offer-document', new Blob([pdfBlob]), file_name, { type: 'application/pdf' });
    };
    console.log(candidateApplicationData);
    if (process.env.REACT_APP_INTEGRATE_CREDENTIALING === "Y") {
      formData.append(
            "request-body",
            new Blob(
              [
                JSON.stringify({
                  jobId: jobId,
                  candidateId: isDetailsPage ?  candidateApplicationData.candidateId : status === "shortlisted" ? candidateApplicationData.id : candidateApplicationData.candidateId,
                  offerExpiryDate: expiryDate,
                  jobStartDate: jobStartDate,
                  jobCompletionDate: jobCompletionDate,
                  payRate: payRate ? parseFloat(payRate) : null,
                  currency: currency,
                  interviewId: interviewDetails?.interviewHeadersDto?.id,
                  workflowUniqueIdentifier: (invitationCode ? invitationCode : null),
                }),
              ],
              {
                type: "application/json",
              }
            )
          );
    } else {
      formData.append(
        "request-body",
        new Blob(
          [
            JSON.stringify({
              jobId: jobId,
              candidateId: isDetailsPage ?  candidateApplicationData.candidateId : status === "shortlisted" ? candidateApplicationData.id : candidateApplicationData.candidateId,
              offerExpiryDate: expiryDate,
              jobStartDate: jobStartDate,
              jobCompletionDate: jobCompletionDate,
              payRate: payRate ? parseFloat(payRate) : null,
              currency: currency,
              interviewId: interviewDetails?.interviewHeadersDto?.id,
            }),
          ],
          {
            type: "application/json",
          }
        )
      );
    }
    await CandidateService.createOffer(formData, sendOfferEmailChecked)
    .then((response) => {
    // interviewClouser("H");
    // setToUpdateDetails(!toUpdateDetails);
    setViewOfferDrawer(false);
    setfilesArrays([]);
    // offerButton("AI");
    success("Offer Letter has been sent successfully.");
    setIsLoading(false);
if (!isDetailsPage) {
    updatedstatus();
    candidateCall();
}
  console.log("till here");
    getCandidatesByStatus(status);
    })
    .catch(function (error) {
      danger("something went wrong.");
      setIsLoading(false);
    });
    };
    const validFileExtensions = ["doc", "docx", "pdf"];

  const onDrop = useCallback(
    (acceptedFiles) => {
        let filesToAdd = acceptedFiles;
        if (acceptedFiles.length > 10) {
          danger(`You cannot upload more than 10 files!`);
        } else {
          const totalFilesDropped = acceptedFiles.length + filesArrays.length;
          if (totalFilesDropped > 10) {
            danger(`You cannot upload more than 10 files!`);
          }
        }
        const remainingSpace = 10 - filesArrays.length;
        if (remainingSpace > 0) {
          filesToAdd = acceptedFiles.slice(0, remainingSpace);
          filesToAdd.forEach((file) => {
            const extension = file.name.split(".").pop().toLowerCase();
          if (validFileExtensions.includes(extension)) {
            setfilesArrays((oldArray) => [
              ...oldArray,
              {
                filename: file.name,
                extension: extension,
                file: file,
                size: file.size,
              },
            ]);
          } else {
              danger(`Please upload only DOC, DOCX, and PDF formats!`);
          }
          });
        } else {
          danger(`You cannot upload more than 10 files!`);
        }
        setTimeout(() => {
          document.getElementById("modal-description")?.scrollTo({
            top: document.getElementById("modal-description").scrollHeight,
            behavior: "smooth",
          });
        }, 600);
    },
    [filesArrays]
  );
  function getFileSizeString(fileSizeInBytes) {
    var i = -1;
    var byteUnits = [" KB", " MB", " GB", " TB", "PB", "EB", "ZB", "YB"];
    do {
      fileSizeInBytes /= 1024;
      i++;
    } while (fileSizeInBytes > 1024);
    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
  }
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
    disabled: filesArrays.length >= 10,
    // maxFiles: 10,
  });
  const handleDeleteDocumentWithIndex = (index) => {
    setdocumentIndex(index);
    setOpenDocumentIndex(true);
  };
  const deleteDocumentFromIndex = (index) => {
    setfilesArrays(
      filesArrays.filter((file, _index) => _index !== documentIndex)
    );
    setdocumentIndex();
    setOpenDocumentIndex(false);
    success("Document is deleted successfully.");
  };

  const populateExistingData = (data) => {
    setEditorState(
      data
        ? EditorState.createWithContent(
            ContentState.createFromBlockArray(htmlToDraft(data).contentBlocks)
          )
        : ""
    );
    setOfferBody(
      draftToHtml(
        convertToRaw(
          (data
            ? EditorState.createWithContent(
                ContentState.createFromBlockArray(
                  htmlToDraft(data).contentBlocks
                )
              )
            : ""
          ).getCurrentContent()
        )
      )
    );
  };
  const generateOfferLetterData = async () => {
    setIsLoading(true);
    const enterprise_user_id = JSON.parse(localStorage.getItem("enterprise_user_id"));
    const salary = payRate !== "" ? payRate : null;
    const payload = {
      jobId: jobId,
      candidateId: isDetailsPage ?  candidateApplicationData.candidateId : status === "shortlisted" ? candidateApplicationData.id : candidateApplicationData.candidateId,
      offerExpiryDate: expiryDate,
      startDate: jobStartDate,
      endDate: jobCompletionDate,
      salary: parseFloat(salary),
      currency: currency,
      enterpriseUserId: enterprise_user_id,
    };
    await AccessService.generateOfferLetter(payload).then((res) => {
      if (res.text != "") {
        populateExistingData(res?.text?.replaceAll("<p></p>", "").replaceAll("<p> </p>", ""));
        success("Offer Letter is generated.");
        document.getElementById("offerLetterFocus");
        setIsLoading(false);
      } else {
        danger("something went wrong.");
        setIsLoading(false);
      }
    }).catch((err) => {
      setIsLoading(false);
      danger("Something went wrong.");
    });
  };
  const toolbarCustomButtons = [<GenerateButton key={`generate-button-${Math.random()}`} generateOfferLetterData={generateOfferLetterData}/>,
  <DownloadButton disabled={!editorState.getCurrentContent().hasText()} generatePDFDocumentDownload={generatePDFDocumentDownload} key={`download-button-${Math.random()}`}/>];
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    if (
      !editorState.getCurrentContent().hasText() &&
      editorState.getCurrentContent().getPlainText().trim().length <= 0
    ) {
      setInvalidError((err) => ({
        ...err,
        ...{ OfferBody: "Offer Letter data is required" },
      }));
    } else {
      setInvalidError((err) => ({ ...err, ...{ OfferBody: "" } }));
    }
    setOfferBody(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState(0);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleStep = (step) => () => {
    setActiveStep(step);
  };
  return (
    <>
      <Grid>
        <Grid className="m-auto text-center">
          <Grid container spacing={1} lg={12} md={12} xs={12}>
            <Grid item lg={11} md={11} xs={12} className="m-auto">
              <Grid>
                <Typography className="display-flex align-center text-center justify-center">
                  <Box className="gray9 f-16 font-weight-600">Offer Letter</Box>
                </Typography>
                <Typography className="mt-10 gray7 font-weight-400 f-14">
                  {/* Send offer letters to candidates by either generating using AI or
                  uploading your customized letter. */}
                  Use offer letter section to provide a clear and detailed description of the
                  position being offered, including the job title, department, and reporting structure.
                </Typography>
              </Grid>
              <Grid className="mt-25" >
                <Typography className="m-auto offer-label job-summary-align" style={{ width: '50%' }}>
                  <Stepper nonLinear activeStep={activeStep}>
                    {steps.map((label, index) => {
                      return (<Step key={label} className="hello">
                        <StepButton color="inherit" onClick={handleStep(index)} className={`${index == 0 ? "offer-stepactive":""}`}>
                        {index === activeStep ? label: ""}
                        </StepButton>
                      </Step>);
                    })}
                  </Stepper>
                </Typography>
                <Typography className="mt-30">
                  {/* Step {activeStep + 1} */}
                  {activeStep === 0 && (
                    <Grid className="mt-30 d-flex">
                      <Typography className="offer-editor-container">
                        <Editor
                          wrapperClassName="wrapper-class"
                          editorClassName="editor-class-offer"
                          toolbarClassName="toolbar-class"
                          id="offerLetterFocus"
                          editorState={editorState}
                          toolbarCustomButtons={toolbarCustomButtons.map(
                            (button) => button
                          )}
                          placeholder={"Enter offer details"}
                          className=""
                          onEditorStateChange={onEditorStateChange}
                          handlePastedText={() => false}
                        />
                      </Typography>
                      <span className="danger-color error-msg" style={{ textAlign: "left" }}>
                        {" "}
                        {invalidError.offerBody}
                      </span>
                    </Grid>
                  )}
                  {activeStep === 1 && (
                    <Grid>
                      <Grid
                        item
                        {...getRootProps({
                          className: "choose-video text-center mt-30 p-30",
                        })}
                        style={filesArrays.length >= 10 ? { cursor: "default" } : {}}
                      >
                        <input {...getInputProps()} />
                        <Typography>
                          <Box>
                            <img
                              src={uploadJobIcon}
                              style={{ width: "25px" }}
                              alt="upload file"
                            />
                          </Box>
                        </Typography>
                        <Typography className="mt-20">
                          <Box className="gray9 f-14 font-weight-400">
                            Drop your file
                          </Box>
                          <Box className="display-flex align-center text-center justify-center">
                            <Box className="gray7 f-12 p-r-5">or</Box>
                            {filesArrays.length < 10 ? (
                              <Box
                                disabled={filesArrays.length == 10}
                                disableRipple="true"
                              >
                                <Link
                                  className="link-color f-14 text-decoration-none cursor-hover m-r-10"
                                  variant="contained"
                                >
                                  choose from system
                                </Link>
                              </Box>
                            ) : (
                              <Box className="f-14 gray7 font-weight-400 m-r-10" onClick={() => danger("Number of documents cannot exceed 10")}>
                                choose from system
                              </Box>
                            )}
                          </Box>
                          <Box className="f-14 gray7 font-weight-400 mt-20">
                            Only .doc, .docx and .pdf format is supported
                          </Box>
                        </Typography>
                      </Grid>
                      <Grid>
                        {filesArrays &&
                          filesArrays.map((file, index) => {
                            return (
                              <Grid
                                key={index}
                                container spacing={1} lg={12} md={12} xs={12}
                                className="justify-between display-flex mt-15 border-1 border-radius-8 bg-white p-5 m-0"
                              >
                                <Grid item lg={9} md={9} xs={9} className="display-flex align-center">
                                  {(file.extension === "doc" ||
                                    file.extension === "docx") && (
                                      <img
                                        src={docIcon}
                                        alt="doc"
                                        className="m-r-10 file-extension-image wth-50"
                                        width="35px"
                                        height="35px"
                                        tabIndex={0}
                                      />
                                    )}
                                  {file.extension === "pdf" && (
                                    <img
                                      src={Resumepdf}
                                      alt="doc"
                                      className="m-r-10 file-extension-image wth-50"
                                      width="35px"
                                      height="35px"
                                      tabIndex={0}
                                    />
                                  )}
                                  {file.extension === "zip" && (
                                    <img
                                      src={zipIcon}
                                      alt="doc"
                                      className="m-r-10 file-extension-image wth-50"
                                      width="35px"
                                      height="35px"
                                      tabIndex={0}
                                    />
                                  )}
                                  {file.filename && (
                                    <p className="f-14 gray7 font-weight-600 textEllipse w-450 text-left">
                                      {file.filename}
                                    </p>
                                  )}
                                </Grid>
                                <Grid item lg={2} md={2} xs={2} className="display-flex align-center f-14 justify-center">
                                  {file.filename && (
                                    <span className="">
                                      {getFileSizeString(file?.size)}
                                    </span>
                                  )}
                                </Grid>
                                <Grid item lg={1} md={1} xs={1} className="display-flex align-center f-14 justify-center">
                                  <span
                                    onClick={() => handleDeleteDocumentWithIndex(index)}
                                    style={{ cursor: "pointer" }}
                                    tabIndex={0}
                                    className="align-center d-flex sub-text-font cursor-hover gray7"
                                    onKeyPress={() =>
                                      handleDeleteDocumentWithIndex(index)
                                    }
                                  >
                                    <img
                                      className="sub-text-font"
                                      src={deleteIcon}
                                      alt="delete icon"
                                      width="18px"
                                      height="18px"
                                      tabIndex={0}
                                    />
                                  </span>
                                </Grid>
                              </Grid>
                            );
                          })}
                      </Grid>
                    </Grid>
                  )}
                </Typography>
                <Grid className="mt-30 d-flex justify-end">
                {activeStep === 0 ?
                  <Button className="btn primary-button" onClick={handleNext}>
                    Next
                  </Button> :
                  <Button className="btn primary-button"
                  onClick={() => {
                    setOfferLetterConfirmation(true);
                      }}
                    disabled= { !editorState.getCurrentContent().hasText() && filesArrays.length <= 0 }>
                  {resendOfferHeading ? "Resend Offer": "Send Offer"}
                </Button>}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* OLD CODE */}
      </Grid>
      <ConfirmDialog
                open={offerLetterConfirmation}
                setOpen={setOfferLetterConfirmation}
                dialogTitle="Offer Letter Confirmation"
                description={resendOfferHeading ? <span>Are you sure you want to <strong>"Resend Offer Letter"</strong></span> : <span>Are you sure you want to <strong>"Send Offer Letter"</strong></span>}
                handleConfirmYes={handleOfferLetterConfirmation}
            />
      <ConfirmDialog
        open={openDocumentIndex}
        setOpen={setOpenDocumentIndex}
        dialogTitle="Delete Document "
        description={"Are you sure?"}
        handleConfirmYes={deleteDocumentFromIndex}
      />
    </>
  );
}
