import React, { useEffect } from "react";
import { Typography, Button, Modal, Box, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { danger } from "../../helpers/message-box";
import { useNavigate } from "react-router";

function ExclusiveWorkPermissionModal({ openExclusiveWorkPermissionModal, setOpenExclusiveWorkPermissionModal, action, jobDetails }) {
    const navigate = useNavigate();
    const candidateId = localStorage.getItem("candidateId");
    const userType = localStorage.getItem("userType");
    const handleCloseExclusiveWorkPermissionModal = () => {
        setOpenExclusiveWorkPermissionModal(false);
    };
    const checkIfUserCanEditJob = () => {
        if (userType === "S" || userType === "C") {
            if (candidateId !== jobDetails.createdBy) {
            danger("You do not have rights to edit this job.");
            return false;
            }
        }
        return true;
    };

    const editJob = () => {
        if (checkIfUserCanEditJob()) {
            navigate("/enterprise/postJob", {
            state: { id: jobDetails.id, checkListId: jobDetails.checkListId, exclusiveWorkPermission: true },
            });
        }
    };
    return (
        <Modal
        open={openExclusiveWorkPermissionModal}
        onClose={handleCloseExclusiveWorkPermissionModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-content work-exclusivity-modal-content inputcontrasts">
          <Typography className="align-left">
            <Typography id="modal-title" className="d-flex align-between">
              <Typography variant="h5" className="f-22 font-weight-600 heading-text-color text-capitalize">
              Offer Condition
              </Typography>
            </Typography>
            <Typography
              id="modal-description"
              className="profileMissingDetails"
            >
              <Typography>
                <Box className="f-16 font-weight-400">
                    {`You cannot ${action} an occupied candidate as this job requires fully available candidates only.`}
                </Box>
              </Typography>
            </Typography>
            <Grid id="modal-footer" className="d-flex justify-center">
              <Typography className="mt-20 justify-center display-flex">
                <Button
                    className="btn secondary-button"
                    onClick={handleCloseExclusiveWorkPermissionModal}
                    onKeyPress={handleCloseExclusiveWorkPermissionModal}
                    disableRipple="true"
                    tabIndex={0}
                >
                  Close
                </Button>
                <Button
                    className="btn primary-button ml-30"
                    onClick={editJob}
                    onKeyPress={editJob}
                    disableRipple="true"
                    tabIndex={0}
                >
                    Edit Job
                </Button>
              </Typography>
            </Grid>
          </Typography>
        </Box>
      </Modal>
    );
}
export default ExclusiveWorkPermissionModal;
