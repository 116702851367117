import * as React from "react";
import {
  Typography,
  Grid,
  Box,
  InputAdornment,
  TextField,
  Button,
  Menu,
  MenuItem,
  styled, useTheme, Drawer, CssBaseline, Toolbar, List, Divider, IconButton, AppBar as MuiAppBar,
  useMediaQuery
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as JobIcon } from "../../assets/images/myJobs-icon.svg";
import { ReactComponent as JobIconWhite } from "../../assets/images/Jobs-white.svg";
import { ReactComponent as EmailIcon } from "../../assets/images/emai-icon.svg";
import { ReactComponent as EmailIconWhite } from "../../assets/images/emai-icon-white.svg";
import { ReactComponent as ClientPortalIcon } from "../../assets/images/candidatePortal-icon.svg";
import { ReactComponent as AccessIcon } from "../../assets/images/accessControl-icon.svg";
import { ReactComponent as AccessWhiteIcon } from "../../assets/images/access-white-icon.svg";
import { ReactComponent as AnalyticsIcon } from "../../assets/images/analytics-icon.svg";
import { ReactComponent as AnalyticsWhiteIcon } from "../../assets/images/analytics-white-icon.svg";
import { ReactComponent as AnalyticsActiveIcon } from "../../assets/images/analytics-active-icon.svg";
import { ReactComponent as AnalyticsIconGray } from "../../assets/images/analytics-icon.svg";
import { ReactComponent as MyJobsIconGray } from "../../assets/images/Jobs.svg";
import { ReactComponent as MyJobsActiveIcon } from "../../assets/images/myJobsIcon-active.svg";
import { ReactComponent as PortalActiveIcon } from "../../assets/images/candidatePortal-activeIcon.svg";
import { ReactComponent as PortalWhiteIcon } from "../../assets/images/candidatePortal-white-Icon.svg";
import { ReactComponent as CampaignActiveIcon } from "../../assets/images/campaign-activeIcon.svg";
import { ReactComponent as AccessActiveIcon } from "../../assets/images/access-activeIcon.svg";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import AccessService from "../../Services/Enterprise/Access.service";
import Analytics from "../EnterprisePortal/Analytics";
import AnalyticsSideBar from "../EnterprisePortal/AnalyticsSideBar";
import { Tooltip, TooltipTrigger, TooltipContent } from "react-tippy";
// import SwitchSideBar from "../EnterprisePortal/SwitchSideBar";
import {
  isSessionValid,
  getFromLocalStorage,
  logout,
  getEnterpriseLogo,
} from "../../helpers/helper";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ReactComponent as LogoutIcon } from "../../assets/images/logout.svg";
import { Outlet } from "react-router-dom";
import { ReactComponent as LogoutGreyIcon } from "../../assets/images/logout-grey.svg";
import { ReactComponent as JobsGreyIcon } from "../../assets/images/myJobs-GreyIcon.svg";
import { ReactComponent as JobsWhiteIcon } from "../../assets/images/myJobs-WhiteIcon.svg";
import { ReactComponent as AccessActiveicon } from "../../assets/images/accessActiveIcon.svg";
import { ReactComponent as AccessGreyIcon } from "../../assets/images/accessGreyIcon.svg";
import { ReactComponent as MyCandidatesActiveIcon } from "../../assets/images/myCandidate-activeIcon.svg";
import { ReactComponent as MyCandidatesWhiteIcon } from "../../assets/images/myCandidate-whiteIcon.svg";
import { ReactComponent as SwitchIcon } from "../../assets/images/arrows-right-left.svg";
import { ReactComponent as ActiveSwitchIcon } from "../../assets/images/arrows-right-left-active.svg";
import { ReactComponent as TalentPoolActiveIcon } from "../../assets/images/talent-pool-active-icon.svg";
import { ReactComponent as TalentPoolWhiteIcon } from "../../assets/images/talent-pool-white-icon.svg";
import SwitchSideBar from "../EnterprisePortal/SwitchSidebar";
import MspInviteModal from "../EnterprisePortal/TalentPool/MspInviteModal";
import { useSelector } from "react-redux";
const Menus = require("../../menu-control");
import { ReactComponent as InterviewWhiteIcon } from "../../assets/images/interviewWhiteIcon.svg";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function SideBar(props) {
  const location = useLocation();
  const URLPath = location.pathname;
  const appOrigin = useSelector((state) => state.location.origin);
  const [isEnterprsieMSP, setIsEnterprsieMSP] = useState(false);

  const goToJoblist = () => {
    if (
      URLPath === "/enterprise/jobList" ||
      URLPath === "/enterprise/JobList"
    ) {
      window.location.reload(false);
    } else {
      navigate("/enterprise/jobList");
    }
  };
  const goToCampaign = () => {
    if (URLPath === "/enterprise/campaignList") {
      window.location.reload(false);
    } else {
      navigate("/enterprise/campaignList");
    }
  };
  const goToInterview = () => {
    if (URLPath === "/enterprise/interviewList") {
      window.location.reload(false);
    } else {
      navigate("/enterprise/interviewList");
    }
  };
  const goToTalentPool = () => {
    if (URLPath === "/enterprise/talent-pool-management") {
      window.location.reload(false);
    } else {
      navigate("/enterprise/talent-pool-management");
    }
  };
  const goToMyCandidates = () => {
    if (URLPath === "/enterprise/my-candidate") {
      navigate("/enterprise/my-candidate", { replace: true });
      window.location.reload(false);
    } else {
      navigate("/enterprise/my-candidate");
    }
  };
  const goToClient = (e) => {
    e.preventDefault();
    // navigate("http://localhost:6805/");
    if (URLPath === "https://career-portal.simplifysource.com/") {
      window.location.reload(false);
    } else {
      navigate("https://career-portal.simplifysource.com/");
    }
  };
  const goToAccess = () => {
    if (URLPath === "/enterprise/access") {
      window.location.reload(false);
    } else {
      navigate("/enterprise/access");
    }
  };
  const goToAnalytics = () => {
    navigate("/enterprise/Analytics");
  };

  const handleMenu = () => {
    setOpenAnalytics(true);
  };
  const handleOpenSwitch = () => {
    setOpenSwitch(true);
  };

  const handleCloseMenu = (event) => {
    setAnchorEl(null);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);

  const theme = useTheme();
  const [open, setOpen] = React.useState(
    localStorage.getItem("sidebarOpened")
      ? localStorage.getItem("sidebarOpened") === "true"
        ? true
        : false
      : true
  );
  const [openAnalytics, setOpenAnalytics] = React.useState(false);
  const [openSwitch, setOpenSwitch] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(null);
  const [isloggedIn, setLoggedIn] = React.useState(false);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [enterpriseAccess, setEnterpriseAccess] = useState([]);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [UserType, setUserType] = useState(null);
  const [childEnterprises, setChildEnterprises] = useState(null);
  const [openMspInviteModal, setMspInviteModal] = useState(false);
  const handleOpenMspInviteModal = () => setMspInviteModal(true);
  const [mspReponse, setMspResponse] = useState(null);
  const handleCloseMspInviteModal = () => {
    setMspInviteModal(false);
  };
  const [openConfirmSwitchModal, setOpenConfirmSwitchModal] = useState(false);
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    localStorage.setItem("sidebarOpened", true);
    setOpen(true);
  };
  const handleOpenConfirmDialog = () => {
    setOpenConfirmSwitchModal(true);
  };

  const handleDrawerClose = () => {
    localStorage.setItem("sidebarOpened", false);
    setOpen(false);
  };
  const switchbackToMsp = () => {
    localStorage.removeItem("childEnterpriseId");
    localStorage.removeItem("navbarLogo");
    localStorage.removeItem("mspEnterpriseId");
    if (localStorage.getItem("JD")) {
      localStorage.removeItem("JD");
    }
    if (localStorage.getItem("skills")) {
      localStorage.removeItem("skills");
    }
    if (localStorage.getItem("additionalDetails")) {
      localStorage.removeItem("additionalDetails");
    }
    navigate("/enterprise/jobList");
    window.location.reload(true);
  };
  function enterpriseLogout() {
    AccessService.enterpriseLogout().then(
      (response) => {
        signOut();
        // setLoggedIn(false)
      }).catch(
      (error) => {
        setLoggedIn(false);
      }
    );
  }
  const signOut = async () => {
    setIsLoading(true);
    await logout();
    setLoggedIn(false);
    setAnchorE2(null);
    navigate("/signin");
    setIsLoading(false);
  };

  const careerPortalSSOLogin = () => {
    let mspName = localStorage.getItem("mspEnterprsieData")
      ? JSON.parse(localStorage.getItem("mspEnterprsieData"))
      : null;
    setAnchorE2(null);
    // window.open(`https://${process.env.REACT_APP_AWS_USER_POOL_DOMAIN}/login?redirect_uri=${process.env.REACT_APP_CAREERPORTAL_BASE_URL}&response_type=code&client_id=${process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID}&identity_provider=COGNITO&scope=phone+email+openid+profile aws.cognito.signin.user.admin`, '_blank', 'noopener,noreferrer');
    window.open(
      `${
        process.env.REACT_APP_CAREERPORTAL_BASE_URL
      }/candidate/homepage?enterpriseId=${localStorage.getItem("childEnterpriseId")}&mspName=${
        mspName?.establishmentName
      }&appOrigin=${appOrigin}`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  const adminUser = localStorage.getItem("activeUser");
  const notAdminUser = localStorage.getItem("notActiveUser");
  const [userTypes, setUserTypes] = React.useState("");

  useEffect(() => {
    let mounted = true;
    AccessService.getUserList().then(
      (response) => {
        if (mounted) {
          setMspResponse(response);
          let arr = response.enterprisesUsers;
          for (let i = 0; i < arr.length; i++) {
            if (arr[i].emailAddress === localStorage.getItem("email")) {
              setUserTypes(arr[i].userType);
            }
          }
          setEnterpriseAccess(arr);
        }
      }).catch(
      (error) => {}
    );
    return () => (mounted = false);
  }, []);
  useEffect(async () => {
    await AccessService.getEnterprises().then((res) => {
      if (res?.isManagedServiceProvider === "Y") {
        setIsEnterprsieMSP(true);
      }
      if (res.childEnterprises && res.childEnterprises.length > 0) {
        setChildEnterprises(res.childEnterprises);
      }
    });
  }, []);

  return (
    <>
      <Grid item>
        {isMobile ? (
          <>
            <Grid item>
              <Grid item className="menu-list text-center">
                <>
                  <Grid
                    className="mt-70 myJobIcon sideBar-icon"
                    onClick={goToJoblist}
                    onKeyPress={goToJoblist}
                    aria-label="Jobs icon"
                    tabIndex={0}
                  >
                    <Tooltip
                      title="Jobs"
                      position="right"
                      trigger="mouseenter"
                      animation="fade"
                      duration={0}
                      distance={0}
                      arrow>
                      {(URLPath === "/enterprise/jobList" ||
                        URLPath === "/enterprise/previewJob" ||
                        URLPath === "/enterprise/postJob" ||
                        URLPath === "/enterprise/screeningQuestions" ||
                        URLPath === "/enterprise/schedule-interview" ||
                        URLPath.includes("matching-candidate")) &&
                      openAnalytics === false &&
                      !openSwitch ? (
                        <Typography className="bg-white p-5">
                          <MyJobsActiveIcon
                            width="16px"
                            height="16px"
                            aria-label="Jobs icon"
                            className="inputcontrasts"
                          />
                        </Typography>
                      ) : (
                        <Typography>
                          <JobsWhiteIcon
                            width="16px"
                            height="16px"
                            aria-label="Jobs icon"
                            className="inputcontrasts"
                          />
                        </Typography>
                      )}
                    </Tooltip>
                  </Grid>
                </>

                {Menus?.candidates?.indexOf(userTypes) !== -1 && (
                  <Grid
                    className="mt-45 sideBar-icon"
                    onClick={goToMyCandidates}
                    onKeyPress={goToMyCandidates}
                    aria-label="MyCandidate icon"
                  >
                    <Tooltip
                      title="Candidates"
                      position="right"
                      trigger="mouseenter"
                      animation="fade"
                      duration={0}
                      distance={0}
                      arrow>
                      {(URLPath === "/enterprise/my-candidate" ||
                        URLPath.includes("candidate-detail")) &&
                      openAnalytics === false &&
                      !openSwitch ? (
                        <Typography className="bg-white p-5">
                          <MyCandidatesActiveIcon
                            width="16px"
                            height="16px"
                            aria-label="MyCandidates icon"
                          />
                        </Typography>
                      ) : (
                        <Typography>
                          <MyCandidatesWhiteIcon
                            width="16px"
                            height="16px"
                            aria-label="MyCandidates icon"
                          />
                        </Typography>
                      )}
                    </Tooltip>
                  </Grid>
                )}
                {localStorage.getItem("activeUser") && (
                  <>
                    <Grid
                      className="mt-45 sideBar-icon"
                      onClick={goToCampaign}
                      onKeyPress={goToCampaign}
                      aria-label="Campaign icon"
                      tabIndex={0}
                    >
                      <Tooltip
                        title="Campaign"
                        position="right"
                        trigger="mouseenter"
                        animation="fade"
                        duration={0}
                        distance={0}
                        arrow>
                        {(URLPath === "/enterprise/campaignList" ||
                          URLPath === "/enterprise/createCampaign" ||
                          URLPath === "/enterprise/campaignDetail" ||
                          URLPath === "/enterprise/edit-campaign") &&
                        openAnalytics === false &&
                        !openSwitch ? (
                          <Typography className="bg-white p-5">
                            <CampaignActiveIcon
                              width="16px"
                              height="16px"
                              aria-label="Campaign icon"
                              className="inputcontrasts"
                            />
                          </Typography>
                        ) : (
                          <Typography>
                            <EmailIconWhite
                              width="16px"
                              height="16px"
                              aria-label="Campaign icon"
                              className="inputcontrasts"
                            />
                          </Typography>
                        )}
                      </Tooltip>
                    </Grid>
                    <Grid
                      className="mt-45 sideBar-icon"
                      onClick={goToInterview}
                      onKeyPress={goToInterview}
                      aria-label="Interview icon"
                      tabIndex={0}
                    >
                      <Tooltip title="Interview" position="right"
                        trigger="mouseenter"
                        animation="fade"
                        arrow>
                        {(URLPath === "/enterprise/interviewList") &&
                          openAnalytics === false &&
                          !openSwitch ? (
                          <Typography className="bg-white p-5">
                            <InterviewWhiteIcon
                              width="16px"
                              height="16px"
                              aria-label="Interview icon"
                              className="inputcontrasts"
                            />
                          </Typography>
                        ) : (
                          <Typography>
                            <InterviewWhiteIcon
                              width="16px"
                              height="16px"
                              aria-label="Interview icon"
                              className="inputcontrasts"
                            />
                          </Typography>
                        )}
                      </Tooltip>
                    </Grid>
                    <Grid
                      className="mt-45 sideBar-icon"
                      onClick={goToTalentPool}
                      onKeyPress={goToTalentPool}
                      aria-label="Talent pool"
                      tabIndex={0}
                    >
                      <Tooltip
                        title="Talent Pool Management"
                        position="right"
                        trigger="mouseenter"
                        animation="fade"
                        duration={0}
                        distance={0}
                        arrow>
                        {(URLPath === "/enterprise/talent-pool-management" ||
                          URLPath.includes("create-talent-pool") ||
                          URLPath ===
                            "/enterprise/talent-pool-candidate-list" ||
                          URLPath.includes("talent-pool/")) &&
                        openAnalytics === false &&
                        !openSwitch ? (
                          <Typography className="bg-white p-5">
                            <TalentPoolActiveIcon
                              width="16px"
                              height="16px"
                              aria-label="Talent pool icon"
                              className="inputcontrasts talent-pool-active-icon"
                            />
                          </Typography>
                        ) : (
                          <Typography>
                            <TalentPoolWhiteIcon
                              width="16px"
                              height="16px"
                              aria-label="Talent pool icon"
                              className="inputcontrasts talent-pool-icon"
                            />
                          </Typography>
                        )}
                      </Tooltip>
                    </Grid>
                    <Grid
                      className="mt-45 sideBar-icon"
                      onClick={careerPortalSSOLogin}
                      onKeyPress={careerPortalSSOLogin}
                      aria-label="Portal icon"
                      tabIndex={0}
                    >
                      <Tooltip
                        title="Career Portal"
                        position="right"
                        trigger="mouseenter"
                        animation="fade"
                        duration={0}
                        distance={0}
                        arrow>
                        {URLPath === "/enterprise/candidatePortal" ||
                        URLPath === "/enterprise/candidatePortal/publish" ||
                        (URLPath === "/enterprise/candidatePortal/preview" &&
                          openAnalytics === false &&
                          !openSwitch) ? (
                          <Typography className="bg-white p-5">
                            <PortalActiveIcon
                              width="16px"
                              height="16px"
                              aria-label="Portal icon"
                              className="inputcontrasts"
                            />
                          </Typography>
                        ) : (
                          <Typography>
                            <PortalWhiteIcon
                              width="16px"
                              height="16px"
                              aria-label="Portal icon"
                              className="inputcontrasts"
                            />
                          </Typography>
                        )}
                      </Tooltip>
                    </Grid>
                    <Grid
                      className="mt-45 sideBar-icon"
                      onClick={handleMenu}
                      onKeyPress={handleMenu}
                      aria-label="Analytics icon"
                      tabIndex={0}
                    >
                      <Tooltip
                        title="Analytics"
                        position="right"
                        trigger="mouseenter"
                        animation="fade"
                        duration={0}
                        distance={0}
                        arrow>
                        {URLPath === "/enterprise/Analytics" ||
                        openAnalytics ? (
                          <Typography className="bg-white p-5">
                            <AnalyticsActiveIcon
                              width="16px"
                              height="16px"
                              tabIndex={0}
                              aria-label="Analytics icon"
                              className="inputcontrasts"
                            />
                          </Typography>
                        ) : (
                          <Typography>
                            <AnalyticsWhiteIcon
                              width="16px"
                              height="16px"
                              tabIndex={0}
                              aria-label="Analytics icon"
                              className="inputcontrasts"
                            />
                          </Typography>
                        )}
                      </Tooltip>
                    </Grid>
                    {!localStorage.getItem("childEnterpriseId") && (
                      <Grid
                        className="mt-45 sideBar-icon"
                        onClick={goToAccess}
                        onKeyPress={goToAccess}
                        aria-label="Access icon"
                        tabIndex={0}
                      >
                        <Tooltip
                          title="Configuration Control"
                          position="right"
                          trigger="mouseenter"
                          animation="fade"
                          duration={0}
                          distance={0}
                          arrow>
                          {URLPath === "/enterprise/access" &&
                          openAnalytics === false &&
                          !openSwitch ? (
                            <Typography className="bg-white p-5">
                              <AccessActiveicon
                                width="18px"
                                height="18px"
                                aria-label="Access icon"
                                className="inputcontrasts"
                              />
                            </Typography>
                          ) : (
                            <Typography>
                              <AccessWhiteIcon
                                width="18px"
                                height="18px"
                                aria-label="Access icon"
                                className="inputcontrasts"
                              />
                            </Typography>
                          )}
                        </Tooltip>
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
              <Grid item>
                <Main
                  open={props.open}
                  sx={{ pr: 0 }}
                  className="responsive-view-align"
                >
                  <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                    className={`zooming-sidebar ${
                      props.open && `zooming-sidebar`
                        ? " responsive-contents"
                        : ""
                    }`}
                    sx={{ ml: 7 }}
                  >
                    <Typography className="responsive-outlet galaxy-padding-top-10">
                      <Outlet />
                    </Typography>
                  </Grid>
                </Main>
              </Grid>
              <Menu
                className={
                  props.openSideBar
                    ? "analytics-report"
                    : "analytic-settings-report"
                }
                id="menu-appbar"
                anchorEl={anchorEl}
                disableScrollLock={true}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                KeepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={openAnalytics}
              >
                <Grid item className="mt-15">
                  <AnalyticsSideBar
                    setOpenAnalytics={setOpenAnalytics}
                    setOpen={setOpen}
                  />
                </Grid>
              </Menu>
            </Grid>
          </>
        ) : (
          <Grid item className="resolution-issues">
            <Grid item className="menu-list">
              <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <Grid
                  item
                  position="fixed"
                  className="collapse-navbar"
                  open={props.open}
                >
                  <Toolbar>
                    <Button
                      // color="inherit"
                      aria-label="open drawer"
                      edge="start"
                      className="collapse-label align-start pl-0 icon-button"
                      sx={{
                        mr: 0,
                        ml: 0,
                        ...(props.open && { display: "none" }),
                      }}
                      role="switch"
                      aria-checked={props.open ? "true" : "false"}
                      tabIndex={0}
                      disableRipple="true"
                    >
                      <Grid item style={{ minWidth: "53px" }}>
                      {(Menus?.jobs?.indexOf(userTypes) !== -1 || userTypes =="") && (
                          <Grid
                            className="myJobIcon"
                            onClick={goToJoblist}
                            onKeyPress={goToJoblist}
                            aria-label="Jobs icon"
                            tabIndex={0}
                          >
                            <Tooltip
                              title="Jobs"
                              position="right"
                              trigger="mouseenter"
                              animation="fade"
                              duration={0}
                              distance={0}
                              arrow>
                              {(URLPath === "/enterprise/jobList" ||
                                URLPath === "/enterprise/JobList" ||
                                URLPath === "/enterprise/previewJob" ||
                                URLPath === "/enterprise/postJob" ||
                                URLPath === "/enterprise/screeningQuestions" ||
                                URLPath === "/enterprise/schedule-interview" ||
                                URLPath.includes("matching-candidate")) &&
                              openAnalytics === false &&
                              !openSwitch ? (
                                <Typography className="side-menu-bg p-10 justify-center d-flex">
                                  <MyJobsActiveIcon
                                    width="20px"
                                    height="20px"
                                    aria-label="Jobs icon"
                                    className="inputcontrasts svg-icons"
                                  />
                                </Typography>
                              ) : (
                                <Typography className="p-10 justify-center d-flex">
                                  <JobsWhiteIcon
                                    width="20px"
                                    height="20px"
                                    aria-label="Jobs icon"
                                    className="inputcontrasts"
                                  />
                                </Typography>
                              )}
                            </Tooltip>
                          </Grid>
                        )}
                        {Menus?.candidates?.indexOf(userTypes) !== -1 && (
                          <Grid
                            className="mt-20 icon-margin sideBar-icon"
                            onClick={goToMyCandidates}
                            onKeyPress={goToMyCandidates}
                            aria-label="MyCandidate icon"
                            tabIndex={0}
                          >
                            <Tooltip title="Candidates" position="right"
                              trigger="mouseenter"
                              animation="fade"
                              arrow>
                              {(URLPath === "/enterprise/my-candidate" ||
                                URLPath.includes("my-candidate")) &&
                                openAnalytics === false &&
                                !openSwitch ? (
                                <Typography className="side-menu-bg p-10 justify-center d-flex">
                                  <MyCandidatesActiveIcon
                                    width="20px"
                                    height="20px"
                                    aria-label="MyCandidates icon"
                                    className="svg-icons"
                                  />
                                </Typography>
                              ) : (
                                <Typography className="p-10 justify-center d-flex">
                                  <MyCandidatesWhiteIcon
                                    width="20px"
                                    height="20px"
                                    aria-label="MyCandidates icon"
                                  />
                                </Typography>
                              )}
                            </Tooltip>
                          </Grid>
                        )}
                        {Menus?.campaign?.indexOf(userTypes) !== -1 && (
                          <Grid
                            className="mt-20 icon-margin sideBar-icon"
                            onClick={goToCampaign}
                            onKeyPress={goToCampaign}
                            aria-label="Campaign icon"
                            tabIndex={0}
                          >
                            <Tooltip title="Campaign" position="right"
                              trigger="mouseenter"
                              animation="fade"
                              arrow>
                              {(URLPath === "/enterprise/campaignList" ||
                                URLPath === "/enterprise/createCampaign" ||
                                URLPath === "/enterprise/campaignDetail" ||
                                URLPath === "/enterprise/edit-campaign") &&
                                openAnalytics === false &&
                                !openSwitch ? (
                                <Typography className="side-menu-bg p-10 justify-center d-flex">
                                  <EmailIconWhite
                                    width="20px"
                                    height="20px"
                                    aria-label="Campaign icon"
                                    className="inputcontrasts svg-icons"
                                  />
                                </Typography>
                              ) : (
                                <Typography className="p-10 justify-center d-flex">
                                  <CampaignActiveIcon
                                    width="20px"
                                    height="20px"
                                    aria-label="Campaign icon"
                                    className="inputcontrasts"
                                  />
                                </Typography>
                              )}
                            </Tooltip>
                          </Grid>
                        )}
                        {Menus?.talentpool?.indexOf(userTypes) !== -1 && (
                          <Grid
                            className="mt-20 icon-margin sideBar-icon"
                            onClick={goToTalentPool}
                            onKeyPress={goToTalentPool}
                            aria-label="Talent pool icon"
                            tabIndex={0}
                          >
                            <Tooltip title="Talent Pool" position="right"
                              trigger="mouseenter"
                              animation="fade"
                              arrow>
                              {(URLPath ===
                                "/enterprise/talent-pool-management" ||
                                URLPath.includes("create-talent-pool") ||
                                URLPath ===
                                "/enterprise/talent-pool-candidate-list" ||
                                URLPath.includes("talent-pool/")) &&
                                openAnalytics === false &&
                                !openSwitch ? (
                                <Typography className="side-menu-bg p-10 justify-center d-flex">
                                  <TalentPoolWhiteIcon
                                    width="20px"
                                    height="20px"
                                    aria-label="Talent pool icon"
                                    className="inputcontrasts talent-pool-active-icon svg-icons"
                                  />
                                </Typography>
                              ) : (
                                <Typography className="p-10 justify-center d-flex">
                                  <TalentPoolActiveIcon
                                    width="20px"
                                    height="20px"
                                    aria-label="Talent pool icon"
                                    className="inputcontrasts talent-pool-icon"
                                  />
                                </Typography>
                              )}
                            </Tooltip>
                          </Grid>
                        )}
                        <>
                        {Menus?.careerportal?.indexOf(userTypes) !== -1 && (
                          <Grid
                            className="mt-20 icon-margin sideBar-icon"
                            onClick={careerPortalSSOLogin}
                            onKeyPress={careerPortalSSOLogin}
                            aria-label="Portal icon"
                            tabIndex={0}
                          >
                            <Tooltip
                              title="Career Portal"
                              position="right"
                              trigger="mouseenter"
                              animation="fade"
                              arrow
                            >
                              {URLPath ===
                                "/enterprise/candidatePortal" ||
                                URLPath ===
                                "/enterprise/candidatePortal/publish" ||
                                (URLPath ===
                                  "/enterprise/candidatePortal/preview" &&
                                  openAnalytics === false &&
                                  !openSwitch) ? (
                                <Typography className="side-menu-bg p-10 justify-center d-flex">
                                  <PortalActiveIcon
                                    width="20px"
                                    height="20px"
                                    aria-label="Portal icon"
                                    className="inputcontrasts svg-icons"
                                  />
                                </Typography>
                              ) : (
                                <Typography className="p-10 justify-center d-flex">
                                  <PortalWhiteIcon
                                    width="20px"
                                    height="20px"
                                    aria-label="Portal icon"
                                    className="inputcontrasts"
                                  />
                                </Typography>
                              )}
                            </Tooltip>
                          </Grid>
                        )}
                        {(!localStorage.getItem("childEnterpriseId")) && (Menus?.configurationcontrol?.indexOf(userTypes) !== -1) && (
                          <Grid
                            className="mt-25 icon-margin sideBar-icon"
                            onClick={goToAccess}
                            onKeyPress={goToAccess}
                            aria-label="Access icon"
                            tabIndex={0}
                          >
                            <Tooltip
                              title="Configuration Control"
                              position="right"
                              trigger="mouseenter"
                              animation="fade"
                              arrow
                            >
                              {URLPath === "/enterprise/access" &&
                                openAnalytics === false &&
                                !openSwitch ? (
                                <Typography className="side-menu-bg p-10 justify-center d-flex">
                                  <AccessWhiteIcon
                                    width="18px"
                                    height="18px"
                                    aria-label="Access icon"
                                    className="inputcontrasts svg-icons"
                                  />
                                </Typography>
                              ) : (
                                <Typography className="p-10 justify-center d-flex">
                                  <AccessActiveIcon
                                    width="18px"
                                    height="18px"
                                    aria-label="Access icon"
                                    className="inputcontrasts"
                                  />
                                </Typography>
                              )}
                            </Tooltip>
                          </Grid>
                        )}
                        {Menus?.analytics?.indexOf(userTypes) !== -1 && (
                        <Grid
                          className="mt-25 icon-margin sideBar-icon"
                          onClick={handleMenu}
                          onKeyPress={handleMenu}
                          aria-label="Analytics icon"
                          tabIndex={0}
                        >
                          <Tooltip title="Analytics" position="right"
                            trigger="mouseenter"
                            animation="fade"
                            arrow>
                            {URLPath === "/enterprise/Analytics" ||
                              openAnalytics ? (
                              <Typography className="side-menu-bg p-10 justify-center d-flex">
                                <AnalyticsWhiteIcon
                                  width="20px"
                                  height="20px"
                                  tabIndex={0}
                                  aria-label="Analytics icon"
                                  className="inputcontrasts svg-icons"
                                />
                              </Typography>
                            ) : (
                              <Typography className="p-10 justify-center d-flex">
                                <AnalyticsActiveIcon
                                  width="16px"
                                  height="16px"
                                  tabIndex={0}
                                  aria-label="Analytics icon"
                                  className="inputcontrasts"
                                />
                              </Typography>
                            )}
                          </Tooltip>
                        </Grid>
                        )}
                          </>
                      </Grid>
                    </Button>
                  </Toolbar>
                </Grid>
                <Drawer
                  sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    mr: 0,
                    "& .MuiDrawer-paper": {
                      width: drawerWidth,
                      boxSizing: "border-box",
                    },
                  }}
                  variant="persistent"
                  anchor="left"
                  open={props.open}
                >
                  {/* <DrawerHeader> */}
                  {/* <IconButton
                    onClick={handleDrawerClose}
                    className="mt-20 position-issue icon-button"
                    aria-label="drawer-button"
                    style={{ padding: "15px 0px 0px 0px" }}
                    role="switch"
                    aria-checked={open ? "true" : "false"}
                    tabIndex={0}
                  >
                    <Tooltip title="Collapse" placement="right">
                      <ChevronLeftIcon
                        className="default-img-border menu-sideIcon"
                        style={{ left: "119px" }}
                        aria-label="Collapse icon"
                        tabIndex={-1}
                      />
                    </Tooltip> */}
                  {/* {theme.direction === 'ltr' ? <ChevronRightIcon className="default-img-border menu-sideIcon" style={{left:'146px'}} /> : <ChevronLeftIcon className="default-img-border menu-sideIcon" />} */}
                  {/* </IconButton> */}
                  {/* </DrawerHeader> */}
                  {/* <Divider /> */}
                  <Grid item className="sidebar-list">
                  {(Menus?.jobs?.indexOf(userTypes) !== -1 || userTypes =="") && (
                    (URLPath === "/enterprise/jobList" ||
                      URLPath === "/enterprise/JobList" ||
                      URLPath === "/enterprise/previewJob" ||
                      URLPath === "/enterprise/postJob" ||
                      URLPath === "/enterprise/screeningQuestions" ||
                      URLPath === "/enterprise/schedule-interview" ||
                      URLPath.includes("matching-candidate")) &&
                    openAnalytics === false &&
                    !openSwitch ? (
                      <Link tabIndex={0} to="/enterprise/jobList">
                        <ul className="mt-0">
                          <li className="pt-10 active-list cursor-hover d-flex align-center">
                            <MyJobsActiveIcon
                              width="20px"
                              height="20px"
                              aria-label="Myjobs icon"
                              className="svg-icons"
                            />
                            <label className="primary-shadow-color pl-10">
                              Jobs
                            </label>
                          </li>
                        </ul>
                      </Link>
                    ) : (
                      <Link tabIndex={0} to="/enterprise/jobList">
                        <ul className="mt-0">
                          <li className="pt-10 cursor-hover d-flex align-center">
                            <JobsWhiteIcon
                              width="20px"
                              height="20px"
                              className="contrasts"
                              aria-label="MyCandidates icon"
                            />
                            <label className="gray9 pl-10">Jobs</label>
                          </li>
                        </ul>
                      </Link>
                    ))}
                    {Menus?.candidates?.indexOf(userTypes) !== -1 && (
                      ((URLPath === "/enterprise/my-candidate" ||
                        URLPath.includes("my-candidate")) &&
                      openAnalytics === false &&
                      !openSwitch ? (
                        <Link tabIndex={0} to="/enterprise/my-candidate">
                          <ul className="mt-0">
                            <li className="pt-10 active-list cursor-hover d-flex align-center">
                              <MyCandidatesActiveIcon
                                width="20px"
                                height="20px"
                                aria-label="MyCandidates icon"
                                className="svg-icons"
                              />
                              <label className="primary-shadow-color pl-10">
                                Candidates
                              </label>
                            </li>
                          </ul>
                        </Link>
                      ) : (
                        <Link tabIndex={0} to="/enterprise/my-candidate">
                          <ul className="mt-0">
                            <li className="pt-10 cursor-hover d-flex align-center">
                              <MyCandidatesWhiteIcon
                                width="20px"
                                height="20px"
                                className="contrasts"
                                aria-label="MyCandidates icon"
                              />
                              <label className="gray9 pl-10">Candidates</label>
                            </li>
                          </ul>
                        </Link>
                      )))}
                    {Menus?.campaign?.indexOf(userTypes) !== -1 && (
                      <>
                        {(URLPath === "/enterprise/campaignList" ||
                          URLPath === "/enterprise/createCampaign" ||
                          URLPath === "/enterprise/campaignDetail" ||
                          URLPath === "/enterprise/edit-campaign") &&
                        openAnalytics === false &&
                        !openSwitch ? (
                          <Link tabIndex={0} to="/enterprise/campaignList">
                            <ul className="mt-0">
                              <li className="pt-10 active-list cursor-hover d-flex align-center">
                                <EmailIconWhite
                                  width="20px"
                                  height="20px"
                                  aria-label="Campaign icon"
                                  className="inputcontrasts svg-icons"
                                />
                                <label className="primary-shadow-color pl-10">
                                  Campaign
                                </label>
                              </li>
                            </ul>
                          </Link>
                        ) : (
                          <Link tabIndex={0} to="/enterprise/campaignList">
                            <ul className="mt-0">
                              <li className="pt-10 cursor-hover d-flex align-center">
                                <CampaignActiveIcon
                                  width="20px"
                                  height="20px"
                                  className="contrasts inputcontrasts"
                                  aria-label="Campaign icon"
                                />
                                <label className="gray9 pl-10">Campaign</label>
                              </li>
                            </ul>
                          </Link>
                        )}
                      </>
                    )}
                    {localStorage.getItem("activeUser") && (
                      <>
                        {(URLPath === "/enterprise/interviewList") &&
                          openAnalytics === false &&
                          !openSwitch ? (
                          <Link tabIndex={0} to="/enterprise/interviewList">
                            <ul className="mt-0">
                              <li className="pt-10 active-list cursor-hover d-flex align-center">
                                <InterviewWhiteIcon
                                  width="20px"
                                  height="20px"
                                  aria-label="Interview icon"
                                  className="inputcontrasts svg-icons"
                                />
                                <label className="primary-shadow-color pl-10">
                                  Interviews
                                </label>
                              </li>
                            </ul>
                          </Link>
                        ) : (
                          <Link tabIndex={0} to="/enterprise/interviewList">
                            <ul className="mt-0">
                              <li className="pt-10 cursor-hover d-flex align-center">
                                <InterviewWhiteIcon
                                  width="20px"
                                  height="20px"
                                  className="contrasts inputcontrasts"
                                  aria-label="Interview icon"
                                />
                                <label className="gray9 pl-10">Interviews</label>
                              </li>
                            </ul>
                          </Link>
                        )}
                      </>
                    )}
                    {Menus?.talentpool?.indexOf(userTypes) !== -1 && (
                      <>
                        {(URLPath === "/enterprise/talent-pool-management" ||
                          URLPath.includes("create-talent-pool") ||
                          URLPath ===
                            "/enterprise/talent-pool-candidate-list" ||
                          URLPath.includes("talent-pool/")) &&
                        openAnalytics === false &&
                        !openSwitch ? (
                          <Link
                            tabIndex={0}
                            to="/enterprise/talent-pool-management"
                          >
                            <ul>
                              <li className="pt-10 active-list cursor-hover d-flex align-center">
                                <TalentPoolWhiteIcon
                                  width="20px"
                                  height="20px"
                                  aria-label="Talent pool icon"
                                  className="inputcontrasts talent-pool-active-icon svg-icons"
                                />
                                <label className="primary-shadow-color pl-10">
                                  Talent Pool
                                </label>
                              </li>
                            </ul>
                          </Link>
                        ) : (
                          <Link
                            tabIndex={0}
                            to="/enterprise/talent-pool-management"
                          >
                            <ul>
                              <li className="pt-10 cursor-hover d-flex align-center">
                                <TalentPoolActiveIcon
                                  width="20px"
                                  height="20px"
                                  className="contrasts inputcontrasts talent-pool-icon"
                                  aria-label="Talent pool icon"
                                />
                                <label className="gray9 pl-10">
                                  Talent Pool
                                </label>
                              </li>
                            </ul>
                          </Link>
                        )}
                      </>
                    )}
                    {Menus?.careerportal?.indexOf(userTypes) !== -1 && (
                      <>
                        {(URLPath === "/enterprise/candidatePortal" ||
                          URLPath === "/enterprise/candidatePortal/publish" ||
                          URLPath === "/enterprise/candidatePortal/preview") &&
                        openAnalytics === false &&
                        !openSwitch ? (
                          <a
                            // href="javascript:void(0)"
                            tabIndex={0}
                            onClick={careerPortalSSOLogin}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <ul>
                              <li className="pt-10 active-list cursor-hover d-flex align-center">
                                <PortalActiveIcon
                                  width="20px"
                                  height="20px"
                                  aria-label="Portal icon"
                                  className="inputcontrasts svg-icons"
                                />
                                <label className="primary-shadow-color pl-10">
                                  Career Portal
                                </label>
                              </li>
                            </ul>
                          </a>
                        ) : (
                          <a
                            // href="javascript:void(0)"
                            tabIndex={0}
                            onClick={careerPortalSSOLogin}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <ul>
                              <li className="pt-10 cursor-hover d-flex align-center">
                                <PortalWhiteIcon
                                  width="20px"
                                  height="20px"
                                  className="contrasts inputcontrasts"
                                  aria-label="Portal icon"
                                />
                                <label className="gray9 pl-10">
                                  Career Portal
                                </label>
                              </li>
                            </ul>
                          </a>
                        )}
                      </>
                    )}
                      {Menus?.configurationcontrol?.indexOf(userTypes) !== -1 && (
                        <>
                          {!localStorage.getItem("childEnterpriseId") && (
                            <>
                              {URLPath === "/enterprise/access" &&
                                openAnalytics === false &&
                                !openSwitch ? (
                                <Link tabIndex={0} to="/enterprise/access">
                                  <ul>
                                    <li className="pt-10 active-list cursor-hover d-flex align-center">
                                      <AccessWhiteIcon
                                        width="20px"
                                        height="20px"
                                        aria-label="Access icon"
                                        className="inputcontrasts svg-icons"
                                      />
                                      <label className="primary-shadow-color pl-10">
                                        Configuration Control
                                      </label>
                                    </li>
                                  </ul>
                                </Link>
                              ) : (
                                <Link tabIndex={0} to="/enterprise/access">
                                  <ul>
                                    <li className="pt-10 cursor-hover d-flex align-center">
                                      <AccessActiveIcon
                                        width="20px"
                                        height="20px"
                                        className="contrasts inputcontrasts"
                                        aria-label="Access icon"
                                      />
                                      <label className="gray9 pl-10">
                                        Configuration Control
                                      </label>
                                    </li>
                                  </ul>
                                </Link>
                              )}
                            </>
                          )}
                        </>
                      )}
                      {Menus?.analytics?.indexOf(userTypes) !== -1 && (
                      <>
                          {URLPath === "/enterprise/Analytics" ||
                            openAnalytics ? (
                            <ul>
                              <li
                                className="pt-10 active-list cursor-hover d-flex align-center icon-button"
                                onClick={handleMenu}
                                onKeyPress={handleMenu}
                                tabIndex={0}
                              >
                                <Typography
                                  tabIndex={0}
                                  onClick={handleMenu}
                                  onKeyPress={handleMenu}
                                  className="d-flex align-center"
                                >
                                  <AnalyticsWhiteIcon
                                    width="20px"
                                    height="20px"
                                    aria-label="Analytics icon"
                                    tabIndex={0}
                                    className="inputcontrasts svg-icons"
                                  />
                                  <label className="primary-shadow-color f-14 font-weight-600 d-block pl-10">
                                    Analytics
                                  </label>
                                </Typography>
                              </li>
                            </ul>
                          ) : (
                            <Link tabIndex={0} to="#">
                              <ul>
                                <li
                                  className="pt-10 cursor-hover d-flex align-center icon-button"
                                  onClick={handleMenu}
                                  onKeyPress={handleMenu}
                                  tabIndex={0}
                                >
                                  <Typography
                                    tabIndex={0}
                                    onClick={handleMenu}
                                    onKeyPress={handleMenu}
                                    className="d-flex align-center"
                                  >
                                    <AnalyticsActiveIcon
                                      width="20px"
                                      height="20px"
                                      className="contrasts inputcontrasts"
                                      aria-label="Analytics icon"
                                      tabIndex={0}
                                    />
                                    <label className="gray9 d-block pl-10">
                                      Analytics
                                    </label>
                                  </Typography>
                                </li>
                              </ul>
                            </Link>
                          )}
                      </>
                      )}
                  </Grid>
                  {/* <Divider /> */}
                </Drawer>
              </Box>
              <Menu
                className={
                  props.openSideBar
                    ? "analytics-report"
                    : "analytic-settings-report"
                }
                id="menu-appbar"
                anchorEl={anchorEl}
                disableScrollLock={true}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                KeepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={openAnalytics}
              >
                <Grid item className="mt-15">
                  <AnalyticsSideBar
                    setOpenAnalytics={setOpenAnalytics}
                    setOpen={setOpen}
                  />
                </Grid>
              </Menu>
              <Menu
                className={
                  open ? "analytics-report" : "analytic-settings-report"
                }
                id="menu-appbar"
                anchorEl={anchorEl}
                disableScrollLock={true}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                KeepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={openSwitch}
              >
                <Grid item className="mt-15 p-relative">
                  <SwitchSideBar
                    handleOpenSwitch={setOpenSwitch}
                    setOpen={setOpen}
                    childEnterprises={childEnterprises}
                    mspReponse={mspReponse}
                  />
                </Grid>
              </Menu>
            </Grid>
            <Main open={open}>
              <Grid
                item
                sx={{ ml: 38, ...(open && { marginLeft: "135px" }) }}
                className={
                  props.openSideBar
                    ? "responsive-main-section side-bar-align preview-side-align screen-left-align fit-body-expanded left-section"
                    : "responsive-main-section side-bar-align preview-side-align screen-left-align fit-body-collapsed left-section"
                }
              >
                <Outlet />
              </Grid>
            </Main>
          </Grid>
        )}
      </Grid>
    </>
  );
}
