import React from "react";
import { Grid, TextField, Box, Typography, Autocomplete } from "@mui/material";
import CandidateServiceClass from "../Services/Enterprise/Candidate.service";
import { success, danger } from "../helpers/message-box";
import ClearIcon from "@mui/icons-material/Clear";
import _ from "lodash";
const Constant = require("../helpers/constants");

class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      city: props.city,
      isDirty: false,
      openLocationsAutoComplete: false,
      locationsAutoFill: [],
      clear: false,
    };
    this.debouncedHandler = _.debounce(
      this.handleDebounceInput.bind(this),
      Constant.DEBOUNCE_TIME
    );
  }
  componentDidUpdate(prevProps, prevState) {
    // Check if the firstState has changed
    if (
      this.props.fromJobWizard &&
      this.props.locationFieldDisabled &&
      !prevProps.locationFieldDisabled
    ) {
      // Update the secondState based on the firstState change
      this.setState({
        city: "",
      });
    }
  }

  handleDebounceInput = (e) => {
    this.getLocations(e.target.value);
  };
  getLocations = async (event) => {
    this.handleChange(event);
    if (!event) {
      this.setState({ locationsAutoFill: [] });
    } else if (event.length >= 1) {
      if (this.props.searchWithZipcode) {
        CandidateServiceClass.getLocations(event + "?type=A")
          .then((res) => {
            this.setState({ locationsAutoFill: res });
          })
          .catch(function (error) {});
      } else {
        CandidateServiceClass.getLocations(
          event + (this.props.stateCheck ? "?type=R" : "")
        )
          .then((res) => {
            this.setState({ locationsAutoFill: res });
          })
          .catch(function (error) {});
      }
    }
  };

  handleChange = (city) => {
    this.setState({ city: city.trimStart() });
    if (city === "" && this.props.clearOnEmptyCity) {
      this.props.onSelectLocation({
        city: "",
        state: "",
        country: "",
        zipCode: "",
      });
      if (this.props.getFullName) {
        this.props.getFullName([]);
      }
    }
    // this.props.onSelectLocation({ state: "", country: "" });
    this.setState({ isDirty: true });
    if (this.props.scrollToBottom) {
      setTimeout(
        () =>
          document.getElementById("modal-description")?.scrollTo({
            top: document.getElementById("modal-description").scrollHeight,
            behavior: "smooth",
          }),
        600
      );
    }
  };
  handleLatLong = (location) => {
    if (this.props?.latlng) {
      this.props.handleLatLong(location);
    }
  };
  handleSelect = (location, placeId) => {
    let locationList = location.split(",");
    let locationDetails;
    if (locationList.length > 3) {
      locationDetails = {
        zipCode: locationList[1].trim(),
        city: locationList[0].trim(),
        state: locationList[2].trim(),
        country: locationList[3].trim(),
        placeId: placeId,
      };
    } else if (locationList.length === 3) {
      locationDetails = {
        city: "",
        zipcode: locationList[0].trim(),
        state: locationList[1].trim(),
        country: locationList[2].trim(),
        placeId: placeId,
      };
    } else if (locationList.length === 2) {
      locationDetails = {
        city: "",
        zipcode: "",
        state: locationList[0].trim(),
        country: locationList[1].trim(),
        placeId: placeId,
      };
    } else {
      locationList = location.split("-");
      locationDetails = {
        city: locationList.length > 1 ? locationList[0].trim() : "",
        state: locationList.length > 1 ? locationList[0].trim() : "",
        country:
          locationList.length > 1
            ? locationList[1].trim()
            : locationList[0].trim(),
        placeId: placeId,
      };
    }
    this.props.onSelectLocation(locationDetails);
    if (this.props.addLocation === true) {
      this.setState({ city: "" });
    } else {
      this.setState({
        city: locationDetails.city
          ? locationDetails.city +
            ", " +
            locationDetails.state +
            ", " +
            locationDetails.country
          : locationDetails.state + ", " + locationDetails.country,
      });
    }
    this.setState({ isDirty: false });
  };

  setLocationCity = (location) => {
    let adr = location && location["address"];
    let locationDetailsMapping = {};
    for (let i = 0; i < adr.length; i++) {
      if (adr[i].types.includes("LOCALITY")) {
        locationDetailsMapping["LOCALITY"] = adr[i].longName;
      } else if (
        adr[i].types[0] === "ADMINISTRATIVE_AREA_LEVEL_1" ||
        adr[i].types[0] === "COUNTRY" ||
        adr[i].types[0] === "POSTAL_CODE"
      ) {
        locationDetailsMapping[adr[i].types[0]] = adr[i].shortName;
      }
    }
    const locationDetails = {
      zipCode: locationDetailsMapping?.POSTAL_CODE || "",
      city: locationDetailsMapping?.LOCALITY || "",
      state: locationDetailsMapping?.ADMINISTRATIVE_AREA_LEVEL_1 || "",
      country: locationDetailsMapping?.COUNTRY || "",
      placeId: location["placeId"],
    };
    this.props.onSelectLocation(locationDetails);
    if (this.props.addLocation === true) {
      this.setState({ city: "" });
    } else {
      this.setState({
        city: locationDetails.city
          ? locationDetails.city +
            ", " +
            locationDetails.state +
            ", " +
            locationDetails.country
          : locationDetails.state + ", " + locationDetails.country,
      });
    }
    this.setState({ clear: !this.state.clear });
    this.setState({ isDirty: false });
  };

  getAddressDetails = (address, placeId) => {
    let totalAddress = {};
    if (address != undefined) {
      CandidateServiceClass.getLocations(address + "?type=D")
        .then((results) => {
          totalAddress["address"] = results[0].addressComponents;
          totalAddress["latlng"] = results[0].geometry.location;
          this.handleLatLong(results[0].geometry.location);
          totalAddress["placeId"] = placeId;
          this.setLocationCity(totalAddress);
        })
        .catch((error) => {
          danger(<div>{error.response.errorDescription}</div>);
          this.setState({ city: "" });
        });
    }
  };

  render() {
    return (
      <>
        <Grid item lg={12} md={12} xs={12}>
          <Autocomplete
            disabled={
              (this.props.fromJobWizard && this.props.locationFieldDisabled) ||
              this.props.disabled
            }
            open={this.state.openLocationsAutoComplete}
            onClose={() => this.setState({ openLocationsAutoComplete: false })}
            key={this.props.addLocation ? this.state.clear : ""}
            id="free-solo-demo"
            onBlur={() => {
              if (this.props.city) {
                this.setState({ city: this.props.city });
              } else {
                this.setState({ city: "" });
              }
            }}
            options={this.state.locationsAutoFill}
            popupIcon={""}
            classes={{
              noOptions: "custom-no-options",
            }}
            className={
              this.props.showClearIcon
                ? "locationAutocomplete-displayClearIcon"
                : "locationAutocomplete"
            }
            noOptionsText={
              <span style={{ color: "#C92938" }}>
                Please select from the list
              </span>
            }
            getOptionLabel={(option) =>
              option.description ? option.description : this.state.city
            }
            value={
              this.state.city && !this.props.isCreateJob
                ? this.state.city
                : this.state.city && this.props.isCreateJob
                ? this.state.city +
                  "" +
                  this.state.state +
                  "" +
                  this.state.country
                : null
            }
            // isOptionEqualToValue={(option, value) =>
            //   option.description[0].trim() === value
            // }
            onInputChange={(_, value) => {
              if (value.length === 0) {
                if (this.state.openLocationsAutoComplete) {
                  this.setState({ openLocationsAutoComplete: false });
                }
              } else {
                if (!this.state.openLocationsAutoComplete) {
                  this.setState({ openLocationsAutoComplete: true });
                }
              }
              if (value == this.state.city) {
                this.setState({ openLocationsAutoComplete: false });
              }
            }}
            clearIcon={
              <>
                {this.props.city !== "" && (
                  <div
                    className="cursor-hover d-flex align-center"
                    onClick={() =>
                      this.props.onSelectLocation({
                        city: "",
                        state: "",
                        country: "",
                        zipCode: "",
                      })
                    }
                  >
                    <ClearIcon />
                  </div>
                )}
              </>
            }
            renderInput={(params) => (
              <TextField
                {...params}
                type="text"
                className={`${
                  this.props.fromJobWizard
                    ? " position-issue form-control1 input-group1 w-100 p-0 border-radius-4"
                    : "autocomplete-text-area autocomplete-text-field"
                }`}
                margin="normal"
                placeholder={
                  this.props.placeholder
                    ? this.props.placeholder
                    : "Enter location"
                }
                onChange={this.debouncedHandler}
                disabled={
                  (this.props.fromJobWizard &&
                    this.props.locationFieldDisabled) ||
                  this.props.disabled
                }
              />
            )}
            onChange={(event, address) =>
              this.getAddressDetails(address?.description, address?.placeId)
            }
            ListboxProps={{
              style: {
                maxHeight: "10rem",
                position: "absolute",
                top: "10px",
                backgroundColor: "white",
                zIndex: "999999999",
                maxHeight: "10rem",
                width: "100%",
              },
            }}
          />
        </Grid>
      </>
    );
  }
}

export default LocationSearchInput;
