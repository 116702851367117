import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import educationIcon from "../../../../assets/images/educationIcon.svg";

export default function Education(props) {
  const educations = props.educations;

  return (
    <>
      <Typography
        className="font-weight-600 f-16 gray9 border-bottom-1 pb-10"
      >
        Education
      </Typography>
      {educations?.length > 0 ? (
        educations?.map((education, index) => {
          return (
            <div key={index}>
              <Grid item className="mt-20 d-flex align-between">
                <Typography className="d-flex">
                  <Box>
                    <img width="45" height="45" src={educationIcon} alt="education"/>
                  </Box>
                  <Box className="pl-10 mt-5">
                    <Typography
                      variant="h4"
                      className="font-weight-600 small-text-font gray9 textEllipse w-300 width-in-galaxy history-align"
                    >
                      {education.nameOfDegree}
                    </Typography>
                    <span className="f-12 sub-text-color align-center d-flex mt-5">
                      {/* {education?.nameOfInstitute} <FiberManualRecordIcon className="px-5" /> {education?.joinedWhen?.slice(0, 4)} {(education.certifiedWhen === null || education.certifiedWhen === "") ? "" : "-"} {education?.certifiedWhen?.slice(0, 4)} </span> */}
                      {education?.universityName ? `${education?.universityName}; ` : ""}
                      {education?.nameOfInstitute}{" "}
                      {education?.certifiedWhen && (
                        <span className="d-flex align-center">
                        <FiberManualRecordIcon className="px-5" aria-label="Dot icon" />
                        </span>
                      )}{" "}
                      {education?.certifiedWhen?.slice(0, 4)}{" "}
                    </span>
                    {education?.grade ?
                    <Typography
                      className="font-weight-500 f-12 gray9"
                    >
                      {`Grade: ${education?.grade}`}
                    </Typography>
                    : ""}
                  </Box>
                </Typography>
              </Grid>
              <Grid item className="mt-10 sub-text-color">
                {education?.candidateEducationDocuments?.length > 0 && (
                  <span className="f-12">Documents</span>
                )}
                <ul className="pl-15 mt-10 documents gray9">
                  {education?.candidateEducationDocuments.map((show, index) => {
                    return (
                      <li key={index} className="mt-5">
                        <a
                          className="small-text-font sub-text-color break-word mt-10"
                          href={show.documentSignedUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {show.documentSignedUrl && decodeURIComponent(
                            show.documentSignedUrl.split("/").pop().split("?")[0]
                          )}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </Grid>
            </div>
          );
        })
      ) : (
        <Typography className="f-14 gray9 mt-10">
          No education details added.
        </Typography>
      )}
    </>
  );
}
