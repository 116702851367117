import DateFnsUtils from "@date-io/date-fns";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Button,
  Container,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Input,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  Link,
} from "@mui/material";
import debounce from "lodash/debounce";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import ApiCalls from "../../../../api/customAxios";
import { danger, success } from "../../../../helpers/message-box";
import { ReactComponent as NewNotesIcon } from "../../../../assets/images/new-notes-icon.svg";
import { ReactComponent as NewNotesStarIcon } from "../../../../assets/images/notes-star-icon.svg";
const label = { inputProps: { "aria-label": "Checkbox demo" } };
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import copy from "copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useSelector } from "react-redux";
import CandidateService from "../../../../Services/Enterprise/Candidate.service";

export default function Notes(props) {
  const enterpriseDetails = useSelector((state) => state.enterprise.enterprise);
  const userType = localStorage.getItem("userType");
  const isShowStage = (userType === "Z" || userType === "T" || userType === "R");
  const [stagesList, setStagesList] = useState([]);
  const params = useParams();
  const [jobTitleSearchKeyword, setJobTitleSearchKeyword] = useState("");
  const [notesFromDate, setNotesFromDate] = useState(new Date());
  const [notesToDate, setNotesToDate] = useState(new Date());
  const [open, setOpen] = useState(false);

  const [notes, setNotes] = useState([]);
  const [currentNote, setCurrentNote] = useState("");
  const [stage, setStage] = useState(null);
  const [selectOpenStage, setSelectOpenStage] = useState(false);
  const [notesJobs, setNotesJobs] = useState([]);
  const [rejectionnotesFilter, setRejectionnotesFilter] =
    useState("Screening Notes");
  const [showJobFilter, setShowJobFilter] = useState(false);
  const [showDateRangeFilter, setShowDateRangeFilter] = useState(false);
  const [selectedStage, setSelectedStage] = useState("");
  const [openRejectReason, setOpenRejectionReason] = useState(false);
  const handleCloseRejectReason = () => setOpenRejectionReason(false);
  const [openNotes, setOpenNotes] = useState(false);

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 250,
      },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
  };

  const getNotes = (queryParameters) => {
    props.setIsLoading(true);
    var _query = "";
    if (queryParameters) _query = queryParameters;
    ApiCalls.get(
      "enterprises/note/" +
        `${params.candidate_id ? params.candidate_id : props.candidateId}` +
        _query
    )
      .then((response) => {
        setNotes(response);
        props.setIsLoading(false);
      })
      .catch(function (error) {
        if (error.statusCode != 500) {
          danger(<div>{error.errorMessage}</div>);
        }
        props.setIsLoading(false);
      });
  };
  useEffect(() => {
    let candidateRecord = props?.candidates?.list?.filter((label) => ((label.id ? label.id : label.candidateId) === props?.candidateId));
    if (candidateRecord?.length > 0) {
      setSelectedStage(candidateRecord[0]?.currentStage);
    }
    if (props?.label) {
      setSelectedStage(props?.label);
    }
  }, [props?.candidates, props?.label]);
  useEffect(() => {
    getNotes1();
    try {
      let _stages = JSON.parse(enterpriseDetails?.customConfigurations)
                    ?.screening_label_configurations
                    ?.filter((config)=>(config.show_in_dropdown == true));
      setStagesList(_stages);
    } catch (err) {}
  }, [params.candidate_id, props.candidateId]);
  const getNotes1 = () => {
    props.setIsLoading(true);
    ApiCalls.get(
      "enterprises/note/" +
        `${params.candidate_id ? params.candidate_id : props.candidateId}` + `?isInterviewNote=false&jobId=${params.job_id ? params.job_id : props.JobId}`
    )
      .then((response) => {
        setNotes(response);
        props.setIsLoading(false);
      })
      .catch(function (error) {
        if (error.statusCode != 500) {
          danger(<div>{error.errorMessage}</div>);
        }
        props.setIsLoading(false);
      });
  };
  const getNotesRejection = (queryParameters) => {
    props.setIsLoading(true);
    var _query = "";
    if (queryParameters) _query = queryParameters;
    ApiCalls.get(
      "enterprises/note/" +
        `${params.candidate_id ? params.candidate_id : props.candidateId}` +
        _query
    )
      .then((response) => {
        setNotes(response);
        props.setIsLoading(false);
      })
      .catch(function (error) {
        if (error.statusCode != 500) {
          danger(<div>{error.errorMessage}</div>);
        }
        props.setIsLoading(false);
      });
  };
  const handleChangeRejectionNotes = (event) => {
    setNotesJobs([]);
    setJobTitleSearchKeyword("");
    let queryParameters = "?isRejectNote=" + true + `&isInterviewNote=false&jobId=${params.job_id ? params.job_id : props.JobId}`;
    if (event.target.value === "Screening Notes") {
      setRejectionnotesFilter(event.target.value);
      getNotes(`?isInterviewNote=false&jobId=${params.job_id ? params.job_id : props.JobId}`);
    } else {
      setRejectionnotesFilter(event.target.value);
      getNotesRejection(queryParameters);
    }
  };

  const getNotesJobs = (jobTitle) => {
    props.setIsLoading(true);
    setNotesJobs([]);
    ApiCalls.get(
      "enterprises/note/" +
        (params.candidate_id ? params.candidate_id : props.candidateId) +
        "?jobTitle=" +
        jobTitle +
        "&searchType=prefix_match_phrase" +
        `${
          rejectionnotesFilter === "Screening Notes" ? "" : "&isRejectNote=true"
        }` + `&isInterviewNote=false&jobId=${params.job_id ? params.job_id : props.JobId}`
    )
      .then((response) => {
        setNotesJobs(response);
        props.setIsLoading(false);
      })
      .catch(function (error) {
        if (error.statusCode != 500) {
          danger(<div>{error.errorMessage}</div>);
        }
        props.setIsLoading(false);
      });
  };

  const handleChangeSearchJob = (event) => {
    const { value } = event.target;
    setJobTitleSearchKeyword(value);
    getNotesJobs(value);
    // handleSearch(value);
  };

  const handleSearch = useCallback(
    debounce((value) => {
      getNotesJobs(value);
    }, 500),
    []
  );

  const addNote = () => {
    if (props.setIsNotesLoading) {
      props.setIsNotesLoading(true);
    } else {
      props.setIsLoading(true);
    }
    var payload = {
      jobId: params.job_id ? params.job_id : props.JobId,
      candidateId: params.candidate_id
        ? params.candidate_id
        : props.candidateId,
      note: currentNote ? currentNote : null,
      category: stage,
    };
    ApiCalls.put("enterprises/note", payload)
      .then((response) => {
        var newNote = response;
        setSelectedStage(response?.category);
        newNote["notedBy"] = localStorage.getItem("name");
        //newNote['jobTtitle'] = job;
        let _notes = notes.slice(); //creates the clone of the state
        _notes.unshift(newNote);
        if (rejectionnotesFilter === "Screening Notes") {
          setNotes(_notes);
        }
        setCurrentNote("");
        setStage(null);
        if (props.isFromMatchingDrawer && newNote?.category) {
          let updated_candidates_list = props.candidates?.list?.map((candidate)=>{
            if ((candidate.id ? candidate.id : candidate.candidateId) === props.candidateId) {
              return {
                ...candidate,
                currentStage: newNote?.category,
                stageTimestamp: new Date(),
              };
            }
            return candidate;
          });
          props.setCandidates({
            allChecked: props.candidates?.allChecked,
            list: updated_candidates_list,
          });
          if ((newNote?.category === "Application Rejected/Ineligible") && (props.status === "applied")) {
            CandidateService.getCandidateAllDetails(
              props.candidateId,
              props.JobId,
              false
            ).then((response) => {
              props.setCandidate(response);
              props.setCandidateApplication(response);
              let updated_candidates_list = props.candidates?.list?.map((candidate)=>{
                if ((candidate.id ? candidate.id : candidate.candidateId) === props.candidateId) {
                  return {
                    ...candidate,
                    currentStage: newNote?.category,
                    stageTimestamp: new Date(),
                    enterpriseProcessStatus: response.enterpriseProcessStatus,
                  };
                }
                return candidate;
              });
              props.setCandidates({
                allChecked: props.candidates?.allChecked,
                list: updated_candidates_list,
              });
            }).catch(function (error) {});
          }
        } else if (props.isFromMatchingCandidateDescription &&
          (newNote?.category === "Application Rejected/Ineligible") &&
          (props.status === "applied")
        ) {
          props.getMatchingCandidates();
        }
        success("Your notes for this candidate is added.");
        if (props.setIsNotesLoading) {
          props.setIsNotesLoading(false);
        } else {
          props.setIsLoading(false);
        }
        if (props.getCandidates) {
          props?.setIsLoading(true);
          props.getCandidates(1, 1, {});
        }
      })
      .catch(function (error) {
        if (error) {
          if (error.statusCode != 500) {
            danger(error.errorMessage);
          }
          // if (error.message === "Request failed with status code 500") {
          //   danger("For some reasons, message could not be sent.");
          // }
        }
        if (props.setIsNotesLoading) {
          props.setIsNotesLoading(false);
        } else {
          props.setIsLoading(false);
        }
      });
  };

  function handleChangeJobCheckbox(event, job) {
    setNotesJobs(
      notesJobs.map((_job) => {
        return _job.id === job.id
          ? {
              id: _job.id,
              jobTitle: _job.jobTitle,
              isSelected: event.target.checked,
            }
          : _job;
      })
    );
  }

  const filterNotes = () => {
    let formatedData = [];
    if (notesJobs) {
      var queryParameters = "";
      var index = 0;
      if (notesJobs.length > 0) {
        notesJobs.forEach(function (job) {
          if (job.isSelected === true) {
            if (index === 0) queryParameters = "?jobTitle=" + job.jobTitle;
            else {
              queryParameters = queryParameters + "&jobTitle=" + job.jobTitle;
            }
            index++;
          }
        });
      }
      if (notesFromDate && notesToDate) {
        if (notesFromDate > notesToDate) {
          danger("To date cannot be less than from date.");
          props.setIsLoading(false);
          return;
        }
      }
      if (notesFromDate || notesToDate) {
        if (
          moment(notesFromDate).format("YYYY-MM-DD") === "Invalid date" ||
          moment(notesToDate).format("YYYY-MM-DD") === "Invalid date"
        ) {
          danger("Please select both dates.");
          props.setIsLoading(false);
          return;
        }
      }
      if (
        showDateRangeFilter === true &&
        moment(notesFromDate).format("YYYY-MM-DD") !== "Invalid date" &&
        moment(notesToDate).format("YYYY-MM-DD") !== "Invalid date"
      ) {
        if (notesJobs.length === 0) {
          queryParameters =
            "?from=" +
            moment(notesFromDate).format("YYYY-MM-DD") +
            "&to=" +
            moment(notesToDate).format("YYYY-MM-DD");
        } else {
          queryParameters =
            queryParameters +
            "&from=" +
            moment(notesFromDate).format("YYYY-MM-DD") +
            "&to=" +
            moment(notesToDate).format("YYYY-MM-DD");
        }
      }
      if (rejectionnotesFilter === "Rejection Notes") {
        queryParameters = queryParameters + "&isRejectNote=" + true;
      }
      queryParameters = queryParameters + `&isInterviewNote=false&jobId=${params.job_id ? params.job_id : props.JobId}`;
      if (rejectionnotesFilter === "Screening Notes") {
        getNotes(queryParameters);
      } else {
        getNotesRejection(queryParameters);
      }
    }
  };
  const openJobs = (notes)=> {
// alert(JSON.stringify(notes));
window.open(`/enterprise/jobs/${notes.jobId}/matching-candidates`,  "_blank");
  };

  const resetFilters = () => {
    setShowJobFilter(false);
    setShowDateRangeFilter(false);
    setJobTitleSearchKeyword("");
    setNotesFromDate(null);
    setNotesToDate(null);
    setRejectionnotesFilter("Screening Notes");
    setNotesJobs([]);
    getNotes(`?isInterviewNote=false&jobId=${params.job_id ? params.job_id : props.JobId}`);
  };
  const handleKeyDown = (event, value) => {
    if (event.key === 'Enter') {
      setRejectionnotesFilter(value);
    }
  };

  const handleSelectStage = (event) => {
    if (event.target.value === "--Select--") {
      setStage(null);
    } else {
      setStage(event.target.value);
    }
  };
  const copyToClipboard = (event, jobReferenceNumber) => {
    event.stopPropagation();
    event.preventDefault();
    copy(jobReferenceNumber);
    success("Reference number is copied.");
  };
  return (
    <>
      <Grid spacing={1} item lg={9} md={9} xs={12} className="p-20">
        <Typography className="">
          <Typography className="font-weight-500 f-14 gray9">
          Notes for Candidate
          </Typography>
          <TextField
            variant="outlined"
            aria-label="minimum height"
            multiline={true}
            rows={2}
            type="text"
            value={currentNote}
            onChange={(event) => setCurrentNote(event.target.value)}
            margin="normal"
            autoFocus={true}
            name="applicationRemarks"
            className="form-control input-group textarea-description inputcontrasts add-message-input"
            placeholder="Enter your text here"
          />
          {(params.job_id || props?.JobId) && isShowStage && enterpriseDetails?.customConfigurations &&
          <Grid item className="mt-10 mb-20">
            <Typography className="font-weight-500 f-14 gray9">
              Select stage
            </Typography>
            <Grid container lg={12} md={12} xs={12} className="rating-category">
              <Select
                className="w-100 mb-0 job-category rating-select-align form-control input-group"
                tabIndex={0}
                value={stage ? stage : "--Select--"}
                open={selectOpenStage}
                onClose={() => setSelectOpenStage(false)}
                onOpen={() => setSelectOpenStage(true)}
                onKeyPress={() => setSelectOpenStage(true)}
                onChange={handleSelectStage}
                MenuProps={MenuProps}
                displayEmpty
              >
                <MenuItem value="--Select--">--Select--</MenuItem>
                {stagesList?.map((config, index)=><MenuItem key={index} value={config.label} disabled={config.label?.toLowerCase() == (selectedStage ? selectedStage.toLowerCase() : "")}>{config.label}</MenuItem>)}
              </Select>
            </Grid>
          </Grid>
          }
          <Box className="mt-10 justify-end d-flex w-100">
            <Button
              onClick={addNote}
              disabled={(params.job_id || props.JobId) ? (currentNote.trim().length === 0 && !stage) : currentNote.trim().length === 0}
              className="btn secondary-button f-14 ml-20"
              disableRipple="true"
            >
              Submit
            </Button>
          </Box>

          <Box>
            <Typography className="font-weight-600 f-16 gray9 border-bottom-1 pb-10 ">
              All Notes
            </Typography>
          </Box>

          <Box className="mt-10 note-jerk">
            <Button
              onClick={() => {
                setShowJobFilter((prevState) => !prevState);
                setJobTitleSearchKeyword("");
                setNotesJobs([]);
              }}
              // className={showJobFilter ? "chip-dropdown-active" : "chip-dropdown"}
              className="chip-dropdown ml-0"
              disableRipple="true"
            >
              Search Job
              {showJobFilter === true ? (
                <KeyboardArrowUpIcon aria-label="Arrow icon" />
              ) : (
                <KeyboardArrowDownIcon aria-label="Arrow icon" />
              )}
            </Button>

            <Button
              onClick={() => {
                setShowDateRangeFilter((prevState) => !prevState);
                setNotesFromDate(null);
                setNotesToDate(null);
              }}
              // className={showDateRangeFilter ? "chip-dropdown-active" : "chip-dropdown"}
              className="chip-dropdown"
              disableRipple="true"
            >
              Dates
              {showDateRangeFilter === true ? (
                <KeyboardArrowUpIcon aria-label="Arrow icon" />
              ) : (
                <KeyboardArrowDownIcon aria-label="Arrow icon" />
              )}
            </Button>
            {!enterpriseDetails?.customConfigurations ?
            <Button
              onClick={() => {
                setNotesFromDate(null);
                setNotesToDate(null);
              }}
              className="chip-dropdown-active"
              disableRipple="true"
            >
              {rejectionnotesFilter}
              {/* <KeyboardArrowDownIcon aria-label="Arrow icon" /> */}
            </Button>
            : ""}
          </Box>
          {showJobFilter === true && (
            <Grid item className="mt-20">
              <Typography>
                <Box className="font-weight-500 f-12">Search Job</Box>
                <Input
                  type="text"
                  name="searchJobTitle"
                  id="searchJobTitle"
                  value={jobTitleSearchKeyword}
                  onChange={handleChangeSearchJob}
                  className="form-control input-group m-b-0 inputcontrasts"
                  margin="normal"
                  placeholder="Enter job name"
                />
              </Typography>

              <Grid
                container
                item
                spacing={1}
                lg={12}
                md={12}
                xs={12}
                className="mt-15 pl-5"
              >
                {notesJobs.map((job, index) =>
                  notesJobs.length > 0 ? (
                    <>
                      <Grid
                        item
                        lg={4}
                        md={4}
                        xs={4}
                        className="assess-question"
                      >
                        <FormControlLabel
                          className="small-text-font font-weight-500 assessment-details"
                          control={
                            <Checkbox
                              onChange={(event) =>
                                handleChangeJobCheckbox(event, job)
                              }
                              sx={{
                                color: "#98A2B3",
                                "&.Mui-checked": {
                                  color: getComputedStyle(document.documentElement).getPropertyValue('--primary-color'),
                                },
                              }}
                            />
                          }
                          label={job.jobTitle}
                        />
                      </Grid>
                    </>
                  ) : (
                    <p className="ml-10 f-14 mt-0">No jobs found</p>
                  )
                )}
                {props.isLoading === false &&
                  notesJobs.length === 0 &&
                  showJobFilter === true &&
                  jobTitleSearchKeyword !== "" && (
                    <p className="ml-10 f-14 mt-0">No jobs found</p>
                  )}
              </Grid>
            </Grid>
          )}

          {showDateRangeFilter === true && (
            <Grid item className="mt-10">
              <Typography>
                <Box className="font-weight-500 f-12">Date From</Box>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    className="form-control input-group inputcontrasts"
                    value={notesFromDate}
                    onChange={(newValue) => {
                      setNotesFromDate(newValue);
                    }}
                    format="MMM dd, yyyy"
                    // emptyLabel="Date from"
                    placeholder="Date from"
                    disableFuture={true}
                  />
                </MuiPickersUtilsProvider>
              </Typography>
              <Typography className="mt-10">
                <Box className="font-weight-500 f-12">Date To</Box>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    className="form-control input-group inputcontrasts"
                    value={notesToDate}
                    onChange={(newValue) => {
                      setNotesToDate(newValue);
                    }}
                    format="MMM dd, yyyy"
                    placeholder="Date to"
                    disableFuture={true}
                  />
                </MuiPickersUtilsProvider>
              </Typography>
            </Grid>
          )}
          {!enterpriseDetails?.customConfigurations ?
          <Grid item className="mt-10">
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={rejectionnotesFilter}
              onChange={handleChangeRejectionNotes}
            >
              <FormControlLabel
                value="Screening Notes"
                className="notes-radio"
                control={<Radio
                  tabindex={0}
                  sx={{
                    color: "#98A2B3",
                    "&.Mui-checked": {
                      color: getComputedStyle(document.documentElement).getPropertyValue('--primary-color'),
                    }
                  }}/>}
                label="Screening Notes"
                onKeyDown={(event) => handleKeyDown(event, "Screening Notes")}
              />
              <FormControlLabel
                value="Rejection Notes"
                className="notes-radio"
                control={<Radio
                  tabindex={0}
                  sx={{
                    color: "#98A2B3",
                    "&.Mui-checked": {
                      color: getComputedStyle(document.documentElement).getPropertyValue('--primary-color'),
                    }
                  }}/>}
                label="Rejection Notes"
                onKeyDown={(event) => handleKeyDown(event, "Rejection Notes")}
              />
            </RadioGroup>
          </Grid>
          : ""}
          {(showJobFilter || showDateRangeFilter === true) && (
            <Grid item className="mt-15 justify-end d-flex align-center">
              <span
                onClick={resetFilters}
                tabIndex={0}
                onKeyPress={resetFilters}
                className="link-color pr-15 font-weight-500 f-14"
              >
                Reset
              </span>
              <Button
                onClick={filterNotes}
                className="btn primary-button mr-10"
                disableRipple="true"
              >
                Apply
              </Button>
            </Grid>
          )}

          <Grid item lg={12} md={12} xs={12} className="">
            {notes.map((note, index) =>
              notes.length > 0 ? (
                <Grid
                  item
                  lg={12}
                  md={12}
                  xs={12}
                  className="border-bottom-1 mt-20"
                >
                  {note.jobTitle ? (
                    <Grid item lg={12} md={12} xs={12} className="d-flex cursor-hover" onClick={(e) => openJobs(note)}>
                      <NewNotesIcon width="40px" height="40px" />
                      <Typography className="pl-10">
                        <Typography className="gray9 f-16 font-weight-600">
                          {note.jobTitle}
                          {/* {note.jobTitle? note.jobTitle : "-"} */}
                        </Typography>
                        <Typography className="f-14 gray9 font-weight-400 d-flex align-center">
                          <Box className="d-flex align-center gray9 f-14 display-flex align-items-center">
                            Job Reference #: {note?.jobReferenceNumber}
                            <Link
                              onClick={(e) => copyToClipboard(e, note?.jobReferenceNumber)}
                              onKeyPress={(e) => copyToClipboard(e, note?.jobReferenceNumber)}
                              className="cursor-hover display-flex p-3"
                              tabIndex={0}
                            >
                              <ContentCopyIcon
                                style={{ width: "14px", height: '14px', margin: "0" }}
                                className="gray7"
                              />
                            </Link>
                          </Box>
                        </Typography>
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid item lg={12} md={12} xs={12} className="d-flex">
                      <NewNotesIcon width="40px" height="40px" />
                      <Typography className="gray9 f-16 font-weight-600 align-item-center pl-10">
                        <span>-</span>
                      </Typography>
                    </Grid>
                  )
                  }
                  <Grid item lg={12} md={12} xs={12} className="d-flex pt-10">
                    <Typography className="w-20 h-20">
                      <NewNotesStarIcon width="20px" height="20px" />
                    </Typography>
                    <Typography className="f-14 gray9 font-weight-400 pl-10">
                      {note.note ? note.note : "-"} <br /> {note.shortlistRemarks}
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} xs={12} className="pb-20">
                    <Typography className="pl-30 d-flex align-center pt-5">
                      <span className="gray7 f-12">
                        Noted by {note.notedBy}
                      </span>
                      {isShowStage && enterpriseDetails?.customConfigurations && note.category && (note.category !== "SHORTLIST" && note.category !== "REJECT") ?
                      <span className="pl-10 d-flex align-center">
                        <FiberManualRecordIcon
                          className="gray7 f-10 mx-2"
                          aria-label="Dot icon"
                        />{" "}
                        <span className="gray7 f-12 pl-10">
                          <span>
                            At Stage
                          </span>
                          <span className="font-weight-700">
                            {" "}
                            {note.category}
                          </span>
                        </span>
                      </span>
                      : ""}
                      <span className="pl-10 d-flex align-center">
                        <FiberManualRecordIcon
                          className="gray7 f-10 mx-2"
                          aria-label="Dot icon"
                        />{" "}
                        <span className="gray7 f-10 pl-10">
                          {" "}
                          {moment(note.createdTimestamp).format(
                            "MMM DD, YYYY"
                          )}
                        </span>
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              ) : null
            )}
          </Grid>

          <Grid>
            {notes.length === 0 && (
              <Typography className="text-center mt-15 sub-text-color f-12">
                No notes found for this candidate.
              </Typography>
            )}
          </Grid>

          {/* <Grid item className="mt-30">
            <TableContainer className="Notes-table" component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: "5px" }}></TableCell>
                    <TableCell style={{ width: "95px" }}>Date</TableCell>
                    <TableCell
                      style={{ width: "100px" }}
                      className="notedby-align"
                    >
                      Noted By
                    </TableCell>
                    <TableCell style={{ width: "200px" }}>Notes</TableCell>
                    <TableCell style={{ width: "100px" }}>Job</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {notes.map((note, index) =>
                    notes.length > 0 ? (
                      <>
                        <TableRow
                          key={note.id}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {index + 1}.
                          </TableCell>
                          <TableCell>
                            {moment(note.createdTimestamp).format(
                              "MMM DD, YYYY HH:mm"
                            )}
                          </TableCell>
                          <TableCell className="word-break">
                            {note.notedBy}
                          </TableCell>
                          <TableCell className="word-break">
                            {note.note} <br /> {note.shortlistRemarks}
                          </TableCell>
                          <TableCell
                            className={
                              note.jobTitle
                                ? "word-break"
                                : "word-break gray9 pl-10"
                            }
                          >
                            {note.jobTitle ? (
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`/enterprise/jobs/${note.jobId}`}
                                className="link-color text-decoration-none"
                              >
                                {note.jobTitle}
                              </a>
                            ) : (
                              "-"
                            )}
                          </TableCell>
                        </TableRow>
                      </>
                    ) : null
                  )}
                </TableBody>
              </Table>
              {notes.length === 0 && (
                <Typography className="text-center mt-15 sub-text-color f-12">
                  No notes found for this candidate.
                </Typography>
              )}
            </TableContainer>
          </Grid> */}
        </Typography>
      </Grid>
    </>
  );
}
