import React, { useRef, useState, useEffect, useMemo } from "react";
import { Grid, Typography, Box, Tooltip, Popover, Button, Tabs, Tab, Link } from "@mui/material";
import PropTypes from "prop-types";
import { formatMoney } from "../../../../helpers/helper-data";
import CloseIcon from "@mui/icons-material/Close";
import { functiondisable, functionenable } from "../../../../helpers/helper";
// import { Link } from "react-router-dom";
import { ReactComponent as DetailCTA } from "../../../../assets/images/job-detail-cta.svg";
import { ReactComponent as LocationIcon } from "../../../../assets/images/location-new-icon.svg";
import { ReactComponent as JobReferenceNumberIcon } from "../../../../assets/images/jobRefNumberIcon.svg";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import copy from "copy-to-clipboard";
import { ReactComponent as ExternalJobIdIcon } from "../../../../assets/images/externalJobIdIcon.svg";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import JobMenu from "./JobMenu";
import JobDetails from "../../JobSearch/JobDetails";
import { getLocation } from '../../../../helpers/helper-data';
import { success } from "../../../../helpers/message-box";
import { useSelector } from "react-redux";

const label = { inputProps: { "aria-label": "Switch demo" } };
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    style: {
      fontSize: "14px",
      fontWeight: "500",
      color: "#1D2939",
    },
  };
}

export default function Job(props) {
  const enterpriseDetails = useSelector((state) => state.enterprise);
  const job = props.job;
  const [tabValue, setTabValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const [jobType, setJobType] = useState([]);
  useEffect(() => {
    setJobType(
      job?.jobType?.replace(/\s|\[|\]/g, "")
        ? job?.jobType?.replace(/\s|\[|\]/g, "")?.split(",")
        : []
    );
  }, [job]);
  const [viewMoreSkills, setViewMoreSkills] = useState(false);
  const [viewLocations, setViewLocations] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event, candidate) => {
    setAnchorEl(event.currentTarget);
    setViewMoreSkills(!viewMoreSkills);
    functiondisable();
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setViewMoreSkills(!viewMoreSkills);
    functionenable();
  };
  const open3 = Boolean(anchorEl);
  // const id = open3 ? 'simple-popover-list' : undefined;

  const [viewMoreJobType, setViewMoreJobType] = useState(false);
  const [viewJobDetailDrawer, setViewJobDetailDrawer] = useState(false);

  const [anchorEl2, setAnchorEl2] = React.useState(null);

  useEffect(() => {
    const bodyElement = document.body;
    const htmlElement = document.documentElement;
    if (viewJobDetailDrawer) {
      bodyElement.style.overflow = "hidden";
      htmlElement.style.overflow = "hidden";
    } else {
      bodyElement.style.overflow = "";
      htmlElement.style.overflow = "";
    }
  }, [viewJobDetailDrawer]);

  const handleOpenJobType = (event) => {
    setAnchorEl2(event.currentTarget);
    setViewMoreJobType(!viewMoreJobType);
    functiondisable();
  };

  const handleCloseJobType = () => {
    setAnchorEl2(null);
    setViewMoreJobType(!viewMoreJobType);
    functionenable();
  };

  const openJobType = Boolean(anchorEl2);

  function currencyFormat(num) {
    if (num) {
      return "$" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    } else return "N/A";
  }
  const MemoizedJobDetails = useMemo(() => (
      <JobDetails
      setViewJobDetailDrawer={setViewJobDetailDrawer}
      viewJobDetailDrawer={viewJobDetailDrawer}
      job={job}
      setRefreshJobHeader={props.setRefreshJobHeader}
      setIsLoading1={props.setIsLoading}
    />
  ), [setViewJobDetailDrawer, viewJobDetailDrawer, job, props.setRefreshJobHeader]);
    const MemoizedJobMenu = useMemo(() => (
       <JobMenu
      job={job}
      index={0}
      value={props.value}
      setRefreshJobHeader={props.setRefreshJobHeader}
    />
  ), [job, props.value, props.setRefreshJobHeader]);

  function remainingSkills() {
    let text = "";
    for (let i = 3; i < job?.jobSkills?.length; i++) {
      text += job.jobSkills[i].skills.keyword + " ";
    }
    return text;
  }

  const getPaymentTerm = (type) => {
    switch (type) {
      case "WK":
        return "/week";
      case "HR":
        return "/hour";
      case "MN":
        return "/month";
      case "DA":
        return "/day";
      default:
        return "";
    }
  };

  const getJobType = (viewAll) => {
    let formatted_jobType = [];
    if (jobType?.length) {
      if (jobType.includes("FT")) {
        formatted_jobType.push("Full Time");
      }
      if (jobType.includes("PT")) {
        formatted_jobType.push("Part Time");
      }
      if (jobType.includes("FL")) {
        formatted_jobType.push("Contingent / Contract");
      }
      if (jobType.includes("IN")) {
        formatted_jobType.push("Internship");
      }
      if (jobType.includes("OT")) {
        formatted_jobType.push("Other");
      }
      if (!viewAll && formatted_jobType.length > 2) {
        formatted_jobType = formatted_jobType.slice(0, 2);
      }
      return formatted_jobType.join(", ");
    } else {
      return "-";
    }
  };

  const convertPayment = (value) => {
    if (value >= 99999) {
      return Math.floor(value / 1000) + "K";
    }
    return value;
  };

  const copyToClipboard = (event, jobReferenceNumber) => {
    event.stopPropagation();
    copy(jobReferenceNumber);
    success("Reference number is copied.");
  };

  const jobDetailPage = (jobId) => {
    setViewJobDetailDrawer(true);
    // window.open(`/enterprise/jobs/${jobId}`, "_blank");
  };

  return (
    <>
      <Grid>
        <Grid container lg={12} md={12} xs={12} spacing={1} className="responsive-block galaxy-block">
          <Grid item lg={7.5} md={7.5} xs={12}>
            <Typography className="main-text-color f-20 mt-2 font-weight-600 descriptionSpace">
              {job.jobTitle}
            </Typography>
            <Typography className="d-content align-center">
              {/* <Typography className="mr-10">
                {job.status == "O" && <Box>Open</Box>}
                {job.status == "C" && <Box>Closed</Box>}
                {job.status == "D" && <Box>Dropped</Box>}
              </Typography> */}
              <Typography className="align-center mt-5 galaxy-block">
                <Typography className="d-inline-flex mr-10 mt-2">
                  { job.status == 'O' &&
                    <Box className="open-status d-flex align-center header-inline-flex">
                      <FiberManualRecordIcon className="success-color f-12 mr-5" />
                      <span>Open</span>
                    </Box>
                  }
                  { job.status == 'C' &&
                    <Box className="closed-status d-flex align-center">
                      <FiberManualRecordIcon className="blue-color f-12 mr-5" />
                      <span>Closed</span>
                  </Box>
                  }
                  { job.status == 'D' &&
                    <Box className="drop-status d-flex align-center">
                      <FiberManualRecordIcon className="f-12 mr-5" style={{ color: '#B42318' }} />
                      <span>Dropped / Cancelled</span>
                  </Box>
                  }
                  { job.status == 'H' &&
                    <Box className="drop-status d-flex align-center">
                      <FiberManualRecordIcon className="f-12 mr-5" style={{ color: '#B42318' }} />
                      <span>Hold</span>
                  </Box>
                  }
                </Typography>
                {(job?.country || job?.city || job?.state) && (
                  <Typography className="d-inline-flex mr-10 mt-2">
                  <Box className="f-14 gray9 font-weight-400 d-flex align-center ml-5 galaxy-margin-top-5">
                    <Box className="d-flex align-center mr-5">
                      <LocationIcon width="15px" />
                    </Box>
                    <Box className="d-flex align-center gray9 f-14">
                    {getLocation(job)}
                      {/* {job.city?.trim()}
                      {job?.city?.trim() ? ", " : ""}{job.state}
                      {(job?.state?.trim() && job?.country?.trim()) ? ", " : ""}{job.country} */}
                      {/* {(job?.city === "" || job?.city === null) &&
                        (job.country === "" || job.country === null) && (
                          <span style={{ display: "block" }}>-</span>
                        )} */}
                    </Box>
                  </Box>
                </Typography>
                )}
                {(job?.jobReferenceNumber) && (
                  <Typography className="d-inline-flex mr-10 mt-2">
                  <Box className="f-14 gray9 font-weight-400 d-flex align-center ml-5">
                    <Box className="d-flex align-center mr-5">
                      <JobReferenceNumberIcon width="15px" />
                    </Box>
                    <Box className="d-flex align-center gray9 f-14 display-flex align-items-center">
                      Job Reference #: {job?.jobReferenceNumber}
                      <Link
                        onClick={(e) => copyToClipboard(e, job?.jobReferenceNumber)}
                        onKeyPress={(e) => copyToClipboard(e, job?.jobReferenceNumber)}
                        className="cursor-hover display-flex ml-3"
                        tabIndex={0}
                      >
                      <ContentCopyIcon
                        style={{ width: "16px", margin: "0" }}
                        className="gray7"
                      />
                      </Link>
                    </Box>
                  </Box>
                </Typography>
                )}
                {(job?.externalJobId) && (
                  <Typography className="d-inline-flex mt-2">
                  <Box className="f-14 gray9 font-weight-400 d-flex align-center ml-5">
                    <Box className="d-flex align-center mr-5">
                      <ExternalJobIdIcon width="15px" />
                    </Box>
                    <Box className="d-flex align-center gray9 f-14">
                      External Job ID: {job?.externalJobId}
                    </Box>
                  </Box>
                </Typography>
                )}
              </Typography>
            </Typography>
          </Grid>
          <Grid item lg={4.5} md={4.5} xs={12} className="d-flex justify-end align-center add-checklist-align matching-details-align galaxy-block">
            {(props.jobStatus != 0) && enterpriseDetails?.enterprise?.customConfigurations && JSON.parse(enterpriseDetails?.enterprise?.customConfigurations)?.location_ribbon_configurations && ['Z', 'T', 'R'].includes(localStorage.getItem("userType")) && (
              <Grid>
                {!viewLocations &&
                  <Box className="f-14 font-weight-600 link-color text-decoration-underline" onClick={() => setViewLocations(true)}>
                    View Locations
                  </Box>
                }
                {viewLocations &&
                  <Box className="f-14 font-weight-600 link-color text-decoration-underline" onClick={() => setViewLocations(false)}>
                    Hide Locations
                  </Box>
                }
              </Grid>
            )}
            <Button
              className="btn primary-button mr-10 gal-slot-align ml-10"
              tabIndex={0}
              onClick={() => jobDetailPage(job.id)}
              onKeyPress={() => jobDetailPage(job.id)}
            >
              View Job Details
            </Button>
            <Typography
              className="job-menu border-radius-8 d-flex align-center cursor-hover galaxy-job-menu matching-menu"
            >
              {/* <JobMenu
                job={job}
                index={0}
                value={props.value}
                // refreshTime={refreshTime}
                setRefreshJobHeader={props.setRefreshJobHeader}
                // jobDropped={() => dropJob(0)}
              /> */}
              {MemoizedJobMenu}
            </Typography>
          </Grid>
        </Grid>
        {viewLocations && props.jobStatus != 0 && (
          <Grid className="mt-20 job-location">
            <Box className="access-control-tabs">
              <Tabs
                value={tabValue}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                {props.locationCounts && props.locationCounts.map((location, index) => (
                  <Tab
                    key={index}
                    disableRipple='true'
                    onClick={() => {
                      if (location?.count) {
                        if (props.loctionResponseFilter == location?.regionCode) {
                          let statusList = props.toShowInterview ? ['', 'A', 'S', 'I', 'F', 'O', 'B'] : ['', 'A', 'S', 'F', 'O', 'B'];
                          props.setLoctionResponseFilter(null);
                          props.getCandidatesByStatus(statusList[props.jobStatus], true);
                        } else {
                          props.setLoctionResponseFilter(location?.regionCode);
                          props.getResponseBasedLocationCandidate(location?.regionCode, props.jobStatus);
                        }
                      }
                    }}
                    label={
                      <Grid className={`location-card p-relative ${props.loctionResponseFilter == location?.regionCode ? "location-card-active" : ""}`}>
                        <Tooltip
                          title={location?.city && location?.region ? `${location?.city}, ${location?.region}` : "Others"}
                          placement="top"
                          trigger="mouseenter"
                          animation="fade"
                          arrow
                          classes={{ tooltip: location?.city && location?.region ? 'tooltipLocation' : 'tooltipLocation other-location' }}
                        >
                          <Typography>
                            <Typography className="f-20 font-weight-500 gray9 hov-color align-left">
                              {location?.regionCode}
                            </Typography>
                            <Typography className="mt-5 step-color f-30 font-weight-500 hov-color align-right">
                              {location?.count}
                            </Typography>
                          </Typography>
                        </Tooltip>
                      </Grid>
                    }
                    {...a11yProps(0)}
                  />
                ))}
              </Tabs>
            </Box>
          </Grid>
        )}
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        xs={12}
        className="align-between jobListFilter"
      >
        <Grid
          item
          lg={12}
          md={12}
          xs={12}
          className="d-flex jobListFilter userDetails adding-skills jobDetail-user responsive-tabs"
        >
          {/* <Grid item lg={3} md={3} xs={12} className="pr-10">
            <Typography className="font-weight-600 descriptionSpace">
              {job.jobTitle}
            </Typography>
            <Link
              className="c-white f-12 font-weight-400 mt-5 cursor-hover"
              tabIndex={0}
              to=''
              onClick={() => jobDetailPage(job.id)}
              onKeyPress={() => jobDetailPage(job.id)}
            >
              View Details
            </Link>
          </Grid> */}

          {/* <Grid item lg={2} md={2} xs={12}>
            <Typography className="descriptionSpace mr-15 jobHeader-align">
              <Box className="f-12">Pay Rate</Box>
              <Box className="f-14 break-word">
                ${ job?.budget }
                <span>
                  $
                </span>
                <span>
                  {convertPayment(job && job.budgetMinimum ? job.budgetMinimum : 0)} to{" "}
                </span>
                <span>
                  {convertPayment(job && job.budgetMaximum ? job.budgetMaximum : 0)}{" "}
                  {getPaymentTerm(job?.paymentTerm)}
                </span>
              </Box>
            </Typography>
          </Grid> */}

          {/* <Grid item lg={3} md={3} xs={12} className="d-flex">
            <Grid item lg={7} md={7} xs={7}>
              <Typography className="descriptionSpace matching-list jobHeader-align">
                <Box className="f-12">Work Type</Box>
                <Box className="f-14">
                  {getJobType(false)}
                  {
                    jobType?.length > 2 && (
                      <Typography
                        className="cursor-hover text-decoration-none d-inline-flex f-14 font-weight-600"
                        onClick={(e) => handleOpenJobType(e)}
                        tabIndex={0}
                        onKeyPress={(e) => handleOpenJobType(e)}
                      >
                        {`+${jobType?.length - 2} more`}
                      </Typography>
                    )
                  }
                </Box>
              </Typography>
            </Grid>
            <Grid item lg={5} md={5} xs={5}>
              <Typography className="descriptionSpace matching-list jobHeader-align">
                <Box className="f-12">No. of Openings</Box>
                <Box className="f-14">{job.numberOfOpenings}</Box>
              </Typography>
            </Grid>
          </Grid>

          <Grid item lg={6} md={6} xs={12} className="d-flex jobListFilter userDetails adding-skills responsive-tabs">
            <Grid item lg={3.6} md={3.6} xs={12}>
              <Typography className="descriptionSpace matching-list jobHeader-align">
                <Box className="f-12">Preferred Job Experience</Box>
                <Box className="f-14 break-word">
                  <span>
                    {job && job.overallExperienceMinimum
                      ? job.overallExperienceMinimum
                      : 0}{" "}
                    -{" "}
                  </span>
                  <span>
                    {job && job.overallExperienceMaximum
                      ? job.overallExperienceMaximum
                      : 0}{" "}
                    Years
                  </span>
                </Box>
              </Typography>
            </Grid>

            <Grid item lg={4.4} md={4.4} xs={12}>
              <Typography className="matchingSkills descriptionSpace matching-list jobHeader-align">
                <Box className="f-12">Skills</Box>
                {job?.jobSkills?.length <= 0 ? <Box>-</Box> : <Box>
                  {job?.jobSkills?.map((skill, index) => {
                    return index < 1 ? (
                      <span key={index} className="skills">
                        {skill.skills.keyword}<span className="text-transform-none">{" [ " + skill.skills.subcategoryKeyword + " - "+ skill.skills.industry + " ]"}</span>
                      </span>
                    ) : null;
                  })}
                  {
                    job?.jobSkills?.length > 1 && (
                      <Typography
                        className="cursor-hover text-decoration-none d-inline-flex f-14 font-weight-600"
                        onClick={(e) => handleClick(e)}
                        tabIndex={0}
                        onKeyPress={(e) => handleClick(e)}
                      >
                        {`+${job.jobSkills?.length - 1} more`}
                      </Typography>
                    )
                    // <Tooltip title={remainingSkills()} placement="top">
                    //     <span className="f-14" >  + {job?.jobSkills?.length-3} more </span>
                    // </Tooltip>
                  }
                </Box>}
              </Typography>
            </Grid>
            <Grid item lg={0.5} md={0.5} xs={12}></Grid>
            <Grid item lg={3.5} md={3.5} xs={12}>
              <Typography className="descriptionSpace matching-list jobHeader-align">
                <Box className="f-12">Location</Box>
                <Box className="f-14">
                  {job.city}
                  {job?.city ? "," : ""} {job.country}
                  {(job?.city === "" || job?.city === null) &&
                    (job.country === "" || job.country === null) && (
                      <span style={{ display: "block" }}>-</span>
                    )}
                </Box>
              </Typography>
            </Grid>
          </Grid> */}
        </Grid>
      </Grid>
      <Popover
        id="simple-popover-list"
        open={open3}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <Grid item sx={{ p: 1 }}>
          <Box className="align-between mb-5">
            <Grid item xs={12}>
              <Typography
                id="modal-title"
                className="d-flex align-between p-10"
              >
                <Typography variant="h5" className="f-16 font-weight-600 heading-text-color">
                  Skills
                </Typography>
                <Typography
                  align="right"
                  className="cursor-hover sub-text-color icon-button"
                >
                  <CloseIcon
                    onClick={handleClosePopover}
                    onKeyPress={handleClosePopover}
                    aria-label="Close icon"
                    tabIndex={0}
                  />
                </Typography>
              </Typography>
            </Grid>
          </Box>
          <Grid
            item
            className="p-10"
            style={{ maxHeight: "250px", overflow: "auto", maxWidth: "300px" }}
          >
            <Box>
              {job?.jobSkills?.map((skill, index) => {
                return (
                  <span key={index} className="skills">
                    {skill.skills.keyword}
                    <span className="text-transform-none">
                      {" [ " +
                        skill.skills.subcategoryKeyword +
                        " - " +
                        skill.skills.industry +
                        " ]"}
                    </span>
                  </span>
                );
              })}
            </Box>
          </Grid>
        </Grid>
      </Popover>
      <Popover
        id="simple-popover-list"
        open={openJobType}
        anchorEl={anchorEl2}
        onClose={handleCloseJobType}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <Grid item sx={{ p: 1 }}>
          <Box className="align-between">
            <Grid item xs={12}>
              <Typography
                id="modal-title"
                className="d-flex align-between p-10"
              >
                <Typography variant="h5" className="f-16 font-weight-600 heading-text-color">
                  Work Type
                </Typography>
                <Typography
                  align="right"
                  className="cursor-hover sub-text-color icon-button"
                >
                  <CloseIcon
                    onClick={handleCloseJobType}
                    onKeyPress={handleCloseJobType}
                    aria-label="Close icon"
                    tabIndex={0}
                  />
                </Typography>
              </Typography>
            </Grid>
          </Box>
          <Grid
            item
            className="pl-10 pr-10 pb-10"
            style={{ maxHeight: "250px", overflow: "auto", maxWidth: "300px" }}
          >
            <Box className="font-family">{getJobType(true)}</Box>
          </Grid>
        </Grid>
      </Popover>
      {viewJobDetailDrawer && MemoizedJobDetails }
      {/* <JobDetails
        setViewJobDetailDrawer={setViewJobDetailDrawer}
        viewJobDetailDrawer={viewJobDetailDrawer}
        job={job}
        setRefreshJobHeader={props.setRefreshJobHeader}
        setIsLoading1={props.setIsLoading}
      /> */}
    </>
  );
}

// export const functionenable = () => {
//     window.onscroll = function() {};
// }
