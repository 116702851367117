import React, { useState, useEffect } from "react";
import { Typography, Modal, Box, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ReactPlayer from "react-player";

function CandidateProfileVideoPreview({
  open,
  setOpen,
  videoSrc,
  candidateName,
}) {
  const handleClose = () => {
    setOpen(false);
  };
  const videoLinkRegex =
    /^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be|player\.vimeo\.com|vimeo\.com|dailymotion\.com|facebook\.com|player\.twitch\.tv|twitch\.tv|dai\.ly|videos\.kaltura\.com|storage\.googleapis\.com)\S*/;
  let youtubeRegex =
    /^(https?\:\/\/)?(www\.)?(youtube\.com\/user|youtu\.?be\/user)\S*/;
  const urlvalidation =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}\/[^\s]{3,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}\/[^\s]{3,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}\/[^\s]{3,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}\/[^\s]{3,})/gi;

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-content inputcontrasts">
          <Grid container item id="modal-title">
            <Grid item xs={6} lg={6} md={6}>
              <Typography className="f-20 font-weight-600 heading-text-color modalTitle">
                {candidateName ? candidateName + " - Profile " : "Pitch "} Video
              </Typography>
            </Grid>
            <Grid item xs={6} lg={6} md={6}>
              <Typography
                align="right"
                className="cursor-hover gray8 icon-button"
              >
                <CloseIcon
                  onClick={handleClose}
                  onKeyPress={handleClose}
                  tabIndex={0}
                  aria-label="Close icon"
                />
              </Typography>
            </Grid>
          </Grid>
          <Grid item id="modal-description">
            <Grid container item columns={12}>
              <Grid item lg={12}>
                <Grid container item>
                  <Grid item lg={12} md={12} xs={12}>
                    <Grid item lg={12} md={12} xs={12} className="m-auto">
                      {videoSrc?.match(urlvalidation) != null &&
                      (videoSrc?.match(videoLinkRegex) != null ||
                        videoSrc?.match(youtubeRegex) != null) ? (
                        <>
                          {/.MP4|.WebM|.OGG|.mp4|.webm|.ogg/.test(videoSrc) ? (
                            <video
                              src={videoSrc}
                              controls
                              height="250"
                              width="100%"
                              className="video-image"
                            />
                          ) : videoSrc?.includes("videos.kaltura.com") ? (
                            <iframe
                              src={videoSrc}
                              width="100%"
                              height="250px"
                              allow="fullscreen; picture-in-picture"
                            ></iframe>
                          ) : (
                            <ReactPlayer
                              controls={true}
                              loop="1"
                              width="100%"
                              height="250px"
                              url={videoSrc}
                            />
                          )}
                        </>
                      ) : /\.(gif|jpg|jpeg|png)$/i.test(videoSrc) ? (
                        <a href={videoSrc} target="_blank" rel="noreferrer">
                          <img width="100%" src={videoSrc}></img>
                        </a>
                      ) : videoSrc?.includes(
                          ".mp4",
                          ".MP4",
                          ".WebM",
                          ".OGG",
                          ".mp4",
                          ".webm",
                          ".ogg"
                        ) ? (
                        <video
                          src={videoSrc}
                          controls
                          height="250"
                          width="100%"
                          className="video-image"
                        />
                      ) : (
                        <>
                          <span className="f-16 font-weight-400 description-preview">
                            <a
                              href={videoSrc}
                              className="link-color text-decoration-none decorationcolor"
                              target="_blank"
                              rel="noreferrer"
                              style={{
                                whiteSpace: "initial",
                                wordBreak: "break-all",
                              }}
                            >
                              {videoSrc}
                            </a>
                          </span>
                          <Typography className="py-10 gray9 f-16 font-weight-400">
                            Note: Preview not available
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default CandidateProfileVideoPreview;
