import React, { useState, useEffect } from "react";
import { Typography, Box, Grid } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import moment from "moment";
import certificationIcon from "../../../assets/images/certification.svg";
import linkIcon from '../../../assets/images/link-icon.svg';
import CertificationName from "./CertificationName";

export default function Certification(props) {
  const [certificationList, setCertificationList] = useState([]);
  useEffect(() => {
    setCertificationList(props.certifications ? props.certifications : []);
  }, [props.certifications]);
  return (
    <div>
      <Grid className="bg-white p-20">
        <Grid className="d-flex justify-between pb-10 border-bottom-1">
          <Typography
            variant="h5"
            className="font-weight-600 gray9 sub-text-font"
          >
            Certifications
          </Typography>
        </Grid>
        <>
          {certificationList.length > 0 ? (
            certificationList.map((certification, index) => {
              return (
                <div key={index}>
                  <Grid item lg={12} md={12} xs={12} className="mt-20 d-flex justify-between">
                    <Grid item lg={10} md={10} xs={10} className="d-flex">
                      <Box>
                        <img
                          style={{ width: "45px" }}
                          src={certificationIcon}
                          alt="certificate logo"
                        />
                      </Box>
                      <Box className="mt-5 pl-10">
                        <CertificationName
                          certificationName={certification?.certificationName}
                          expiringOn={certification?.expiringOn}
                        />
                        <p className="supporting-text-large mt-0 mb-0">
                          {certification?.issuedBy}
                        </p>

                        <span className="supporting-text-light editProfileBullet">
                          {moment(certification?.certifiedWhen.substring(2, 6) + "-" + certification?.certifiedWhen.substring(0, 2)).format(
                            "MMM YYYY"
                          )}
                          {certification?.expiringOn
                            && (" - " + moment(certification?.expiringOn.substring(2, 6) + "-" + certification?.expiringOn.substring(0, 2)).format(
                              "MMM YYYY"
                            ))}{" "}
                          {certification?.certificationNumber && <FiberManualRecordIcon aria-label="Dot icon" />}{" "}
                          {certification?.certificationNumber}
                        </span>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid className="mt-10 sub-text-color">
                    <Box>
                      {certification?.certificationUrl && <>
                        <span className="supporying-text-medium">Certification Link</span>
                        <ul className="pl-0 mt-10 mb-0">
                          <li className="mt-5 sub-text-color d-flex align-center">
                            <img src={linkIcon} alt="img"
                              width="16"
                              height="16" />
                            <a
                              className="small-text-font link-color break-word text-decor-link w-300 textEllipse ml-10"
                              href={certification?.certificationUrl.indexOf("://") === -1
                                ? "http://" + certification?.certificationUrl
                                : certification?.certificationUrl
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {decodeURIComponent(certification?.certificationUrl)}
                            </a>
                          </li>
                        </ul></>}
                    </Box>
                  </Grid>
                </div>
              );
            })
          ) : (
            <Typography className="f-14 light-text mt-10">No certification details added.</Typography>
          )}
        </>
      </Grid>
    </div>
  );
}
