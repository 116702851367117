import React from "react";
import { Button, DialogTitle, DialogContentText, DialogContent, DialogActions, Dialog } from "@mui/material";
import { Typography } from "@material-ui/core";

function ConfirmDialog({
  open,
  setOpen,
  dialogTitle,
  description,
  handleConfirmYes,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className="heading-text-color">
        {dialogTitle}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          className="f-14 font-weight-400"
        >
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions className="pb-20">
        <Button
          className="confirm-link dialog-secondary font-weight-600"
          onClick={handleClose}
          aria-label="no title"
          disableRipple="true"
        >
          No
        </Button>
        <Button
          className="confirm-link dialog-primary"
          onClick={handleConfirmYes}
          autoFocus
          aria-label="yes title"
          disableRipple="true"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
