import { Grid, Typography, Container, Button, Card, CardContent, CardActionArea, CardMedia } from "@mui/material";
import React, { useRef, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import LaunchCarousel1 from "../../assets/images/launch-page-images/launch-carousel-1.png";
import LaunchCarousel2 from "../../assets/images/launch-page-images/launch-carousel-2.png";
import LaunchCarousel3 from "../../assets/images/launch-page-images/launch-carousel-3.png";
import LaunchCarousel4 from "../../assets/images/launch-page-images/launch-carousel-4.png";
import Certificate from "../../assets/images/launch-page-images/certificate-solid.svg";
import UsersSolid from "../../assets/images/launch-page-images/users-solid.svg";
import ChalkBoard from "../../assets/images/launch-page-images/chalkboard-teacher-solid.svg";
import Robot from "../../assets/images/launch-page-images/robot-solid.svg";
import UserFriends from "../../assets/images/launch-page-images/user-friends-solid.svg";
import UserGraduate from "../../assets/images/launch-page-images/user-graduate-solid.svg";
import CommentDots from "../../assets/images/launch-page-images/comment-dots.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import 'swiper/swiper-bundle.min.css';

// Import any additional Swiper modules you need
import { Pagination } from "swiper";

// Import Material-UI components if needed
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    swiperContainer: {
        width: "100%",
        height: "100%", // Adjust the height as per your requirements
        // Apply any additional styling you need
    },
});

export default function DirectSourceQuality() {
    let navigate = useNavigate();
    const classes = useStyles();
    return (
        <>
            <Grid item
                sx={{ py: 3, px: 4 }}
                className="body-container responsive-direct-source"
            >
                <Grid item lg={12} md={12} xs={12} sx={{ py: 3, mt: 4 }} className="direct-source">
                    <Grid>
                        <Typography className="launch-page-title text-center">
                            Improve the speed,
                        </Typography>
                        <Typography className="launch-page-title text-center">
                            quality and cost of acquiring talent
                        </Typography>
                        <Grid className="w-100 mt-10 d-flex justify-center">
                            <Grid className="title-border"></Grid>
                        </Grid>
                        <Typography className="pt-15 launch-page-subtext text-center">
                            Direct Source provides a range of features that allow any business find and engage top contingent talent.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid className={classes.swiperContainer}>
                    <Swiper
                        slidesPerView={3}
                        spaceBetween={30}
                        pagination={{ dynamicBullets: true, clickable: true }}
                        modules={[Pagination]}
                        style={{
                            "--swiper-pagination-color": "#D0D5DD",
                            "--swiper-pagination-bullet-inactive-color": "#98A2B3",
                            "--swiper-pagination-bullet-inactive-opacity": "1",
                            "--swiper-pagination-bullet-size": "16px",
                            "--swiper-pagination-bullet-horizontal-gap": "6px",
                            "--swiper-pagination-bottom": "0px",
                          }}
                    >
                        <SwiperSlide>
                            <Card className="launch-page-card c-white features-card" sx={{ minHeight: 402, maxWidth: 452, py: 4 }}>
                                <CardActionArea >
                                <Grid className="card-image">
                                    <CardMedia
                                        component="img"
                                        height="50"
                                        src={Certificate}
                                        alt="Tailored Candidate"
                                        style={{ objectFit: "contain" }}
                                    />
                                    </Grid>
                                    <CardContent>
                                        <Typography className="text-center launch-page-title1 pb-20">
                                            Tailored Candidate Scoring</Typography>
                                        <Typography className="text-center launch-page-subtext">
                                            Customize your candidate scoring and matching parameters and with our Job-Candidate matching score, discover the best fit.
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Card className="launch-page-card c-white features-card" sx={{ minHeight: 402, maxWidth: 452, py: 4 }}>
                                <CardActionArea >
                                <Grid className="card-image">
                                    <CardMedia
                                        component="img"
                                        height="50"
                                        src={UsersSolid}
                                        alt="Talent Pools"
                                        style={{ objectFit: "contain" }}
                                    />
                                    </Grid>
                                    <CardContent>
                                        <Typography className="text-center launch-page-title1 pb-20">
                                            Talent Pools</Typography>
                                        <Typography className="text-center launch-page-subtext">
                                            Get the best of both worlds! Choose from a shared pool of candidates or create an exclusive pool using your channels.
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Card className="launch-page-card c-white features-card" sx={{ minHeight: 402, maxWidth: 452, py: 4 }}>
                                <CardActionArea >
                                <Grid className="card-image">
                                    <CardMedia
                                        component="img"
                                        height="50"
                                        src={ChalkBoard}
                                        alt="Customizable"
                                        style={{ objectFit: "contain" }}
                                    />
                                    </Grid>
                                    <CardContent>
                                        <Typography className="text-center launch-page-title1 pb-20">
                                            Customizable Career Portal</Typography>
                                        <Typography className="text-center launch-page-subtext">
                                            Attract top talent with a customizable branded experience, leading to increased applicant pools and higher quality hires
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Card className="launch-page-card c-white features-card" sx={{ minHeight: 402, maxWidth: 452, py: 4 }}>
                                <CardActionArea >
                                <Grid className="card-image">
                                    <CardMedia
                                        component="img"
                                        height="50"
                                        src={Robot}
                                        alt="AI Powered"
                                        style={{ objectFit: "contain" }}
                                    />
                                    </Grid>
                                    <CardContent>
                                        <Typography className="text-center launch-page-title1 pb-20">
                                            AI Powered Matching</Typography>
                                        <Typography className="text-center launch-page-subtext">
                                            Future-proof your hiring process with  AI-powered candidate matching,  saving you time and effort.
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Card className="launch-page-card c-white features-card" sx={{ minHeight: 402, maxWidth: 452, py: 4 }}>
                                <CardActionArea >
                                <Grid className="card-image">
                                    <CardMedia
                                        component="img"
                                        height="50"
                                        src={UserFriends}
                                        alt="Diversity"
                                        style={{ objectFit: "contain" }}
                                    />
                                    </Grid>
                                    <CardContent>
                                        <Typography className="text-center launch-page-title1 pb-20">
                                            Diversity and Inclusivity</Typography>
                                        <Typography className="text-center launch-page-subtext">
                                            We support hiring neurodiverse talent with tailored features and ease of use for both neurodiverse candidates and recruiters.
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Card className="launch-page-card c-white features-card" sx={{ minHeight: 402, maxWidth: 452, py: 4 }}>
                                <CardActionArea >
                                <Grid className="card-image">
                                    <CardMedia
                                        component="img"
                                        height="50"
                                        src={UserGraduate}
                                        alt="Access Top"
                                        style={{ objectFit: "contain" }}
                                    />
                                    </Grid>
                                    <CardContent>
                                        <Typography className="text-center launch-page-title1 pb-20">
                                            Access Top Talent</Typography>
                                        <Typography className="text-center launch-page-subtext">
                                            Discover top talent efficiently with access  to 6.5+ million professionals from various industries and experience levels.
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Card className="launch-page-card c-white features-card" sx={{ minHeight: 402, maxWidth: 452, py: 4 }}>
                                <CardActionArea >
                                <Grid className="card-image">
                                    <CardMedia
                                        component="img"
                                        height="50"
                                        src={CommentDots}
                                        alt="Engage via Chatbot"
                                        style={{ objectFit: "contain" }}
                                    />
                                    </Grid>
                                    <CardContent>
                                        <Typography className="text-center launch-page-title1 pb-20">
                                            Engage via Chatbot</Typography>
                                        <Typography className="text-center launch-page-subtext">
                                            Improve candidate experience with 24/7 support that offers personalized communication and flexibility.
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </SwiperSlide>
                    </Swiper>
                </Grid>
            </Grid>

        </>

    );
}
