import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Container, Grid, Typography } from "@mui/material";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import AnalyticsImage from "../../assets/images/launch-page-images/analytics-image.png";
import AnalyticsImage1 from "../../assets/images/launch-page-images/analytics-image1.png";
import DemandHiring from "../../assets/images/launch-page-images/demand-hiring.png";
import Facebook from "../../assets/images/launch-page-images/facebook.png";
import LinkedIn from "../../assets/images/launch-page-images/linkedIn.png";
import Twitter from "../../assets/images/launch-page-images/twitter.png";
import Constants from "../../helpers/constants";

export default function HireTopTalent() {
  let navigate = useNavigate();

  return (
    <Grid item sx={{ py: 3, mt: 3 }} className="">
      <Container>
        <Grid
          data-aos="zoom-in-up"
          item
          lg={12}
          md={12} xs={12}
          spacing={1}
          className="align-item-center demand-hiring"
        >
          <Grid
            item
            lg={10}
            md={10} xs={12}
            className="align-item-center demand-hiring-inner-section responsive-tabs"
          >
            <Grid item lg={6} md={6} xs={12}>
              <Grid item className="inner-image-section-left">
                <span className="image-shadow">
                  <div className="box-shadow-overlay"></div>
                  <img src={DemandHiring} alt="on-demand hiring" width="" />
                </span>
              </Grid>
            </Grid>

            <Grid item lg={6} md={6} xs={12} className="design-section">
              <Grid item className="inner-design-section">
                <h1 className="c-white f-25 text-center">
                  Read our guide to on-demand hiring
                </h1>

                <Typography className="f-20 c-white text-center">
                  Discover how your business can use AI and the power of its
                  brand to direct source top talent faster and dramatically cut
                  agency costs.
                </Typography>
              </Grid>

              <Grid
                data-aos="zoom-in-left"
                item
                lg={12}
                md={12} xs={12}
                className="align-item-center"
              >
                <a
                  href="https://themetablue.com/download-ai-direct-sourcing-guide"
                  className="btn get-it-now-button text-decoration-none section-hover-zoom"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Get it now
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          lg={12}
          md={12} xs={12}
          spacing={1}
          className="align-item-center"
          sx={{ py: 4 }}
        >
          <Grid
            item
            lg={10}
            md={10} xs={12}
            className="align-item-center responsive-block"
            sx={{ py: 4 }}
          >
            <Grid item lg={6} md={6} xs={12} className="design-section">
              <Grid item className="inner-design-section">
                <h1 className="launch-page-heading-color f-25 text-center">
                  Experience the power of intuitive design
                </h1>

                <Typography className="f-20 landing-sub-text-color text-center">
                  Every aspect of the interface in {Constants.APP_NAME_GENERAL} has been
                  thoughtfully designed to achieve superior user experience.
                </Typography>
              </Grid>
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <Grid item className="inner-image-section">
                <img src={AnalyticsImage1} alt="intuitive design" width="100%" height="100%"/>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          data-aos="fade-up"
          item
          lg={12}
          md={12} xs={12}
          spacing={1}
          className="align-item-center"
        >
          <Grid item lg={6} md={6} xs={12} className="align-item-center hire-top-section">
            <Grid item lg={12} md={12} xs={12}>
              <Grid item lg={12} md={12} xs={12} className="hire-top-inner">
                <h1 className="c-white">
                  Hire top talent on-demand the easy way
                </h1>
                <p className="c-white f-20">
                  Experience {Constants.APP_NAME_GENERAL} and discover a smarter way of sourcing
                  talent
                </p>
              </Grid>

              <Grid
                data-aos="zoom-in-left"
                item
                lg={12}
                md={12} xs={12}
                className="align-item-center"
              >
                <a
                  href="https://themetablue.com/contact-us/"
                  className="btn contactUs-button text-decoration-none section-hover-zoom"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Contact us
                </a>
              </Grid>

              <Grid item lg={12} md={12} xs={12} className="align-item-center p-10">
                <p className="c-white">
                  <span>
                    <a
                      href="https://themetablue.com/speak-to-a-product-specialist/"
                      className="text-decoration-none c-white"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Get in touch with a product specialist
                    </a>
                  </span>
                  <span>
                    <ArrowRightAltIcon style={{ verticalAlign: "middle" }} aria-label="Arrow icon" />
                  </span>
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={12} md={12} xs={12} spacing={1} className="align-item-center">
          <Grid item lg={6} md={6} xs={12} className="align-item-center">
            <Grid item lg={12} md={12} xs={12} className="align-item-center p-15">
              <ul className="social-media-icons">
                <li className="p-10">
                  <a
                    href="https://www.linkedin.com/company/11181300/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={LinkedIn} alt="linkedIn" width="50px" />
                    {/* <LinkedInIcon className="c-white"/>*/}
                  </a>
                </li>

                <li className="p-10">
                  <a
                    href="https://www.facebook.com/simplifyworkforce/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={Facebook} alt="facebook" width="50px" />
                    {/*  <FacebookOutlinedIcon />*/}
                  </a>
                </li>

                <li className="p-10">
                  <a
                    href="https://twitter.com/simplifychatter"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={Twitter} alt="twitter" width="50px" />
                    {/*<TwitterIcon />*/}
                  </a>
                </li>
              </ul>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}
