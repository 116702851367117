import React, { useEffect, useState } from "react";
import { Grid, Typography, Button, Card, CardContent, Box } from "@mui/material";
import { useNavigate, Link, useParams } from "react-router-dom";
import Loader from "../Loader";
import { useSelector } from "react-redux";
import verifiedIcon from "../../assets/images/verified-icon.svg";
import ApiCalls from "../../api/customAxios";
import KeycloakService from "../../keycloak";
import Constants from "../../helpers/constants";

export default function DomainRequestApprovedPage() {
  const isAuthenticated = KeycloakService.isLoggedIn();
  const { params } = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const [decodedData, setDecodedData] = useState(null);
  const [newEnterprise, setNewEnterprise] = useState();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const appOrigin = useSelector((state) => state.location.origin);
  const [existEnterprise, setExistEnterprise] = useState(null);
  useEffect(() => {
    let url = process.env.REACT_APP_API_URL.replace("api/v1/", "public/api/v1/") + "enterprises/approve/registration/request";
    try {
      const decodedString = atob(urlSearchParams.get("params"));
      const decodedObject = JSON.parse(decodedString);
      const newEnterprise = decodedObject.isNewEnterpriseRegistration;
      setNewEnterprise(newEnterprise);
      setDecodedData(decodedObject);
      const payload = {
        enterpriseId: decodedObject.enterpriseId,
        enterpriseUserId: decodedObject.enterpriseUserId
      };
      ApiCalls.post(url, payload).then((resp) => {
      }).catch(function (error) {
        setIsLoading(false);
        if (error) {
          if (error.statusCode === 409) {
            setExistEnterprise(true);
          }
        }
      });
    } catch (error) {
      console.error('Error decoding string:', error);
      setError(error);
    }
  }, [params]);

  return (
    <>
      {!isAuthenticated &&
      <Grid item className="navbar-fixed-loggedout resolution-issues">
        <Grid
          item
          container
          spacing={1}
          sx={{ px: 4 }}
          className="body-container"
        >
          <Grid
            container
            spacing={1}
            item
            lg={3}
            md={3}
            xs={2}
            className="align-center logoHeader pb-5"
          >
            <Link to="/">
              <Box className="cursor-hover navLogo align-item-center">
                {appOrigin === "D" ?
                  <img
                    className="logoImg-navbar Nav-cont"
                    src={Constants.APP_LOGO_GENERAL}
                    alt="Logo"
                  />
                  : appOrigin === "N" ?
                    <img
                      className="logoImg-navbar Nav-cont"
                      src={Constants.APP_LOGO_NEURODIVERSIFY}
                      alt="Logo"
                    /> :
                    <img
                      className="logoImg-navbar Nav-cont"
                      src={Constants.APP_LOGO_HEALTHCARE}
                      alt="Logo"
                    />
                }
              </Box>
            </Link>
          </Grid>
        </Grid>
      </Grid>}
      <Grid
        item
        lg={12}
        md={12}
        xs={12}
        className="align-item-center h-100 bg-grey"
        sx={{ py: 30 }}
      >
        <Grid
          item
          lg={5}
          md={8}
          xs={9}
          align="center"
          spacing={4}
          className="m-auto"
        // style={{ paddingBottom: '220px' }}
        >
          {isLoading && <Loader />}
          <Card className="border-1 shadow-0">
            <CardContent>
              {decodedData && !existEnterprise &&
                <>
                  <Typography className="f-16 font-weight-600 d-flex justify-center" sx={{ mt: 2 }}>
                    <img src={verifiedIcon} alt="verified icon" className="mr-5" />
                    <Typography className="f-16 font-weight-600">Request Approved</Typography>
                  </Typography>
                  <Grid class="w-100 mt-10 d-flex justify-center"><Grid class="approve-title"></Grid></Grid>
                  <Typography sx={{ mt: 4, mb: 2 }} className="f-14 font-weight-400">
                    This request has been approved and access is granted to {decodedData && decodedData.emailId} for {!newEnterprise && "your enterprise."}
                  </Typography>
                  {newEnterprise && <Typography sx={{ mb: 2 }} className="f-14 font-weight-400">
                    <strong>{newEnterprise && "Enterprise Name:"} </strong> {decodedData && decodedData.enterpriseName}
                  </Typography>}
                </>
              }
              {existEnterprise &&
                <Typography sx={{ mt: 4, mb: 2 }} className="f-14 font-weight-400">
                  This request has already been approved and access is granted to {decodedData && decodedData.emailId} for your enterprise.
                </Typography>}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
