module.exports = {
    jobs: ["R", "Z", "S", "H", "T", "E"],
    candidates: ["R", "Z", "S", "H", "T", "E"],
    campaign: ["R", "Z", "S", "T", "E"],
    interviews: ["T", "R", "S", "H", "Z"],
    talentpool: ["R", "Z", "S", "H", "T", "E"],
    careerportal: ["Z", "H", "T", "A"],
    configurationcontrol: ["Z", "A"],
    analytics: ["R", "Z", "S", "H", "T", "E", "A"],
    beanbag: ["T", "R", "A", "Z"],
    dashboard: ["R", "Z", "T"],
};
