import { Grid, Typography, Container, Button } from "@mui/material";
import * as React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import LaunchCarousel1 from "../../assets/images/launch-page-images/launch-carousel-1.png";
import LaunchCarousel2 from "../../assets/images/launch-page-images/launch-carousel-2.png";
import LaunchCarousel3 from "../../assets/images/launch-page-images/launch-carousel-3.png";
import LaunchCarousel4 from "../../assets/images/launch-page-images/launch-carousel-4.png";
import FindTOpTalent from "../../assets/images/launch-page-images/find-top-talent.png";
import UpgradeJob from "../../assets/images/launch-page-images/upgrade-your-job.svg";
import FastTrackYourHiring from "../../assets/images/launch-page-images/fast-track-your-hiring.svg";
import SaveBigData from "../../assets/images/launch-page-images/save-big-data.png";
import MakeSmartHiring from "../../assets/images/launch-page-images/make-smart-hiring.svg";
import BridgeTheGap from "../../assets/images/launch-page-images/bridging-the-gap.svg";
import InclusiveHiring from "../../assets/images/launch-page-images/inclusive-hiring.png";

export default function DirectSourceingContent() {
    let navigate = useNavigate();

    return (
        <Grid item
            // data-aos="fade-in-left"
            // data-aos-anchor="#example-anchor"
            // data-aos-offset="500"
            // data-aos-duration="500"
            sx={{ pb: 3, px: 4 }}
            className="body-container "
        >
            <Grid item lg={12} md={12} xs={12} sx={{ py: 3 }} className="d-flex justify-center">
                <Grid item lg={10} md={12} xs={12} className="d-flex responsive-block galaxy-block">
                    <Grid item lg={6} md={12} xs={12}>
                        <Typography className="launch-page-title pt-35 ">
                            Take control of your contingent workforce sourcing
                        </Typography>
                        <Grid className="w-100 mt-10 d-flex">
                            <Grid className="title-border"></Grid>
                        </Grid>
                        <Typography className="pb-15 launch-page-subtext pt-15">
                            Finding top talent for your business shouldn't feel like searching for
                            a needle in a haystack. Supercharge your hiring process, create hundreds of
                            jobs in seconds, easily integrate with ATS and job boards, and create specific
                            talent pools - all designed to make your hiring process work for you - not your suppliers.
                        </Typography>
                    </Grid>
                    <Grid item lg={6} md={12} xs={12}
                        //  data-aos="fade-up"
                        className="p-30">
                        <img src={FindTOpTalent} className="responsive-img-block"
                            width="408" height="348"
                            alt="Finding top talent" />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item lg={12} md={12} xs={12} sx={{ py: 3, mt: 4 }} className="d-flex justify-center">
                <Grid item lg={10} md={12} xs={12} className="d-flex responsive-block galaxy-block">
                    <Grid item lg={6} md={12} xs={12}
                        //  data-aos="fade-up"
                        className="p-30">
                        <img src={UpgradeJob} width="408" height="348" alt="Gain an edge" className="responsive-img-block" />
                    </Grid>
                    <Grid item lg={6} md={12} xs={12}>
                        <Typography className="launch-page-title pt-35 responsive-content ">
                            Gain an edge in securing the best contract talent
                        </Typography>
                        <Grid className="w-100 mt-10 d-flex ">
                            <Grid className="title-border"></Grid>
                        </Grid>
                        <Typography className="pb-15 launch-page-subtext pt-15">
                            Find candidates that don't work through staffing firms. Engage
                            them successfully with a tailored candidate-centric experience.
                            Improve the quality of your hires with our platform's advanced
                            matching algorithms and filters, customizable scoring capabilities,
                            and a lot more.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item lg={12} md={12} xs={12} sx={{ py: 3, mt: 4 }} className="d-flex justify-center">
                <Grid item lg={10} md={12} xs={12} className="d-flex responsive-block galaxy-block">
                    <Grid item lg={6} md={12} xs={12}>
                        <Typography className="launch-page-title pt-35 responsive-content">
                            Start sourcing and engaging talent on your own terms
                        </Typography>
                        <Grid className="w-100 mt-10 d-flex">
                            <Grid className="title-border"></Grid>
                        </Grid>
                        <Typography className="pb-15 launch-page-subtext pt-15">
                            Handle talent acquisition with ease by utilizing our comprehensive
                            suite of tools. Quickly build qualified candidate pools and make
                            the hiring process lightning-fast. Plus, our platform is user-friendly
                            and intuitive, making it easy to get started and find the best
                            candidates for your open roles.
                        </Typography>
                    </Grid>
                    <Grid item lg={6} md={12} xs={12}
                        //  data-aos="fade-up"
                        className="p-30">
                        <img src={FastTrackYourHiring} alt="Handle talent"
                        width="400" height="384"
                        className="responsive-img-block" />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item lg={12} md={12} xs={12} sx={{ py: 3, mt: 4 }} className="d-flex justify-center">
                <Grid item lg={10} md={12} xs={12} className="d-flex responsive-block galaxy-block">
                    <Grid item lg={6} md={12} xs={12}
                        //data-aos="fade-up"
                        className="p-30">
                        <img src={SaveBigData} alt="Drive ROI" width="400" height="225" className="responsive-img-block" />
                        {/* //  data-aos="fade-up"
                        className="p-30"> */}
                    </Grid>
                    <Grid item lg={6} md={12} xs={12}>
                        <Typography className="launch-page-title pt-35 responsive-content">
                            Drive ROI through contingent candidate engagement
                        </Typography>
                        <Grid className="w-100 mt-10 d-flex">
                            <Grid className="title-border"></Grid>
                        </Grid>
                        <Typography className="pb-15 launch-page-subtext pt-15">
                            Reduce your time-to-hire and job aging, resulting in a more
                            efficient hiring process. DSaaS helps cut costs by streamlining
                            the hiring process and reducing the time spent on administrative
                            tasks. Free up time to focus on strategic hiring initiatives and
                            ensure that you are getting the best possible return on your
                            investment.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item lg={12} md={12} xs={12} sx={{ py: 3, mt: 4 }} className="d-flex justify-center">
                <Grid item lg={10} md={12} xs={12} className="d-flex responsive-block galaxy-block">
                    <Grid item lg={6} md={12} xs={12}>
                        <Typography className="launch-page-title pt-35 responsive-content">
                            Continuously improve hiring based on data analytics
                        </Typography>
                        <Grid className="w-100 mt-10 d-flex">
                            <Grid className="title-border"></Grid>
                        </Grid>
                        <Typography className="pb-15 launch-page-subtext pt-15">
                            Optimize and improve your hiring processes with data-driven insights
                            and tools to identify the best talent, make informed hiring decisions,
                            and developing new sourcing strategies and campaigns to reach more
                            of the candidates you want.
                        </Typography>
                    </Grid>
                    <Grid item lg={6} md={12} xs={12}
                        //  data-aos="fade-up"
                        className="p-30">
                        <img src={MakeSmartHiring} alt="Optimize and improve"
                            width="500" height="400"
                            className="responsive-img-block" />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item lg={12} md={12} xs={12} sx={{ py: 3, mt: 4 }} className="d-flex justify-center">
                <Grid item lg={10} md={12} xs={12} className="d-flex responsive-block galaxy-block">
                    <Grid item lg={6} md={12} xs={12}
                        //  data-aos="fade-up"
                        className="p-30">
                        <img src={InclusiveHiring} width="523" height="240" alt="Inclusive hiring made easy"
                        className="responsive-img-block" />
                    </Grid>
                    <Grid item lg={6} md={12} xs={12}>
                        <Typography className="launch-page-title pt-35 responsive-content">
                            Inclusive hiring made easy
                        </Typography>
                        <Grid className="w-100 mt-10 d-flex">
                            <Grid className="title-border"></Grid>
                        </Grid>
                        <Typography className="pb-15 launch-page-subtext pt-15">
                            We believe that neurodiverse talent brings unique perspectives
                            and strengths to the workplace. We offer tailored features and
                            ease of use for neurodiverse candidates and recruiters. With
                            neurodiversity metrics, track your progress and create a more
                            inclusive workplace.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item lg={12} md={12} xs={12} sx={{ py: 3, mt: 4 }} className="d-flex justify-center">
                <Grid item lg={10} md={12} xs={12} className="d-flex responsive-block galaxy-block">
                    <Grid item lg={6} md={12} xs={12}>
                        <Typography className="launch-page-title pt-35 responsive-content">
                            Bridging the gap by bringing transparency
                        </Typography>
                        <Grid className="w-100 mt-10 d-flex">
                            <Grid className="title-border"></Grid>
                        </Grid>
                        <Typography className="pb-15 launch-page-subtext pt-15">
                            Transparency leads to better decisions! We believe in transparency
                            between enterprises and candidates. Our platform provides ratings,
                            availability status, timeline view and application stats, eliminating
                            the lack of clarity in traditional hiring processes.
                        </Typography>
                    </Grid>
                    <Grid item lg={6} md={12} xs={12}
                        //  data-aos="fade-up"
                        className="p-30">
                        <img src={BridgeTheGap} alt="Transparency leads"
                        width="600" height="350"
                        className="responsive-img-block" />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
