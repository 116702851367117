import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
  Container,
  Breadcrumbs,
  Box,
  Popover,
} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate, useParams } from "react-router-dom";
import AccessService from "../../../Services/Enterprise/Access.service";
import { useSelector } from "react-redux";
import { Tooltip, TooltipTrigger, TooltipContent } from "react-tippy";
import GenerateOfferLetterRehire from "./AiGenerateOfferLetterRehire";


const OfferLetterRehire = React.memo(function OfferLetterRehire(props) {
  const appOrigin = useSelector((state) => state.location.origin);
  const [offerType, setOfferType] = useState("AI");
  useEffect(() => {
    const bodyElement = document.body;
    const htmlElement = document.documentElement;
    if (props.viewOfferDrawer) {
      bodyElement.style.overflow = "hidden";
      htmlElement.style.overflow = "hidden";
    } else {
      bodyElement.style.overflow = "";
      htmlElement.style.overflow = "";
    }
  }, []);
  return (
    <>
      <Drawer
        transitionDuration={{ enter: 500, exit: 500 }}
        variant="temporary"
        anchor="right"
        open={props.viewOfferDrawer}
        onClose={(e) => {
          e.stopPropagation();
          props.setViewOfferDrawer(false);
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="jobDetail-drawer"
      >
      <Grid sx={{ p: 5 }} className="drawer-align">
          <Grid className="display-flex align-center login-responsive">
              <Typography
                onClick={(e) => {
                  e.stopPropagation();
                  props.setViewOfferDrawer(false);
                }}
                className="cursor-hover gray7 display-flex align-center apply-job-align"
              >
                <ChevronLeftIcon className="f-24" />
              </Typography>
              <Typography className="heading-text-color f-20 font-weight-600 ml-10">
              {props.resendOfferHeading ? "Resend Offer Letter" : "Generate Offer Letter"}
              </Typography>
          </Grid>
          <Grid className="mt-30">
            <GenerateOfferLetterRehire
                setIsLoading={props.setIsLoading}
                setViewOfferDrawer={props.setViewOfferDrawer}
                jobStartDate={props.jobStartDate}
                jobCompletionDate={props.jobCompletionDate}
                payRate={props.payRate}
                currency={props.currency}
                jobs={props.jobs}
                candidateApplicationData={props.candidateApplicationData}
                expiryDate={props.expiryDate}
                getCandidatesByStatus={props.getCandidatesByStatus}
                sendOfferEmailChecked={props.sendOfferEmailChecked}
              />
          </Grid>
      </Grid>
      </Drawer>
    </>
  );
});
export default OfferLetterRehire;

