import DateFnsUtils from "@date-io/date-fns";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Button,
  Container,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Input,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stepper,
  Tabs
} from "@mui/material";
import debounce from "lodash/debounce";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import ApiCalls from "../../../../api/customAxios";
import { danger, success } from "../../../../helpers/message-box";
import { ReactComponent as NewNotesIcon } from "../../../../assets/images/new-notes-icon.svg";
import { ReactComponent as NewNotesStarIcon } from "../../../../assets/images/notes-star-icon.svg";
const label = { inputProps: { "aria-label": "Checkbox demo" } };
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Step, StepLabel, StepContent } from "@material-ui/core";
import Resumepdf from "../../../../assets/images/extension-icon/pdf-icon.png";
import docIcon from "../../../../assets/images/extension-icon/doc-icon.png";
import videoCameraIcon from "../../../../assets/images/extension-icon/video-camera.svg";
import downloadIcon from "../../../../assets/images/download-icon.svg";
import shortlistedIcon from "../../../../assets/images/shortlisted-icon.svg";
import AppliedIcon from "../../../../assets/images/applied-icon.svg";
import OffboardedIcon from "../../../../assets/images/offboarded-icon.svg";
import OnboardedIcon from "../../../../assets/images/onboarded-icon.svg";
import OfferSentIcon from "../../../../assets/images/offer-sent-icon.svg";
import CandidateService from "../../../../Services/Enterprise/Candidate.service";
import { formatDate } from "../../../../helpers/helper-data";
import { ReactComponent as TimelineIcon } from "../../../../assets/images/timelineStatusIcon.svg";
import Tab from "@material-ui/core/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { ReactComponent as DetailIcon } from "../../../../assets/images/detail-screen.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../../Loader";
import _ from "lodash";
import CandidateProfileVideoPreview from "../../../../Components/shared/CandidateProfileVideoPreview";

export default function TimelineCandidate(props) {
  const [pitchVideo, setPitchVideo] = useState(null);
  const [openPitchVideoPreview, setOpenPitchVideoPreview] = useState(false);
  const [activeStep, setActiveStep] = useState();
  const [candidateTimeLines, setCandidateTimeLines] = useState([]);
  const [containerHeight, setContainerHeight] = useState(window.innerHeight * 0.7);
  const [tabValue, setTabValue] = React.useState(
    props.tabStatus == "applied"
      ? 2
      : props.tabStatus == "shortlisted"
      ? 3
      : props.tabStatus == "offered"
      ? 4
      : props.tabStatus == "onboarded"
      ? 5
      : props.tabStatus == "completed-cancelled"
      ? 6
      : 1
  );
  useEffect(() => {
    const bodyElement = document.body;
    const htmlElement = document.documentElement;
    if (props?.isSideDrawer) {
      bodyElement.style.overflow = "hidden";
      htmlElement.style.overflow = "hidden";
    } else {
      bodyElement.style.overflow = "";
      htmlElement.style.overflow = "";
    }
    const handleResize = () => {
      setContainerHeight(window.innerHeight * 0.8);
  };
  window.addEventListener('resize', handleResize);
  return () => {
      window.removeEventListener('resize', handleResize);
  };
  }, [props?.isSideDrawer]);
  const [candidateStatistics, setCandidateStatistics] = useState({
    matchingCandidates: 0,
    shortlistedCandidates: 0,
    appliedCandidates: 0,
    offeredCandidates: 0,
    onboardedCandidates: 0,
    offboardedCandidates: 0,
    totalCount: 0,
  });
  const [loadMore, setLoadMore] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);

  const params = useParams();
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    setPageNumber(0);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  const candidateName =
    props?.candidate?.firstName && props?.candidate?.lastName
      ? props?.candidate?.firstName + " " + props?.candidate?.lastName
      : props?.candidate?.candidateName;

  useEffect(() => {
    switch (tabValue) {
      case 1:
        getCandidatesByStatus(""); // all
        break;
      case 2:
        getCandidatesByStatus("A"); // shortlisted
        break;
      case 3:
        getCandidatesByStatus("S"); // applied
        break;
      case 4:
        getCandidatesByStatus("F"); // offered
        break;
      case 5:
        getCandidatesByStatus("O"); // offboarded
        break;
      case 6:
        getCandidatesByStatus("B"); // offboarded
        break;
      default:
      //alert( "I don't know such values" );
    }
    // }
  }, [tabValue]);
  const handleResize = () => {
    setContainerHeight(window.innerHeight * 0.8);
};

  function getCandidatesByStatus(status) {
    props.setIsLoading(true);
    const page = 0;
    CandidateService.getTimeLine(
      params.candidate_id ? params.candidate_id : props.candidateId,
      page,
      status
    )
      .then((res) => {
        if (res.length < 10) {
          setLoadMore(false);
        } else {
          setLoadMore(true);
        }
        setCandidateTimeLines(res);
        props.setIsLoading(false);
      })
      .catch(function (error) {
        props.setIsLoading(false);
      });
  }
  const fetchMoreData = () => {
    let pgNo = pageNumber + 1;
    setPageNumber(pgNo);
    let status = "";
    if (tabValue === 1) {
      status = "";
    } else if (tabValue === 3) {
      status = "S";
    } else if (tabValue === 2) {
      status = "A";
    } else if (tabValue === 4) {
      status = "F";
    } else if (tabValue === 5) {
      status = "O";
    } else if (tabValue === 6) {
      status = "B";
    }
    CandidateService.getTimeLine(
      params.candidate_id ? params.candidate_id : props.candidateId,
      pgNo,
      status
    ).then((response) => {
      if (response.length < 10) {
        setLoadMore(false);
      } else {
        setLoadMore(true);
      }
      setCandidateTimeLines([...candidateTimeLines, ...response]);
    });
  };
  useEffect(() => {
    props.setIsLoading(true);
    ApiCalls.get(
      `enterprises/pool/candidate/timeline/statistics/${
        params.candidate_id ? params.candidate_id : props.candidateId
      }`
    )
      .then((res) => {
        setCandidateStatistics(res);
        props.setIsLoading(false);
      })
      .catch((err) => {
        props.setIsLoading(false);
      });
  }, []);
  const downloadDocument = (url) => {
    // console.log(url);
    success("File downloaded successfully.");
    var element = document.createElement("a");
    element.href = url;
    if (element.click) {
      element.click();
    } else {
      window.open(url);
    }
    element.parentNode.removeChild(a);
  };

  const handleOpenPitchVideoPreview = (videoSrc) => {
    setPitchVideo(videoSrc);
    setOpenPitchVideoPreview(true);
  };

  const lastUpdatedTimestamp = (timeStamp) => {
    return (
      <Typography className="f-14 gray7 font-weight-400 mr-15">
        <Box>{moment(timeStamp).format("MMM DD, YYYY")}</Box>
        <Box className="align-right">{moment(timeStamp).format("HH:mm")}</Box>
      </Typography>
    );
  };
  console.log(candidateTimeLines, "222");
  const candidateTimeLinesList =
    candidateTimeLines.length > 0
      ? candidateTimeLines.map((timeLine, index) => {
          return (
            <Grid className="d-flex" key={index}>
              {timeLine?.candidateProcessStatus === "A" && (
                <>
                  {lastUpdatedTimestamp(timeLine?.processTimestamp)}
                  <Step expanded className="mt-10">
                    <StepLabel className="f-16 gray9 font-weifgt-600">
                      <span className="p-absolute timeline-img">
                        <TimelineIcon
                          width="35px"
                          height="35px"
                          className="circle-svg-icons"
                        />
                      </span>
                      <span className="f-16 font-weight-600 gray9 m-40">
                        <span className="font-weight-600">Applied</span> for{" "}
                        <span className="font-weight-600">
                          {timeLine?.jobTitle}
                        </span>
                      </span>
                    </StepLabel>
                    <StepContent className="">
                      <Typography className="f-14 gray7 font-weight-400 ml-10">
                        {candidateName} applied for {timeLine?.jobTitle}
                      </Typography>
                      <>
                        {timeLine.applyMediaFileSigned && (
                          <>
                            <Grid
                              className="d-flex align-center p-adsolute cursor-hover"
                              onClick={() =>
                                handleOpenPitchVideoPreview(timeLine.applyMediaFileSigned)
                              }
                              onKeyPress={() =>
                                handleOpenPitchVideoPreview(timeLine.applyMediaFileSigned)
                              }
                            >
                              <Grid className="mb-10 mr-5 resume-upload mt-10 ml-25">
                                <a
                                  //key={index}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  tabIndex={0}
                                  className="text-decoration-none align-center d-flex"
                                >
                                  <Grid
                                    item
                                    className="d-flex uploadResume align-center w-100"
                                  >
                                    <Typography className="justify-between d-flex align-center w-100">
                                      <Typography className="d-flex">
                                        <Box className="d-flex align-center">
                                          {timeLine.applyMediaFileSigned
                                            .split(".")
                                            .pop()
                                            .split("?")[0] === "mp4" && (
                                            <img
                                              src={videoCameraIcon}
                                              alt="img"
                                              width="30px"
                                              className="m-r-10"
                                            />
                                          )}
                                        </Box>
                                        <Typography>
                                          <span className="gray9 f-14 font-weight-400 break-word">
                                            {timeLine?.applyMediaFile &&
                                              decodeURIComponent(
                                                timeLine?.applyMediaFile
                                                  ?.split("/")
                                                  .pop()
                                              )}
                                          </span>
                                        </Typography>
                                      </Typography>
                                      <img
                                        src={downloadIcon}
                                        alt="img"
                                        width="16px"
                                        className="ml-20"
                                      />
                                    </Typography>
                                  </Grid>
                                </a>
                              </Grid>
                            </Grid>
                          </>
                        )}
                      </>
                    </StepContent>
                  </Step>
                </>
              )}
                            {timeLine?.enterpriseProcessStatus === "R" && (
                <>
                  {lastUpdatedTimestamp(timeLine?.processTimestamp)}
                  <Step expanded className="mt-10">
                    <StepLabel className="f-16 gray9 font-weifgt-600">
                      <span className="p-absolute timeline-img">
                        <TimelineIcon
                          width="35px"
                          height="35px"
                          className="bg-white circle-svg-icons"
                        />
                      </span>
                      <span className="f-16 font-weight-600 gray9 m-40">
                        <span className="font-weight-600">Rejected</span> for{" "}
                        <span className="font-weight-600">
                          {timeLine?.jobTitle}
                        </span>
                      </span>
                    </StepLabel>
                    <StepContent className="">
                      <Typography className="f-14 gray7 font-weight-400 ml-10">
                        {candidateName} rejected for {timeLine?.jobTitle}
                      </Typography>
                      <>
                        {timeLine.applyMediaFileSigned && (
                          <>
                            <Grid
                              className="d-flex align-center p-adsolute cursor-hover"
                              onClick={() =>
                                handleOpenPitchVideoPreview(timeLine.applyMediaFileSigned)
                              }
                              onKeyPress={() =>
                                handleOpenPitchVideoPreview(timeLine.applyMediaFileSigned)
                              }
                            >
                              <Grid className="mb-10 mr-5 resume-upload mt-10 ml-25">
                                <a
                                  //key={index}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  tabIndex={0}
                                  className="text-decoration-none align-center d-flex"
                                >
                                  <Grid
                                    item
                                    className="d-flex uploadResume align-center w-100"
                                  >
                                    <Typography className="justify-between d-flex align-center w-100">
                                      <Typography className="d-flex">
                                        <Box className="d-flex align-center">
                                          {timeLine.applyMediaFileSigned
                                            .split(".")
                                            .pop()
                                            .split("?")[0] === "mp4" && (
                                            <img
                                              src={videoCameraIcon}
                                              alt="img"
                                              width="30px"
                                              className="m-r-10"
                                            />
                                          )}
                                        </Box>
                                        <Typography>
                                          <span className="gray9 f-14 font-weight-400 break-word">
                                            {timeLine?.applyMediaFile &&
                                              decodeURIComponent(
                                                timeLine?.applyMediaFile
                                                  ?.split("/")
                                                  .pop()
                                              )}
                                          </span>
                                        </Typography>
                                      </Typography>
                                      <img
                                        src={downloadIcon}
                                        alt="img"
                                        width="16px"
                                        className="ml-20"
                                      />
                                    </Typography>
                                  </Grid>
                                </a>
                              </Grid>
                            </Grid>
                          </>
                        )}
                      </>
                    </StepContent>
                  </Step>
                </>
              )}
              {timeLine?.enterpriseProcessStatus === "S" && (
                <>
                  {lastUpdatedTimestamp(timeLine?.processTimestamp)}

                  <Step expanded className="mt-10">
                    <StepLabel className="f-16 gray9 font-weifgt-600">
                      <span className="p-absolute timeline-img">
                        {/* <img src={shortlistedIcon} /> */}
                        <TimelineIcon
                          width="35px"
                          height="35px"
                          className="circle-svg-icons"
                        />
                      </span>
                      <span className="f-16 font-weight-600 gray9 m-40">
                        <span className="font-weight-600">Shortlisted</span> for{" "}
                        <span className="font-weight-600">
                          {timeLine?.jobTitle}
                        </span>
                      </span>
                    </StepLabel>
                    <StepContent className="">
                      <Typography className="f-14 gray7 font-weight-400 ml-10">
                        {candidateName} shortlisted for {timeLine?.jobTitle}
                      </Typography>
                    </StepContent>
                  </Step>
                </>
              )}
              {timeLine?.enterpriseProcessStatus === "F" && (
                <>
                  {lastUpdatedTimestamp(timeLine?.processTimestamp)}

                  <Step expanded className="mt-10">
                    <StepLabel className="f-16 gray9 font-weifgt-600">
                      <span className="p-absolute timeline-img">
                        {/* <img src={OfferSentIcon} /> */}
                        <TimelineIcon
                          width="35px"
                          height="35px"
                          className="bg-white circle-svg-icons"
                        />
                      </span>
                      <span className="f-16 font-weight-600 gray9 m-40">
                        <span className="font-weight-600">Offer sent</span> for{" "}
                        <span className="font-weight-600">
                          {timeLine?.jobTitle}
                        </span>
                      </span>
                    </StepLabel>
                    <StepContent className="">
                      <Typography className="f-14 gray7 font-weight-400 ml-10">
                        Offer sent to {candidateName} for {timeLine?.jobTitle}
                      </Typography>
                      <>
                      <Grid className="stepper_time_line">
                        { timeLine.offerDocumentSignedUrl?.length > 0 ?
                        timeLine.offerDocumentSignedUrl.map((offer_document, index) => {
                          return (
                            <Typography key={index}>
                              {offer_document && (
                                <>
                                  <Grid
                                    className="d-flex align-center p-adsolute cursor-hover"
                                    onClick={() =>
                                      downloadDocument(
                                        offer_document
                                      )
                                    }
                                    onKeyPress={() =>
                                      downloadDocument(
                                        offer_document
                                      )
                                    }
                                  >
                                    <Grid className="mb-10 mr-5 resume-upload mt-10 ml-25">
                                      <a
                                        //key={index}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        tabIndex={0}
                                        className="text-decoration-none align-center d-flex"
                                      >
                                        <Grid
                                          item
                                          className="d-flex uploadResume align-center w-100"
                                        >
                                          <Typography className="justify-between d-flex align-center w-100">
                                            <Typography className="d-flex">
                                              <Box className="d-flex align-center">
                                                {offer_document
                                                  .split(".")
                                                  .pop()
                                                  .split("?")[0] === "pdf" && (
                                                  <img
                                                    src={Resumepdf}
                                                    alt="img"
                                                    width="30px"
                                                    className="m-r-10"
                                                  />
                                                )}
                                                {offer_document
                                                  .split(".")
                                                  .pop()
                                                  .split("?")[0] === "doc" && (
                                                  <img
                                                    src={docIcon}
                                                    alt="img"
                                                    width="30px"
                                                    className="m-r-10"
                                                  />
                                                )}
                                                {offer_document
                                                  .split(".")
                                                  .pop()
                                                  .split("?")[0] === "docx" && (
                                                  <img
                                                    src={docIcon}
                                                    alt="img"
                                                    width="30px"
                                                    className="m-r-10"
                                                  />
                                                )}
                                              </Box>
                                              <Typography>
                                                <span className="gray9 f-14 font-weight-400 break-word">
                                                  {offer_document &&
                                                    decodeURIComponent(
                                                      offer_document
                                                        ?.split("/")
                                                        .pop()
                                                        .split("?")[0]
                                                        // .split("_")[1]
                                                    )}
                                                </span>
                                              </Typography>
                                            </Typography>
                                            <img
                                              src={downloadIcon}
                                              alt="img"
                                              width="16px"
                                              className="ml-20"
                                            />
                                          </Typography>
                                        </Grid>
                                      </a>
                                    </Grid>
                                  </Grid>
                                </>
                              )}
                            </Typography>
                            );
                          }) : ("")
                        }
                      </Grid>
                      </>
                    </StepContent>
                  </Step>
                </>
              )}
              {timeLine?.candidateProcessStatus === "P" && (
                <>
                  {lastUpdatedTimestamp(timeLine?.processTimestamp)}

                  <Step expanded className="mt-10">
                    <StepLabel className="f-16 gray9 font-weifgt-600">
                      <span className="p-absolute timeline-img">
                        {/* <img src={OfferSentIcon} /> */}
                        <TimelineIcon
                          width="35px"
                          height="35px"
                          className="circle-svg-icons"
                        />
                      </span>
                      <span className="f-16 font-weight-600 gray9 m-40">
                        <span className="font-weight-600">Offer Accepted</span>{" "}
                        &nbsp;by&nbsp;{candidateName} for{" "}
                        <span className="font-weight-600">
                          {timeLine?.jobTitle}
                        </span>
                      </span>
                    </StepLabel>
                    <StepContent className="">
                      <Typography className="f-14 gray7 font-weight-400 ml-10">
                        {candidateName} accepted offer for {timeLine?.jobTitle}
                      </Typography>
                      <>
                      <Grid className="stepper_time_line">
                        { timeLine.offerAcceptedDocumentSignedUrl?.length > 0 ?
                        timeLine.offerAcceptedDocumentSignedUrl.map((offer_document, index) => {
                          return (
                            <Typography key={index}>
                              {offer_document && (
                                <>
                                  <Grid
                                    className="d-flex align-center p-adsolute cursor-hover"
                                    onClick={() =>
                                      downloadDocument(
                                        offer_document
                                      )
                                    }
                                    onKeyPress={() =>
                                      downloadDocument(
                                        offer_document
                                      )
                                    }
                                  >
                                    <Grid className="mb-10 mr-5 resume-upload mt-10 ml-25">
                                      <a
                                        //key={index}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        tabIndex={0}
                                        className="text-decoration-none align-center d-flex"
                                      >
                                        <Grid
                                          item
                                          className="d-flex uploadResume align-center w-100"
                                        >
                                          <Typography className="justify-between d-flex align-center w-100">
                                            <Typography className="d-flex">
                                              <Box className="d-flex align-center">
                                                {offer_document
                                                  .split(".")
                                                  .pop()
                                                  .split("?")[0] === "pdf" && (
                                                  <img
                                                    src={Resumepdf}
                                                    alt="img"
                                                    width="30px"
                                                    className="m-r-10"
                                                  />
                                                )}
                                                {offer_document
                                                  .split(".")
                                                  .pop()
                                                  .split("?")[0] === "doc" && (
                                                  <img
                                                    src={docIcon}
                                                    alt="img"
                                                    width="30px"
                                                    className="m-r-10"
                                                  />
                                                )}
                                                {offer_document
                                                  .split(".")
                                                  .pop()
                                                  .split("?")[0] === "docx" && (
                                                  <img
                                                    src={docIcon}
                                                    alt="img"
                                                    width="30px"
                                                    className="m-r-10"
                                                  />
                                                )}
                                              </Box>
                                              <Typography>
                                                <span className="gray9 f-14 font-weight-400 break-word">
                                                  {offer_document &&
                                                    decodeURIComponent(
                                                      offer_document
                                                        ?.split("/")
                                                        .pop()
                                                        .split("?")[0]
                                                        // .split("_")[1]
                                                    )}
                                                </span>
                                              </Typography>
                                            </Typography>
                                            <img
                                              src={downloadIcon}
                                              alt="img"
                                              width="16px"
                                              className="ml-20"
                                            />
                                          </Typography>
                                        </Grid>
                                      </a>
                                    </Grid>
                                  </Grid>
                                </>
                              )}
                            </Typography>
                            );
                          }) : ("")
                        }
                      </Grid>
                      </>
                    </StepContent>
                  </Step>
                </>
              )}
              {timeLine?.enterpriseProcessStatus === "B" &&
                timeLine?.offBoardingStatus === "S" && (
                  <>
                    {lastUpdatedTimestamp(timeLine?.processTimestamp)}
                    <Step expanded className="mt-10">
                      <StepLabel className="f-16 gray9 font-weifgt-600">
                        <span className="p-absolute timeline-img">
                          <TimelineIcon
                            width="35px"
                            height="35px"
                            className="bg-white circle-svg-icons"
                          />
                        </span>
                        <span className="f-16 font-weight-600 gray9 m-40">
                          <span className="font-weight-600">Completed</span>{" "}
                          <span className="font-weight-600">
                            {timeLine?.jobTitle}
                          </span>
                        </span>
                      </StepLabel>
                      <StepContent className="">
                        <Typography className="f-14 gray7 font-weight-400 ml-10">
                          Completed {timeLine?.jobTitle} for {candidateName}{" "}
                        </Typography>
                      </StepContent>
                    </Step>
                  </>
                )}
              {timeLine?.enterpriseProcessStatus === "B" &&
                timeLine?.offBoardingStatus === "Z" && (
                  <>
                    {lastUpdatedTimestamp(timeLine?.processTimestamp)}
                    <Step expanded className="mt-10">
                      <StepLabel className="f-16 gray9 font-weifgt-600">
                        <span className="p-absolute timeline-img">
                          <TimelineIcon
                            width="35px"
                            height="35px"
                            className="circle-svg-icons"
                          />
                        </span>
                        <span className="f-16 font-weight-600 gray9 m-40">
                          <span className="font-weight-600">Terminated</span>{" "}
                          <span className="font-weight-600">
                            {timeLine?.jobTitle}
                          </span>
                        </span>
                      </StepLabel>
                      <StepContent className="">
                        <Typography className="f-14 gray7 font-weight-400 ml-10">
                          {candidateName} terminated {timeLine?.jobTitle}
                        </Typography>
                      </StepContent>
                    </Step>
                  </>
                )}
              {timeLine?.enterpriseProcessStatus === "B" &&
                timeLine?.offBoardingStatus === "X" && (
                  <>
                    {lastUpdatedTimestamp(timeLine?.processTimestamp)}
                    <Step expanded className="mt-10">
                      <StepLabel className="f-16 gray9 font-weifgt-600">
                        <span className="p-absolute timeline-img">
                          <TimelineIcon
                            width="35px"
                            height="35px"
                            className="bg-white circle-svg-icons"
                          />
                        </span>
                        <span className="f-16 font-weight-600 gray9 m-40">
                          <span className="font-weight-600">Terminated</span>{" "}
                          <span className="font-weight-600">
                            {timeLine?.jobTitle}
                          </span>
                        </span>
                      </StepLabel>
                      <StepContent className="">
                        <Typography className="f-14 gray7 font-weight-400 ml-10">
                          {timeLine?.enterpriseName} terminated {timeLine?.jobTitle}
                        </Typography>
                      </StepContent>
                    </Step>
                  </>
                )}
              {timeLine?.enterpriseProcessStatus === "O" && (
                <>
                  {lastUpdatedTimestamp(timeLine?.processTimestamp)}
                  <Step expanded className="mt-10">
                    <StepLabel className="f-16 gray9 font-weifgt-600">
                      <span className="p-absolute timeline-img">
                        <TimelineIcon
                          width="35px"
                          height="35px"
                          className="circle-svg-icons"
                        />
                      </span>
                      <span className="f-16 font-weight-600 gray9 m-40">
                        <span className="font-weight-600">Onboarded</span> for{" "}
                        <span className="font-weight-600">
                          {timeLine?.jobTitle}
                        </span>
                      </span>
                    </StepLabel>
                    <StepContent className="">
                      <Typography className="f-14 gray7 font-weight-400 ml-10">
                        {candidateName} onboarded for {timeLine?.jobTitle}
                      </Typography>
                    </StepContent>
                  </Step>
                </>
              )}
              {timeLine?.noteProcessStatus === "SHORTLIST" && (
                <>
                  {lastUpdatedTimestamp(timeLine?.processTimestamp)}

                  <Step expanded className="mt-10">
                    <StepLabel className="f-16 gray9 font-weifgt-600">
                      <span className="p-absolute timeline-img">
                        {/* <img src={shortlistedIcon} /> */}
                        <TimelineIcon
                          width="35px"
                          height="35px"
                          className="circle-svg-icons"
                        />
                      </span>
                      <span className="f-16 font-weight-600 gray9 m-40">
                        Added a <span className="font-weight-600">note</span>&nbsp;by&nbsp;
                        {timeLine?.enterpriseName} for{" "}
                        <span className="font-weight-600">
                          {timeLine?.jobTitle}
                        </span>
                      </span>
                    </StepLabel>
                    <StepContent className="">
                      <Typography className="f-14 gray7 font-weight-400 ml-10">
                        {timeLine?.note ? timeLine?.note : "-"}
                      </Typography>
                    </StepContent>
                  </Step>
                </>
              )}
            </Grid>
          );
        })
      : "Candidate is not associated to any of the jobs yet";
  return (
    <>
      <Grid
        spacing={1}
        item
        lg={12}
        md={12}
        xs={12}
        className="time-line-border"
      >
        <Grid>
          <Grid>
            <Box className="timeline-tabs">
              <Tabs
                value={tabValue}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab
                  label={`All (${
                    candidateStatistics?.totalCount >= 10000
                      ? "10000+"
                      : candidateStatistics?.totalCount
                  })`}
                  value={1}
                />
                <Tab
                  label={`Applied (${
                    candidateStatistics?.appliedCandidates >= 10000
                      ? "10000+"
                      : candidateStatistics?.appliedCandidates
                  })`}
                  value={2}
                />
                <Tab
                  label={`Shortlist (${
                    candidateStatistics?.shortlistedCandidates >= 10000
                      ? "10000+"
                      : candidateStatistics?.shortlistedCandidates
                  })`}
                  value={3}
                />

                <Tab
                  label={`Offered (${
                    candidateStatistics?.offeredCandidates >= 10000
                      ? "10000+"
                      : candidateStatistics?.offeredCandidates
                  })`}
                  value={4}
                />
                <Tab
                  label={`Onboarded (${
                    candidateStatistics?.onboardedCandidates >= 10000
                      ? "10000+"
                      : candidateStatistics?.onboardedCandidates
                  })`}
                  value={5}
                />
                <Tab
                  label={`Offboarded (${
                    candidateStatistics?.offboardedCandidates >= 10000
                      ? "10000+"
                      : candidateStatistics?.offboardedCandidates
                  })`}
                  value={6}
                />
              </Tabs>
            </Box>
          </Grid>
        </Grid>

        <Box className="time-lineStepper p-30 mt-10 galaxy-padding-5">
          {props.isSideDrawer ? (
            <div style={{ height: containerHeight, overflow: 'auto' }} >
            <InfiniteScroll
              dataLength={candidateTimeLines.length}
              next={() => fetchMoreData()}
              hasMore={loadMore}
              loader={<Loader/>}
              inverse={false}
              className="hello"
              // minHeight={100}
              // maxHeight={900}
              height={containerHeight}
            >
              {candidateTimeLinesList}
            </InfiniteScroll>
            </div>
          ) : (
            <InfiniteScroll
              dataLength={candidateTimeLines.length}
              next={() => fetchMoreData()}
              hasMore={loadMore}
              loader={<Loader/>}
              inverse={false}
              style={{
                overflow: "hidden",
              }}
            >
              {candidateTimeLinesList}
            </InfiniteScroll>
          )}
        </Box>
        <CandidateProfileVideoPreview
          open={openPitchVideoPreview}
          setOpen={setOpenPitchVideoPreview}
          videoSrc={pitchVideo}
        />
      </Grid>
    </>
  );
}
