import React, { useState } from "react";
import { Grid, Typography, Box, Stack, Rating, styled, LinearProgress, linearProgressClasses } from "@mui/material";
import microsoftLogo from "../../../../assets/images/microsoftLogo.svg";
import moment from "moment";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ReactStars from "react-rating-stars-component";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CandidateService from "../../../../Services/Enterprise/Candidate.service";
import starEmpty from "../../../../assets/images/star-icon-empty.svg";
import starFilled from "../../../../assets/images/star-icon-filled.svg";
import Constants from "../../../../helpers/constants";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },

  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#F2C029" : "#EFF0F6",
  },
}));

const ratingStar = {
  size: 25,
  count: 5,
  isHalf: true,
  color: "rgba(242, 192, 41, 0.2)",
  activeColor: "#F2C029",
  lineHeight: 1,
  onChange: (newValue) => {
  },
};

export default function RatingsAndReviewsView(props) {
  const [openRatingCategory, setRatingCategory] = useState(false);
  const [ratingDetails, setRatingDetails] = useState({});

  const openRatingCategorySection = () => {
    setRatingCategory(!openRatingCategory);
  };

  const { candidateId } = props;

  React.useEffect(async () => {
    await CandidateService.getCandidateReview(candidateId)
      .then((res) => {
        setRatingDetails(res);
      })
      .catch(function (error) {});
  }, []);

  return (
    <>
      <Grid item>
        <Typography
          variant="h5"
          className="font-weight-600 sub-text-font gray9 border-bottom-1 pb-10"
        >
          Rating and Reviews
        </Typography>

        <Grid item className="px-5">
          <Grid container item spacing={1} className="align-center mt-20">
            <Grid item lg={2} md={2} xs={2}>
              <Typography className="font-weight-400 f-14 gray9">
                5 star
              </Typography>
            </Grid>

            <Grid item lg={9} md={9} xs={9} className="rating-progress">
              <BorderLinearProgress
                variant="determinate"
                value={
                  ratingDetails.ratingPercentage
                    ? Math.floor(
                        ratingDetails.ratingPercentage[4]
                          ? ratingDetails.ratingPercentage[4]
                          : 0
                      )
                    : 0
                }
                aria-labelledby="Progress bar"
                aria-label="Progress bar"
              />
            </Grid>

            <Grid item lg={1} md={1} xs={1} className="pl-12">
              <Typography className="font-weight-400 f-14 gray9">
                {ratingDetails.ratingPercentage
                  ? Math.floor(
                      ratingDetails.ratingPercentage[4]
                        ? ratingDetails.ratingPercentage[4]
                        : 0
                    )
                  : 0}
                %
              </Typography>
            </Grid>
          </Grid>

          <Grid container item spacing={1} className="align-center mt-5">
            <Grid item lg={2} md={2} xs={2}>
              <Typography className="font-weight-400 f-14 gray9">
                4 star
              </Typography>
            </Grid>

            <Grid item lg={9} md={9} xs={9} className="rating-progress">
              <BorderLinearProgress
                variant="determinate"
                value={
                  ratingDetails.ratingPercentage
                    ? Math.floor(
                        ratingDetails.ratingPercentage[3]
                          ? ratingDetails.ratingPercentage[3]
                          : 0
                      )
                    : 0
                }
                aria-labelledby="Progress bar"
                aria-label="Progress bar"
              />
            </Grid>

            <Grid item lg={1} md={1} xs={1} className="pl-12">
              <Typography className="font-weight-400 f-14 gray9">
                {ratingDetails.ratingPercentage
                  ? Math.floor(
                      ratingDetails.ratingPercentage[3]
                        ? ratingDetails.ratingPercentage[3]
                        : 0
                    )
                  : 0}
                %
              </Typography>
            </Grid>
          </Grid>

          <Grid container item spacing={1} className="align-center mt-5">
            <Grid item lg={2} md={2} xs={2}>
              <Typography className="font-weight-400 f-14 gray9">
                3 star
              </Typography>
            </Grid>

            <Grid item lg={9} md={9} xs={9} className="rating-progress">
              <BorderLinearProgress
                variant="determinate"
                value={
                  ratingDetails.ratingPercentage
                    ? Math.floor(
                        ratingDetails.ratingPercentage[2]
                          ? ratingDetails.ratingPercentage[2]
                          : 0
                      )
                    : 0
                }
                aria-labelledby="Progress bar"
                aria-label="Progress bar"
              />
            </Grid>

            <Grid item lg={1} md={1} xs={1} className="pl-12">
              <Typography className="font-weight-400 f-14 gray9">
                {ratingDetails.ratingPercentage
                  ? Math.floor(
                      ratingDetails.ratingPercentage[2]
                        ? ratingDetails.ratingPercentage[2]
                        : 0
                    )
                  : 0}
                %
              </Typography>
            </Grid>
          </Grid>

          <Grid container item spacing={1} className="align-center mt-5">
            <Grid item lg={2} md={2} xs={2}>
              <Typography className="font-weight-400 f-14 gray9">
                2 star
              </Typography>
            </Grid>

            <Grid item lg={9} md={9} xs={9} className="rating-progress">
              <BorderLinearProgress
                variant="determinate"
                value={
                  ratingDetails.ratingPercentage
                    ? Math.floor(
                        ratingDetails.ratingPercentage[1]
                          ? ratingDetails.ratingPercentage[1]
                          : 0
                      )
                    : 0
                }
                aria-labelledby="Progress bar"
                aria-label="Progress bar"
              />
            </Grid>

            <Grid item lg={1} md={1} xs={1} className="pl-12">
              <Typography className="font-weight-400 f-14 gray9">
                {ratingDetails.ratingPercentage
                  ? Math.floor(
                      ratingDetails.ratingPercentage[1]
                        ? ratingDetails.ratingPercentage[1]
                        : 0
                    )
                  : 0}
                %
              </Typography>
            </Grid>
          </Grid>

          <Grid container item spacing={1} className="align-center mt-5">
            <Grid item lg={2} md={2} xs={2}>
              <Typography className="font-weight-400 f-14 gray9">
                1 star
              </Typography>
            </Grid>

            <Grid item lg={9} md={9} xs={9} className="rating-progress">
              <BorderLinearProgress
                variant="determinate"
                value={
                  ratingDetails.ratingPercentage
                    ? Math.floor(
                        ratingDetails.ratingPercentage[0]
                          ? ratingDetails.ratingPercentage[0]
                          : 0
                      )
                    : 0
                }
                aria-labelledby="Progress bar"
                aria-label="Progress bar"
              />
            </Grid>

            <Grid item lg={1} md={1} xs={1} className="pl-12">
              <Typography className="font-weight-400 f-14 gray9">
                {ratingDetails.ratingPercentage
                  ? Math.floor(
                      ratingDetails.ratingPercentage[0]
                        ? ratingDetails.ratingPercentage[0]
                        : 0
                    )
                  : 0}
                %
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid item
            className="mt-20 d-flex justify-between px-5 cand-rating-align"
            onClick={openRatingCategorySection}
            onKeyPress={openRatingCategorySection}
            tabIndex={0}
          >
            <Typography className="f-14 link-color align-item-center">
              Ratings based on categories
              <span className="gray9 mt-5 px-5">
                {openRatingCategory ? (
                  <KeyboardArrowDownIcon aria-label="Arrow icon" tabIndex={0} />
                ) : (
                  <ChevronRightIcon aria-label="Arrow icon" tabIndex={0} />
                )}
              </span>
            </Typography>
          </Grid>

          {openRatingCategory && (
            <Grid item className="px-5 mt-10">
              <Grid item lg={12} md={12} xs={12} className="d-flex mt-5">
                <Grid item lg={7} md={7} xs={4}>
                  <span className="f-14 gray9 userBg-space">
                    Delivery Quality
                  </span>
                </Grid>

                <Grid item lg={4} md={4} xs={7} className="justify-center d-flex categories-align">
                  <span>
                    <Rating
                      count={5}
                      // edit={false}
                      // size={24}
                      isHalf={true}
                      // activeColor="#ffd700"
                      // {...ratingStar}
                      style={{ lineHeight: 1 }}
                      value={ratingDetails.deliveryQuality}
                      readOnly
                      icon={
                        <img
                          src={starFilled}
                          style={{ width: "20px", height: "20px" }}
                          alt="img"
                        />
                      }
                      emptyIcon={
                        <img
                          src={starEmpty}
                          style={{ width: "20px", height: "20px" }}
                          alt="img"
                        />
                      }
                    />
                  </span>
                </Grid>

                <Grid item lg={1} md={1} xs={1} className="justify-center d-flex categories-align category-rating">
                  <span className="f-14 gray9">
                    {Math.round(
                      ratingDetails.deliveryQuality
                        ? ratingDetails.deliveryQuality
                        : 0
                    ) > 5
                      ? 5
                      : Math.round(
                          ratingDetails.deliveryQuality
                            ? ratingDetails.deliveryQuality
                            : 0
                        )}
                  </span>
                </Grid>
              </Grid>

              <Grid item lg={12} md={12} xs={12} className="d-flex mt-10">
                <Grid item lg={7} md={7} xs={4}>
                  <span className="f-14 gray9 userBg-space">
                    Schedule Adherence
                  </span>
                </Grid>

                <Grid item lg={4} md={4} xs={7} className="justify-center d-flex categories-align">
                  <span>
                    <Rating
                      count={5}
                      //  edit={false}
                      //  size={24}
                      isHalf={true}
                      //  activeColor="#ffd700"
                      //  {...ratingStar}
                      style={{ lineHeight: 1 }}
                      value={ratingDetails.scheduleAdherence}
                      readOnly
                      icon={
                        <img
                          src={starFilled}
                          style={{ width: "20px", height: "20px" }}
                          alt="img"
                        />
                      }
                      emptyIcon={
                        <img
                          src={starEmpty}
                          style={{ width: "20px", height: "20px" }}
                          alt="img"
                        />
                      }
                    />
                  </span>
                </Grid>

                <Grid item lg={1} md={1} xs={1} className="justify-center d-flex categories-align category-rating">
                  <span className="f-14 gray9">
                    {Math.round(
                      ratingDetails.scheduleAdherence
                        ? ratingDetails.scheduleAdherence
                        : 0
                    ) > 5
                      ? 5
                      : Math.round(
                          ratingDetails.scheduleAdherence
                            ? ratingDetails.scheduleAdherence
                            : 0
                        )}
                  </span>
                </Grid>
              </Grid>

              <Grid item lg={12} md={12} xs={12} className="d-flex mt-10">
                <Grid item lg={7} md={7} xs={4}>
                  <span className="f-14 gray9  userBg-space">
                    Sticking to Commitment
                  </span>
                </Grid>

                <Grid item lg={4} md={4} xs={7} className="justify-center d-flex categories-align">
                  <span>
                    <Rating
                      count={5}
                      //  edit={false}
                      //  size={24}
                      isHalf={true}
                      //  activeColor="#ffd700"
                      //  {...ratingStar}
                      style={{ lineHeight: 1 }}
                      value={ratingDetails.stickingToCommitment}
                      readOnly
                      icon={
                        <img
                          src={starFilled}
                          style={{ width: "20px", height: "20px" }}
                          alt="img"
                        />
                      }
                      emptyIcon={
                        <img
                          src={starEmpty}
                          style={{ width: "20px", height: "20px" }}
                          alt="img"
                        />
                      }
                    />
                  </span>
                </Grid>

                <Grid item lg={1} md={1} xs={1} className="justify-center d-flex categories-align category-rating">
                  <span className="f-14 gray9">
                    {Math.round(
                      ratingDetails.stickingToCommitment
                        ? ratingDetails.stickingToCommitment
                        : 0
                    ) > 5
                      ? 5
                      : Math.round(
                          ratingDetails.stickingToCommitment
                            ? ratingDetails.stickingToCommitment
                            : 0
                        )}
                  </span>
                </Grid>
              </Grid>

              <Grid item lg={12} md={12} xs={12} className="d-flex mt-10">
                <Grid item lg={7} md={7} xs={4}>
                  <span className="f-14 gray9 userBg-space">
                    Value for Money
                  </span>
                </Grid>

                <Grid item lg={4} md={4} xs={7} className="justify-center d-flex categories-align">
                  <span>
                    <Rating
                      count={5}
                      //  edit={false}
                      //  size={24}
                      isHalf={true}
                      //  activeColor="#ffd700"
                      //  {...ratingStar}
                      style={{ lineHeight: 1 }}
                      value={ratingDetails.valueForMoney}
                      readOnly
                      icon={
                        <img
                          src={starFilled}
                          style={{ width: "20px", height: "20px" }}
                          alt="img"
                        />
                      }
                      emptyIcon={
                        <img
                          src={starEmpty}
                          style={{ width: "20px", height: "20px" }}
                          alt="img"
                        />
                      }
                    />
                  </span>
                </Grid>

                <Grid item lg={1} md={1} xs={1} className="justify-center d-flex categories-align category-rating">
                  <span className="f-14 gray9">
                    {Math.round(
                      ratingDetails.valueForMoney
                        ? ratingDetails.valueForMoney
                        : 0
                    ) > 5
                      ? 5
                      : Math.round(
                          ratingDetails.valueForMoney
                            ? ratingDetails.valueForMoney
                            : 0
                        )}
                  </span>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>

        {/* <Grid item className="border-bottom-1">
                    <Grid item className="mt-20 d-flex justify-between px-5">
                        <Typography className="d-flex pl-5">
                            <Box>
                                <img style={{ width: '45px' }} src="https://daas-prog-test.s3.amazonaws.com/default-logo.png" />
                            </Box>
                            <Box className="px-5 mt-5 pl-10">
                                <Typography variant="h4" className="font-weight-600 small-text-font gray9 textEllipse w-300 width-in-galaxy">
                                    Microsoft
                                </Typography>
                                <p className="small-text-font mt-0 mb-0" style={{lineHeight: '1.2'}}>
                                    <ReactStars
                                        count={5}
                                        edit={false}
                                        size={24}
                                        isHalf={true}
                                        activeColor="#ffd700"
                                        {...ratingStar}
                                        style={{lineHeight: 1}}
                                    />
                                </p>
                                <p className="f-12 sub-text-color m-0">Posted on 22 Jan, 2022</p>
                                <p className="small-text-font m-t-b-10">She is familiar with our company and showed interest in learning more about our upcoming projects</p>
                            </Box>
                        </Typography>
                    </Grid>
                </Grid> */}

        <Grid item
          className="mt-15"
          // style={{ maxHeight: "250px", overflowY: "auto" }}
        >
          {ratingDetails.reviews?.map((review, index) => (
            <Grid  item key={index}>
              <Grid item className="border-bottom-1">
                <Grid item className="mt-10 d-flex justify-between px-5">
                  <Typography className="d-flex pl-5">
                    <Box>
                      <img
                      key={index}
                        className="round-image"
                        style={{ width: "45px", height: '45px' }}
                        src={
                          review.logoFileSigned
                            ? review.logoFileSigned
                            : Constants.DEFAULT_LOGO
                        }
                        alt="img"
                      />
                      {/* <img style={{width:'65%'}} src={workLogo} /> */}
                    </Box>
                    <Box className="px-5 mt-5 pl-10">
                      <Typography
                        variant="h4"
                        className="font-weight-600 small-text-font gray9 textEllipse w-300 width-in-galaxy history-align"
                      >
                        {review.establishmentName}
                      </Typography>
                      <p
                        className="small-text-font mt-0 mb-0"
                        style={{ lineHeight: "1.2" }}
                      >
                        <Rating
                          count={5}
                          // edit={false}
                          // size={24}
                          isHalf={true}
                          // activeColor="#ffd700"
                          // {...ratingStar}
                          style={{ lineHeight: 1 }}
                          value={review.rating_overall}
                          readOnly
                          icon={
                            <img
                              src={starFilled}
                              style={{ width: "20px", height: "20px" }}
                              alt="img"
                            />
                          }
                          emptyIcon={
                            <img
                              src={starEmpty}
                              style={{ width: "20px", height: "20px" }}
                              alt="img"
                            />
                          }
                        />
                      </p>
                      <p className="f-12 sub-text-color m-0">
                        Posted on{" "}
                        {moment(review.offboardedDate).format("MMM DD, YYYY")}
                      </p>
                      <p className="small-text-font m-t-b-10 break-word">
                        {review.ratingRemarksByEnterprise}
                      </p>
                    </Box>
                  </Typography>
                </Grid>

                {review.ratingRemarksByCandidate && (
                  <Grid item className="mt-10 d-flex justify-between px-5 pl-50">
                    <Typography className="d-flex pl-5">
                      <Box>
                        <img
                          className="round-image"
                          style={{ width: "45px", height: '45px' }}
                          src={
                            review.mediaFileSigned
                              ? review.mediaFileSigned
                              : Constants.DEFAULT_USER
                          }
                          alt="img"
                        />
                      </Box>
                      <Box className="px-5 mt-5 pl-10">
                        <Typography
                          variant="h4"
                          className="font-weight-600 small-text-font gray9 textEllipse w-300 width-in-galaxy history-align"
                        >
                          {review.candidateName}
                        </Typography>
                        <p className="f-12 sub-text-color m-0">
                          Replied on{" "}
                          {moment(review.candidateRepliedOn).format(
                            "MMM DD, YYYY"
                          )}
                        </p>
                        <p className="small-text-font m-t-b-10 word-break">
                          {review.ratingRemarksByCandidate}
                        </p>
                      </Box>
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
}
