import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
} from "@mui/material";
import moment from "moment";
import { Tooltip, TooltipTrigger, TooltipContent } from 'react-tippy';
export default function CertificationName(props) {
  const certificationNameRef = useRef();
  const [isOverflow, setIsOverflow] = useState(false);
  useEffect(()=>{
    if (certificationNameRef.current?.offsetWidth < certificationNameRef.current?.scrollWidth) {
      setIsOverflow(true);
    } else {
      setIsOverflow(false);
    }
  }, [props.certificationName]);
  return (
    <>
    {isOverflow ?
        <Tooltip
          position="right"
          trigger="mouseenter"
          animation="fade"
          arrow
          title={<div>{props.certificationName}</div>}
        >
        <Typography
          className={`font-weight-500 gray11 f-16 textEllipse w-500 address-align
          ${props.expiringOn && !moment(props.expiringOn.substring(2, 6)+"-"+props.expiringOn.substring(0, 2))
              .add(1, "months")
              .isAfter(
                moment().format(
                  "MMM DD, YYYY"
                ),
                "month"
              )
              ? " danger-color"
              : ""
          }`}
        >
            {props.certificationName}
        </Typography>
        </Tooltip>
        :
        <Typography ref={certificationNameRef}
          className={`body-medium-text textEllipse w-500 address-align
          ${props.expiringOn && !moment(props.expiringOn.substring(2, 6)+"-"+props.expiringOn.substring(0, 2))
              .add(1, "months")
              .isAfter(
                moment().format(
                  "MMM DD, YYYY"
                ),
                "month"
              )
              ? " danger-color"
              : ""
          }`}
        >
            {props.certificationName}
        </Typography>
        }
        </>
  );
}
