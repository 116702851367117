import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../Components/LaunchPage/Footer";
import Navbar from "../Components/LaunchPage/Navbar";
import { Helmet } from "react-helmet";
import NewNavBar from "../Components/NewLaunchPage/NewNavBar";
import NewFooter from "../Components/NewLaunchPage/NewFooter";
import HireTopTalent from "../Components/NewLaunchPage/HireTopTalent";
import SimplePowerAffordable from "../Components/NewLaunchPage/SimplePowerAffordable";
import QualityCandidates from "../Components/NewLaunchPage/QualityCandidates";
import CaursoulSection from "../Components/NewLaunchPage/CaursoulSection";
import DirectSourceLaunch from "../Components/NewLaunchPage/DirectSourceLaunch";
import DirectSourceQuality from "../Components/NewLaunchPage/DirectSourceQuality";
import DirectSourceingContent from  "../Components/NewLaunchPage/DirectSourceingContent";
import { Grid, Container } from "@mui/material";
import { HideScroll } from "react-hide-on-scroll";
import Loader from "../Views/Loader";
import { useSelector } from "react-redux";
import Constants from "../helpers/constants";

const DefaultLayout = (props) => {
  const appOrigin = useSelector((state) => state.location.origin);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    setInterval(() => {
      setIsLoading(true);
        <Loader />;
        setIsLoading(false);
    }, 1200);
}, []);

  return (
    <>
     {isLoading &&  <Loader />}
      <Helmet>
        {appOrigin === "D" ?
        <title>Your one stop shop for your hiring needs | {Constants.APP_NAME_GENERAL}</title>
          : appOrigin === "N" ? <title>Your one stop shop for your hiring needs | {Constants.APP_NAME_NEURODIVERSIFY}</title> :
          <title>Your one stop shop for your hiring needs | {Constants.APP_NAME_DIRECT_HEALTH}</title>
        }
        {/* <title>SimplifyHire | Your one stop shop for your hiring needs</title> */}
        <meta
          property="og:description"
          content={`${Constants.APP_NAME_GENERAL} | Your one stop shop for your hiring needs`}
        />
        <link
          property="og:url"
          content={process.env.REACT_APP_AWS_USER_POOL_REDIRECT_SIGN_IN}
        />
      </Helmet>
      {props.doNotDisplay ? <Navbar/> :
      <HideScroll variant="down">
        <Grid item className="sticky hideScrollDown" >
          <Navbar enterpriseName={props.enterpriseName} />
        </Grid>
      </HideScroll>
      }
      {/* <NewNavBar enterpriseName={props.enterpriseName}/>*/}
      {/* every child component pass from there*/}
      <Outlet />
      {!props.doNotDisplay && <>
      <DirectSourceLaunch />
      <DirectSourceQuality />
      <DirectSourceingContent />
      {/* <CaursoulSection /> */}
      {/* <QualityCandidates /> */}
      {/* <SimplePowerAffordable /> */}
      {/* <HireTopTalent /> */}
      {/* <NewFooter />*/}
      <Footer />
      </>}
    </>
  );
};
export default DefaultLayout;
