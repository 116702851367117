import React, { useState, useEffect } from "react";
import { Grid, Typography, Container, Button, Drawer } from "@mui/material";
import Loader from "../../Loader";
import moment from "moment";
import ApiCalls from "../../../api/customAxios";
import { Helmet } from "react-helmet";
import BlukUploadJobsPreview from "../../../Components/shared/BlukUploadJobsPreview";
import { useNavigate } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import {
  formatDate,
  handleSpaces,
  getFormattedLocation,
  isMinutesOrHours,
} from "../../../helpers/helper-data";
import { ReactComponent as NotificationDefaultIcon } from "../../../assets/images/notification-default-icon.svg";
import { Tooltip, TooltipTrigger, TooltipContent } from "react-tippy";
import CandidateService from "../../../Services/Enterprise/Candidate.service";
import { danger } from "../../../helpers/message-box";
import InterviewService from "../../../Services/Enterprise/Interview.service";
import AccessService from "../../../Services/Enterprise/Access.service";
import InfiniteScroll from "react-infinite-scroll-component";
import DownloadAnalytics from "../../EnterprisePortal/Download_analytics";

export default function NotificationList(props) {
  let navigate = useNavigate();
  // const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadJobsDone, setUploadJobsDone] = React.useState(false);
  const [uploadJobs, setUploadJobs] = useState([]);
  const [allnotifications, setallnotifications] = useState([]);
  const [loadMore, setLoadMore] = React.useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [selectedbutton, setselectedbutton] = useState("All");
  const [scrollnotifications, setScrollnotifications] = useState([]);
  const [containerHeight, setContainerHeight] = useState(window.innerHeight * 0.8);
  const [downloadPopup, setDownloadPopup] = React.useState(false);
  const [url, setUrl] = useState("");
  // useEffect(() => {
  //   getNotificationsDtoList();
  // }, []);

  // const getNotificationsDtoList = () => {
  //   let mounted = true;
  //   setIsLoading(true);
  //   ApiCalls.get("candidates/notifications/E/0/10")
  //     .then((response) => {
  //       if (mounted) {
  //         setNotifications(response.notificationsDtoList);
  //         handleNotificationRead();
  //       }
  //     })
  //     .catch(function (error) {
  //       setIsLoading(false);
  //     });
  //   return () => (mounted = false);
  // };

  // const handleNotificationRead = () => {
  //   ApiCalls.put("candidates/notifications/E")
  //     .then((response) => {
  //       //setNotificationsCount(response.data.unReadNotifications);
  //       setIsLoading(false);
  //     })
  //     .catch(function (error) {});
  // };
  const handleOpenUploadJobsDone = (data) => {
    let json = JSON.parse(data);
    localStorage.setItem("filterUploadId", json.data);
    navigate("/enterprise/jobsLog");
    props.setNotificationListDrawer(false);
    setselectedbutton("All");
    setIsLoading(false);
  };
  const handleAllNotificationRead = () => {
    ApiCalls.put("candidates/notifications/E")
  .then((response) => {
    props.getNotificationsDtoList();
    props.setNotificationsCount(0);
    props.setNotificationListDrawer(false);
    setselectedbutton("All");
  })
  .catch(function (error) { });
};
const reportsPopup = (value) => {
  setDownloadPopup(value);
};
const fetchMoreData = () => {
  let pgNo = pageNumber + 1;
  setPageNumber(pgNo);
  console.log("Inside fetchMoreData");
  let apiCall = "";
  if (selectedbutton === "All" && pgNo <= 4) {
      apiCall = () => ApiCalls.get(`candidates/notifications/E/${pgNo}/10`);
  } else if (selectedbutton === "Unread" && pgNo <= 4) {
      apiCall = () => ApiCalls.get("candidates/notifications/unread/E");
  }
  if (apiCall == "") {
    return;
  }
  apiCall()
      .then((response) => {
          const newNotifications = response.notificationsDtoList;
          if (response.notificationsDtoList.length < 10) {
            setLoadMore(false);
          } else {
            setLoadMore(true);
          }
          let startingindex = pgNo * 10;
          let endingindex = startingindex + 10;
          setIsLoading(false);
          if (selectedbutton == "All") {
          const updatedNotifications = [...allnotifications, ...newNotifications];
          setallnotifications(updatedNotifications);
          } else {
          const updatedNotifications = [...allnotifications, ...newNotifications.slice(startingindex, endingindex)];
          setallnotifications(updatedNotifications);
          }
      })
      .catch((error) => {
          console.error('Error fetching more notifications:', error);
          setIsLoading(false);
      });
};
const getNotificationsDtoList = () => {
  let mounted = true;
  setPageNumber(0);
  ApiCalls.get("candidates/notifications/E/0/10")
      .then((response) => {
          if (mounted) {
            setselectedbutton("All");
            if (response.notificationsDtoList.length < 10) {
              setLoadMore(false);
            } else {
              setLoadMore(true);
            }
              setallnotifications(response.notificationsDtoList);
          }
      })
      .catch(function (error) { });
  return () => (mounted = false);
};
const getUnreadNotificationsDtoList = () => {
  let mounted = true;
  setPageNumber(0);
  ApiCalls.get("candidates/notifications/unread/E")
      .then((response) => {
          if (mounted) {
            setselectedbutton("Unread");
            if (response.notificationsDtoList.length < 10) {
              setLoadMore(false);
            } else {
              setLoadMore(true);
            }
              // setallnotifications(response.notificationsDtoList);
              const firstTenNotifications = response.notificationsDtoList.slice(0, 10);
              setallnotifications(firstTenNotifications);
              props.setNotificationsCount(response.unReadNotifications);
          }
      })
      .catch(function (error) { });
  return () => (mounted = false);
};
  // const isMinutesOrHours = (date) => {
  //   const date1 = moment.utc(date).toDate();
  //   const date2 = new Date();
  //   const diffInMs = Math.abs(date2 - date1);
  //   const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
  //   const diffInMinutes = Math.floor((diffInMs / (1000 * 60)) % 60);
  //   if (diffInMinutes == 0) {
  //     return "just now";
  //   }
  //   if (diffInMinutes <= 60) {
  //     return diffInMinutes + "min ago";
  //   } else {
  //     return diffInHours + "hr ago";
  //   }
  // };
  var onedayBefore = new Date();
  onedayBefore.setDate(onedayBefore.getDate() - 1);
  var currentDateWeekFormat = new Date();
  currentDateWeekFormat.setDate(currentDateWeekFormat.getDate() - 7);
  useEffect(() => {
    const bodyElement = document.body;
    const htmlElement = document.documentElement;
    if (props.notificationListDrawer) {
      bodyElement.style.overflow = "hidden";
      htmlElement.style.overflow = "hidden";
      if (selectedbutton == "All") {
        getNotificationsDtoList();
      } else {
        getUnreadNotificationsDtoList();
      }
    } else {
      bodyElement.style.overflow = "";
      htmlElement.style.overflow = "";
    }
    const handleResize = () => {
      setContainerHeight(window.innerHeight * 0.8);
  };
  window.addEventListener('resize', handleResize);
  return () => {
      window.removeEventListener('resize', handleResize);
  };
  }, [props.notificationListDrawer]);
  function extractDateFromString(startDate, endDate) {
    const currentDate = moment().format("YYYY-MM-DD");
    const slotExpiry = moment(startDate).local().format("YYYY-MM-DD");
    let diffInMinutes = moment(endDate).local().diff(moment(), "minutes");
    let diffInDays = slotExpiry ? moment(currentDate).diff(moment(slotExpiry), 'days') : 0;
    return diffInDays <= 0 && diffInMinutes < 0;
};
  const interviewDetails = (url, notification) => {
      let arr = url.split("/");
      InterviewService.getInterviewDetails(arr[arr?.length - 2])
        .then((response) => {
          let scheduleRound = response?.enterpriseInterviewScheduleList[arr[arr?.length - 1] - 1];
          let hasMatch = scheduleRound.interviewPanelMembers?.find((mem) => {
                return (
                  localStorage.getItem("enterprise_user_id") == mem?.enterpriseUserId
                );
              });
          if (((hasMatch?.recommendationStatus == null && response.interviewHeadersDto.closureStatus != null) || hasMatch?.recommendationStatus != null) && notification.notificationValue?.includes("interview feedback")) {
            danger("The notification you're trying to access has expired.");
            return;
          } else if (scheduleRound?.interviewSchedulesDto?.status == "A" && scheduleRound?.interviewSchedulesDto?.roundCompleted == "Y" && notification.notificationValue?.includes("accepted the interview")) {
            danger("The notification you're trying to access has expired.");
            return;
          } else if (scheduleRound?.interviewSchedulesDto?.status != "A" && scheduleRound?.interviewSchedulesDto?.roundCompleted != "Y" && notification.notificationValue?.includes("accepted the interview")) {
            danger("The notification you're trying to access has expired.");
            return;
          } else if (scheduleRound?.interviewSchedulesDto?.status != "P" && notification.notificationValue?.includes("rescheduled")) {
            danger("The notification you're trying to access has expired.");
            return;
          } else if (scheduleRound?.interviewSchedulesDto?.status == "P" && notification.notificationValue?.includes("rescheduled") && localStorage.getItem("userType") == "S") {
            // Restricting associate when interview status is pending
            return;
          } else if ((scheduleRound?.interviewSchedulesDto?.status != "A" || (scheduleRound?.interviewSchedulesDto?.status == "A" && scheduleRound?.interviewSchedulesDto?.roundCompleted == "Y")) && notification.notificationValue?.includes("Interview scheduled")) {
            danger("The notification you're trying to access has expired.");
            return;
          } else if (scheduleRound?.interviewSchedulesDto?.status != "X" && notification.notificationValue?.includes("cancelled the interview")) {
            danger("The notification you're trying to access has expired.");
            return;
          } else if (scheduleRound?.interviewSchedulesDto?.status != "D" && notification.notificationValue?.includes("declined the interview")) {
            danger("The notification you're trying to access has expired.");
            return;
          } else if (scheduleRound?.interviewSchedulesDto?.status == "A" && notification.notificationValue?.includes("upcoming interview") && extractDateFromString(scheduleRound?.interviewSchedulesDto.scheduleStartDateTime, scheduleRound?.interviewSchedulesDto.scheduleCompleteDateTime)) {
            danger("The notification you're trying to access has expired.");
            return;
          } else if (((scheduleRound?.interviewSchedulesDto?.status == "A" && scheduleRound?.interviewSchedulesDto?.roundCompleted == "Y") || scheduleRound?.interviewSchedulesDto?.status != "A") && notification.notificationValue?.includes("upcoming interview")) {
            danger("The notification you're trying to access has expired.");
            return;
          } else if (hasMatch?.recommendationStatus == null && notification.notificationValue?.includes("interview feedback") && response.interviewHeadersDto.closureStatus == null) {
            navigate(url, { state: { isScorecard: true } });
            window.location.reload();
          } else {
            navigate(url);
          }
        })
        .catch((err) => {
          danger("Something went wrong.");
        });
  };
  const getUpdatedUnreadCount = () => {
    console.log("2-> Inside getUpdatedUnreadCount");
    ApiCalls.get("candidates/notifications/unread/E")
      .then((response) => {
        console.log("3->After API call");
        console.log(response.unReadNotifications);
        props.setNotificationsCount(response.unReadNotifications);
      })
      .catch(function (error) { });
  };
  const handleNotificationNavigation = async(notification) => {
    setIsLoading(true);
    ApiCalls.put(`candidates/notifications/${notification.id}/R`)
    .then((response) => {
      props.getNotificationsDtoList();
      getUpdatedUnreadCount();
      // setNotificationsCount(0);
    })
    .catch(function (error) { });
    let candidateID;
    let JobId;
    if (notification?.callToActionUrl?.includes("Applied")) {
      const searchString = "/enterprise";
      const startIndex = notification.callToActionUrl.indexOf(searchString);
      const extractedString = notification.callToActionUrl.substring(startIndex);
      const segments = notification.callToActionUrl.split('/');
      const jobIdIndex = segments.findIndex((segment) => segment === 'jobs');
      const candidateIdIndex = notification.callToActionUrl.indexOf('candidateId=');
      if (jobIdIndex !== -1 && segments[jobIdIndex + 1]) {
        JobId = segments[jobIdIndex + 1];
      }
      if (candidateIdIndex !== -1) {
         candidateID = notification.callToActionUrl.substring(candidateIdIndex + 12);
      }
      const statusResponse = await getCandidateStatusApi("A", true, JobId, candidateID);
      if (statusResponse[0]?.enterpriseProcessStatus == null && statusResponse[0]?.candidateProcessStatus == "A") {
        navigate(extractedString);
        props.setNotificationListDrawer(false);
        setselectedbutton("All");
        setIsLoading(false);
        getUpdatedUnreadCount();
      } else {
        danger("The notification you're trying to access has expired.");
        props.setNotificationListDrawer(false);
        setselectedbutton("All");
        setIsLoading(false);
        getUpdatedUnreadCount();
        return;
      }
    } else if (notification?.callToActionUrl?.includes("analytics_report")) {
      // setDownloadPopup(true);
      const urlData = new URL(notification?.callToActionUrl);
      const queryParams = new URLSearchParams(urlData.search);
      setUrl(queryParams.get("analytics_report"));
      setIsLoading(false);
    } else if (notification?.callToActionUrl?.includes("Offered")) {
      const searchString = "/enterprise";
      const startIndex = notification.callToActionUrl.indexOf(searchString);
      const extractedString = notification.callToActionUrl.substring(startIndex);
      const segments = notification.callToActionUrl.split('/');
      const jobIdIndex = segments.findIndex((segment) => segment === 'jobs');
      const candidateIdIndex = notification.callToActionUrl.indexOf('candidateId=');
      if (jobIdIndex !== -1 && segments[jobIdIndex + 1]) {
        JobId = segments[jobIdIndex + 1];
      }
      if (candidateIdIndex !== -1) {
         candidateID = notification.callToActionUrl.substring(candidateIdIndex + 12);
      }
      const statusResponse = await getCandidateStatusApi("F", true, JobId, candidateID);
      if (statusResponse[0]?.enterpriseProcessStatus !== null && statusResponse[0]?.enterpriseProcessStatus === "F" && (statusResponse[0]?.candidateProcessStatus === "P" ||  statusResponse[0]?.candidateProcessStatus === "D")) {
        navigate(extractedString);
        props.setNotificationListDrawer(false);
        setselectedbutton("All");
        setIsLoading(false);
        getUpdatedUnreadCount();
      } else {
        danger("The notification you're trying to access has expired.");
        props.setNotificationListDrawer(false);
        setselectedbutton("All");
        setIsLoading(false);
        getUpdatedUnreadCount();
        return;
      }
    } else if (notification?.callToActionUrl?.includes("Offboarded")) {
      const searchString = "/enterprise";
      const startIndex = notification.callToActionUrl.indexOf(searchString);
      const extractedString = notification.callToActionUrl.substring(startIndex);
      const segments = notification.callToActionUrl.split('/');
      const jobIdIndex = segments.findIndex((segment) => segment === 'jobs');
      const candidateIdIndex = notification.callToActionUrl.indexOf('candidateId=');
      if (jobIdIndex !== -1 && segments[jobIdIndex + 1]) {
        JobId = segments[jobIdIndex + 1];
      }
      if (candidateIdIndex !== -1) {
         candidateID = notification.callToActionUrl.substring(candidateIdIndex + 12);
      }
      const statusResponse = await getCandidateStatusApi("B", true, JobId, candidateID);
      if (statusResponse[0]?.enterpriseProcessStatus !== null && statusResponse[0]?.enterpriseProcessStatus == "B" && statusResponse[0]?.candidateProcessStatus == null) {
        navigate(extractedString);
        props.setNotificationListDrawer(false);
        setIsLoading(false);
        getUpdatedUnreadCount();
      } else {
        danger("The notification you're trying to access has expired.");
        props.setNotificationListDrawer(false);
        setselectedbutton("All");
        setIsLoading(false);
        getUpdatedUnreadCount();
        return;
      }
    } else if (notification?.callToActionUrl?.includes("talent-pool")) {
      const searchString = "/enterprise";
      const startIndex = notification.callToActionUrl.indexOf(searchString);
      const extractedString = notification.callToActionUrl.substring(startIndex);
      const segments = notification.callToActionUrl.split('/');
      const jobIdIndex = segments.findIndex((segment) => segment === 'jobs');
      const candidateIdIndex = notification.callToActionUrl.indexOf('candidateId=');
      if (jobIdIndex !== -1 && segments[jobIdIndex + 1]) {
        JobId = segments[jobIdIndex + 1];
      }
      if (candidateIdIndex !== -1) {
         candidateID = notification.callToActionUrl.substring(candidateIdIndex + 12);
      }
      let talentPoolId = segments[segments.length - 1];
      if (segments[segments.length - 1]?.includes("?")) {
        talentPoolId = segments[segments.length - 1]?.split("?")[0];
      }
      await ApiCalls.get(`candidates/talentPool/${talentPoolId}/${candidateID}`)
      .then((response)=>{
        if (response.talentPoolCandidateStatus != "P") {
          danger("The notification you're trying to access has expired.");
        } else {
          navigate(extractedString);
        }
      }).catch((err)=>{
        danger("The notification you're trying to access has expired.");
      });
      props.setNotificationListDrawer(false);
      setselectedbutton("All");
      setIsLoading(false);
      getUpdatedUnreadCount();
    } else if (notification?.callToActionUrl?.includes("/access")) {
      const searchString = "/enterprise";
      const startIndex = notification.callToActionUrl.indexOf(searchString);
      const extractedString = notification.callToActionUrl.substring(startIndex);
      let userName = '';
      let candidateId = null;
      if (notification.callToActionUrl.includes("?params=")) {
        try {
          const params = notification.callToActionUrl.split("?params=")[1];
          const decodedString = atob(params);
          const decodedObject = JSON.parse(decodedString);
          userName = decodedObject.name;
          candidateId = decodedObject.id;
        } catch (err) {}
      }
      AccessService.getUsers(0, 10, userName).then(
        (response) => {
          let { responsePayload } = { ...response };
          let users = responsePayload;
          for (let i = 0; i < users.length; i++) {
            if (users[i].candidateId === candidateId) {
              if (users[i].activeOrNot === "A") {
                danger("The notification you're trying to access has expired.");
              } else {
                if (window.location.pathname.includes("/access")) {
                  navigate(extractedString);
                  window.location.reload();
                } else {
                  navigate(extractedString);
                }
              }
              break;
            } else if (i === users.length - 1) {
              if (window.location.pathname.includes("/access")) {
                navigate(extractedString);
                window.location.reload();
              } else {
                navigate(extractedString);
              }
            }
            getUpdatedUnreadCount();
          }
          if (users.length === 0) {
            if (window.location.pathname.includes("/access")) {
              navigate(extractedString);
              window.location.reload();
            } else {
              navigate(extractedString);
            }
          }
        }
      ).catch(() => {});
      props.setNotificationListDrawer(false);
      setselectedbutton("All");
      setIsLoading(false);
      getUpdatedUnreadCount();
    } else {
      if (notification?.notificationData) {
        handleOpenUploadJobsDone(
          notification.notificationData
        );
        props.setNotificationListDrawer(false);
        setselectedbutton("All");
      } else if (notification.callToActionUrl) {
        const searchString = "/enterprise";
        const startIndex = notification.callToActionUrl.indexOf(searchString);
        const extractedString = notification.callToActionUrl.substring(startIndex);
        if (notification.notificationValue?.includes("interview feedback") ||
        notification.notificationValue?.includes("accepted the interview") ||
        notification.notificationValue?.includes("Interview scheduled") ||
        notification.notificationValue?.includes("rescheduled") ||
        notification.notificationValue?.includes("cancelled the interview") ||
        notification.notificationValue?.includes("declined the interview") ||
        notification.notificationValue?.includes("upcoming interview")) {
           interviewDetails(extractedString, notification);
        } else {
          navigate(extractedString);
          window.location.reload();
        }
        props.setNotificationListDrawer(false);
        setselectedbutton("All");
        setIsLoading(false);
        getUpdatedUnreadCount();
      } else {
        return;
      }
    }
  };
  const getCandidateStatusApi = async(status, resetPageToZero = false, jobId, candidate_Id) => {
    try {
      const response = await CandidateService.getCandidatesFromNotification(
        jobId,
        status,
        candidate_Id,
        resetPageToZero === true ? 0 : currentPage - 1
      );
      return response;
    } catch (error) {
      // Handle error if needed
      console.error("An error occurred:", error);
      return null;
    }
  };
  return (
    <>
      {/* <Helmet>
        <title>Notifications | SimplifyHire</title>
        <meta
          name="description"
          content="Edit Profile | SimplifyHire"
        />
        <link
          rel="simplify"
          href={process.env.REACT_APP_AWS_USER_POOL_REDIRECT_SIGN_IN}
        />
      </Helmet> */}
      <Drawer
        transitionDuration={{ enter: 500, exit: 500 }}
        variant="temporary"
        anchor="right"
        open={props.notificationListDrawer}
        onClose={() => {
          props.setNotificationListDrawer(false);
          props.getNotificationsDtoList();
          setselectedbutton("All");
          props?.closeBackpopups();
          setUrl("");
        }}
        className="notification-drawer"
      >
        <Container
          lg={12}
          md={12}
          xs={12}
          className="zooming-issue bg-white pl-0 pr-0 sideBar-icon"
        >
          {isLoading && <Loader />}
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
            className="m-auto notification-align"
          >
            <Grid sx={{ pt: 2 }} className="d-flex justify-between align-center file-upload responsive-settings">
              <Grid className="d-flex align-center">
                <Grid
                  onClick={() => {
                    props.setNotificationListDrawer(false);
                    props.getNotificationsDtoList();
                    setselectedbutton("All");
                    props?.closeBackpopups();
                  }}
                  className="mt-7 cursor-hover"
                  style={{ color: "#98A2B3" }}
                >
                  <Tooltip
                    title="Collapse"
                    position="top"
                    trigger="mouseenter"
                    animation="fade"
                    arrow
                  >
                    <ChevronLeftIcon className="f-30 ml-10" />
                  </Tooltip>
                </Grid>
                <Typography className="heading-text-color f-20 font-weight-600 ml-20">
                  Notifications
                </Typography>
              </Grid>
              {props.notifications?.length >= 1 && props.notificationsCount > 0 &&
              <Typography className="f-14 link-color font-weight-500 pr-15 justify-end-flex" onClick={handleAllNotificationRead}>
                Mark all as read
              </Typography>}
            </Grid>
            <Grid className="pl-20 d-flex align-center mt-20 mb-20">
                  <Button
                    style={{ width: '80px', height: '25px' }}
                    className={`btn d-flex talent-pool-responsive-btn border-radius-100 font-weight-500 font-size-12 ${selectedbutton == "All" ? "all-unread-button-selected" : "all-unread-button"}`}
                    onClick={getNotificationsDtoList}
                    >
                    <span className="ml-20 mr-20">All</span>
                  </Button>
                  <Button
                    style={{ width: '80px', height: '25px' }}
                    className={`ml-8 btn d-flex talent-pool-responsive-btn border-radius-100 font-weight-500 font-size-12 ${selectedbutton == "Unread" ? "all-unread-button-selected" : "all-unread-button"}`}
                    onClick={getUnreadNotificationsDtoList}
                    >
                    <span className="ml-20 mr-20">Unread</span>
                  </Button>
                </Grid>
                <div style={{ height: containerHeight }}>
                <InfiniteScroll
                dataLength={allnotifications.length}
                next={() => fetchMoreData()}
                hasMore={loadMore}
                inverse={false}
                height={containerHeight}
                >
                <Grid item className="">
              {allnotifications &&
                allnotifications.map((notification, index) => {
                  // notification.callToActionUrl =
                  //   "/enterprise/jobs/{jobId}/matching-candidates?tabValue=Applied";
                  // notification.callToActionUrl =
                  //   "/enterprise/jobs/cf037af1-d9e5-4f0c-a761-5ff442ce618b/matching-candidates?tabValue=Offboarded";
                  // notification.callToActionUrl="/enterprise/jobsLog";
                  return (
                    <Grid item className={`p-15 border-bottom-gray5 ${(notification.callToActionUrl || notification.notificationData) && "cursor-hover"} ${notification.notificationStatus == "U" && "notification-secondary-bg-color d-flex justify-between"}`} key={index} onClick={()=> handleNotificationNavigation(notification)}>
                      <Typography className="d-flex">
                        <Typography>
                          <Typography className="notification-default-icon justify-center align-center d-flex">
                            <NotificationDefaultIcon
                              className="rect-svg-icons"
                              width="40px"
                              height="40px"
                            />
                            {/* {getValidFullName(fullname).trim().substring(0, 1)} */}
                          </Typography>
                        </Typography>
                        <Typography className="pl-10">
                          <Typography className="">
                            <Typography className="gray9 f-14 font-weight-500 m-0 notification-value">
                              {notification.notificationValue}
                            </Typography>
                          </Typography>
                          <Typography className="l-h-1 posted-align-view">
                            {/* {getdays(notification?.createdTimestamp)} */}
                            {formatDate(new Date()) ===
                            formatDate(notification?.createdTimestamp) ? (
                              <Typography className="gray7 f-12 pt-5">
                                {isMinutesOrHours(
                                  notification?.createdTimestamp
                                )}
                                {/* {moment(new Date()).startOf('hour').fromNow()} */}
                              </Typography>
                            ) : (
                              <Typography>
                                {formatDate(onedayBefore) ===
                                formatDate(notification?.createdTimestamp) ? (
                                  <Typography className="gray7 f-12 pt-5">
                                    {notification?.createdTimestamp === null ||
                                    notification?.createdTimestamp === ""
                                      ? "-"
                                      : `Yesterday`}
                                  </Typography>
                                ) : (
                                  <>
                                    {formatDate(currentDateWeekFormat) <=
                                    formatDate(
                                      notification?.createdTimestamp
                                    ) ? (
                                      <Typography className="gray7 f-12 pt-5">
                                        {notification?.createdTimestamp ===
                                          null ||
                                        notification?.createdTimestamp === ""
                                          ? "-"
                                          : moment(
                                              notification?.createdTimestamp
                                            ).format("MMM DD, YYYY")}
                                      </Typography>
                                    ) : (
                                      <Typography className="gray7 f-12 pt-5">
                                        {notification?.createdTimestamp ===
                                          null ||
                                        notification?.createdTimestamp === ""
                                          ? "-"
                                          : moment(
                                              notification?.createdTimestamp
                                            ).format("MMM DD, YYYY")}
                                      </Typography>
                                    )}
                                  </>
                                )}
                              </Typography>
                            )}
                          </Typography>
                          {notification.callToActionUrl && (
                            <Typography
                              className="cursor-hover"
                              tabIndex={0}
                            >
                              <span className="link-color f-12  font-weight-400">
                                {notification.callToActionUrl.includes(
                                  "Applied"
                                )
                                  ? "View Candidate"
                                  : notification.callToActionUrl.includes(
                                      "Offered"
                                    )
                                  ? "View Candidate"
                                  : notification.callToActionUrl.includes(
                                      "Offboarded"
                                    )
                                  ? "View Candidate"
                                  : notification.callToActionUrl.includes(
                                      "jobsLog"
                                    )
                                  ? "View Job Logs"
                                  : notification.callToActionUrl.includes(
                                      "candidate-detail"
                                    )
                                  ? "Talent Pool"
                                    : notification.callToActionUrl.includes(
                                        "talent-pool-request"
                                      )
                                  ? "View Candidate"
                                  : notification.callToActionUrl.includes(
                                      "/access"
                                    )
                                  ? "View Request"
                                  : ""}
                              </span>
                            </Typography>
                          )}
                          {/* {notification.notificationData && (
                            <Typography
                              className="cursor-hover"
                              tabIndex={0}
                            >
                              <span className="link-color f-12  font-weight-400">
                              View Job Log
                              </span>
                            </Typography>
                          )} */}
                        </Typography>
                      </Typography>
                      {notification.notificationStatus == "U" &&
                        <Typography className="d-flex align-center ml-30">
                          <span className="unread-dot"></span>
                        </Typography>}
                    </Grid>
                  );
                })}
            </Grid>
                </InfiniteScroll>
                </div>
          </Grid>
        </Container>
      </Drawer>
      <DownloadAnalytics
              open={downloadPopup}
              close={setDownloadPopup}
              download_hint={url}
              setUrlParams={setUrl}
              reportsPopup={reportsPopup}
            />
      {/* <BlukUploadJobsPreview
        open={uploadJobsDone}
        setOpen={setUploadJobsDone}
        uploadJobsResponse={uploadJobs}
      /> */}
    </>
  );
}
