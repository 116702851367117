import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  enterprise: {},
};

export const enterpriseDetailSlice = createSlice({
  name: "enterprise",
  initialState,
  reducers: {
    getEnterpriseRecode: (state, action) => {
      state.enterprise = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { getEnterpriseRecode } = enterpriseDetailSlice.actions;

export default enterpriseDetailSlice.reducer;
