import ApiCalls from "../../api/customAxios";
class TalentPoolManagementServiceClass {
  talentPoolURL = `${process.env.REACT_APP_API_URL}enterprises/talentPool`;
  appliedJobsURL = `${process.env.REACT_APP_API_URL}enterprises/pool/candidate/applied-jobs`;
  async searchTalentPool() {
    return ApiCalls.get(`${this.talentPoolURL}/search`);
  }
  async getTalentPoolList(page, keyword) {
    return ApiCalls.getEntireResponseData(
      `${this.talentPoolURL}/all/${page}/16?keyword=${keyword}`
    );
  }
  async addTalentPool(payload) {
    return ApiCalls.put(`${this.talentPoolURL}`, payload);
  }
  async showTalentPool(id) {
    return ApiCalls.get(`${this.talentPoolURL}/${id}`);
  }
  async addCandidatesIntoTalentPool(talent_Pool_Id, payload) {
    return ApiCalls.put(
      `${this.talentPoolURL}/${talent_Pool_Id}/candidates`,
      payload
    );
  }
  async editTalentPool(talent_pool_id, page, keyword, status) {
    return ApiCalls.getEntireResponseData(
      `${this.talentPoolURL}/${talent_pool_id}/candidates/${page}/10?keyword=${keyword}&talentPoolStatus=${status}`
    );
  }
  async removeCandidateFromTalentPool(talent_pool_id, payload) {
    return ApiCalls.put(
      `${this.talentPoolURL}/${talent_pool_id}/candidates/remove`,
      payload
    );
  }
  async disableOrEnableTalentPool(payload) {
    return ApiCalls.put(`${this.talentPoolURL}`, payload);
  }
  async getInviteMailBody() {
    return ApiCalls.get(`${this.talentPoolURL}/invite/mail`);
  }
  async sendInviteMail(payload) {
    return ApiCalls.put(`${this.talentPoolURL}/invite`, payload);
  }
  async getCandidateUnbindRequests(page) {
    return ApiCalls.get(`${this.talentPoolURL}/unbind/requests/${page}/10`);
  }
  async candidateUnbindRequests(status, payload) {
    return ApiCalls.put(
      `${this.talentPoolURL}/unbind/requests/${status}`,
      payload
    );
  }
  async getCandidateAppliedJobs(candidateId) {
    return ApiCalls.get(`${this.appliedJobsURL}/${candidateId}`);
  }
  async listOfTalentPool(candidateId, page, pageFrom) {
    return ApiCalls.getEntireResponseData(
      `${this.talentPoolURL}/candidates/${candidateId}/${page}/${pageFrom}`
    );
  }
  async addPoolWithCandidate(candidateId, payload) {
    return ApiCalls.put(
      `${this.talentPoolURL}/candidates/${candidateId}`,
      payload
    );
  }
}

const TalentPoolManagementService = new TalentPoolManagementServiceClass();
export default TalentPoolManagementService;
