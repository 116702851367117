import React, { useState, useEffect, useRef } from "react";
import {
    Badge,
    Box,
    Button,
    Grid,
    MenuItem,
    Typography,
    Menu
} from "@mui/material";
import moment from "moment";
import ApiCalls from "../../../api/customAxios";
import {
    formatDate,
    isMinutesOrHours,
} from "../../../helpers/helper-data";
import { Tooltip } from "react-tippy";
import { ReactComponent as NotificationDefaultIcon } from "../../../assets/images/notification-default-icon.svg";
import { ReactComponent as NoNotificationIcon } from "../../../assets/images/NoJobsFound.svg";
import { ReactComponent as NotificationIcon } from "../../../assets/images/notification-new-icon.svg";
import NotificationList from "./NotificationList";
import notificationNavIcon from "../../../assets/images/notificationNav-icon.svg";
import CandidateService from "../../../Services/Enterprise/Candidate.service";
import { useNavigate } from "react-router-dom";
import { danger, success } from "../../../helpers/message-box";
import InterviewService from "../../../Services/Enterprise/Interview.service";
import AccessService from "../../../Services/Enterprise/Access.service";
import DownloadAnalytics from "../../EnterprisePortal/Download_analytics";

export default function NotificationButton(props) {
    const navigate = useNavigate();
    const [notifications, setNotifications] = useState([]);
    const [notificationsCount, setNotificationsCount] = useState(0);
    const [notificationListDrawer, setNotificationListDrawer] = useState(false);
    const [anchorE3, setAnchorE3] = useState(null);
    const open3 = Boolean(anchorE3);
    const [isLoading, setIsLoading] = useState(true);
    const [allnotifications, setallnotifications] = useState([]);
    const [selectedbutton, setselectedbutton] = useState("All");
    const [keepDropdownOpen, setKeepDropdownOpen] = useState(false);
    const [downloadPopup, setDownloadPopup] = React.useState(false);
    const [url, setUrl] = useState("");
    const notificationbody = useRef();
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (anchorE3 && !anchorE3.contains(event.target) && !keepDropdownOpen && notificationbody.current && !notificationbody.current.contains(event.target) && url == "") {
          setAnchorE3(null);
          setselectedbutton("All");
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [anchorE3, url]);
    const handleButtonClick = (value) => {
      setselectedbutton(value);
      if (value === "All") {
        allnotificationslist();
      } else if (value === "Unread") {
        getUnreadNotificationsDtoList();
      }
      setKeepDropdownOpen(false);
    };
    const handleClick3 = (event) => {
      if (!anchorE3) {
        setAnchorE3(event.currentTarget);
        setUrl("");
      } else {
        setAnchorE3(null);
        setselectedbutton("All");
        setUrl("");
      }
    };
    const handleClose3 = () => {
        setAnchorE3(null);
        setselectedbutton("All");
        setUrl("");
    };
    const handleAllNotificationRead = () => {
        ApiCalls.put("candidates/notifications/E")
            .then((response) => {
                getNotificationsDtoList();
                // setNotificationsCount(0);
                setNotificationsCount(response.unReadNotifications);
                setAnchorE3(null);
                setselectedbutton("All");
            })
            .catch(function (error) { });
    };

    const allnotificationslist = () => {
      // setNotifications(allnotifications);
      setselectedbutton("All");
      getNotificationsDtoList();
      console.log(allnotifications);
    };

    const getNotificationsDtoList = () => {
        let mounted = true;
        ApiCalls.get("candidates/notifications/E/0/10")
            .then((response) => {
                if (mounted) {
                    setNotifications(response.notificationsDtoList);
                    setallnotifications(response.notificationsDtoList);
                    // setNotificationsCount(response.unReadNotifications);
                }
            })
            .catch(function (error) { });
        return () => (mounted = false);
    };

    const getUnreadNotificationsDtoList = (e) => {
      console.log("Inside getUnreadNotificationsDtoList");
      let mounted = true;
      ApiCalls.get("candidates/notifications/unread/E")
          .then((response) => {
              if (mounted) {
                console.log(response);
                  setNotifications(response.notificationsDtoList);
                  setNotificationsCount(response.unReadNotifications);
                  setselectedbutton("Unread");
              }
          })
          .catch(function (error) { });
      return () => (mounted = false);
  };
    useEffect(() => {
        const interval = setInterval(() => {
          if (selectedbutton == "All") {
            getNotificationsDtoList();
          } else {
            getUnreadNotificationsDtoList();
          }
        }, 10000);
        return () => clearInterval(interval);
    }, [selectedbutton]);
    useEffect(() => {
      ApiCalls.get("candidates/notifications/unread/E")
      .then((response) => {
              setNotificationsCount(response.unReadNotifications);
      })
      .catch(function (error) { });
        getNotificationsDtoList();
    }, []);
    const reportsPopup = (value) => {
      setDownloadPopup(value);
    };
    function extractDateFromString(startDate, endDate) {
      const currentDate = moment().format("YYYY-MM-DD");
      const slotExpiry = moment(startDate).local().format("YYYY-MM-DD");
      let diffInMinutes = moment(endDate).local().diff(moment(), "minutes");
      let diffInDays = slotExpiry ? moment(currentDate).diff(moment(slotExpiry), 'days') : 0;
      return diffInDays <= 0 && diffInMinutes < 0;
  };
    const interviewDetails = (url, notification) => {
        let arr = url.split("/");
        InterviewService.getInterviewDetails(arr[arr?.length - 2])
          .then((response) => {
            let scheduleRound = response?.enterpriseInterviewScheduleList[arr[arr?.length - 1] - 1];
            let hasMatch = scheduleRound.interviewPanelMembers?.find((mem) => {
                  return (
                    localStorage.getItem("enterprise_user_id") == mem?.enterpriseUserId
                  );
                });
            if (((hasMatch?.recommendationStatus == null && response.interviewHeadersDto.closureStatus != null) || hasMatch?.recommendationStatus != null) && notification.notificationValue?.includes("interview feedback")) {
              danger("The notification you're trying to access has expired.");
              return;
            } else if (scheduleRound?.interviewSchedulesDto?.status == "A" && scheduleRound?.interviewSchedulesDto?.roundCompleted == "Y" && notification.notificationValue?.includes("accepted the interview")) {
              danger("The notification you're trying to access has expired.");
              return;
            } else if (scheduleRound?.interviewSchedulesDto?.status != "A" && scheduleRound?.interviewSchedulesDto?.roundCompleted != "Y" && notification.notificationValue?.includes("accepted the interview")) {
              danger("The notification you're trying to access has expired.");
              return;
            } else if ((scheduleRound?.interviewSchedulesDto?.status != "A" || (scheduleRound?.interviewSchedulesDto?.status == "A" && scheduleRound?.interviewSchedulesDto?.roundCompleted == "Y")) && notification.notificationValue?.includes("Interview scheduled")) {
              danger("The notification you're trying to access has expired.");
              return;
            } else if (scheduleRound?.interviewSchedulesDto?.status != "P" && notification.notificationValue?.includes("rescheduled")) {
              danger("The notification you're trying to access has expired.");
              return;
            } else if (scheduleRound?.interviewSchedulesDto?.status == "P" && notification.notificationValue?.includes("rescheduled") && localStorage.getItem("userType") == "S") {
              // Restricting associate when interview status is pending
              return;
            } else if (scheduleRound?.interviewSchedulesDto?.status != "X" && notification.notificationValue?.includes("cancelled the interview")) {
              danger("The notification you're trying to access has expired.");
              return;
            } else if (scheduleRound?.interviewSchedulesDto?.status != "D" && notification.notificationValue?.includes("declined the interview")) {
              danger("The notification you're trying to access has expired.");
              return;
            } else if (scheduleRound?.interviewSchedulesDto?.status == "A" && notification.notificationValue?.includes("upcoming interview") && extractDateFromString(scheduleRound?.interviewSchedulesDto.scheduleStartDateTime, scheduleRound?.interviewSchedulesDto.scheduleCompleteDateTime)) {
              danger("The notification you're trying to access has expired.");
              return;
            } else if (((scheduleRound?.interviewSchedulesDto?.status == "A" && scheduleRound?.interviewSchedulesDto?.roundCompleted == "Y") || scheduleRound?.interviewSchedulesDto?.status != "A") && notification.notificationValue?.includes("upcoming interview")) {
              danger("The notification you're trying to access has expired.");
              return;
            } else if (hasMatch?.recommendationStatus == null && notification.notificationValue?.includes("interview feedback") && response.interviewHeadersDto.closureStatus == null) {
              navigate(url, { state: { isScorecard: true } });
              window.location.reload();
            } else {
              navigate(url);
            }
          })
          .catch((err) => {
            danger("Something went wrong.");
          });
    };
  const getUpdatedUnreadCount = () => {
    console.log("2-> Inside getUpdatedUnreadCount");
    ApiCalls.get("candidates/notifications/unread/E")
      .then((response) => {
        console.log("3->After API call");
        console.log(response.unReadNotifications);
        setNotificationsCount(response.unReadNotifications);
      })
      .catch(function (error) { });
  };
    const handleNotificationNavigation = async(notification) => {
      console.log("1-> Inside handleNotificationNavigation");
        setIsLoading(true);
        ApiCalls.put(`candidates/notifications/${notification.id}/R`)
        .then((response) => {
          getNotificationsDtoList();
          // setNotificationsCount(0);
          getUpdatedUnreadCount();
        })
        .catch(function (error) { });
        let candidateID;
        let JobId;
        if (notification?.callToActionUrl?.includes("Applied")) {
          const searchString = "/enterprise";
          const startIndex = notification.callToActionUrl.indexOf(searchString);
          const extractedString = notification.callToActionUrl.substring(startIndex);
          const segments = notification.callToActionUrl.split('/');
          const jobIdIndex = segments.findIndex((segment) => segment === 'jobs');
          const candidateIdIndex = notification.callToActionUrl.indexOf('candidateId=');
          if (jobIdIndex !== -1 && segments[jobIdIndex + 1]) {
            JobId = segments[jobIdIndex + 1];
          }
          if (candidateIdIndex !== -1) {
             candidateID = notification.callToActionUrl.substring(candidateIdIndex + 12);
          }
          const statusResponse = await getCandidateStatusApi("A", true, JobId, candidateID);
          if (statusResponse[0]?.enterpriseProcessStatus == null && statusResponse[0]?.candidateProcessStatus == "A") {
            navigate(extractedString);
            setAnchorE3(null);
            setselectedbutton("All");
            setIsLoading(false);
            getUpdatedUnreadCount();
          } else {
            danger("The notification you're trying to access has expired.");
            setAnchorE3(null);
            setselectedbutton("All");
            setIsLoading(false);
            getUpdatedUnreadCount();
            return;
          }
        } else if (notification?.callToActionUrl?.includes("Offered")) {
          const searchString = "/enterprise";
          const startIndex = notification.callToActionUrl.indexOf(searchString);
          const extractedString = notification.callToActionUrl.substring(startIndex);
          const segments = notification.callToActionUrl.split('/');
          const jobIdIndex = segments.findIndex((segment) => segment === 'jobs');
          const candidateIdIndex = notification.callToActionUrl.indexOf('candidateId=');
          if (jobIdIndex !== -1 && segments[jobIdIndex + 1]) {
            JobId = segments[jobIdIndex + 1];
          }
          if (candidateIdIndex !== -1) {
             candidateID = notification.callToActionUrl.substring(candidateIdIndex + 12);
          }
          const statusResponse = await getCandidateStatusApi("F", true, JobId, candidateID);
          if (statusResponse[0]?.enterpriseProcessStatus !== null && statusResponse[0]?.enterpriseProcessStatus === "F" && (statusResponse[0]?.candidateProcessStatus === "P" ||  statusResponse[0]?.candidateProcessStatus === "D")) {
            navigate(extractedString);
            setAnchorE3(null);
            setselectedbutton("All");
            setIsLoading(false);
            getUpdatedUnreadCount();
          } else {
            danger("The notification you're trying to access has expired.");
            setAnchorE3(null);
            setselectedbutton("All");
            setIsLoading(false);
            getUpdatedUnreadCount();
            return;
          }
        } else if (notification?.callToActionUrl?.includes("analytics_report")) {
          // setDownloadPopup(true);
          const urlData = new URL(notification?.callToActionUrl);
          const queryParams = new URLSearchParams(urlData.search);
          setUrl(queryParams.get("analytics_report"));
        } else if (notification?.callToActionUrl?.includes("Offboarded")) {
          const searchString = "/enterprise";
          const startIndex = notification.callToActionUrl.indexOf(searchString);
          const extractedString = notification.callToActionUrl.substring(startIndex);
          const segments = notification.callToActionUrl.split('/');
          const jobIdIndex = segments.findIndex((segment) => segment === 'jobs');
          const candidateIdIndex = notification.callToActionUrl.indexOf('candidateId=');
          if (jobIdIndex !== -1 && segments[jobIdIndex + 1]) {
            JobId = segments[jobIdIndex + 1];
          }
          if (candidateIdIndex !== -1) {
             candidateID = notification.callToActionUrl.substring(candidateIdIndex + 12);
          }
          const statusResponse = await getCandidateStatusApi("B", true, JobId, candidateID);
          if (statusResponse[0]?.enterpriseProcessStatus !== null && statusResponse[0]?.enterpriseProcessStatus == "B" && statusResponse[0]?.candidateProcessStatus == null) {
            navigate(extractedString);
            setAnchorE3(null);
            setselectedbutton("All");
            setIsLoading(false);
            getUpdatedUnreadCount();
          } else {
            danger("The notification you're trying to access has expired.");
            setAnchorE3(null);
            setselectedbutton("All");
            setIsLoading(false);
            getUpdatedUnreadCount();
            return;
          }
        } else if (notification?.callToActionUrl?.includes("talent-pool")) {
          const searchString = "/enterprise";
          const startIndex = notification.callToActionUrl.indexOf(searchString);
          const extractedString = notification.callToActionUrl.substring(startIndex);
          const segments = notification.callToActionUrl.split('/');
          const jobIdIndex = segments.findIndex((segment) => segment === 'jobs');
          const candidateIdIndex = notification.callToActionUrl.indexOf('candidateId=');
          if (jobIdIndex !== -1 && segments[jobIdIndex + 1]) {
            JobId = segments[jobIdIndex + 1];
          }
          if (candidateIdIndex !== -1) {
             candidateID = notification.callToActionUrl.substring(candidateIdIndex + 12);
          }
          let talentPoolId = segments[segments.length - 1];
          if (segments[segments.length - 1]?.includes("?")) {
            talentPoolId = segments[segments.length - 1]?.split("?")[0];
          }
          await ApiCalls.get(`candidates/talentPool/${talentPoolId}/${candidateID}`)
          .then((response)=>{
            if (response.talentPoolCandidateStatus != "P") {
              danger("The notification you're trying to access has expired.");
            } else {
              navigate(extractedString);
            }
          }).catch((err)=>{
            danger("The notification you're trying to access has expired.");
          });
          setAnchorE3(null);
          setselectedbutton("All");
          setIsLoading(false);
          getUpdatedUnreadCount();
        } else if (notification?.callToActionUrl?.includes("/access")) {
          const searchString = "/enterprise";
          const startIndex = notification.callToActionUrl.indexOf(searchString);
          const extractedString = notification.callToActionUrl.substring(startIndex);
          let userName = '';
          let candidateId = null;
          if (notification.callToActionUrl.includes("?params=")) {
            try {
              const params = notification.callToActionUrl.split("?params=")[1];
              const decodedString = atob(params);
              const decodedObject = JSON.parse(decodedString);
              userName = decodedObject.name;
              candidateId = decodedObject.id;
            } catch (err) {}
          }
          AccessService.getUsers(0, 10, userName).then(
            (response) => {
              let { responsePayload } = { ...response };
              let users = responsePayload;
              for (let i = 0; i < users.length; i++) {
                if (users[i].candidateId === candidateId) {
                  if (users[i].activeOrNot === "A") {
                    danger("The notification you're trying to access has expired.");
                  } else {
                    if (window.location.pathname.includes("/access")) {
                      navigate(extractedString);
                      window.location.reload();
                    } else {
                      navigate(extractedString);
                    }
                  }
                  break;
                } else if (i === users.length - 1) {
                  if (window.location.pathname.includes("/access")) {
                    navigate(extractedString);
                    window.location.reload();
                  } else {
                    navigate(extractedString);
                  }
                }
              }
              if (users.length === 0) {
                if (window.location.pathname.includes("/access")) {
                  navigate(extractedString);
                  window.location.reload();
                } else {
                  navigate(extractedString);
                }
              }
            }
          ).catch(() => {});
          setAnchorE3(null);
          setselectedbutton("All");
          setIsLoading(false);
          getUpdatedUnreadCount();
        } else {
          if (notification?.notificationData) {
            handleOpenUploadJobsDone(
              notification.notificationData
            );
            setAnchorE3(null);
            setselectedbutton("All");
            getUpdatedUnreadCount();
          } else if (notification.callToActionUrl) {
            const searchString = "/enterprise";
            const startIndex = notification.callToActionUrl.indexOf(searchString);
            const extractedString = notification.callToActionUrl.substring(startIndex);
             if (notification.notificationValue?.includes("interview feedback") ||
             notification.notificationValue?.includes("accepted the interview") ||
             notification.notificationValue?.includes("Interview scheduled") ||
             notification.notificationValue?.includes("rescheduled") ||
             notification.notificationValue?.includes("cancelled the interview") ||
             notification.notificationValue?.includes("declined the interview") ||
             notification.notificationValue?.includes("upcoming interview")) {
                interviewDetails(extractedString, notification);
              } else {
                navigate(extractedString);
                window.location.reload();
              }
            setAnchorE3(null);
            setselectedbutton("All");
            setIsLoading(false);
            getUpdatedUnreadCount();
          }
        }
      };
    const handleNotificationRead = () => {
        // ApiCalls.put("candidates/notifications/E")
        //   .then((response) => {
        //     setNotificationsCount(0);
        //   })
        //   .catch(function (error) { });
    };
    const viewAllNotification = () => {
        setNotificationListDrawer(true);
        // setNotificationsCount(0);
        setAnchorE3(null);
        setselectedbutton("All");
    };
    var onedayBefore = new Date();
    onedayBefore.setDate(onedayBefore.getDate() - 1);
    var currentDateWeekFormat = new Date();
    currentDateWeekFormat.setDate(currentDateWeekFormat.getDate() - 7);
    const getCandidateStatusApi = async(status, resetPageToZero = false, jobId, candidate_Id) => {
        try {
          const response = await CandidateService.getCandidatesFromNotification(
            jobId,
            status,
            candidate_Id,
            resetPageToZero === true ? 0 : currentPage - 1
          );
          return response;
        } catch (error) {
          // Handle error if needed
          console.error("An error occurred:", error);
          return null;
        }
      };
    return (
        <>
            <Box>
                <Button
                    id="basic-button3"
                    aria-controls={open3 ? "basic-menu3" : undefined}
                    aria-haspopup="true"
                    className="dropdown-arrow text-transform-none cursor-normal position-issue"
                    aria-expanded={open3 ? "true" : undefined}
                    onClick={handleClick3}
                    role="button"
                    aria-label="Notification"
                    disableRipple="true"
                >
                    {notifications &&
                        (notifications.length >= 1 && notifications.length <= 50) && (typeof notificationsCount === 'undefined' || (notificationsCount >= 0 && notificationsCount <= 50))&& (
                            <Box>
                                <Badge
                                    badgeContent={notificationsCount}
                                    color="secondary"
                                    className="notification-icon contrasts icon-button"
                                >
                                    {/* <img
                                  src={notificatioIcon}
                                  alt="Notification icon"
                                  width="20px"
                                  height="20px"
                                  className="cursor-hover constrats"
                                  onClick={handleNotificationRead}
                                  onKeyPress={handleNotificationRead}
                                  tabIndex={0}
                                /> */}
                                    <Tooltip
                                        title="Notifications"
                                        position="bottom"
                                        trigger="mouseenter"
                                        animation="fade"
                                        arrow
                                    >
                                        {props.isMobile ? <img
                                            src={notificationNavIcon}
                                            alt="Notification icon"
                                            width="20px"
                                            height="20px"
                                            className="cursor-hover"
                                            onClick={handleNotificationRead}
                                            onKeyPress={handleNotificationRead}
                                            tabIndex={0}
                                        /> : <NotificationIcon
                                            alt="Notification icon"
                                            width="24px"
                                            height="24px"
                                            className="cursor-hover constrats icon-button"
                                            onClick={handleNotificationRead}
                                            onKeyPress={handleNotificationRead}
                                            tabIndex={0}
                                        />}
                                    </Tooltip>
                                </Badge>
                            </Box>
                        )}

                        {notificationsCount > 50 && (
                        <Box>
                            <Badge
                                badgeContent="50+"
                                color="secondary"
                                className="notification-icon contrasts icon-button"
                            >
                                <Tooltip
                                    title="Notifications"
                                    position="bottom"
                                    trigger="mouseenter"
                                    animation="fade"
                                    arrow
                                >
                                    {props.isMobile ? <img
                                        src={notificationNavIcon}
                                        alt="Notification icon"
                                        width="20px"
                                        height="20px"
                                        className="cursor-hover"
                                        onClick={handleNotificationRead}
                                        onKeyPress={handleNotificationRead}
                                        tabIndex={0}
                                    /> : <NotificationIcon
                                        alt="Notification icon"
                                        width="24px"
                                        height="24px"
                                        className="cursor-hover constrats icon-button"
                                        onClick={handleNotificationRead}
                                        onKeyPress={handleNotificationRead}
                                        tabIndex={0}
                                    />}
                                </Tooltip>
                            </Badge>
                        </Box>
                    )}

                    {notifications?.length < 1 && (
                        <Box className="d-flex">
                            <Tooltip
                                title="Notifications"
                                position="bottom"
                                trigger="mouseenter"
                                animation="fade"
                                arrow
                            >
                                {props.isMobile ? <img
                                    src={notificationNavIcon}
                                    alt="Notification icon"
                                    width="20px"
                                    height="20px"
                                    className="cursor-hover"
                                    onClick={handleNotificationRead}
                                    onKeyPress={handleNotificationRead}
                                    tabIndex={0}
                                /> : <NotificationIcon
                                    alt="Notification icon"
                                    width="24px"
                                    height="24px"
                                    className="cursor-hover icon-button"
                                    onClick={handleNotificationRead}
                                    onKeyPress={handleNotificationRead}
                                    tabIndex={0}
                                />}
                            </Tooltip>
                        </Box>
                    )}
                    {props.isMobile ? <span className="f-14 gray9 ml-10"> Notifications </span> : ""}
                </Button>

                <Menu
                    id="basic-menu3"
                    anchorEl={anchorE3}
                    open={open3}
                    onClose={handleClose3}
                    disableScrollLock={true}
                    PaperProps={{
                        style: {
                            width: 400,
                        },
                    }}
                    className="all-notifications notification-hover cursor-hover ipad-profile"
                    MenuListProps={{
                        "aria-labelledby": "basic-button3",
                    }}
                >
                  <Grid className="notification-body" ref={notificationbody}>
                    <Grid className="mt-15">
                        <Grid className="d-flex align-center justify-between">
                            <Typography className="ml-20 pr-10 py-10 heading-text-color f-20 font-weight-600">
                                Notifications
                            </Typography>
                            {notifications?.length >= 1 && notificationsCount > 0 &&
                                <Typography className="f-14 link-color font-weight-500 pr-15" onClick={handleAllNotificationRead}>
                                    Mark all as read
                                </Typography>}
                        </Grid>
                          <Grid className="pl-15 d-flex align-center mb-8">
                            <Button
                              style={{ width: '80px', height: '25px' }}
                              className={`btn d-flex talent-pool-responsive-btn border-radius-100 font-weight-500 font-size-12 ${selectedbutton == "All" ? "all-unread-button-selected" : "all-unread-button"}`}
                              // onClick={allnotificationslist}
                              // onClick={() => handleButtonClick("All")}
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                handleButtonClick("All");
                                // setKeepDropdownOpen(false);
                              }}
                              value={"All"}
                            >
                              <span className="ml-20 mr-20">All</span>
                            </Button>
                            <Button
                              style={{ width: '80px', height: '25px' }}
                              className={`ml-8 btn d-flex talent-pool-responsive-btn border-radius-100 font-weight-500 font-size-12 ${selectedbutton == "Unread" ? "all-unread-button-selected" : "all-unread-button"}`}
                              // onClick={getUnreadNotificationsDtoList}
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                handleButtonClick("Unread");
                                // setKeepDropdownOpen(false);
                              }}
                              value={"Unread"}
                            >
                              <span className="ml-20 mr-20">Unread</span>
                            </Button>
                          </Grid>
                        {notifications?.length >= 1 ? (
                                  notifications?.map((notification, index) => {
                                    return ((index < 5) ?
                                      <MenuItem
                                        className={`d-block cursor-normal pl-0 pr-0 pt-5 pb-5  ${notification.notificationStatus == "U" && "notification-secondary-bg-color"}`}
                                        key={index}
                                      >
                                        <Typography className={`d-flex justify-between border-bottom pl-18 pr-18 py-5 ${(notification.callToActionUrl || notification.notificationData) && "cursor-hover"} ${notification.notificationStatus == "U" && "notification-secondary-bg-color"}`} onClick={() =>
                                          (notification.callToActionUrl || notification.notificationData) && handleNotificationNavigation(notification)
                                                }>
                                          <Box className="d-flex">
                                            <span>
                                              <Typography className="notification-default-icon justify-center align-center d-flex">
                                                <NotificationDefaultIcon
                                                  width="40px"
                                                  height="40px"
                                                  className="rect-svg-icons"
                                                />
                                                {/* {getValidFullName(fullname).trim().substring(0, 1)} */}
                                              </Typography>
                                            </span>
                                            <span className="pl-10">
                                              <Typography className="cursor-defaul break-space">
                                                <Typography className="gray9 f-14 font-weight-500">
                                                  {notification.notificationValue}
                                                </Typography>
                                              </Typography>
                                              <Typography className="cursor-default">
                                                {formatDate(new Date()) ===
                                                  formatDate(
                                                    notification?.createdTimestamp
                                                  ) ? (
                                                  <Typography className="gray7 f-12 pt-5">
                                                    {/* {moment(new Date()).startOf('hour').fromNow()} */}
                                                    {isMinutesOrHours(
                                                      notification?.createdTimestamp
                                                    )}
                                                  </Typography>
                                                ) : (
                                                  <Typography>
                                                    {formatDate(onedayBefore) ===
                                                      formatDate(
                                                        notification?.createdTimestamp
                                                      ) ? (
                                                      <Typography className="gray7 f-12 pt-5">
                                                        {notification?.createdTimestamp ===
                                                          null ||
                                                          notification?.createdTimestamp ===
                                                          ""
                                                          ? "-"
                                                          : "Yesterday"}
                                                      </Typography>
                                                    ) : (
                                                      <>
                                                        {formatDate(
                                                          currentDateWeekFormat
                                                        ) <=
                                                          formatDate(
                                                            notification?.createdTimestamp
                                                          ) ? (
                                                          <Typography className="gray7 f-12 pt-5">
                                                            {notification?.createdTimestamp ===
                                                              null ||
                                                              notification?.createdTimestamp ===
                                                              ""
                                                              ? "-"
                                                              : moment(
                                                                notification?.createdTimestamp
                                                              ).format(
                                                                "MMM DD, YYYY"
                                                              )}
                                                          </Typography>
                                                        ) : (
                                                          <Typography className="gray7 f-12 pt-5">
                                                            {notification?.createdTimestamp ===
                                                              null ||
                                                              notification?.createdTimestamp ===
                                                              ""
                                                              ? "-"
                                                              : moment(
                                                                notification?.createdTimestamp
                                                              ).format(
                                                                "MMM DD, YYYY"
                                                              )}
                                                          </Typography>
                                                        )}
                                                      </>
                                                    )}
                                                  </Typography>
                                                )}
                                              </Typography>
                                            </span>
                                          </Box>
                                          {notification.notificationStatus == "U" &&
                                        <Typography className="d-flex align-center ml-30">
                                          <span className="unread-dot"></span>
                                        </Typography>}
                                        </Typography>
                                      </MenuItem>
                                      : ""
                                    );
                                  })
                                ) : (
                                  <MenuItem className="d-block p-10 mt-20">
                                    <Typography className="l-h-1 cursor-default text-center">
                                      <Typography>
                                        <NoNotificationIcon
                                          width={120}
                                          height={120}
                                          className="icon-button"
                                        />
                                      </Typography>
                                      <Typography className="py-20">
                                        <span className="gray9 f-16 font-weight-600 py-20">
                                          You don’t have any notifications for
                                          now
                                        </span>
                                      </Typography>
                                    </Typography>
                                  </MenuItem>
                                )}
                              </Grid>
                              </Grid>
                              {notifications.length >= 6 && (
                                <>
                                  {/* <Divider /> */}
                                  <MenuItem
                                    tabIndex={0}
                                    className="link-color font-weight-600 f-14 align-item-center cursor-hover my-15"
                                    // onClick={viewAllNotification}
                                    onMouseDown={viewAllNotification}
                                    onKeyPress={viewAllNotification}
                                  >
                                    View all notifications
                                  </MenuItem>
                                </>
                              )}
                </Menu>
            </Box>
            <NotificationList
                notifications={notifications}
                //handleNotificationDrawer={handleNotificationDrawer}
                closeBackpopups={props.viewAllNotification}
                notificationListDrawer={notificationListDrawer}
                setNotificationListDrawer={setNotificationListDrawer}
                getNotificationsDtoList={getNotificationsDtoList}
                notificationsCount={notificationsCount}
                getUnreadNotificationsDtoList={getUnreadNotificationsDtoList}
                allnotificationslist={allnotificationslist}
                setNotifications={setNotifications}
                selectedbutton={selectedbutton}
                setNotificationsCount={setNotificationsCount}
            />
            <DownloadAnalytics
              open={downloadPopup}
              close={setDownloadPopup}
              download_hint={url}
              reportsPopup={reportsPopup}
            />
        </>
    );
}
