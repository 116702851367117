import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  origin: "D",
};

export const appOriginSlice = createSlice({
  name: 'origin',
  initialState,
  reducers: {
      getAppOriginRecode: (state, action) => {
          state.origin = action.payload;
      },
}
});

// Action creators are generated for each case reducer function
export const { getAppOriginRecode } = appOriginSlice.actions;

export default appOriginSlice.reducer;
