import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useNavigate } from "react-router-dom";
import { Box, Button, Grid, Typography, Modal } from "@mui/material";
import UploadSuccess from "../../assets/images/upload-success-icon.svg";
import UploadFail from "../../assets/images/upload-fail-icon.svg";

function BlukUploadJobsPreview({
  open,
  setOpen,
  uploadJobsResponse, rows
}) {
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  const handleJson = () => {
    navigate("/enterprise/edit_logs", { state: { data: uploadJobsResponse, logId: rows } });
  };

  const goToPendingJobs = (e) => {
    e.preventDefault();
    window.open("/enterprise/jobList", "_self");
    localStorage.setItem("filterStatus", "P");
    handleClose();
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-content inputcontrasts">
          <Grid container item id="modal-title" className="justify-between d-flex">
            {/* <Grid item> */}
              <Typography className="f-20 font-weight-600 heading-text-color modalTitle d-flex align-center">
                Failed Jobs
                <Box className="upload-job-failed danger-color">{uploadJobsResponse?.jobDetails ? uploadJobsResponse?.jobDetails?.length : 0 }</Box>
              </Typography>
              <Typography align="right" className="cursor-hover sub-text-color icon-button">
                <CloseIcon onClick={handleClose} onKeyPress={handleClose} tabIndex={0} aria-label="Close icon" />
              </Typography>
            {/* </Grid> */}
            {/* <Grid item xs={6}>
            <Grid item className="justify-between d-flex">
              <Typography align="right" className="btn profile-button cursor-hover" onClick={handleJson} style={{ marginLeft: "97px" }}>
                  Correct Online
              </Typography>
              </Grid>
            </Grid> */}
          </Grid>
          <Grid item>
            <Grid item id="modal-description">
              <Grid item className="">
                {uploadJobsResponse?.jobDetails && uploadJobsResponse?.jobDetails?.length > 0 &&
                <Typography className="mt-10">
                  <Typography className="d-flex align-center">
                    {/* <Box className="light-black font-weight-700">Job Title and Error Description</Box> */}
                    {/* <Box className="upload-job-failed danger-color">{uploadJobsResponse?.jobDetails ? uploadJobsResponse?.jobDetails?.length : 0 }</Box> */}
                  </Typography>
                  {uploadJobsResponse?.jobDetails && uploadJobsResponse?.jobDetails.map((job, index) => {
                      return (
                        <>
                        <Typography className="d-flex justify-between mt-15 borderBottom pb-10" key={index} style={{ flexDirection: "column" }}>
                          <Box className="f-14 font-weight-400 light-text mb-10">
                          {`Record${job?.jobPost != undefined ? (typeof(JSON.parse(job?.jobPost).serialNo) == 'number' ? " " + JSON.parse(job?.jobPost).serialNo : "") : ""}${job?.jobTitle ? ":" : ""}`}{" "}{job?.jobTitle}
                          </Box>
                          <Typography className="d-flex">
                            <Box>
                              <img width="14px" height="14px" src={UploadFail} />
                            </Box>
                            <Box className="">
                            {(job?.errorDescription.length > 0 && job?.jobPost == undefined) ?
                              <Typography className="danger-color f-14 ml-5">An error has occurred. This may be due to missing or incorrect contents in the excel. Please correct the file and reupload it. It cannot be changed online.</Typography> :
                              job?.errorDescription.length > 0 && job?.errorDescription?.map((e, i) => {
                                return <Typography className="danger-color f-14 ml-5" key={i}>{e + (job?.errorDescription?.length -1 == i ? "." : ",")}</Typography>;
                                })}
                            </Box>
                          </Typography>
                        </Typography>
                        </>
                      );
                    })}
                </Typography>}
                {/* <Box className="mt-20">
                  <span className="sub-text-color font-weight-700">NOTE: </span>
                  <span className="f-14">All the uploaded jobs are marked as pending, <Link className="cursor-hover  link-color"
                    to="/enterprise/jobList"
                    onClick={goToPendingJobs} onKeyPress={goToPendingJobs} tabIndex={0}>click here</Link> to view jobs.</span>
                </Box> */}
              </Grid>
            </Grid>
            <Grid item className="mt-30 justify-center d-flex">
                {/* <Button className="btn profile-button" onClick={handleClose} onKeyPress={handleClose} aria-label="done" disableRipple="true" tabIndex={0}>Done</Button> */}
                <Button
                  type="submit"
                  className="btn secondary-button"
                  aria-label="Submit"
                  disabled={uploadJobsResponse?.jobDetails != undefined && uploadJobsResponse?.jobDetails[0]?.errorDescription?.length > 0 && uploadJobsResponse?.jobDetails[0]?.jobPost == undefined}
                  onClick={handleJson}
                >
                  Correct Online
                </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default BlukUploadJobsPreview;
