import React, { useEffect, useState } from 'react';
import {
    Typography,
    Grid,
    Container,
    Select,
    Button,
    MenuItem,
    MenuList,
    Box,
    Modal,
    Tooltip
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { ReactComponent as DownloadProcessIcon } from "../../assets/images/download-process.svg";
import { ReactComponent as SuccessIcon } from "../../assets/images/toaster-icons/success-icon.svg";
import moment from "moment";
import calenderIcon from "../../assets/images/assessment-calender.svg";
import { danger, success } from '../../helpers/message-box';
import ApiCalls from '../../api/customAxios';
import axios from 'axios';
import KeycloakService from '../../keycloak';

const DownloadReports = (props) => {
    const [porgressBar, setProgressBar] = useState(0);
    const [processScreen, setProcessScreen] = useState("");
    const [openDatePicker, setOpenDatePicker] = useState(false);
    const [openStartDatePicker, setOpenStartDatePicker] = useState(false);
    const [endDateErr, setEndDateErr] = useState(null);
    const [loading, setLoading] = useState(false);
    const [url, setUrl] = useState("");
    const [fields, setFields] = useState({
        duration: "today",
        startDate: moment(new Date())?.format("YYYY-MM-DD"),
        endDate: moment(new Date())?.format("YYYY-MM-DD")
    });
    function fixBase64(base64String) {
        base64String = base64String.replace(/-/g, '+').replace(/_/g, '/');
        while (base64String.length % 4 !== 0) {
          base64String += '=';
        }
        return base64String;
      };
      function decodeBase64(base64String) {
        try {
          // Fix the Base64 string if necessary
          const fixedBase64String = fixBase64(base64String);
          return atob(fixedBase64String);
        } catch (error) {
          console.error('Failed to decode Base64 string:', error);
          return null;
        }
      };
    useEffect(() => {
        if (props?.download_hint) {
            let header = {
                Authorization: `Bearer ${KeycloakService.getToken()}`,
                "enterpriseId": localStorage.getItem("enterpriseId") == localStorage.getItem("childEnterpriseId") ? localStorage.getItem("mspEnterpriseId") : localStorage.getItem("enterpriseId")
            };
            axios.get(`${process.env.REACT_APP_ANALYTICS_EXPORT}/api/v1/analyticsexport`, {
            headers: header
          })
            .then((res) => {
                let results = res?.data;
                setUrl(results?.signed_url);
            })
            .catch(function (error) {
                if (error?.statuscode == 500) {
                    danger("Oops! Something went wrong. Please try again later.");
                } else {
                    danger("Something went Wrong");
                }
            });
            const data = JSON.parse(decodeBase64(props?.download_hint));
            setFields({
                duration: data?.duration,
                startDate: data?.startDate,
                endDate: data?.endDate
            });
            // setUrl(data?.file_url);
            setProcessScreen("done");
            if ((data?.candidateId == localStorage.getItem("candidateId")) && (data?.enterprise_id == localStorage.getItem("enterpriseId"))) {
                props?.reportsPopup(true);
            } else {
                removeQueryParams();
            }
        }
    }, [props?.download_hint]);
    const GenerateReport = () => {
        let time = 0;
        const interval = setInterval(() => {
            if (porgressBar < 100) {
                time += 1;
                setProgressBar(time);
                if (time >= 100) {
                    clearInterval(interval);
                }
            }
        }, 500);
        let data = {
            "duration": fields.duration,
            "startDate": fields.duration != "custom" ? moment(moment().startOf(fields.duration).toDate())?.format("YYYY-MM-DD") : fields.startDate,
            "endDate": fields.duration != "custom" ? moment(new Date())?.format("YYYY-MM-DD") : fields.endDate,
            "candidateId": localStorage.getItem("candidateId")
        };
        let header = {
            Authorization: `Bearer ${KeycloakService.getToken()}`,
            "enterpriseId": localStorage.getItem("enterpriseId") == localStorage.getItem("childEnterpriseId") ? localStorage.getItem("mspEnterpriseId") : localStorage.getItem("enterpriseId")
        };
        axios.post(`${process.env.REACT_APP_ANALYTICS_EXPORT}/api/v1/analyticsexport`, data, {
            headers: header
          })
        // ApiCalls.post(`${process.env.REACT_APP_API_URL.replace('api/v1/', 'public/api/v1/')}candidates/analytics/analytics-export/`, data)
            .then((res) => {
                let results = res?.data?.responsePayload;
                setUrl(results?.file_url);
                setProcessScreen("done");
                clearInterval(interval);
            })
            .catch(function (error) {
                clearInterval(interval);
                onModelClose();
                if (error?.statuscode == 500) {
                    danger("Oops! Something went wrong. Please try again later.");
                } else {
                    danger("Something went Wrong");
                }
            });
    };
    const DownloadReport = () => {
        setLoading(true);
        var a = document.createElement("a");
        a.href = url;
        if (a.click) {
          a.click();
        } else {
          window.open(url);
        }
        setTimeout(() => {
            success("File downloaded successfully.");
            onModelClose();
        }, 300);
        a?.parentNode?.removeChild(a);
    };
    const confirm = () => {
        setProcessScreen("processing");
        GenerateReport();
    };
    const handleChangeStartDate = (date) => {
        let start = new Date(moment(date).format("YYYY-MM-DD"));
        let end = new Date(fields?.endDate);
        if (fields?.endDate) {
            if (start > end) {
                setFields({ ...fields, startDate: null });
                setEndDateErr("Start date cannot be greater than end date");
            } else {
                setEndDateErr(null);
            }
        } else if (!date) {
            setEndDateErr(null);
        }
    };
    const removeQueryParams = async () => {
        const baseUrl = window.location.origin + window.location.pathname;
        window.history.replaceState({}, document.title, baseUrl);
    };
    const onModelClose = () => {
        if (props?.download_hint) {
            removeQueryParams();
        }
        props.close(false);
        setProcessScreen("");
        setLoading(false);
        setUrl("");
        if (props?.setUrlParams) {
            props?.setUrlParams("");
        }
        setFields({
            duration: "today",
            startDate: moment(new Date())?.format("YYYY-MM-DD"),
            endDate: moment(new Date())?.format("YYYY-MM-DD")
        });
    };
    const handleDuration = (e) => {
        setFields({ ...fields, duration: e.target.value, startDate: moment(moment().startOf(e.target.value).toDate())?.format("YYYY-MM-DD") });
    };
    return (
        <>
            <Modal
                open={props.open}
                onClose={() => {
                    onModelClose();
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal-content">
                    <Grid container id="modal-title">
                        <Grid item xs={6}>
                            <Typography className="f-20 font-weight-600 modalTitle heading-text-color">
                                Download Analytics Data
                            </Typography>
                        </Grid>
                        <Grid item xs={6} aria-label="Close icon">
                            <Typography align="right" className="cursor-hover gray7" aria-label="Close icon">
                                <CloseIcon onClick={() => {
                                    onModelClose();
                                }} aria-label="Close icon" />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid id="modal-description" className="p-0">
                        {processScreen != "processing" ?
                            <>
                                <Grid container spacing={1}>
                                    <Grid
                                        item
                                        lg={6}
                                        md={6}
                                        xs={12}
                                        className="mt-10"
                                    >
                                        <Box>
                                            <h6 className="mb-0 mt-0 f-14 gray7 font-weight-400">
                                                Select Duration
                                                <span className="">*</span>
                                            </h6>
                                            <Select
                                                className="w-100 mb-0 mt-10 form-control input-group mb-0 inputcontrasts galaxy-select"
                                                value={fields.duration}
                                                onChange={(e) => handleDuration(e)}
                                                disabled={processScreen == "done"}
                                            >
                                                <MenuItem value="today">Today</MenuItem>
                                                <MenuItem value="week">This week</MenuItem>
                                                <MenuItem value="month">This month</MenuItem>
                                                <MenuItem value="quarter">This quarter</MenuItem>
                                                <MenuItem value="year">This year</MenuItem>
                                                <MenuItem value="custom">Custom</MenuItem>
                                            </Select>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    spacing={1}
                                    lg={12}
                                    md={12}
                                    xs={12}
                                    className="mt-10"
                                >
                                    <Grid item lg={6} md={6} xs={12}>
                                        <Box className="galaxy-width-input">
                                            <h6 className="mb-0 mt-0 f-14 gray7 font-weight-400">
                                                Start Date<span className="">*</span>
                                            </h6>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <div className="w-100 mb-0 mb-0 inputcontrasts galaxy-select"
                                                    // onMouseOver={() => {
                                                    // document.getElementById("start-date").style.cursor = "pointer";
                                                    // }}
                                                    // onMouseOut={() => {
                                                    // document.getElementById("start-date").style.cursor = "default";
                                                    // }}
                                                    style={{ position: "relative" }}
                                                >
                                                    <DatePicker
                                                        className={`form-control input-group inputcontrasts ${processScreen == "done" || fields.duration != "custom" ? "form-control-background-gray" : "cursor-hover"}`}
                                                        value={fields.startDate}
                                                        onChange={(e) => {
                                                            setFields({ ...fields, startDate: moment(e)?.format("YYYY-MM-DD") });
                                                            handleChangeStartDate(e);
                                                        }}
                                                        open={openStartDatePicker}
                                                        onOpen={() => setOpenStartDatePicker((processScreen != "done" && fields.duration == "custom") ? true : false)}
                                                        onClose={() => setOpenStartDatePicker(false)}
                                                        format="MMM dd, yyyy"
                                                        placeholder="Start date"
                                                        id="start-date"
                                                        minDateMessage=''
                                                        // inputProps={{ role: "textbox" }}
                                                        disabled={processScreen == "done" || fields.duration != "custom"}
                                                    />
                                                    <img
                                                        style={{
                                                            position: "absolute",
                                                            top: "17px",
                                                            right: "12px",
                                                            cursor: (processScreen != "done" && fields.duration == "custom" ? "pointer" : "default"),
                                                        }}
                                                        className="mt-3 calendar-icon"
                                                        src={calenderIcon}
                                                        width="15px"
                                                        height="15px"
                                                        onClick={(e) => {
                                                            if (processScreen != "done" && fields.duration == "custom") {
                                                                setOpenStartDatePicker(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </MuiPickersUtilsProvider>
                                            <span className="danger-color error-msg">
                                                {" "}
                                                {endDateErr}
                                            </span>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={6} md={6} xs={12}>
                                        <Box className="galaxy-width-input">
                                            <h6 className="mb-0 mt-0 f-14 gray7 font-weight-400">
                                                End Date
                                            </h6>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <div className="w-100 mb-0 mb-0 inputcontrasts galaxy-select"
                                                    // onMouseOver={() => {
                                                    // document.getElementById("end-date").style.cursor = "pointer";
                                                    // }}
                                                    // onMouseOut={() => {
                                                    // document.getElementById("end-date").style.cursor = "default";
                                                    // }}
                                                    style={{ position: "relative" }}
                                                >
                                                    <DatePicker
                                                        id="end-date"
                                                        className={`form-control input-group inputcontrasts ${processScreen == "done" || fields.duration != "custom" ? "form-control-background-gray" : "cursor-hover"}`}
                                                        value={fields.endDate}
                                                        onChange={(e) => {
                                                            setFields({ ...fields, endDate: moment(e)?.format("YYYY-MM-DD") });
                                                        }}
                                                        format="MMM dd, yyyy"
                                                        placeholder="End date"
                                                        open={openDatePicker}
                                                        onOpen={() => setOpenDatePicker((processScreen != "done" && fields.duration == "custom") ? true : false)}
                                                        onClose={() => setOpenDatePicker(false)}
                                                        minDate={
                                                            new Date(new Date(fields.startDate).getTime() + 86400000)
                                                        }
                                                        minDateMessage=''
                                                        // inputProps={{ role: "textbox" }}
                                                        disabled={processScreen == "done" || fields.duration != "custom"}
                                                    />
                                                    <img
                                                        style={{
                                                            position: "absolute",
                                                            top: "17px",
                                                            right: "12px",
                                                            cursor: ((processScreen != "done" && fields.duration == "custom") ? "pointer" : "default"),
                                                        }}
                                                        className="mt-3 calendar-icon"
                                                        src={calenderIcon}
                                                        width="15px"
                                                        height="15px"
                                                        onClick={(e) => {
                                                            if (processScreen != "done" && fields.duration == "custom") {
                                                                setOpenDatePicker(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </MuiPickersUtilsProvider>
                                        </Box>
                                    </Grid>
                                </Grid></>
                            :
                            <>
                                <Box className='text-center'>
                                    <DownloadProcessIcon />
                                    <Typography className='mt-5'><div className='mx-125 gray7 font-weight-400'>Your download is in progress, we will notify you once it's complete.</div></Typography>
                                </Box>
                            </>
                        }
                    </Grid>
                    <Grid id="modal-footer" className={`d-flex mt-25 ${processScreen != "" ? "justify-between align-center" : "justify-end"}`}>
                        {processScreen != "" && <Box className='d-flex align-center'>
                            <Typography className='mr-5 f-14 gray7 font-weight-400'>Processing {processScreen == "done" && "done"}</Typography>
                            {processScreen == "processing" ? <Tooltip
                                role="tooltip"
                                title="Downloading progress"
                                placement="bottom"
                                trigger="mouseenter"
                                animation="fade"
                                arrow
                            >
                                <Typography
                                    className="justify-end d-flex cursor-hover sort-filter-center"
                                    style={{
                                        height: "30px",
                                        width: "60px",
                                    }}
                                >
                                    <span className="matching-score-align w-100 p-5 d-flex align-center w-100">
                                        <CircularProgressbar
                                            aria-label="Circular Progress"
                                            strokeWidth={12}
                                            value={
                                                porgressBar
                                                    ? porgressBar
                                                    : 0
                                            }
                                            // text={`${Math.round(
                                            //   candidate.matchingScore
                                            //     ? candidate.matchingScore
                                            //     : 0
                                            // )}%`}
                                            styles={buildStyles({
                                                textColor: "#027A48",
                                                pathColor: "#027A48",
                                                trailColor: "#adc6ce",
                                                textSize: "25px",
                                            })}
                                        />
                                        <span className="d-flex align-center success-color f-12 font-weight-500 ml-5">
                                            {`${Math.round(
                                                porgressBar
                                                    ? porgressBar
                                                    : 0
                                            )}%`}
                                        </span>
                                    </span>
                                </Typography>
                            </Tooltip> : <SuccessIcon width="20px" height="20px" />}
                        </Box>}
                        <Button
                            type="submit"
                            className="btn primary-button profileButton"
                            variant="contained"
                            disabled={(fields.duration == "custom" && fields.startDate == null && fields.endDate == null) || endDateErr != null}
                            onClick={() => processScreen == "processing" ? onModelClose() : processScreen == "done" ? DownloadReport() : confirm()}
                        >
                            {processScreen == "processing" ? "Close" : processScreen == "done" ? "Download" : "Confirm"}
                            {loading ? <span className="loader-login"></span> : ""}
                        </Button>
                    </Grid>
                </Box>
            </Modal >
        </>
    );
};
export default DownloadReports;
