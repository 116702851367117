import { Box, Grid, Modal, Typography, Button, Link, Input } from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from "@mui/icons-material/Close";
import fileUpload from "../../../assets/images/uploadNew.svg";
import Resumepdf from "../../../assets/images/extension-icon/pdf-icon.png";
import DocIcon from "../../../assets/images/extension-icon/doc.png";
import { handleSpaces } from '../../../helpers/helper-data';
import { danger, success } from '../../../helpers/message-box';
import AccessService from '../../../Services/Enterprise/Access.service';


function UploadResumeModal({ open, setOpenAddResumeModal, jobId, candidateId, setIsLoading, setResumeDocuments, resumeDocuments }) {
  const [isFileValid, setIsFileValid] = useState(false);
  const [filename, setFilename] = useState("");
  const [extension, setExtension] = useState("");
  const [resumeTitle, setResumeTitle] = useState("");
  const [pictureFile, setPictureFile] = useState(null);

  const handleCloseUploadResume = () => {
        setIsFileValid(false);
        setExtension("");
        setFilename("");
        setResumeTitle("");
        setOpenAddResumeModal(false);
    };
    const handleEmpty = (event) => {
        event.preventDefault();
        setFilename("");
        setExtension("");
        setIsFileValid(false);
      };
    const handleChangeResumeTitle = (event) => {
        setResumeTitle(event.target.value);
      };
    function ValidateSingleInput(event) {
        var validFileExtensions = ["pdf", "doc", "docx"];
        var file = event.target.files[0];
        if (file) {
          var extension = file.name.split(".").pop();
          if (validFileExtensions.includes(extension)) {
            setFilename(file.name);
            setExtension(extension);
            setPictureFile(file);
            setIsFileValid(true);
          } else {
            setIsFileValid(false);
            setFilename("");
            setExtension("");
            danger("Please upload only DOC, DOCX and PDF formats!");
          }
          event.target.value = null;
        }
        return true;
  };
  const sendResume = () => {
    setIsLoading(true);
    if (pictureFile) {
      var formData = new FormData();
      formData.append("document", pictureFile, filename);
      const data = {
        enterpriseId: localStorage.getItem("childEnterpriseId") ? localStorage.getItem("childEnterpriseId") : localStorage.getItem("enterpriseId"),
        candidateId: candidateId,
        ...(jobId && { jobId: jobId }),
        documentTitle: resumeTitle,
        documentCategory: "R"
      };
      formData.append("request-body",
        new Blob([JSON.stringify(data)], { type: 'application/json' }));
      AccessService.resumeUpload(formData).then((res) => {
        console.log(res);
        if (res) {
          setResumeDocuments([...resumeDocuments, res]);
          handleCloseUploadResume();
          success("Resume is uploaded.");
          setIsLoading(false);
        }
      }).catch((e) => {
        if (e) {
          danger(e.errorMessage);
        } else {
          danger("Something went wrong.");
        }
        setIsLoading(false);
      });
    }
  };
  return (
    <Modal
        open={open}
        onClose={handleCloseUploadResume}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-content inputcontrasts galaxy-width">
          <Grid container id="modal-title">
            <Grid item xs={6}>
              <Typography className="h3-text heading-text-color modalTitle">
                Upload Resume
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography align="right" className="cursor-hover gray7">
                <CloseIcon
                  tabIndex={0}
                  onKeyPress={handleCloseUploadResume}
                  onClick={handleCloseUploadResume}
                  aria-label="Close icon"
                />
              </Typography>
            </Grid>
          </Grid>

          <Grid id="modal-description ">
            <Grid container spacing={1}>
            <Grid item lg={12} md={12} xs={12} className="mt-10">
                <Typography className={`text-center upload-resume m-auto galaxy-upload-resume`}>
                  <input
                    style={{ display: "none" }}
                    id="raised-button-file"
                    multiple
                    type="file"
                    onChange={ValidateSingleInput}
                  />
                  <Typography>
                    <label htmlFor="raised-button-file">
                      <span className="upload-resume-section">
                        {!(isFileValid && filename) && (
                          <>
                            <img
                              src={fileUpload}
                              alt="img"
                              className="cursor-hover"
                              onKeyDown={(event) => {
                                event.key == "Enter" &&
                                  document
                                    .getElementById("raised-button-file")
                                    .click();
                              }}
                              tabIndex={0}
                            />
                          </>
                        )}
                        {isFileValid && extension === "pdf" && (
                          <>
                            <img
                              src={Resumepdf}
                              alt="img"
                              className="cursor-hover"
                              onKeyDown={(event) => {
                                event.key == "Enter" &&
                                  document
                                    .getElementById("raised-button-file")
                                    .click();
                              }}
                              tabIndex={0}
                            />
                            <CloseIcon
                              tabIndex={0}
                              onKeyPress={handleEmpty}
                              onClick={handleEmpty}
                              aria-label="Close icon"
                            />
                          </>
                        )}
                        {isFileValid && extension === "doc" && (
                          <>
                            <img
                              src={DocIcon}
                              alt="img"
                              className="cursor-hover"
                              onKeyDown={(event) => {
                                event.key == "Enter" &&
                                  document
                                    .getElementById("raised-button-file")
                                    .click();
                              }}
                              tabIndex={0}
                            />
                            <CloseIcon
                              tabIndex={0}
                              onKeyPress={handleEmpty}
                              onClick={handleEmpty}
                              aria-label="Close icon"
                            />
                          </>
                        )}
                        {isFileValid && extension === "docx" && (
                          <>
                            <img
                              src={DocIcon}
                              alt="img"
                              className="cursor-hover"
                              onKeyDown={(event) => {
                                event.key == "Enter" &&
                                  document
                                    .getElementById("raised-button-file")
                                    .click();
                              }}
                              tabIndex={0}
                            />
                            <CloseIcon
                              tabIndex={0}
                              onKeyPress={handleEmpty}
                              onClick={handleEmpty}
                              aria-label="Close icon"
                            />
                          </>
                        )}
                      </span>
                      <Typography>
                        {!(isFileValid && filename) && (
                          <Link
                            className="link-color f-13 "
                            underline="hover"
                            tabIndex={0}
                            onKeyDown={(event) => {
                              event.key == "Enter" &&
                                document
                                  .getElementById("raised-button-file")
                                  .click();
                            }}
                          >
                            Select File
                          </Link>
                        )}
                        {isFileValid && filename && (
                          <Link
                            className="gray7 f-14 textEllipse w-200"
                            underline="hover"
                            tabIndex={0}
                          >
                            {filename}
                          </Link>
                        )}
                      </Typography>
                    </label>
                  </Typography>
                </Typography>

                <Grid item lg={5} md={5} xs={12} className="m-auto">
                  <Box>
                    <p className={`f-14 mb-0 gray7 font-weight-500 mt-15 align-left`}>
                      Resume Title<span className="text-black">*</span>
                    </p>
                    <Input
                      type="text"
                      name="title"
                      id="title"
                      className={`form-control input-group m-b-0 inputcontrasts`}
                      margin="normal"
                      placeholder="Enter resume title"
                      value={resumeTitle}
                      onKeyPress={handleSpaces}
                      onChange={handleChangeResumeTitle}
                    />
                  </Box>
                </Grid>
                <Typography className="text-center d-block mt-20">
                  <Button
                    className="btn primary-button profileButton"
                    disabled={!isFileValid || !resumeTitle.trim().length}
                    onClick={sendResume}
                    disableRipple="true"
                  >
                    Upload
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
  );
}


export default UploadResumeModal;
