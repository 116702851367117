import React, { useState } from "react";
import { Grid, Typography, Box, Stack, styled, Rating, LinearProgress, linearProgressClasses } from "@mui/material";
import moment from "moment";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CandidateService from "../../../../Services/Enterprise/Candidate.service";
import { useParams } from "react-router-dom";
import starEmpty from "../../../../assets/images/star-icon-empty.svg";
import starFilled from "../../../../assets/images/star-icon-filled.svg";
import { checkIsImage } from "../../../../helpers/helper-data";
import CandidateProfileVideoPreview from "../../../../Components/shared/CandidateProfileVideoPreview";
import constants from "../../../../helpers/constants";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },

  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#F2C029" : "#EFF0F6",
  },
}));

export default function RatingsAndReviews(props) {
  const [openRatingCategory, setRatingCategory] = useState(false);
  const [ratingDetails, setRatingDetails] = useState({});

  const openRatingCategorySection = () => {
    setRatingCategory(!openRatingCategory);
  };

  const [videoSrc, setVideoSrc] = useState(null);
  const [candidateName, setCandidateName] = useState(null);
  const [openVideoPreview, setOpenVideoPreview] = useState(false);
  const handleOpenVideoPreview = (profileVideo, candidateName) => {
    setVideoSrc(profileVideo);
    setCandidateName(candidateName);
    setOpenVideoPreview(true);
  };
  const params = useParams();

  React.useEffect(async () => {
    await CandidateService.getCandidateReview(
      params.candidate_id ? params.candidate_id : props?.candidateId
    )
      .then((res) => {
        setRatingDetails(res);
      })
      .catch(function (error) {});
  }, []);

  return (
    <>
      <Grid item>
        <Grid item className="px-5">
          <Grid container item spacing={1} className="align-center mt-20">
            <Grid item lg={2} md={2} xs={2}>
              <Typography className="font-weight-400 f-14 gray8">
                5 star
              </Typography>
            </Grid>

            <Grid item lg={8} md={8} xs={8} className="rating-progress">
              <BorderLinearProgress
                variant="determinate"
                value={
                  ratingDetails.ratingPercentage
                    ? Math.floor(ratingDetails.ratingPercentage[4])
                    : 0
                }
                aria-labelledby="Progress bar"
                aria-label="Progress bar"
              />
            </Grid>

            <Grid item lg={2} md={2} xs={2} className="ratings-align d-flex justify-center">
              <Typography className="font-weight-400 f-14 gray8">
                {ratingDetails.ratingPercentage
                  ? Math.floor(ratingDetails.ratingPercentage[4])
                  : 0}
                %
              </Typography>
            </Grid>
          </Grid>

          <Grid container item spacing={1} className="align-center mt-5">
            <Grid item lg={2} md={2} xs={2}>
              <Typography className="font-weight-400 f-14 gray8">
                4 star
              </Typography>
            </Grid>

            <Grid item lg={8} md={8} xs={8} className="rating-progress">
              <BorderLinearProgress
                variant="determinate"
                value={
                  ratingDetails.ratingPercentage
                    ? Math.floor(ratingDetails.ratingPercentage[3])
                    : 0
                }
                aria-labelledby="Progress bar"
                aria-label="Progress bar"
              />
            </Grid>

            <Grid item lg={2} md={2} xs={2} className="ratings-align d-flex justify-center">
              <Typography className="font-weight-400 f-14 gray8">
                {ratingDetails.ratingPercentage
                  ? Math.floor(ratingDetails.ratingPercentage[3])
                  : 0}
                %
              </Typography>
            </Grid>
          </Grid>

          <Grid container item spacing={1} className="align-center mt-5">
            <Grid item lg={2} md={2} xs={2}>
              <Typography className="font-weight-400 f-14 gray8">
                3 star
              </Typography>
            </Grid>

            <Grid item lg={8} md={8} xs={8} className="rating-progress">
              <BorderLinearProgress
                variant="determinate"
                value={
                  ratingDetails.ratingPercentage
                    ? Math.floor(ratingDetails.ratingPercentage[2])
                    : 0
                }
                aria-labelledby="Progress bar"
                aria-label="Progress bar"
              />
            </Grid>

            <Grid item lg={2} md={2} xs={2} className="ratings-align d-flex justify-center">
              <Typography className="font-weight-400 f-14 gray8">
                {ratingDetails.ratingPercentage
                  ? Math.floor(ratingDetails.ratingPercentage[2])
                  : 0}
                %
              </Typography>
            </Grid>
          </Grid>

          <Grid container item spacing={1} className="align-center mt-5">
            <Grid item lg={2} md={2} xs={2}>
              <Typography className="font-weight-400 f-14 gray8">
                2 star
              </Typography>
            </Grid>

            <Grid item lg={8} md={8} xs={8} className="rating-progress">
              <BorderLinearProgress
                variant="determinate"
                value={
                  ratingDetails.ratingPercentage
                    ? Math.floor(ratingDetails.ratingPercentage[1])
                    : 0
                }
                aria-labelledby="Progress bar"
                aria-label="Progress bar"
              />
            </Grid>

            <Grid item lg={2} md={2} xs={2} className="ratings-align d-flex justify-center">
              <Typography className="font-weight-400 f-14 gray8">
                {ratingDetails.ratingPercentage
                  ? Math.floor(ratingDetails.ratingPercentage[1])
                  : 0}
                %
              </Typography>
            </Grid>
          </Grid>

          <Grid container item spacing={1} className="align-center mt-5">
            <Grid item lg={2} md={2} xs={2}>
              <Typography className="font-weight-400 f-14 gray8">
                1 star
              </Typography>
            </Grid>

            <Grid item lg={8} md={8} xs={8} className="rating-progress">
              <BorderLinearProgress
                variant="determinate"
                value={
                  ratingDetails.ratingPercentage
                    ? Math.floor(ratingDetails.ratingPercentage[0])
                    : 0
                }
                aria-labelledby="Progress bar"
                aria-label="Progress bar"
              />
            </Grid>

            <Grid item lg={2} md={2} xs={2} className="ratings-align d-flex justify-center">
              <Typography className="font-weight-400 f-14 gray8">
                {ratingDetails.ratingPercentage
                  ? Math.floor(ratingDetails.ratingPercentage[0])
                  : 0}
                %
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid
            item
            className="mt-20 d-flex justify-between px-5"
            onClick={openRatingCategorySection}
            onKeyPress={openRatingCategorySection}
            tabIndex={0}
          >
            <Typography className="f-14 link-color align-item-center font-weight-500">
              Ratings based on categories
              <span className="gray9 mt-5 px-5">
                {openRatingCategory ? (
                  <KeyboardArrowDownIcon
                    aria-label="Arrow icon"
                    tabIndex={-1}
                  />
                ) : (
                  <ChevronRightIcon aria-label="Arrow icon" tabIndex={-1} />
                )}
              </span>
            </Typography>
          </Grid>

          {openRatingCategory && (
            <Grid item className="px-5 mt-5 responsive-layout">
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
                className="d-flex mt-5"
              >
                <Grid item lg={6} md={6} xs={4}>
                  <span className="font-weight-400 f-14 gray8">
                    Delivery Quality
                  </span>
                </Grid>

                <Grid
                  item
                  lg={4}
                  md={4}
                  xs={7}
                  className="text-center cand-rating-align"
                >
                  <span>
                    <Rating
                      count={5}
                      value={Math.floor(ratingDetails.deliveryQuality)}
                      name="half-rating"
                      readOnly
                      icon={
                        <img
                          src={starFilled}
                          style={{ width: "20px", height: "20px" }}
                          alt="img"
                        />
                      }
                      emptyIcon={
                        <img
                          src={starEmpty}
                          style={{ width: "20px", height: "20px" }}
                          alt="img"
                        />
                      }
                    />
                  </span>
                </Grid>

                <Grid
                  item
                  lg={2}
                  md={2}
                  xs={1}
                  className="d-flex justify-center cand-rating-align"
                >
                  <span className="font-weight-400 f-14 gray8">
                    {ratingDetails.deliveryQuality
                      ? Math.floor(ratingDetails.deliveryQuality)
                      : 0}
                  </span>
                </Grid>
              </Grid>

              <Grid
                item
                lg={12}
                md={12}
                xs={12}
                className="d-flex mt-10"
              >
                <Grid item lg={6} md={6} xs={4}>
                  <span className="font-weight-400 f-14 gray8">
                    Schedule Adherence
                  </span>
                </Grid>

                <Grid
                  item
                  lg={4}
                  md={4}
                  xs={7}
                  className="text-center cand-rating-align"
                >
                  <span>
                    <Rating
                      count={5}
                      value={Math.floor(ratingDetails.scheduleAdherence)}
                      name="half-rating"
                      readOnly
                      icon={
                        <img
                          src={starFilled}
                          style={{ width: "20px", height: "20px" }}
                          alt="img"
                        />
                      }
                      emptyIcon={
                        <img
                          src={starEmpty}
                          style={{ width: "20px", height: "20px" }}
                          alt="img"
                        />
                      }
                    />
                  </span>
                </Grid>

                <Grid
                  item
                  lg={2}
                  md={2}
                  xs={1}
                  className="text-center pl-5 cand-rating-align"
                >
                  <span className="font-weight-400 f-14 gray8">
                    {ratingDetails.scheduleAdherence
                      ? Math.floor(ratingDetails.scheduleAdherence)
                      : 0}
                  </span>
                </Grid>
              </Grid>

              <Grid
                item
                lg={12}
                md={12}
                xs={12}
                className="d-flex mt-10"
              >
                <Grid item lg={6} md={6} xs={4}>
                  <span className="font-weight-400 f-14 gray8">
                    Sticking to Commitment
                  </span>
                </Grid>

                <Grid
                  item
                  lg={4}
                  md={4}
                  xs={7}
                  className="text-center cand-rating-align"
                >
                  <span>
                    <Rating
                      count={5}
                      value={Math.floor(ratingDetails.stickingToCommitment)}
                      name="half-rating"
                      readOnly
                      icon={
                        <img
                          src={starFilled}
                          style={{ width: "20px", height: "20px" }}
                          alt="img"
                        />
                      }
                      emptyIcon={
                        <img
                          src={starEmpty}
                          style={{ width: "20px", height: "20px" }}
                          alt="img"
                        />
                      }
                    />
                  </span>
                </Grid>

                <Grid
                  item
                  lg={2}
                  md={2}
                  xs={1}
                  className="text-center pl-5 cand-rating-align"
                >
                  <span className="font-weight-400 f-14 gray8">
                    {ratingDetails.stickingToCommitment
                      ? Math.floor(ratingDetails.stickingToCommitment)
                      : 0}
                  </span>
                </Grid>
              </Grid>

              <Grid
                item
                lg={12}
                md={12}
                xs={12}
                className="d-flex mt-10"
              >
                <Grid item lg={6} md={6} xs={4}>
                  <span className="font-weight-400 f-14 gray8">
                    Value for Money
                  </span>
                </Grid>

                <Grid
                  item
                  lg={4}
                  md={4}
                  xs={7}
                  className="text-center cand-rating-align"
                >
                  <span>
                    <Rating
                      count={5}
                      value={Math.floor(ratingDetails.valueForMoney)}
                      name="half-rating"
                      readOnly
                      size="large"
                      icon={
                        <img
                          src={starFilled}
                          style={{ width: "20px", height: "20px" }}
                          alt="img"
                        />
                      }
                      emptyIcon={
                        <img
                          src={starEmpty}
                          style={{ width: "20px", height: "20px" }}
                          alt="img"
                        />
                      }
                    />
                  </span>
                </Grid>

                <Grid
                  item
                  lg={2}
                  md={2}
                  xs={1}
                  className="text-center pl-5 cand-rating-align"
                >
                  <span className="font-weight-400 f-14 gray8">
                    {ratingDetails.valueForMoney
                      ? Math.floor(ratingDetails.valueForMoney)
                      : 0}
                  </span>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>

        <Grid
          item
          className="mt-10"
        >
          {ratingDetails.reviews?.map((review, index) => (
            <Grid item key={index}>
              <Grid item className="">
                <Grid item className="mt-10 d-flex justify-between px-5">
                  <Typography className="d-flex pl-5">
                    <Box>
                      <img
                        className="default-img-border"
                        style={{ width: "45px", height: "45px" }}
                        src={
                          review.logoFileSigned
                            ? review.logoFileSigned
                            : constants.DEFAULT_LOGO
                        }
                        alt="img"
                      />
                      {/* <img style={{width:'65%'}} src={workLogo} /> */}
                    </Box>
                    <Box className="px-5 mt-5 pl-10">
                      <Typography
                        variant="h4"
                        className="font-weight-600 f-16 gray9 textEllipse w-300 history-align galaxy-width-100"
                      >
                        {review.establishmentName}
                      </Typography>
                      <p
                        className="small-text-font mt-0 mb-0"
                      >
                        <Rating
                          count={5}
                          value={review.rating_overall}
                          name="half-rating"
                          readOnly
                          icon={
                            <img
                              src={starFilled}
                              style={{ width: "20px", height: "20px" }}
                              alt="img"
                            />
                          }
                          emptyIcon={
                            <img
                              src={starEmpty}
                              style={{ width: "20px", height: "20px" }}
                              alt="img"
                            />
                          }
                        />
                      </p>
                      <p className="f-12 font-weight-400 gray7 m-0">
                        Posted on{" "}
                        {moment(review.offboardedDate).format("MMM DD, YYYY")}
                      </p>
                      <p className="small-text-font m-t-b-5 word-break">
                        {review.ratingRemarksByEnterprise}
                      </p>
                    </Box>
                  </Typography>
                </Grid>

                {review.ratingRemarksByCandidate && (
                  <Grid item className="d-flex justify-between px-5 pl-50 mt-5">
                    <Typography className="d-flex pl-5 profile-video-align">
                      <Box>
                        {review.mediaFile !== "****" && review.mediaFileSigned ? (
                          checkIsImage(review.mediaFileSigned) ? (
                            <img
                              className="default-img-border"
                              style={{ width: "45px", height: "45px" }}
                              src={review.mediaFileSigned}
                              alt="img"
                            />
                          ) : (
                            <video
                              className="default-img-border cursor-hover round-image video-image cursor-hover"
                              style={{ width: "45px", height: "45px" }}
                              src={review.mediaFileSigned}
                              onClick={() => {
                                handleOpenVideoPreview(
                                  review.mediaFileSigned,
                                  review.candidateName
                                );
                              }}
                              muted
                              onKeyPress={() => {
                                handleOpenVideoPreview(
                                  review.mediaFileSigned,
                                  review.candidateName
                                );
                              }}
                              tabIndex={0}
                            />
                          )
                        ) : (
                          <Typography
                            className="default-img-border justify-center align-center d-flex primary-shadow-color bg-input f-16 font-weight-700 text-capitalize"
                            width="45px"
                            height="45px"
                            aria-label="Default image"
                          >
                            {review.candidateName.trim().substring(0, 1)}
                          </Typography>
                          // <img
                          //   className="default-img-border"
                          //   style={{ width: "45px" }}
                          //   src={
                          //     "https://daas-prog-test.s3.amazonaws.com/default-user.png"
                          //   }
                          //   alt="img"
                          // />
                        )}
                      </Box>
                      <Box className="px-5 mt-5 pl-10">
                        <Typography
                          variant="h4"
                          className="font-weight-600 f-16 gray9 textEllipse w-300 history-align galaxy-width-100"
                        >
                          {review.candidateName}
                        </Typography>
                        <p className="f-12 gray7 m-0">
                          Replied on{" "}
                          {moment(review.candidateRepliedOn).format(
                            "MMM DD, YYYY"
                          )}
                        </p>
                        <p className="f-16 gray9 font-weight-400">
                          {review.ratingRemarksByCandidate}
                        </p>
                      </Box>
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <CandidateProfileVideoPreview
        open={openVideoPreview}
        setOpen={setOpenVideoPreview}
        videoSrc={videoSrc}
        candidateName={candidateName}
      />
    </>
  );
}
