import { Typography, Checkbox, Box, } from '@mui/material';
import React, { Fragment, useState } from 'react';
const label = { inputProps: { "aria-label": "Checkbox demo" } };
import deleteIcon from "../../../assets/images/delete-new-icon.svg";
import AccessService from '../../../Services/Enterprise/Access.service';
import { danger, success } from '../../../helpers/message-box';
import ConfirmDialog from '../../../Components/shared/ConfirmDialog';
import moment from 'moment';
import { AppContext } from '../../../contexts/context';


function DocumentListing({ document, setDocuments, type, selectedValues, setSelectValues, setIsLoading, fetchMoreDocuments, loadMore }) {
  const [fileToDelete, setFileToDelete] = useState(null);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const { superAdminEntityObj } = React.useContext(AppContext);


  const handleSelectDocuments = (e, value) => {
    let check;
    if (e.target?.type === "checkbox") {
      check = e.target.checked;
    } else {
      check = !selectedValues.some((item) => item.id === value.id);
    }
    if (check) {
      setSelectValues((prevSelectedValues) => [
        ...prevSelectedValues,
        {
          name: value.documentTitle,
          urlSigned: value.documentSignedUrl,
          id: value.id,
        }
      ]);
    } else {
      setSelectValues((prevSelectedValues) =>
        prevSelectedValues.filter((item) => item.id !== value.id)
      );
    }
  };
  const checkIfUserIsSuperAdmin = (id) => {
    if (id in superAdminEntityObj) {
      if (superAdminEntityObj[id] === 'Z') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
};
  const handleDeleteResume = () => {
    setIsLoading(true);
    AccessService.fileResumeDelete(fileToDelete.id).then((res) => {
      if (res) {
        setDocuments((document) =>
          document.filter((item) => item.id !== fileToDelete.id));
        setSelectValues((prevSelectedValues) =>
          prevSelectedValues.filter((item) => item !== fileToDelete.id)
        );
        setFileToDelete(null);
        setOpenDeletePopup(false);
        success(`${type == "O" ? "Resume is deleted successfully." : "File is deleted successfully."}`);
        setIsLoading(false);
      }
    }).catch((err) => {
      danger("Something went wrong");
      setIsLoading(false);
    });
  };
  const handleOpenDeletePopup = (e, document) => {
    e.stopPropagation();
    setFileToDelete(document);
    setOpenDeletePopup(true);
  };
  const dateFormat = (date) => {
    if (date) {
      const timestampString = date;
      const formattedDateString = moment(timestampString).format('MMM DD, YYYY HH:mm');
      return formattedDateString;
    } else {
      console.log("Timestamp or document value is missing or invalid.");
    }
  };
  return (
    <Fragment>
      {document?.map(
        (documentValue, index) => (
          <Typography
            className="align-left mt-10 d-flex align-start checkboxDocument"
            key={index}
          >
            <Checkbox
              {...label}
              sx={{
                color: "#98A2B3",
                padding: "0px",
              }}
              checked={
                selectedValues.find(
                  (d) =>
                    d.id === documentValue.id
                )
                  ? true
                  : false
              }
              onChange={(e) =>
                handleSelectDocuments(e, documentValue)
              }
              className="mt-3"
            />
            <Box
              className="small-text-font pl-10 cursor-hover gray9 mt-2 d-flex align-start"
              onClick={(e) =>
                handleSelectDocuments(e, documentValue)
              }
              onKeyPress={(e) =>
                handleSelectDocuments(e, documentValue)
              }
              tabIndex={0}
            >
              {`${documentValue?.documentTitle}`}
              <span className='ml-30'><i>{`(Uploaded by ${documentValue.documentUploadedEnterpriseUserName} on ${dateFormat(documentValue?.createdTimestamp)})`}</i></span>
              {(documentValue.createdBy === localStorage.getItem("candidateId") || checkIfUserIsSuperAdmin(documentValue.enterpriseId)) &&<span
                tabIndex={0}
                className="align-center d-flex sub-text-font ml-10 cursor-hover gray7 mt-2"
                onClick={(e) => handleOpenDeletePopup(e, documentValue)}
              >
                <img
                  className="sub-text-font"
                  src={deleteIcon}
                  alt="delete icon"
                />
              </span>}
            </Box>
          </Typography>
        )
      )}
      {/* </InfiniteScroll> */}
      <ConfirmDialog
        open={openDeletePopup}
        setOpen={setOpenDeletePopup}
        dialogTitle={`${type === "Resume" ? "Delete Resume" : "Delete File"}`}
        description={"Are you sure?"}
        handleConfirmYes={() => handleDeleteResume()}
      />
    </Fragment>
  );
}

export default DocumentListing;
