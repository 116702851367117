import { Grid, Typography } from "@mui/material";
import * as React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import LaunchCarousel1 from "../../assets/images/launch-page-images/launch-carousel-1.png";
import LaunchCarousel2 from "../../assets/images/launch-page-images/launch-carousel-2.png";
import LaunchCarousel3 from "../../assets/images/launch-page-images/launch-carousel-3.png";
import LaunchCarousel4 from "../../assets/images/launch-page-images/launch-carousel-4.png";

export default function CaursoulSection() {
  let navigate = useNavigate();

  return (
    <Grid item
      data-aos="fade-in-left"
      data-aos-anchor="#example-anchor"
      data-aos-offset="500"
      data-aos-duration="500"
      sx={{ py: 3 }}
      className=""
    >
      {/* <Container>*/}
      <Grid item lg={12} md={12} xs={12} sx={{ py: 3, mt: 1 }} className="carousel-responsive">
        {/*   <Container>*/}

        <Carousel
          additionalTransfrom={0}
          arrows
          autoPlaySpeed={3000}
          centerMode={false}
          className=""
          containerClass="container"
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024,
              },
              items: 1,
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0,
              },
              items: 1,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464,
              },
              items: 1,
            },
          }}
          showDots
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          <Grid item lg={12} md={12} xs={12} className="image-container">
            <img
              src={LaunchCarousel1}
              style={{
                display: "block",
                height: "100%",
                margin: "auto",
                width: "100%",
              }}
              alt="candidates"
            />

            <Grid item lg={12} md={12} xs={12} className="caursoul-text p-10">
              <Typography className=" f-30 launch-page-heading-color text-left ">
                6.5 million candidates
              </Typography>
              <Typography className="f-18 landing-sub-text-color text-left">
                Tap into 6.5 million candidates ready
              </Typography>
              <Typography className="f-18 landing-sub-text-color text-left">
                to service your requirements
              </Typography>
            </Grid>

            <Grid item lg={12} md={12} xs={12} className="caursoul-button1">
              <a
                  href={process.env.REACT_APP_AWS_USER_POOL_REDIRECT_SIGN_IN + "/signup"}
                  className="btn watch-video-btn text-decoration-none"
                target="_blank"
                rel="noopener noreferrer"
              >
                Register
              </a>
            </Grid>
          </Grid>

          <Grid item lg={12} md={12} xs={12} className="image-container">
            <img
              src={LaunchCarousel2}
              style={{
                display: "block",
                height: "100%",
                margin: "auto",
                width: "100%",
              }}
              alt="Cut hiring"
            />
            <Grid item lg={12} md={12} xs={12} className="caursoul-text py-10">
              <Typography className=" f-32 launch-page-heading-color text-left ">
                Cut hiring costs by 15%
              </Typography>
              <Typography className="f-18 landing-sub-text-color text-left">
                {" "}
                Achieve year on year savings spend by sourcing talent direct
              </Typography>
            </Grid>

            <Grid item lg={12} md={12} xs={12} className="caursoul-button1">
              <a
                  href={process.env.REACT_APP_AWS_USER_POOL_REDIRECT_SIGN_IN + "/signup"}
                  className="btn watch-video-btn text-decoration-none"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                Register
              </a>
            </Grid>

          </Grid>

          <Grid item lg={12} md={12} xs={12} className="image-container">
            <img
              src={LaunchCarousel3}
              style={{
                display: "block",
                height: "100%",
                margin: "auto",
                width: "100%",
              }}
              alt="Onboard"
            />
            <Grid item lg={12} md={12} xs={12} className="caursoul-text py-10">
              <Typography className=" f-30 launch-page-heading-color text-left ">
                Onboard your business in less than 4 hours
              </Typography>
              <Typography className="f-18 landing-sub-text-color text-left">
                {" "}
                Instantly post projects and get ready to hire your next
                candidate
              </Typography>
            </Grid>

            <Grid item lg={12} md={12} xs={12} className="caursoul-button1">
              <a
                  href={process.env.REACT_APP_AWS_USER_POOL_REDIRECT_SIGN_IN + "/signup"}
                  className="btn watch-video-btn text-decoration-none"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                Register
              </a>
            </Grid>
          </Grid>

          <Grid item lg={12} md={12} xs={12} className="image-container">
            <img
              src={LaunchCarousel4}
              style={{
                display: "block",
                height: "100%",
                margin: "auto",
                width: "100%",
              }}
              alt="Find your best"
            />

            <Grid item lg={12} md={12} xs={12} className="caursoul-text py-10">
              <Typography className=" f-32 launch-page-heading-color text-left ">
                Find your best candidates faster
              </Typography>
              <Typography className="f-18 landing-sub-text-color text-left">
                <ul>
                  <li>Hire the top talent</li>
                  <li>Save on time</li>
                  <li>Reduce your spend</li>
                </ul>
              </Typography>
            </Grid>

            <Grid item lg={12} md={12} xs={12} className="caursoul-button2">
              <a
                  href={process.env.REACT_APP_AWS_USER_POOL_REDIRECT_SIGN_IN + "/signup"}
                className="btn watch-video-btn text-decoration-none"
                target="_blank"
                rel="noopener noreferrer"
              >
                Register
              </a>
            </Grid>
          </Grid>
        </Carousel>
      </Grid>
    </Grid>
  );
}
