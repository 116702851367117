import React, { useState } from "react";
import { Button, Box, Modal, Grid, TextareaAutosize } from "@mui/material";
import { Typography } from "@material-ui/core";
import { handleSpaces } from "../../helpers/helper-data";
import CloseIcon from "@mui/icons-material/Close";
function MessageDialogModel({
  open,
  setOpen,
  title,
  description,
  handleConfirmYes,
  message,
  setMessage
}) {
  const handleClose = () => {
    setOpen(false);
    setMessage("");
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modal-content inputcontrasts galaxy-width">
        <Grid container id="modal-title">
          <Grid item xs={6} className="align-center display-flex">
            <Typography className="h3-text heading-text-color galaxy-width">
              {title}
            </Typography>
          </Grid>
          <Grid item xs={6} className="align-item-right">
              <Typography align="right" className=" cursor-hover gray7">
                <CloseIcon
                  tabIndex={0}
                  onKeyPress={handleClose}
                  onClick={handleClose}
                  aria-label="Close icon"
                />
              </Typography>
            </Grid>
        </Grid>
        <Grid id="modal-description" className="p-0">
          <Grid item xs={12}>
            <Typography className="input-focus-none">
              <Grid className="f-14 font-weight-400">{description}</Grid>
              <Grid className="form-control">
                <TextareaAutosize
                  minRows={3}
                  maxRows={4}
                  aria-label="maximum height"
                  className={`form-control input-group inputcontrasts text-justify cursor-auto`}
                  maxLength={1000}
                  inputProps={{ maxLength: 1000 }}
                  onChange={(e) => {
                    const inputText = e.target.value;
                    if (inputText.length > 1000) {
                      setMessage(inputText.slice(0, 1000));
                    } else {
                      setMessage(inputText);
                    }
                    // if (inputText.length === 0) {
                    //   setShouldOpenconfirmation(false);
                    // }
                  }}
                  onKeyPress={handleSpaces}
                  name="message"
                  id="message"
                  value={message}
                  placeholder="Enter message"
                  style={{ whiteSpace: "pre-line" }}
                />
              </Grid>
              <Typography align="right" className="f-14 gray7">
                {message?.length ? message?.length : 0}/1000
              </Typography>
            </Typography>
          </Grid>
        </Grid>
        <Grid id="modal-footer" className="align-right justify-end mt-20">
          <Button
            type="submit"
            className="btn primary-button profileButton"
            onClick={handleConfirmYes}
            variant="contained"
            disabled={message?.length ? message?.length > 1000 : true}
            disableRipple="true"
          >
            Send
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
}
export default MessageDialogModel;
