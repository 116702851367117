import React from 'react';
import { styled, LinearProgress, linearProgressClasses } from "@mui/material";
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: "20px !important",
    borderRadius: 25,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor:
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === "light" ? getComputedStyle(document.documentElement).getPropertyValue('--primary-color') : "#8F9DDD",
    },
}));

const ProgressBar = ({ parentBgColor, childBgColor, progress, height, borderRadius, progresstextColor, weightage }) => {
    const Parentdiv = {
        height: height,
        width: '100%',
        backgroundColor: parentBgColor,
        borderRadius: borderRadius,
    };
    const Childdiv = {
        height: height,
        width: `${progress}%`,
        backgroundColor: childBgColor,
        borderRadius: borderRadius,
        textAlign: 'right'
    };
    const progresstext = {
        marginRight: 5,
        height: height,
        color: progresstextColor,
        fontWeight: 600,
        fontSize: 12,
        paddingTop: "2px",
    };
    const zeroprogresstext = {
        marginLeft: 5,
        height: height,
        width: '100%',
        textAlign: 'left',
        color: progresstextColor,
        fontWeight: 600,
        fontSize: 12,
        paddingTop: "2px",
    };
    return (
    (weightage > 0 && progress) ?
    <span className="matching-breakdown-progress p-relative"><BorderLinearProgress
        className="progressbarcont"
        aria-label="Progress bar"
        variant="determinate"
        value={progress}
        borderRadius={25}
        max={100}
    />
    <span className='c-white p-absolute justify-center align-center text-center f-12 d-flex' style={{ top: "0px", left: "0px", bottom: "0px", right: "0px", fontWeight: "600", paddingTop: "2px" }}>{progress + "% Matched"}</span>
    </span>
    :
    <span className="matching-breakdown-progress p-relative"><BorderLinearProgress
        className="progressbarcont"
        aria-label="Progress bar"
        variant="determinate"
        value={0}
        borderRadius={25}
        max={100}
    />
    <span className='c-white p-absolute justify-center align-center text-center f-12 d-flex' style={{ top: "0px", left: "0px", bottom: "0px", right: "0px", fontWeight: "600", paddingTop: "2px" }}>{weightage === 0 ? "Disabled from Matching score" : "0% Matched"}</span>
    </span>
    );
};

export default ProgressBar;
