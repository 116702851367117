import { Nationalities } from "../data/nationalities";
import checklistAdministrativeAssistant from "../assets/Forms/checklistAdministrativeAssistant.txt";
import checklistCathLab from "../assets/Forms/checklistCathLab.txt";
import checklistClinicalLabaratorySkills from "../assets/Forms/checklistClinicalLabaratorySkills.txt";
import checklistCRNASkills from "../assets/Forms/checklistCRNASkills.txt";
import checklistCVORRnSKills from "../assets/Forms/checklistCVORRnSKills.txt";
import checklistEmergencyRoomSkills from "../assets/Forms/checklistEmergencyRoomSkills.txt";
import checklistEndoscopySkills from "../assets/Forms/checklistEndoscopySkills.txt";
import checklistICUCCUSkills from "../assets/Forms/checklistICUCCUSkills.txt";
import checklistLongTermCareSkills from "../assets/Forms/checklistLongTermCareSkills.txt";
import checklistLPNCompetencySkills from "../assets/Forms/checklistLPNCompetencySkills.txt";
import checklistMedicalTechnologistSkills from "../assets/Forms/checklistMedicalTechnologistSkills.txt";
import checkListMRITechnologistSkills from "../assets/Forms/checklistMRITechnologistSkills.txt";
import checklistMedicalSurgicalRNSkills from "../assets/Forms/checklistMedicalSurgicalRNSkills.txt";
import checklistOccupationalTherapySkills from "../assets/Forms/checklistOccupationalTherapySkills.txt";
import checklistOperatingRoomNursingSkills from "../assets/Forms/checklistOperatingRoomNursingSkills.txt";
import checklistPACURNSkillst4h from "../assets/Forms/checklistPACURNSkillst4h.txt";
import checklistPatientCareAssociate from "../assets/Forms/checklistPatientCareAssociate.txt";
import checklistPatientCareTechnician from "../assets/Forms/checklistPatientCareTechnician.txt";
import checklistPCUSkills from "../assets/Forms/checklistPCUSkills.txt";
import checklistPeriOpRNSkills from "../assets/Forms/checklistPeriOpRNSkills.txt";
import checklistPhlebotomistComp from "../assets/Forms/checklistPhlebotomistComp.txt";
import checklistPhysicalTherapySkills from "../assets/Forms/checklistPhysicalTherapySkills.txt";
import checklistPICUSkills from "../assets/Forms/checklistPICUSkills.txt";
import checklistProgressiveCareSkills from "../assets/Forms/checklistProgressiveCareSkills.txt";
import checklistPsychiatricSkill from "../assets/Forms/checklistPsychiatricSkill.txt";
import checklistRegisteredRespiratoryTherapist from "../assets/Forms/checklistRegisteredRespiratoryTherapist.txt";
import checklistNICUSkills from "../assets/Forms/checklistRNNICUSkills.txt";
import checklistSkillChecklistId from "../assets/Forms/checklistSkillChecklistId.txt";
import checklistSpeechLanguagePathology from "../assets/Forms/checklistSpeechLanguagePathology.txt";
import checklistSurgicalTechSkills from "../assets/Forms/checklistSurgicalTechSkills.txt";
import checklistTelemetryRN from "../assets/Forms/checklistTelemetryRN.txt";
import checklistUltrasoundTechnologist from "../assets/Forms/checklistUltrasoundTechnologist.txt";
import moment from "moment";
import { tz } from "moment-timezone";
import { Tooltip, styled, tooltipClasses } from "@mui/material";

export const formatDate = (date) => {
  var d;
  if (typeof date === "string") {
    d = new Date(date);
  } else {
    d = date;
  }

  if (d instanceof Date) {
    var month = "" + (d.getMonth() + 1);
    var day = "" + d.getDate();
    var year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  } else {
    return null;
  }
};

export const formatDateYYYYMM = (date) => {
  var d;
  if (typeof date === "string") {
    d = new Date(date);
  } else {
    d = date;
  }

  if (d instanceof Date) {
    var month = "" + (d.getMonth() + 1);
    var day = "" + d.getDate();
    var year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month].join("");
  } else {
    return null;
  }
};

export const populateNationality = (key) => {
  for (var i = 0; i < Nationalities.length; i++) {
    var nationality = Nationalities[i];
    if (nationality.alpha_3_code === key) {
      return nationality.nationality;
    }
  }
};

export const getselectedNationality = (key) => {
  for (var i = 0; i < Nationalities.length; i++) {
    var nationality = Nationalities[i];
    if (nationality.alpha_3_code === key) {
      return nationality;
    }
  }
};

export const handleSpaces = (event) => {
  var e = window.event || event;

  var key = e.keyCode;

  //space pressed

  if (key === 32) {
    //space

    if (event.target.value < 1) event.preventDefault();
  }
};
export const dateDisplayFormat = (date) => {
  var d;
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  if (typeof date === "string") {
    d = new Date(date);
  } else {
    d = date;
  }

  if (d instanceof Date) {
    var month = monthNames[d.getMonth()];
    var day = "" + d.getDate();
    var year = d.getFullYear();

    if (day.length < 2) day = "0" + day;

    return month + " " + [day, year].join(", ");
  } else {
    return null;
  }
};

export const formatMoney = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
});

export const convertHtmlToText = (passHtmlBlock) => {
  const text = passHtmlBlock.toString();
  return text.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, " ");
};

export const workDuration = (workedFrom, workedTo) => {
  var currentDate = moment().format("YYYY-MM-DD");
  var start = moment(workedFrom, "YYYY-MM-DD");
  var end = moment(
    workedTo === "1900-01-01" ? currentDate : workedTo,
    "YYYY-MM-DD"
  );
  var years = end.diff(start, "years");
  var months = end.diff(start, "months");
  var output = "";
  if (years > 0 && months === 0) {
    output = years === 0 ? "" : `${years} ${years === 1 ? "Year " : "Years "}`;
  }
  if (years === 0 && months > 0) {
    output =
      months === 0
        ? "1 month"
        : `${months} ${months === 1 ? "month " : "months "}`;
  }
  if (years === 0 && months === 0) output = "1 month";
  if (years > 0 && months % years === 0) {
    output = `${years} ${years === 1 ? "Year " : "Years "}`;
  }
  if (years > 0 && months % 12 > 0) {
    output =
      `${years} ${years === 1 ? "Year " : "Years "}` +
      `${months % 12} ${months % 12 === 1 ? "month " : "months "}`;
  }

  return output;
};

export const checkIsImage = (mediaFile) => {
  let x = mediaFile != undefined && mediaFile?.split(".").pop().split("?")[0];
  // let format = x[x.length - 1];
  if (["jpg", "jpeg", "png", "gif"].includes(x)) {
    return true;
  }
  return false;
};

const skill_checklists = [
  {
    id: 1,
    file: checklistAdministrativeAssistant
  },
  {
    id: 2,
    file: checklistCathLab
  },
  {
    id: 3,
    file: checklistClinicalLabaratorySkills
  },
  {
    id: 4,
    file: checklistCRNASkills
  },
  {
    id: 5,
    file: checklistCVORRnSKills
  },
  {
    id: 6,
    file: checklistEmergencyRoomSkills
  },
  {
    id: 7,
    file: checklistEndoscopySkills
  },
  {
    id: 8,
    file: checklistICUCCUSkills
  },
  {
    id: 9,
    file: checklistLongTermCareSkills
  },
  {
    id: 10,
    file: checklistLPNCompetencySkills
  },
  {
    id: 11,
    file: checklistMedicalTechnologistSkills
  },
  {
    id: 12,
    file: checklistMedicalSurgicalRNSkills
  },
  {
    id: 13,
    file: checkListMRITechnologistSkills
  },
  {
    id: 14,
    file: checklistOccupationalTherapySkills
  },
  {
    id: 15,
    file: checklistOperatingRoomNursingSkills
  },
  {
    id: 16,
    file: checklistPACURNSkillst4h
  },
  {
    id: 17,
    file: checklistPatientCareAssociate
  },
  {
    id: 18,
    file: checklistPatientCareTechnician
  },
  {
    id: 19,
    file: checklistPCUSkills
  },
  {
    id: 20,
    file: checklistPeriOpRNSkills
  },
  // {
  //   id: 21,
  //   file: checklistPeriOpRNSkills // TODO
  // },
  {
    id: 21,
    file: checklistPhysicalTherapySkills
  },
  {
    id: 22,
    file: checklistPICUSkills
  },
  {
    id: 23,
    file: checklistProgressiveCareSkills
  },
  {
    id: 24,
    file: checklistPsychiatricSkill
  },
  {
    id: 25,
    file: checklistRegisteredRespiratoryTherapist
  },
  // {
  //   id: 27,
  //   file: checklistOccupationalTherapySkills // TODO
  // },
  {
    id: 26,
    file: checklistNICUSkills
  },
  {
    id: 27,
    file: checklistSkillChecklistId
  },
  {
    id: 28,
    file: checklistSpeechLanguagePathology
  },
  {
    id: 29,
    file: checklistSurgicalTechSkills
  },
  {
    id: 30,
    file: checklistTelemetryRN
  },
  {
    id: 31,
    file: checklistUltrasoundTechnologist
  }
];
export const restrictText = (event) => {
  let e = window.event || event;
  let key = e.keyCode || e.which;
  if (
    (key >= 48 && key <= 57) || // numeric keys
    key == 8 || // backspace
    key == 9 || // tab
    key == 13 || // enter
    key == 27 || // escape
    (key >= 37 && key <= 40) // arrow keys
) {
    return true;
} else {
    e.preventDefault();
    return false;
}
};
export const getValidFullName = (fullname) => {
  if (fullname) {
    let name = fullname.split(" ");
    if (name.length > 0 && (name[name.length - 1] === "null" || name[name.length - 1] === "undefined")) {
      return name.slice(0, name.length - 1)?.join(" ");
    }
    return fullname;
  }
  return "";
};

export const getSkillChecklist = (id) => {
  for (let i=0; i < skill_checklists.length; i++) {
    if (skill_checklists[i].id === id) {
      return skill_checklists[i].file;
    }
  }
};
export const isMinutesOrHours = (date) => {
  // Convert the input date to a Moment.js object
  const momentDate = moment.utc(date);

  // Get the current date and time as a Moment.js object
  const now = moment.utc();

  // Calculate the difference between the two dates in minutes
  const diffInMinutes = now.diff(momentDate, 'minutes');

  // Handle the case where the date is less than a minute ago
  if (diffInMinutes < 1) {
    return 'just now';
  }

  // Handle the case where the date is less than an hour ago
  if (diffInMinutes < 60) {
    return `${diffInMinutes} ${diffInMinutes < 2 ? 'minute' : 'minutes'} ago`;
  }

  // Handle the case where the date is earlier than today
  const diffInHours = Math.floor(diffInMinutes / 60);
  if (diffInHours <= 24) {
    return `${diffInHours} ${diffInHours === 1 ? "hour" : "hours" } ago`;
  }

  // Handle the case where the date is yesterday
  // if (momentDate.isSame(now.clone().subtract(1, 'day'), 'day')) {
  //   return `yesterday ${momentDate.format('h:mm A')}`;
  // }

  // // Handle the case where the date is earlier than yesterday
  // return momentDate.format('MMM D, YYYY h:mm A');
};

export const getFormattedLocation = (city, state, country) => {
  if (city || state || country) {
    return (
      (city ? city : "") +
      (city && state ? ", " : "") +
      (state ? state : "") +
      (city || state ? ", " : "") +
      (country ? country : "")
    );
  }
  return "No location";
};
export const getSource = (type) => {
  switch (type) {
    case "C":
      return "Campaign";
    case "I":
      return "Invite";
    case "R":
      return "Career Portal";
    case "J":
      return "Job Board";
    case "A":
      return "ATS";
    case "D":
      return "Platform";
    case "P":
      return "Platform";
    default:
      return "-";
  }
};

export const formattedInterviewStatus = {
    "P": "Pending",
    "A": "Accepted",
    "C": "Cancelled",
    "X": "Cancelled",
    "D": "Declined",
    "E": "Expired",
    "Y": "Completed"
};
const formatTimeforTeams = (inputDateTime) => {
  const dateObject = new Date(inputDateTime);
  const formattedTime = dateObject.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });
  return formattedTime;
};
export const getFormattedTime = (value) => {
  if (value) {
      const dateObj = moment(value).local().format("hh:mm A");
      const timeParts = dateObj.split(":");
      let hours = timeParts[0];
      const minutes = timeParts[1];
      const timeString = `${hours}:${minutes}`;
      return timeString;
  } else {
      return "";
  }
};
export const getFormattedTimeUtc = (value) => {
  if (value) {
      const dateObj = moment(value).utc().format("hh:mm A");
      const timeParts = dateObj.split(":");
      let hours = timeParts[0];
      const minutes = timeParts[1];
      const timeString = `${hours}:${minutes}`;
      return timeString;
  } else {
      return "";
  }
};
export const gettimeZoneOffset = () => {
  let TimeZone = "";
    try {
      TimeZone = moment().tz(tz.guess()).format("z");
    } catch (err) {
      console.log(err);
    }
  // var rightNow = new Date();
  // let standatardTime = String(String(rightNow).split("(")[1]).split(")")[0];
  // standatardTime = standatardTime.split(" ");
  // let TimeZone = `${standatardTime[0].substring(
  //   0,
  //   1
  // )}${standatardTime[1].substring(0, 1)}${standatardTime[2].substring(0, 1)}`;
  return TimeZone;
};
export const getFormattedStartEndTime = (scheduleStartTime, scheduleCompleteTime) => {
  if (!scheduleStartTime) {
    return "-";
  }
  return getFormattedTime(scheduleStartTime) + " - " + getFormattedTime(scheduleCompleteTime) + " " + gettimeZoneOffset();
};
export const getFormatted_startEndTime = (scheduleStartTime, scheduleCompleteTime) => {
  if (!scheduleStartTime) {
    return "-";
  }
  return formatTimeforTeams(scheduleStartTime) + " - " + formatTimeforTeams(scheduleCompleteTime) + " " + gettimeZoneOffset();
};
export const getFormattedStartEndTimeUtc = (scheduleStartTime, scheduleCompleteTime) => {
  if (!scheduleStartTime) {
    return "-";
  }
  return getFormattedTimeUtc(scheduleStartTime) + " - " + getFormattedTimeUtc(scheduleCompleteTime) + " " + gettimeZoneOffset();
};

export const getColorForChar= (letter) => {
  let firstLetter = letter.trim().substring(0, 1);
  switch (firstLetter.toUpperCase()) {
    case 'A':
      return "#5e0926";
    case 'B':
      return "#5e0926";
    case 'C':
      return "#5a0e67";
    case 'D':
      return "#14678c";
    case 'E':
      return "#6200EA";
    case 'F':
      return "#103e65";
    case 'G':
      return "#0f4964";
    case 'H':
      return "#0c8094";
    case 'I':
      return "#094b2b";
    case 'J':
      return "#224d01";
    case 'K':
      return "#48590d";
    case 'L':
      return "#6b6309";
    case 'M':
      return "#6b550f";
    case 'N':
      return "#985e11";
    case 'O':
      return "#5e2210";
    case 'P':
      return "#e03f3f";
    case 'Q':
      return "#6D4C41";
    case 'R':
      return "#1E88E5";
    case 'S':
      return "#9b3d0d";
    case 'T':
      return "#5d0e1e";
    case 'U':
      return "#455A64";
    case 'V':
      return "#046370";
    case 'W':
      return "#066e3b";
    case 'X':
      return "#39700b";
    case 'Y':
      return "#84a219";
    case 'Z':
      return "#6b6207";
    default:
      return "#000000"; // black color
  }
};

export const getLocation = (job) => {
  let location = "";
  if (job.city && job.state && job.country) {
    location = job.city + ", " + job.state + ", " + job.country;
  } else if (job.city && job.state && (job.country == null || job.country == "")) {
    location = job.city + ", " + job.state;
  } else if (job.state && job.country && (job.city == null || job.city == "")) {
    location = job.state + ", " + job.country;
  } else if (job.city && job.country && (job.state == null || job.state == "")) {
    location = job.city + ", " + job.country;
  } else if (job.state && (job.country == null || job.country == "") && (job.city == null || job.city == "")) {
    location = job.state;
  } else if (job.country && (job.city == null || job.city == "") && (job.state == null || job.state == "")) {
    location = job.country;
  } else if (job.city && (job.country == null || job.country == "") && (job.state == null || job.state == "")) {
    location = job.city;
  }
  return location;
};
export const checkDateRangeForOfferExpiry=  (date)=> {
  const dateToCheck = new Date(date);
const today = new Date();
today.setHours(0, 0, 0, 0);
const dateToCheckWithoutTime = new Date(dateToCheck.getFullYear(), dateToCheck.getMonth(), dateToCheck.getDate());
const todayWithoutTime = new Date(today.getFullYear(), today.getMonth(), today.getDate());
if (todayWithoutTime > dateToCheckWithoutTime) {
return true;
}  else {
return false;
}
};
export const getPaymentTerm = (term) => {
  switch (term) {
    case "Per hour":
      return "hour";
    case "Per day":
      return "day";
    case "Per month":
      return "month";
    case "Per week":
      return "week";
    default:
      return "";
  }
};

export const getWorkType = (type) => {
  switch (type) {
    case "R":
      return "Remote";
    case "O":
      return "Onsite";
    case "H":
      return "Hybrid";
    default:
      return "-";
  }
};
export const getJobExpLevel = (level) => {
  switch (level) {
    case "IN":
      return "Internship";
    case "EL":
      return "Entry Level";
    case "AT":
      return "Associate";
    case "ML":
      return "Mid-Senior Level";
    case "DR":
      return "Director";
    case "ET":
      return "Executive";
    default:
      return "-";
  }
};

export const getUserType = (userType) => {
  switch (userType) {
    case "Z":
      return "Super Administrator";
    case "A":
      return "Administrator";
    case "S":
      return "Associate";
    case "R":
      return "Recruiter";
    case "H":
      return "HR Manager";
    case "T":
      return "Talent Acquisition Manager";
    case "E":
      return "Executive Leader";
    default:
      return "-";
  }
};
export const getEducationType = (type) => {
  switch (type) {
      case "B":
          return "Bachelors";
      case "P":
          return "Doctorate";
      case "M":
          return "Masters";
      case "D":
          return "Diploma";
      case "S":
          return "School";
      default:
          return "-";
  }
};
export const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: `${theme.palette.common.white} !important`,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: `${theme.palette.common.white} !important`,
    color: '#1D2939 !important',
    opacity: '1 !important',
    boxShadow: `${theme.shadows[1]} !important`,
  },
}));
export const extractValueFromCookie = (cookieName, cookieString) => {
  const matchedCookie = cookieString.split(';').find((cookie) => cookie.includes(cookieName));
  if (matchedCookie) {
      return matchedCookie.trim().split('=')[1];
  }
  return null;
};
export const saveCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};
export const getDurationValue = (duration) => {
  let value = "";
  if (duration && duration.length > 1) {
    try {
      value = parseInt(duration.slice(0, duration.length - 1));
    } catch (err) {}
  }
  return value;
};
export const getDurationPeriod = (duration) => {
  let value = "";
  if (duration && duration.length > 1) {
    let period = duration.slice(duration.length - 1, duration.length);
    switch (period) {
      case "D":
          value = "Day(s)";
          break;
      case "W":
          value = "Week(s)";
          break;
      case "M":
          value = "Month(s)";
          break;
      case "Y":
          value = "Year(s)";
          break;
    }
  }
  return value;
};
export const getDurationPeriodForDropDown = (duration) => {
  let value = "";
  if (duration && duration.length > 1) {
    let period = duration.slice(duration.length - 1, duration.length);
    switch (period) {
      case "D":
          value = "Days";
          break;
      case "W":
          value = "Weeks";
          break;
      case "M":
          value = "Months";
          break;
      case "Y":
          value = "Years";
          break;
    }
  }
  return value;
};
export const timeGreetings = () => {
  const day = new Date();
  const hr = day.getHours();
  if (hr >= 0 && hr < 12) {
    return "Good Morning!";
  } else if (hr >= 12 && hr < 17) {
    return "Good Afternoon!";
  } else {
    return "Good Evening!";
  }
};
export const normalizeKeys = (obj) => {
  const normalizedObj = {};
  Object.keys(obj).forEach((key) => {
    const normalizedKey = key.toLowerCase();
    normalizedObj[normalizedKey] = obj[key];
  });
  return normalizedObj;
};
export const getCandidateShiftTimings = (shiftStartTime, shiftEndTime, shiftTimezone) => {
  let candidateShiftTimings = "-";
  if (shiftStartTime != undefined && shiftStartTime != "" && shiftStartTime != null) {
    let startTime = shiftStartTime?.toString()?.slice(0, 2) +
    ":" +
    shiftStartTime?.toString()?.slice(2, 4);
    let endTime = shiftEndTime?.toString()?.slice(0, 2) +
    ":" +
    shiftEndTime?.toString()?.slice(2, 4);
    let _shiftStartTime = startTime
      ? moment(
          "2017-08-30T" +
            startTime +
            ":00Z"
        ).utc().format("hh:mm A")
      : null;
    let _shiftEndTime = endTime
      ? moment(
          "2017-08-30T" +
            endTime +
            ":00Z"
        ).utc().format("hh:mm A")
      : null;
    candidateShiftTimings = `${_shiftStartTime} - ${_shiftEndTime} ${shiftTimezone}`;
  }
  return candidateShiftTimings;
};
export const isReferredByJohnDeere = (data) => {
  if (data) {
    let answer = JSON.parse(data);
    for (let i=0; i<answer?.length; i++) {
      if (answer[i]?.Type == "dropDown" && answer[i]?.Answer == "Referred by John Deere employee") {
        return true;
      }
    }
    return false;
  }
};
