import React, { useEffect, useState, useRef } from "react";
import { Grid, Typography, Select, Box, TextField, Switch, Tooltip, Checkbox, FormControlLabel, MenuItem } from "@mui/material";
import closeIcon from "../../../../assets/images/close-icon.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/images/delete-new-icon.svg";
import { ReactComponent as StopCriteria } from "../../../../assets/images/stop-criteria-img.svg";
import { ReactComponent as ChooseOption } from "../../../../assets/images/choose-option-img.svg";
import { ReactComponent as RadioIcon } from "../../../../assets/images/radioButton.svg";
import { ReactComponent as CheckboxIcon } from "../../../../assets/images/checkbox-icon.svg";
import { ReactComponent as AnswerboxIcon } from "../../../../assets/images/answerBox.svg";
import { ReactComponent as AttachmentIcon } from "../../../../assets/images/attachment-icon.svg";

export default function Question(props) {
  const [question, setQuestion] = React.useState([]);
  const availability = props.questions[0].default === true ? true : false;
  const [selectOpen, setSelectOpen] = useState(false);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    document.addEventListener('scroll', ()=>setOpen(false));
    return () => {
      document.removeEventListener('scroll', ()=>setOpen(false));
    };
  }, []);
  useEffect(() => {
    setQuestion(props.question);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  const onScroll = () => {
    setSelectOpen(false);
  };
  const handleQuestionChange = (value) => {
    if (!props.preview) {
      var ques = { ...question };
      if (value.length === 1) {
        ques["Question"] = value.trimStart().toUpperCase();
      } else {
        ques["Question"] = value.trimStart();
      }
      setQuestion(ques);
      handleChange(ques);
    }
  };

  const handleQuestionType = (event) => {
    if (!props.preview) {
      var ques = { ...question };
      if (ques["Options"]) {
        while (ques["Options"].length < 2) {
          ques["Options"].push("");
        }
      } else {
        ques.Options = ["", ""];
      }
      ques["Type"] = event.target.value;
      setQuestion(ques);
      handleChange(ques);
    }
  };

  const addEmptyOption = () => {
    if (!props.preview) {
      var ques = { ...question };
      ques["Options"].push("");
      setQuestion(ques);
      handleChange(ques);
    }
  };

  const addOption = (event, index) => {
    if (!props.preview) {
      var ques = { ...question };
      if (event.target.value.length === 1) {
        ques["Options"][index] = event.target.value.toUpperCase();
      } else {
        ques["Options"][index] = event.target.value;
      }
      setQuestion(ques);
      handleChange(ques);
    }
  };

  const deleteOption = (index) => {
    if (!props.preview) {
      var ques = { ...question };
      ques["Options"].splice(index, 1);
      if (Array.isArray(ques["stopCriteria"])) {
        ques["stopCriteria"] = ques["stopCriteria"].filter(
          (number) => number !== index
        );
      }
      setQuestion(ques);
      handleChange(ques);
    }
  };

  const handleChange = (data) => {
    props.handleChange(data, props.index);
  };
  const generateString = (index) => {
    const baseCharCode = "a".charCodeAt(0);
    const firstCharCode = Math.floor(index / 26) - 1 + baseCharCode;
    const secondCharCode = index % 26 + baseCharCode;
    const firstChar = firstCharCode >= baseCharCode ? String.fromCharCode(firstCharCode) : "";
    const secondChar = String.fromCharCode(secondCharCode);
    return firstChar + secondChar + ")";
};
  const handleAddStopCriteria = (index) => {
    var ques = { ...question };
    if (ques["stopCriteria"]) {
      ques["stopCriteria"] = [...ques["stopCriteria"], index];
    } else {
      ques["stopCriteria"] = [index];
    }
    setQuestion(ques);
    handleChange(ques);
  };
  const handleRemoveStopCriteria = (index) => {
    var ques = { ...question };
    ques["stopCriteria"] = ques["stopCriteria"].filter(
      (number) => number !== index
    );
    setQuestion(ques);
    handleChange(ques);
  };

  return (
    <>
      <Grid
        container
        spacing={1}
        item
        lg={12}
        md={12}
        xs={12}
        className={(((props.defaultQuestionsLength ? props.index + props.defaultQuestionsLength : props.index) < props.questions?.length - 1) && !props.isTalentPoolQuestions)
          ? "jobHeader-align border-bottom-gray6 border-gray6 pt-10 pb-10 pl-15 pr-15 border-radius-8 m-0"
          : "jobHeader-align border-gray6 pt-10 pb-10 pl-15 pr-15 border-radius-8 m-0"}
      >
        <Grid item lg={12} md={12} xs={12} className="pl-0 pt-0">
          <Typography className="font-weight-700 f-16 gray9">
              {/* Assessment Question */}
              {/* {"Q." +
                (availability && props.jobType === "FT"
                  ? props.index
                  : availability && props.jobType === "PT"
                  ? props.index - (props.questions[0].Type === 'date'?2:1)
                  : availability && props.jobType === "FL"
                  ? props.index - 3
                  : props.index + 1)} */}
              {"Question " + (props.index + 1)}
            </Typography>
          </Grid>
        <Grid item lg={8} md={8} xs={12} className="mt-10 p-0">
          <Typography className="tp-align mt-5">
            <Typography className="mb-0 borderRadius-l galaxy-padding-0">
                <Box className="gray7 font-weight-400 f-14">
                  Question
                </Box>
                <TextField
                  type="text"
                  // margin="normal"
                  className="w-100 form-control input-group mb-0 textEllipse inputcontrasts tp-input"
                  placeholder="Enter question"
                  value={question.Question}
                  onChange={(e) => handleQuestionChange(e.target.value)}
                />
            </Typography>

            {question.Type === "radio" ? (
              <Typography className="mt-10 flex-wrap">
                {question["Options"]
                  ? question["Options"].map((option, index) => {
                      return (
                        <Grid
                          item
                          lg={10}
                          md={10}
                          xs={12}
                          key={index}
                        >
                          <Typography>
                            {/* <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              className="radio-check-position"
                            >
                              <Typography className="d-flex">
                                <FormControlLabel
                                  disabled
                                  className="assessment-details mr-0"
                                  value=""
                                  control={<Radio  sx={{
                                    color: "#98A2B3",
                                    "&.Mui-checked": {
                                      color: getComputedStyle(document.documentElement).getPropertyValue('--primary-color'),
                                    }
                                  }}/>}
                                  label=""
                                  aria-label="assessment question"
                                />
                                <TextField
                                  variant="outlined"
                                  className="form-control input-group mb-0 mr-10 inputcontrasts"
                                  placeholder={"Option " + (index + 1)}
                                  onChange={(e) => addOption(e, index)}
                                  value={option}
                                />

                                <img
                                  className="ml-10 cursor-hover"
                                  style={{ width: "14px" }}
                                  src={closeIcon}
                                  onClick={() => deleteOption(index)}
                                  alt="img"
                                  tabIndex={0}
                                  onKeyPress={() => deleteOption(index)}
                                />
                              </Typography>
                            </RadioGroup> */}
                            <Typography className="d-flex align-center">
                              <Box className="gray7 f-14 font-weight-400 mr-5" style={{ minWidth: "25px", maxWidth: "25px" }}>
                                {/* {String.fromCharCode(97 + index) + ")"} */}
                                {generateString(index)}
                              </Box>
                              <TextField
                                variant="outlined"
                                className="form-control input-group mb-0 inputcontrasts"
                                placeholder={"Option " + (index + 1)}
                                onChange={(e) => addOption(e, index)}
                                value={option}
                              />
                              <img
                                className="ml-10 cursor-hover"
                                style={{ width: "10px" }}
                                src={closeIcon}
                                onClick={() => deleteOption(index)}
                                alt="img"
                                tabIndex={0}
                                onKeyPress={() => deleteOption(index)}
                              />
                              <Typography className="ml-20">
                                <Tooltip
                                  title={
                                    <div>
                                      <Box className="f-14 font-weight-500">
                                        Stop Criteria
                                      </Box>
                                      <Box className="f-12 font-weight-400 mt-3">
                                        Stop the candidate's progress when
                                        this response is chosen.
                                      </Box>
                                    </div>
                                  }
                                  position="top"
                                  trigger="mouseenter"
                                  animation="fade"
                                  arrow
                                >
                                  <Box className="d-flex">
                                    {question["stopCriteria"] &&
                                      question["stopCriteria"].includes(
                                        index
                                      ) ? (
                                      <Box
                                        className="stop-criteria-img d-flex cursor-hover"
                                        onClick={() => {
                                          handleRemoveStopCriteria(index);
                                        }}
                                      >
                                        <StopCriteria
                                          width="14px"
                                          height="14px"
                                        />
                                      </Box>
                                    ) : (
                                      <Box
                                        className="choose-option-img d-flex cursor-hover"
                                        onClick={() => {
                                          handleAddStopCriteria(index);
                                        }}
                                      >
                                        <ChooseOption
                                          width="14px"
                                          height="14px"
                                        />
                                      </Box>
                                    )}
                                  </Box>
                                </Tooltip>
                              </Typography>
                            </Typography>
                          </Typography>
                        </Grid>
                      );
                    })
                  : ""}
              </Typography>
            ) : (
              ""
            )}

            {question.Type === "checkbox" ? (
              <Grid className="mt-10 flex-wrap">
                {question["Options"]
                  ? question["Options"].map((option, index) => {
                      return (
                        <Grid
                        item
                          lg={10}
                          md={10}
                          xs={12}
                          className="radio-check-position"
                          key={index}
                          >
                          <Typography className="d-flex align-center">
                            <>
                              {/* <FormControlLabel
                                control={
                                  <Checkbox
                                    disabled
                                    sx={{
                                      color: "#98A2B3",
                                      "&.Mui-checked": {
                                        color: getComputedStyle(document.documentElement).getPropertyValue('--primary-color'),
                                      },
                                    }}
                                    aria-label="assessment question"
                                  />
                                }
                                className="gray9 f-16 font-weight-400"
                                label=""
                              /> */}
                              <Box className="gray7 f-14 font-weight-400 mr-5" style={{ minWidth: "25px", maxWidth: "25px" }}>
                                {/* {String.fromCharCode(97 + index) + ")"} */}
                                {generateString(index)}
                              </Box>
                              <TextField
                                variant="outlined"
                                className="form-control input-group mb-0 inputcontrasts"
                                placeholder={"Option " + (index + 1)}
                                onChange={(e) => addOption(e, index)}
                                value={option}
                              />
                              <img
                                className="ml-10 cursor-hover"
                                style={{ width: "10px" }}
                                src={closeIcon}
                                onClick={() => deleteOption(index)}
                                alt="img"
                                tabIndex={0}
                                onKeyPress={() => deleteOption(index)}
                              />
                              <Typography className="ml-20">
                                <Tooltip
                                  title={
                                    <div>
                                      <Box className="f-14 font-weight-500">
                                        Stop Criteria
                                      </Box>
                                      <Box className="f-12 font-weight-400 mt-3">
                                        Stop the candidate's progress when this
                                        response is chosen.
                                      </Box>
                                    </div>
                                  }
                                  position="top"
                                  trigger="mouseenter"
                                  animation="fade"
                                  arrow
                                >
                                  <Box className="d-flex">
                                    {question["stopCriteria"] &&
                                      question["stopCriteria"].includes(index) ? (
                                      <Box
                                        className="stop-criteria-img d-flex cursor-hover"
                                        onClick={() => {
                                          handleRemoveStopCriteria(index);
                                        }}
                                      >
                                        <StopCriteria
                                          width="14px"
                                          height="14px"
                                        />
                                      </Box>
                                    ) : (
                                      <Box
                                        className="choose-option-img d-flex cursor-hover"
                                        onClick={() => {
                                          handleAddStopCriteria(index);
                                        }}
                                      >
                                        <ChooseOption
                                          width="14px"
                                          height="14px"
                                        />
                                      </Box>
                                    )}
                                  </Box>
                                </Tooltip>
                                <Box className="d-flex"></Box>
                              </Typography>
                            </>
                          </Typography>
                        </Grid>
                      );
                    })
                  : ""}
              </Grid>
            ) : (
              ""
            )}
          </Typography>
          {question.Type === "radio" || question.Type === "checkbox"
            ? !props.preview && (
              <Typography
                className="link-color font-weight-400 f-14 d-flex mt-15"
                onClick={addEmptyOption}
                onKeyPress={addEmptyOption}
                tabIndex={0}
              >
                Add Option +
              </Typography>
            )
            : ""}
        </Grid>

        <Grid item lg={4} md={4} xs={12} className="jobHeader-align pt-0 mt-15">
        <Typography className="f-14 gray7 font-weight-400">Answer Type</Typography>
          <Typography className="assessment-questions">
            {/* <Select
              inputProps={{ "aria-label": "With label" }}
              className="form-control input-group mb-0 p-0 inputcontrasts"
              style={{ height: "44px" }}
              value={question.Type ? question.Type : ""}
              open={selectOpen}
              onClose={() => setSelectOpen(false)}
              onOpen={() => setSelectOpen(true)}
              onChange={(e) => handleQuestionType(e)}
            >
              <MenuItem value="radio">Select One</MenuItem>
              <MenuItem value="text">
                  <Box className="talent-poolFilter question-type-align">Answer Box
                    <Box>(Candidate can enter)</Box>
                  </Box>
              </MenuItem>
              <MenuItem value="checkbox">Options</MenuItem>
              {props.isTalentPoolQuestions && <MenuItem value="file">Attachment</MenuItem>}
            </Select> */}
            <Select
              name="answer-type-menu"
              //inputProps={{ "aria-label": "With label" }}
              className={`form-control input-group mb-0 pl-0 pr-0 inputcontrasts ${question.Type === "file" ? "pt-10 pb-10" : "pt-12 pb-12"}`}
              // style={{ height: "44px" }}
              value={question.Type ? question.Type : ""}
              onChange={(e) => handleQuestionType(e)}
              // value={question.Type ? question.Type : ""}
              // open={selectOpen}
              // onClose={() => setSelectOpen(false)}
              // onOpen={() => setSelectOpen(true)}
              // onChange={(e) => handleQuestionType(e)}
            // displayEmpty
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
            >
              {/* <MenuItem value= "" >--Select One--</MenuItem> */}
              <MenuItem value="radio">
                <Box className="d-block">
                  <Box className="d-flex">
                    <RadioIcon
                      className="cursor-hover mr-5"
                      width="20px" />
                    <Box className="f-6 gray10">Single Selection</Box>
                  </Box>
                  <Box className="f-14 gray7 d-flex mt-5 ml-25 white-space" id="select-none">
                    This allows candidates to choose only one option from a list of choices.
                  </Box>
                </Box>
              </MenuItem>
              <MenuItem value="checkbox" >
              <Box className="d-block">
                  <Box className="d-flex">
                    <CheckboxIcon
                      className="cursor-hover mr-5"
                      width="20px" />
                    <Box className="f-6 gray10">Multiple Selection</Box>
                  </Box>
                  <Box className="f-14 gray7 d-flex mt-5 ml-25 white-space" id="select-none">
                    This allows candidates to choose multiple options from a list of choices.
                  </Box>
                </Box>
              </MenuItem>
              <MenuItem value="text">
              <Box className="d-block">
                  <Box className="d-flex">
                    <AnswerboxIcon
                      className="cursor-hover mr-5"
                      width="20px" />
                    <Box className="f-6 gray10">Answer Box</Box>
                  </Box>
                  <Box className="f-14 gray7 d-flex mt-5 ml-25 white-space" id="select-none">
                    This allows candidates to input a single response in a text box.
                  </Box>
                </Box>
              </MenuItem>
              {props.isTalentPoolQuestions && <MenuItem value="file">
              <Box className="d-block">
                <Box className="d-flex">
                  <AttachmentIcon
                    className="cursor-hover mr-5"
                    width="15px" />
                  <Box className="f-6 gray10">Attachment</Box>
                </Box>
                <Box className="f-14 gray7 d-flex ml-25 white-space" id="select-none">
                  This allows candidates to upload multiple files in various formats like PDF, PPT, XLS, XLSX, DOC, DOCX and TXT.
                </Box>
              </Box>
              </MenuItem>}
            </Select>
            {/* {question.Type === "radio" || question.Type === "checkbox"
              ? !props.preview && (
                  <Typography
                    className="link-color font-weight-400 f-14 justify-end d-flex mt-10"
                    onClick={addEmptyOption}
                    onKeyPress={addEmptyOption}
                    tabIndex={0}
                  >
                    Add Options +
                  </Typography>
                )
              : ""} */}
          </Typography>
        </Grid>

        {/* <Grid item lg={1} md={1} xs={2}>
          <Typography
            className="question-type"
            onClick={() => props.deleteQuestion(props.index)}
            tabIndex={0}
            onKeyPress={() => props.deleteQuestion(props.index)}
          >
            <img style={{ width: "10px", cursor: "pointer" }} src={closeIcon} alt="img" tabIndex={0} />
          </Typography>
        </Grid> */}
        <Grid item lg={12} md={12} xs={12} className="border-top-gray5 mt-15">
          <Typography className={((props.index + props.defaultQuestionsLength) < props.questions?.length - 1) ? "justify-end d-flex" : "justify-end d-flex"}>
            <Box className="d-flex align-center">
              <Typography
                align="left"
                className="switch-case-section"
              >
                <span className="f-14 gray7 font-weight-400 mr-5">Required</span>
                <Switch
                  checked={question.required}
                  onChange={(e) => {
                    let ques = { ...question, required: e.target.checked };
                    setQuestion(ques);
                    handleChange(ques);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                  className="switch-position"
                />
              </Typography>
              <Box className="d-flex">
                <DeleteIcon
                  className="cursor-hover ml-10 screening-del-align"
                  width="20px"
                  onClick={() => props.deleteQuestion(props.index)}
                  onKeyPress={() => props.deleteQuestion(props.index)}
                  aria-label="Delete icon"
                />
              </Box>
            </Box>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
