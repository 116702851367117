import {
  Card, CardActionArea, CardContent, CardMedia, Grid, Typography
} from "@mui/material";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import Business from "../../assets/images/launch-page-images/Businessman.png";
import Cloud from "../../assets/images/launch-page-images/cloud.png";
import Freelancer from "../../assets/images/launch-page-images/freelancer.png";
import GoalImage from "../../assets/images/launch-page-images/goal-image.png";
import IdCard from "../../assets/images/launch-page-images/id-card.png";
import MobileRecruitment from "../../assets/images/launch-page-images/mobile-recruitment.png";
import SectionImage from "../../assets/images/launch-page-images/section-image.png";
import Skill from "../../assets/images/launch-page-images/skill.png";
import Constants from "../../helpers/constants";

export default function SimplePowerAffordable() {
  let navigate = useNavigate();

  return (
    <Grid
      item
      lg={12}
      md={12}
      xs={12}
      sx={{ py: 3, mt: 3 }}
      className="simple-background-section"
    >
      <Grid item lg={12} md={12} xs={12} className="simple-section">
        <Grid item lg={12} md={12} xs={12} className="inner-simple-section">
          <Grid item lg={12} md={12} xs={12} className="align-item-center">
            <h1 className="c-white text-center">
              Simple, powerful, affordable
            </h1>
          </Grid>

          <Grid item lg={12} md={12} xs={12} className="align-item-center">
            <p className="c-white text-center f-18">
              We make finding <strong>top talent</strong> simple.&nbsp; It’s
              about having the <strong>greatest reach</strong> into the talent
              marketplace, matching the <strong>best candidate profiles</strong>
              , easy <strong>screening</strong>, leveraging the latest{" "}
              <strong>AI and chatbot tools</strong> to avoid tedious admin
              tasks, and designing apps for <strong>mobile</strong> use to meet
              the needs of a flexible workforce.
            </p>
          </Grid>
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          xs={12}
          className="align-item-center responsive-block"
          sx={{ px: 4 }}
        >
          <Grid item lg={4} md={12} xs={12} className="card-section">
            <Card className="simple-card-section launch-page-card" sx={{ maxWidth: 345 }} >
              <CardActionArea  >
                <CardMedia
                  data-aos="fade-down"
                  className="p-20"
                  component="img"
                  height="150"
                  src={MobileRecruitment}
                  alt="Candidate experience"
                  style={{ objectFit: "contain", marginTop: "22px" }}
                />
                <CardContent>
                  <Typography className="c-white f-25 text-center">
                    No.1 in Candidate experience
                  </Typography>
                  <Typography className="c-white f-16 text-center pt-20">
                    Our design ethos is to focus on candidate experience and
                    make sure the design of every step, form, and click goes to
                    creating an awesome app that candidates will keep coming
                    back to.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item lg={4} md={12} xs={12} className="card-section">
            <Card className="simple-card-section launch-page-card" sx={{ maxWidth: 345 }}>
              <CardActionArea  >
                <CardMedia
                  data-aos="fade-down"
                  className="p-20"
                  component="img"
                  height="150"
                  src={SectionImage}
                  alt="AI-powered"
                  style={{ objectFit: "contain" }}
                />
                <CardContent>
                  <Typography className="c-white f-25 text-center">
                    AI-powered matching and Screening
                  </Typography>

                  <Typography className="c-white f-16 text-center pt-20">
                    We recommend the best candidates based on past search
                    history, current availability, location, skills, previous
                    projects, job history, and more.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item lg={4} md={12} xs={12} className="card-section">
            <Card className="simple-card-section launch-page-card" sx={{ maxWidth: 345 }}>
              <CardActionArea  >
                <CardMedia
                  data-aos="fade-down"
                  className="p-20"
                  component="img"
                  height="150"
                  src={GoalImage}
                  alt="Careers sites"
                  style={{ objectFit: "contain", marginTop: "22px" }}
                />
                <CardContent>
                  <Typography className="c-white f-25 text-center">
                    Careers sites to attract top Talent
                  </Typography>

                  <Typography className="c-white f-16 text-center pt-20">
                    One of our stand-out features comes from an ability to help
                    businesses tailor their own career sites. Build landing
                    pages for your talent audience by location, industry, job
                    category, etc.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          xs={12}
          className="align-item-center responsive-block"
          sx={{ px: 4 }}
        >
          <Grid item lg={4} md={12} xs={12} className="card-section">
            <Card className="simple-card-section launch-page-card" sx={{ maxWidth: 345 }}>
              <CardActionArea  >
                <CardMedia
                  data-aos="fade-down"
                  className="p-20"
                  component="img"
                  height="150"
                  src={Freelancer}
                  alt="User Centric"
                  style={{ objectFit: "contain" }}
                />
                <CardContent>
                  <Typography className="c-white f-25 text-center">
                    User Centric Experiences
                  </Typography>

                  <Typography className="c-white f-18 text-center pt-20">
                    We know how important user experience is to encourage
                    platform adoption and day-to-day use. That’s why we do
                    everything to minimize data entry and maximize the use of
                    personalizable charts, dashboards, and notifications.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item lg={4} md={12} xs={12} className="card-section">
            <Card className="simple-card-section launch-page-card" sx={{ maxWidth: 345 }}>
              <CardActionArea  >
                <CardMedia
                  data-aos="fade-down"
                  className="p-20"
                  component="img"
                  height="150"
                  src={Skill}
                  alt="Productivity"
                  style={{ objectFit: "contain" }}
                />
                <CardContent>
                  <Typography className="c-white f-25 text-center">
                    Productivity built-in
                  </Typography>

                  <Typography className="c-white f-16 text-center pt-20">
                    We’ve stripped back every step in the talent sourcing and
                    management process and applied new thinking. We’ve cut out
                    the human in the loop where humans bring no value and
                    released time to better serve areas where they do. That
                    means less form filling and unnecessary emails, and more AI
                    and chatbots.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item lg={4} md={12} xs={12} className="card-section">
            <Card className="simple-card-section launch-page-card" sx={{ maxWidth: 345 }}>
              <CardActionArea  >
                <CardMedia
                  data-aos="fade-down"
                  className="p-20"
                  component="img"
                  height="150"
                  src={IdCard}
                  alt="Safe for data"
                  style={{ objectFit: "contain", marginTop: "-37px" }}
                />
                <CardContent>
                  <Typography className="c-white f-25 text-center">
                    Safe for data, and personal Information
                  </Typography>

                  <Typography className="c-white f-16 text-center pt-20">
                    We take data security and privacy seriously. That’s why
                    we’ve designed {Constants.APP_NAME_GENERAL} to meet your data privacy
                    expectations by design. Additionally, we are an ISO 27002
                    compliant organization.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          xs={12}
          className="align-item-center responsive-block"
          sx={{ px: 4 }}
        >
          <Grid item lg={4} md={12} xs={12} className="card-section">
            <Card className="simple-card-section launch-page-card" sx={{ maxWidth: 345 }}>
              <CardActionArea  >
                <CardMedia
                  data-aos="fade-down"
                  className="p-20"
                  component="img"
                  height="150"
                  src={Skill}
                  alt="Innovation advantage"
                  style={{ objectFit: "contain" }}
                />
                <CardContent>
                  <Typography className="c-white f-25 text-center">
                    Innovation advantage
                  </Typography>

                  <Typography className="c-white f-16 text-center pt-20">
                    We embrace the latest innovation in tech. Our strong partner
                    network and a commitment to our customers makes it easy to
                    connect to the best technology. Our cloud platform and tight
                    platform version controls ensure regular updates to your
                    platform with no down-time.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item lg={4} md={12} xs={12} className="card-section">
            <Card className="simple-card-section launch-page-card" sx={{ maxWidth: 345 }}>
              <CardActionArea >
                <CardMedia
                  data-aos="fade-down"
                  className="p-20"
                  component="img"
                  height="150"
                  src={Cloud}
                  alt="Instant cloud"
                  style={{ objectFit: "contain", marginTop: "-22px" }}
                />
                <CardContent>
                  <Typography className="c-white f-25 text-center">
                    Instant cloud deployment
                  </Typography>

                  <Typography className="c-white f-16 text-center pt-20">
                    Our cloud technology is platform agnostic and embraces the
                    latest advances in data security, scalability, replication,
                    and serviceability. A global footprint of data centers
                    ensures that {Constants.APP_NAME_GENERAL} is instantly accessible, anywhere in
                    the world.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item lg={4} md={12} xs={12} className="card-section">
            <Card className="simple-card-section launch-page-card" sx={{ maxWidth: 345 }}>
              <CardActionArea  >
                <CardMedia
                  data-aos="fade-down"
                  className="p-20"
                  component="img"
                  height="150"
                  src={Business}
                  alt="World Class Support"
                  style={{ objectFit: "contain" }}
                />
                <CardContent>
                  <Typography className="c-white f-25 text-center">
                    World Class Support
                  </Typography>

                  <Typography className="c-white f-18 text-center pt-20">
                    We think you’ll notice the difference when working with the
                    team at {Constants.APP_NAME_GENERAL}. Whether it’s the quality of our training
                    and support resources, you’re sure to notice how responsive
                    we are.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
