import React, { useContext, useEffect, useState } from "react";
import Loader from "../Loader";
import ApiCalls from "../../api/customAxios";


const MicrosoftToken = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  useEffect(() => {
    const handleBeforeUnload = () => {
      window.opener.postMessage('childWindowClosed', '*');
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  useEffect(() => {
    const paramsObject = {};
    for (const [key, value] of urlSearchParams.entries()) {
      paramsObject[key] = value;
    }
    ApiCalls.put("/microsoft/access", paramsObject)
      .then((resp) => {
        window.close();
      })
      .catch((err) => {});
  }, []);
  return <Loader />;
};

export default MicrosoftToken;
