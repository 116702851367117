// CookieConsent.js
import { Box, Button, Grid, Modal, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import { useLocation } from 'react-router-dom';
import KeycloakService from "./keycloak";
import { ReactComponent as CookieIcon } from "./assets/images/cookie-policy-icon.svg";
import { extractValueFromCookie, saveCookie } from './helpers/helper-data';
import { checkForCookiePopUp } from './helpers/helper';
import { useModal } from './ModalContext';
const getCookie = (name) => {
    const matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  };
  // Function to set a cookie
  const setCookie = (name, value, options = {}) => {
    options = {
      path: '/',
      ...options
    };
    if (options.expires instanceof Date) {
      options.expires = options.expires.toUTCString();
    }
    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
    for (let optionKey in options) {
        if (options.hasOwnProperty(optionKey)) {
          updatedCookie += "; " + optionKey;
          let optionValue = options[optionKey];
          if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
          }
        }
      }
    document.cookie = updatedCookie;
  };

  const CookieConsent = () => {
    const [show, setShow] = useState(false);
    const location = useLocation();
  const { openModal, closeModal } = useModal();
    useEffect(() => {
      if (checkForCookiePopUp(location) && !getCookie("cookieConsentEnterprise")) {
        const urlParams = new URLSearchParams(window.location.search);
        // if (urlParams.has('cookieConsentEnterprise')) {
        //       // setCookie('cookieConsentEnterprise', 'accepted', { path: '/', maxAge: 365 * 24 * 60 * 60 });
        //       saveCookie("cookieConsentEnterprise", "accepted", 365);
        //       const currentUrl = new URL(window.location.href);
        //         currentUrl.searchParams.set('cookieConsentEnterprise', 'true');
        //         const modifiedUrl = currentUrl.toString();
        //         history.replaceState(null, '', modifiedUrl);
        //   } else {
          if (location.pathname == "/") {
            setTimeout(() => {
              setShow(true);
              openModal(true);
            }, 1200);
          } else {
            setShow(true);
          openModal(true);
        }
          // }
      }
    }, [location]);

    const handleAccept = () => {
      saveCookie("cookieConsentEnterprise", "accepted", 365);
      setShow(false);
      closeModal();
    };

    const handleClose = () => {
      setShow(false);
    };

    const handleOpenViewDetails = () => {
      window.open(`/cookiepolicy`);
    };
  return (
    <>
    <Modal
    className=""
    open={show}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    id="upload-logo-image-modal"
  >
    <Box className="modal-content inputcontrasts" style={{ width: "700px" }}>
    <Grid container id="modal-title">
        <Grid item xs={8} className="d-flex align-center">
          <Box className="d-flex">
            <CookieIcon  width="25px" height="25px" />
          </Box>
          <Typography className="f-20 font-weight-600 zoom-font heading-text-color ml-10">
           Cookie Policy
          </Typography>
        </Grid>
      </Grid>
                  <Grid id="modal-description">
                      <Typography className="m-l-5 f-14 font-weight-400 sub-text-color mt-5">
                      We use cookies and similar technologies for technical purposes and, with your consent, to enhance functionality and user experience as outlined in our cookie policy. Click "View Details" to read the full policy. Use the "Accept Essential Cookies" button to consent.
                      </Typography>
                  </Grid>
        <Grid id="modal-footer"  className="justify-end display-flex mt-30">
                    <Typography className={`display-flex align-center ${window.location.pathname === "/" ? "cookie-btn" : ""}`}>
                        {/* <Typography className="link-color f-14 font-weight-400 mr-20" onClick={handleReset}>Reset</Typography> */}
                        <Button
                            className="btn secondary-button m-r-15"
                            onClick={handleOpenViewDetails}
                            tabIndex={0}
                            disableRipple="true"
                        >
                            View Details
                        </Button>
                        <Button
                            className="btn primary-button"
                            onClick={handleAccept}
                            tabIndex={0}
                            disableRipple="true"
                        >
                            Accept Essential Cookies
                        </Button>
                    </Typography>
                    </Grid>
    </Box>
  </Modal>
  </>
  );
};
export default CookieConsent;
