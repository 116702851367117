import * as React from "react";
import { styled,
  AppBar,
  Box,
  CssBaseline,
  Toolbar,
  Typography,
  IconButton,
  Paper,
  Fab,
  List,
  Menu,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Avatar,
  Grid,
  Button,
  ClickAwayListener,
  Grow,
  Popper,
  MenuItem,
  MenuList,
  Stack } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import MoreIcon from "@mui/icons-material/MoreVert";
import Navbar from "../LaunchPage/Navbar";
import { Link } from "react-router-dom";
import logoimgnew from "../../assets/images/logo-new.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const messages = [
  {
    id: 1,
    primary: "Brunch this week?",
    secondary:
      "I'll be in the neighbourhood this week. Let's grab a bite to eat",
    person: "/static/images/avatar/5.jpg",
  },
  {
    id: 2,
    primary: "Birthday Gift",
    secondary: `Do you have a suggestion for a good present for John on his work
      anniversary. I am really confused & would love your thoughts on it.`,
    person: "/static/images/avatar/1.jpg",
  },
  {
    id: 3,
    primary: "Recipe to try",
    secondary:
      "I am try out this new BBQ recipe, I think this might be amazing",
    person: "/static/images/avatar/2.jpg",
  },
  {
    id: 4,
    primary: "Yes!",
    secondary: "I have the tickets to the ReactConf for this year.",
    person: "/static/images/avatar/3.jpg",
  },
  {
    id: 5,
    primary: "Doctor's Appointment",
    secondary:
      "My appointment for the doctor was rescheduled for next Saturday.",
    person: "/static/images/avatar/4.jpg",
  },
  {
    id: 6,
    primary: "Discussion",
    secondary: `Menus that are generated by the bottom app bar (such as a bottom
      navigation drawer or overflow menu) open as bottom sheets at a higher elevation
      than the bar.`,
    person: "/static/images/avatar/5.jpg",
  },
  {
    id: 7,
    primary: "Summer BBQ",
    secondary: `Who wants to have a cookout this weekend? I just got some furniture
      for my backyard and would love to fire up the grill.`,
    person: "/static/images/avatar/1.jpg",
  },
];

const StyledFab = styled(Fab)({
  position: "absolute",
  zIndex: 1,
  top: -30,
  left: 0,
  right: 0,
  margin: "0 auto",
});

export default function NewNavBar() {
  const [anchorElInsights, setIbsightsAnchorEl] = React.useState(null);
  const [anchorElAbout, setAboutAnchorEl] = React.useState(null);
  const [anchorElPlatform, setPlatformAnchorEl] = React.useState(null);
  const openAbout = Boolean(anchorElAbout);
  const openInsights = Boolean(anchorElInsights);
  const openPlatform = Boolean(anchorElPlatform);

  const handleClickInsights = (event) => {
    setIbsightsAnchorEl(event.currentTarget);
  };
  const handleCloseInsights = () => {
    setIbsightsAnchorEl(null);
  };
  const handleClickAbout = (event) => {
    setAboutAnchorEl(event.currentTarget);
  };
  const handleCloseAbout = () => {
    setAboutAnchorEl(null);
  };
  const handleClickPlatform = (event) => {
    setPlatformAnchorEl(event.currentTarget);
  };
  const handleClosePlatform = () => {
    setPlatformAnchorEl(null);
  };
  return (
    <>
      <Navbar />

      <Grid itemclassName="sub-navbar">
        <Grid item lg={12} className="d-flex">
          <Grid item lg={5}>
            <Link to="/">
              <img className="logoImg" src={logoimgnew} alt="Home" />
            </Link>
          </Grid>
          <Grid item lg={7} className="align-item-right">
            <Typography className="color-info align-item-center px-10">
              Home
            </Typography>
            <Typography
              id="demo-positioned-button"
              aria-controls={openPlatform ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openPlatform ? "true" : undefined}
              onClick={handleClickPlatform}
              onKeyPress={handleClickPlatform}
              className="align-item-center px-10 launch-page-heading-color cursor-hover"
              tabIndex={0}
            >
              Platform <KeyboardArrowDownIcon aria-label="Arrow icon" />
            </Typography>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorElPlatform}
              open={openPlatform}
              onClose={handleClosePlatform}
            >
              <MenuItem onClick={handleClosePlatform} onKeyPress={handleClosePlatform} tabIndex={0}>
                Candidate Experirnce
              </MenuItem>
              <MenuItem onClick={handleClosePlatform} onKeyPress={handleClosePlatform} tabIndex={0}>
                Hirer Experience
              </MenuItem>
            </Menu>
            <Typography
              id="demo-positioned-button"
              aria-controls={openInsights ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openInsights ? "true" : undefined}
              onClick={handleClickInsights}
              onKeyPress={handleClickInsights}
              className="align-item-center px-10 launch-page-heading-color cursor-hover"
              tabIndex={0}
            >
              Insights <KeyboardArrowDownIcon aria-label="Arrow icon" />
            </Typography>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorElInsights}
              open={openInsights}
              onClose={handleCloseInsights}
            >
              <MenuItem onClick={handleCloseInsights} onKeyPress={handleCloseInsights} tabIndex={0}>
                Flexible Workforce
              </MenuItem>
              <MenuItem onClick={handleCloseInsights} onKeyPress={handleCloseInsights} tabIndex={0}>
                AI in Recruitment
              </MenuItem>
              <MenuItem onClick={handleCloseInsights} onKeyPress={handleCloseInsights} tabIndex={0}>Direct Sourcing</MenuItem>
              <MenuItem onClick={handleCloseInsights} onKeyPress={handleCloseInsights} tabIndex={0}>
                Statement of Work
              </MenuItem>
              <MenuItem onClick={handleCloseInsights} onKeyPress={handleCloseInsights} tabIndex={0}>Talent Portals</MenuItem>
              <MenuItem onClick={handleCloseInsights} onKeyPress={handleCloseInsights} tabIndex={0}>
                Total Talent Management
              </MenuItem>
            </Menu>

            <Typography
              id="demo-positioned-button"
              aria-controls={openAbout ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openAbout ? "true" : undefined}
              onClick={handleClickAbout}
              onKeyPress={handleClickAbout}
              tabIndex={0}
              className="align-item-center px-10 launch-page-heading-color cursor-hover"
            >
              About <KeyboardArrowDownIcon aria-label="Arrow icon" />
            </Typography>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorElAbout}
              open={openAbout}
              onClose={handleCloseAbout}
            >
              <MenuItem onClick={handleCloseAbout} onKeyPress={handleCloseAbout} tabIndex={0}>Diversity</MenuItem>
            </Menu>
            <Typography className="align-item-center px-10">
              <button className="btn demo-button" aria-label="Book demo" disableRipple="true">Book Demo</button>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>

    /* <React.Fragment>
             <CssBaseline />
             <Paper square sx={{ pb: '50px' }}>
                 <Typography variant="h5" gutterBottom component="div" sx={{ p: 2, pb: 0 }}>
                     Inbox
                 </Typography>
                 <List sx={{ mb: 2 }}>
                     {messages.map(({ id, primary, secondary, person }) => (
                         <React.Fragment key={id}>
                             {id === 1 && (
                                 <ListSubheader sx={{ bgcolor: 'background.paper' }}>
                                     Today
                                 </ListSubheader>
                             )}
                             {id === 3 && (
                                 <ListSubheader sx={{ bgcolor: 'background.paper' }}>
                                     Yesterday
                                 </ListSubheader>
                             )}
                             <ListItem button>
                                 <ListItemAvatar>
                                     <Avatar alt="Profile Picture" src={person} />
                                 </ListItemAvatar>
                                 <ListItemText primary={primary} secondary={secondary} />
                             </ListItem>
                         </React.Fragment>
                     ))}
                 </List>
             </Paper>
             <AppBar position="static">
                 <Toolbar>
                     <IconButton color="inherit" aria-label="open drawer">
                         <MenuIcon />
                     </IconButton>
                     <StyledFab color="secondary" aria-label="add">
                         <AddIcon />
                     </StyledFab>
                     <Box sx={{ flexGrow: 1 }} />
                     <IconButton color="inherit">
                         <SearchIcon />
                     </IconButton>
                     <IconButton color="inherit">
                         <MoreIcon />
                     </IconButton>
                 </Toolbar>
             </AppBar>
         </React.Fragment>*/
  );
}
