import React from "react";
import { Grid, Typography, Box } from "@mui/material";
// import noCandidate from "../../assets/images/no-result-img.svg";
import noCandidate from "../../assets/images/Candidatepagenoresultfound.png";

export default function NoJobsImage() {
  return (
    <>
      <Grid item>
        <Grid container item spacing={1} lg={12} md={12} xs={12}>
          <Grid item lg={6} md={6} xs={12} className="m-auto">
            <Typography className="text-center" sx={{ mt: 8 }}>
              <Box>
                <img width="300px" height="300px" className="profile-video-thumbnail" src={noCandidate} alt="img" />
              </Box>
              <Box className="gray10 font-weight-500 f-16 mt-5">
                 Oops! We could not find any results for you
              </Box>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
