import {
  Grid,
  InputAdornment,
  Link,
  Modal,
  TextField,
  Typography,
  Box,
  Button,
  IconButton
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import ClearIcon from "@mui/icons-material/Clear";
import AccessService from "../../../Services/Enterprise/Access.service";
import { success } from "../../../helpers/message-box";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip, TooltipTrigger, TooltipContent } from 'react-tippy';
const Constant = require("../../../helpers/constants");

const MspInviteModal = (props) => {
  const { open, close } = props;
  Yup.addMethod(Yup.string, "emailDomain", function (message) {
    return this.test("email-domain", message, function (value) {
      const domains = [
        "gmail.com",
        "yahoo.com",
        "outlook.com",
        "hotmail.com",
        "aol.com",
        "protonmail.com",
        "zoho.com",
        "mail.com",
        "gmx.com",
        "icloud.com",
        "yandex.com",
        "hushmail.com",
        "tutanota.com",
        "fastmail.com",
        "mailinator.com",
        "guerrillamail.com",
        "inbox.com",
        "startmail.com",
        "tutamail.com",
        "runbox.com",
        "luxsci.com",
        "mail.ru",
        "rediffmail.com",
        "hush.com",
        "mailfence.com",
        "openmailbox.org",
        "posteo.de",
        "scryptmail.com",
        "riseup.net",
        "zoho.eu",
        "disroot.org",
        "kolabnow.com",
        "gandi.net",
        "mailbox.org",
        "yopmail.com",
        "sharklasers.com",
        "cock.li",
        "torguard.net",
        "vfemail.net",
        "soverin.net",
        "migadu.com",
        "anonaddy.com",
        "fastmail.fm",
        "unseen.is",
        "safe-mail.net",
        "fastmail.net",
        "33mail.com",
        "vivaldi.net",
      ];
      const domain = value.split("@")[1];
      return !domains.includes(domain);
    });
  });
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("The email is required")
      .max(128, "Exceeded maximum character length of 128")
      .matches(Constant.EMAIL_REGEX, "Please enter valid email address")
      .emailDomain("Please use a business email"),
  });
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isValid, isDirty },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const onEmailSubmit = (data) => {
    const InviteObj = {
      enterpriseUserEmailAddress: data.email,
    };
    AccessService.userMspInvite(InviteObj).then((response) => {
      if (response == true) {
        success("Invite sent successfully.");
        setValue("email", "");
        close();
      }
    });
  };
  return (
    <Modal
      open={open}
      onClose={close}
      id="modal"
      aria-labelledby="Add-User"
      aria-describedby="modal-modal-description"
    >
      <form onSubmit={handleSubmit(onEmailSubmit)}>
        <Grid className="modal-content inputcontrasts">
          <Grid container item id="modal-title">
            <Grid item xs={11}>
              <Typography
                variant="h5"
                className="f-24 font-weight-700 heading-text-color add-user-enterprise align-center d-flex"
              >
                Send invite to enterprise
                <Tooltip
                  position="right"
                  title="A request to partner email will be sent"
                  trigger="mouseenter"
                  animation="fade"
                  arrow
                >
                  <IconButton aria-label="Info icon" className="pr-0 mt-2">
                    <InfoIcon aria-label="Info icon" />
                  </IconButton>
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography
                align="right"
                className="cursor-hover gray7 icon-button"
              >
                <CloseIcon
                  tabIndex={0}
                  aria-label="close icon"
                  onClick={close}
                />
              </Typography>
            </Grid>
          </Grid>
          <Grid
            style={{ height: 150, maxHeight: 200 }}
            id="modal-description"
            className="mt-10 mb-12"
          >
            <Grid container item spacing={1} className="add-user-enterprise">
              <Grid item lg={12} md={12} xs={12} className="pl-5 pt-0">
                <Box style={{ position: "relative" }}>
                  <Box className="d-flex justify-between">
                    <Box className="small-text-font mb-0 font-weight-500 mt-0">
                      Send To<span className="text-black">*</span>
                    </Box>
                  </Box>
                  <TextField
                    id="emailinputtype"
                    //   inputRef={inputRef}
                    type="text"
                    name="email"
                    placeholder="Enter email address"
                    //   onKeyPress={handleEnterKey}
                    //   onInput={handleEnterKey}
                    //   onClick={handleEnterKey}
                    //   onPaste={handlePaste}
                    {...register("email")}
                    className={`form-control input-group mb-0 p-0 position-issue inputcontrasts`}
                  //   InputProps={{
                  //     endAdornment:
                  //       <InputAdornment position="end">
                  //         <ClearIcon
                  //           onClick={() => {
                  //           }}
                  //           className="cursor-hover f-15"
                  //           aria-label="Clear icon"
                  //         />
                  //       </InputAdornment>
                  //   }}
                  />
                  <span className="danger-color error-msg">
                    {" "}
                    {errors.email && errors.email.message}
                  </span>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            id="modal-footer"
            className="justify-center d-flex align-center"
          >
            <Button
              type="submit"
              className="btn primary-button mt-10"
              variant="contained"
              disabled={!isDirty || !isValid}
              aria-label="Send"
              disableRipple="true"
            >
              Send
            </Button>
          </Grid>
        </Grid>
      </form>
    </Modal>
  );
};

export default MspInviteModal;
