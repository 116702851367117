import ApiCalls from "../../api/customAxios";
class InterviewServiceClass {
  interviewListUrl = `${process.env.REACT_APP_API_URL}enterprises/interviews/`;
  interviewDetailsUrl = `${process.env.REACT_APP_API_URL}enterprises/interviews/`;

  async getInterviewList(pageNumber, pageSize, sortByInterview, data) {
    return ApiCalls.put(
      `${this.interviewListUrl}${pageNumber}/${pageSize}?sortByInterview=${sortByInterview}`,
      data
    );
  }
  async getInterviewDetails(interviewId) {
    return ApiCalls.get(`${this.interviewDetailsUrl}${interviewId}`);
  }
  async getCandidateList(keyword) {
    return await ApiCalls.get(
      this.interviewListUrl + `search/candidate?keyword=${keyword}`
    );
  }
  async getJobTitles(keyword) {
    return await ApiCalls.get(
      this.interviewListUrl + `search/jobTitle?keyword=${keyword}`
    );
  }
  async getJobManagerList(keyword) {
    return await ApiCalls.get(
      this.interviewListUrl + `search/jobManager?keyword=${keyword}`
    );
  }
}

const InterviewService = new InterviewServiceClass();
export default InterviewService;
