import axios from "axios";
import { authHeader } from "../../helpers/auth-header";
import ApiCalls from "../../api/customAxios";
class ReferralServiceClass {
  createReferralUrl = process.env.REACT_APP_API_URL + "enterprises/referrals";
  getReferralUrl = process.env.REACT_APP_API_URL + "enterprises/referrals";
  emailValidation = process.env.REACT_APP_API_URL + "candidates/referrals/validate-email";
  phoneValidation = process.env.REACT_APP_API_URL + "candidates/referrals/validate-mobile-number";
  searchBarUrl = process.env.REACT_APP_API_URL.replace("api/v1/", "public/api/v1/") + "enterprises/";
  companyUrl = process.env.REACT_APP_API_URL.replace("api/v1/", "public/api/v1/") + "enterprises?keyword=";
  jobTitleUrl = process.env.REACT_APP_API_URL + "candidates/job-titles/";
  referredByUrl = process.env.REACT_APP_API_URL + "enterprises/user/matching-enterprise-user-names/";
  referral_count = process.env.REACT_APP_API_URL + "enterprises/referrals/count";
  talentPoolUrl = process.env.REACT_APP_API_URL + "enterprises/talentPool/matching-names/";
  async createReferral(payload) {
    return ApiCalls.put(this.createReferralUrl, payload);
  }
  async getReferral(pageNumber, pageSize, sortByReferralDate, data, keyword) {
    if (keyword !== "") {
      data.searchString = keyword;
    }
    return ApiCalls.getDetailedResponse(`${this.getReferralUrl}/${pageNumber}/${pageSize}?sortByReferralDate=${sortByReferralDate}`, data);
  }
  async companySearch(query) {
    return ApiCalls.get(this.companyUrl + query);
  }
  async searchBar(query) {
    return ApiCalls.get(this.searchBarUrl + `search?` + query);
  }
  async jobTitleSearch(query) {
    return ApiCalls.get(this.jobTitleUrl + query);
  }
  async talentPoolSearch(query) {
    return ApiCalls.get(this.talentPoolUrl + query);
  }
  async referredBySearch(query) {
    return ApiCalls.get(this.referredByUrl + query);
  }
  async referredForSearch(query) {
    return ApiCalls.get(this.referredByUrl + query);
  }
  async validateEmail(mail, jobId, queryParam) {
    let query = "";
    if (queryParam == "jobId") {
      query = "jobId";
    } else {
      query = "talentPoolId";
    }
    return ApiCalls.get(`${this.emailValidation}?emailAddress=${mail}&${queryParam}=${jobId}`);
  }
  async validatePhone(number, jobId, queryParam) {
    let query = "";
    if (queryParam == "jobId") {
      query = "jobId";
    } else {
      query = "talentPoolId";
    }
    return ApiCalls.get(`${this.phoneValidation}?mobileNumber=${number}&${queryParam}=${jobId}`);
  }
  async referralCount() {
    return ApiCalls.get(this.referral_count);
  }
}

const ReferralService = new ReferralServiceClass();
export default ReferralService;
