import * as React from "react";
import {
  Typography,
  Grid,
  Box,
  InputAdornment,
  TextField,
  Button,
  MenuItem,
  Select,
  Menu,
  useMediaQuery,
  Toolbar,
  CssBaseline,
  Drawer,
  styled, useTheme,
  AppBar as MuiAppBar,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as AnalyticsActiveIcon } from "../../assets/images/analytics-active-icon.svg";
import { ReactComponent as PortalWhiteIcon } from "../../assets/images/candidatePortal-white-Icon.svg";
import { ReactComponent as CampaignActiveIcon } from "../../assets/images/campaign-activeIcon.svg";
import { ReactComponent as AccessActiveIcon } from "../../assets/images/access-activeIcon.svg";
import { useEffect, useState } from "react";
import AccessService from "../../Services/Enterprise/Access.service";
import AnalyticsSideBar from "../EnterprisePortal/AnalyticsSideBar";
import { Tooltip, TooltipTrigger, TooltipContent } from 'react-tippy';
import { Outlet } from "react-router-dom";
import { ReactComponent as JobsWhiteIcon } from "../../assets/images/myJobs-WhiteIcon.svg";
import { ReactComponent as DashboardIcon } from "../../assets/images/dashboard-icon.svg";
import { ReactComponent as MyCandidatesWhiteIcon } from "../../assets/images/myCandidate-whiteIcon.svg";
import { ReactComponent as TalentPoolActiveIcon } from "../../assets/images/talent-pool-active-icon.svg";
import SwitchSideBar from "../EnterprisePortal/SwitchSidebar";
import { useSelector } from "react-redux";
const Menus = require("../../menu-control");
import { ReactComponent as InterviewWhiteIcon } from "../../assets/images/interviewWhiteIcon.svg";
import { ReactComponent as NewCareerPortalIcon } from "../../assets/images/NewCareerPortalIcon.svg";
import { danger } from "../../helpers/message-box";
import { saveCookie, extractValueFromCookie } from "../../helpers/helper-data";
import Loader from "../Loader";
import { AppContext } from "../../contexts/context";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function SideBar(props) {
  const location = useLocation();
  const URLPath = location.pathname;
  const appOrigin = useSelector((state) => state.location.origin);
  const enterpriseDetails = useSelector((state) => state.enterprise.enterprise);
  const [isEnterprsieMSP, setIsEnterprsieMSP] = useState(false);
  const [activeUser, setActiveUser] = useState(false);
  const [isWtw, setIsWtw] = useState("");
  const { setSuperAdminEntityObj } = React.useContext(AppContext);
  const indexesToRemove = [0, 1, 2, 3, 4, 5];
  const EnterpriseMenu = [
    {
      name: "Jobs",
      url: "/enterprise/JobList",
      icon: JobsWhiteIcon,
      role: activeUser ? ["All"] : Menus?.jobs,
      activeUrls: ["JobList", "jobList", "previewJob", "postJob", "screeningQuestions", "jobs", "matching-candidate-description", "admin-access", "schedule-interviews", "jobWizard"]
    },
    {
      name: "Talent Console",
      url: "/enterprise/talent-console",
      icon: DashboardIcon,
      role: activeUser ? [] : Menus?.dashboard,
      activeUrls: ["talent-console", "talent-console-candidates"]
    },
    {
      name: "Candidates",
      url: "/enterprise/my-candidate",
      icon: MyCandidatesWhiteIcon,
      role: activeUser ? [] : Menus?.candidates,
      activeUrls: ["my-candidate", "my-candidate"]
    },
    {
      name: "Campaign",
      url: "/enterprise/campaignList",
      icon: CampaignActiveIcon,
      role: activeUser ? [] : Menus?.campaign,
      activeUrls: ["campaignList", "createCampaign", "campaignDetail", "edit-campaign"]
    },
    {
      name: "Interviews",
      url: "/enterprise/interviewList",
      icon: InterviewWhiteIcon,
      role: activeUser ? [] : Menus?.interviews,
      activeUrls: ["interviewList", "interview-details", "schedule-interview"]
    },
    {
      name: "Talent Pool",
      url: "/enterprise/talent-pool-management",
      icon: TalentPoolActiveIcon,
      role: activeUser ? [] : Menus?.talentpool,
      activeUrls: ["talent-pool-management", "create-talent-pool", "talent-pool-candidate-list", "talent-pool", "talent-pool-request", "talent-pool-campaign"]
    },
    {
      name: "Career Portal",
      url: "Career Portal",
      icon: NewCareerPortalIcon,
      role: activeUser ? [] : Menus?.careerportal,
      activeUrls: []
    },
    {
      name: "Configuration Control",
      url: "/enterprise/access",
      icon: AccessActiveIcon,
      role: activeUser ? [] : Menus?.configurationcontrol,
      activeUrls: ["access"]
    },
    {
      name: "Analytics",
      url: "Analytics",
      icon: AnalyticsActiveIcon,
      role: activeUser ? [] : Menus?.analytics,
      activeUrls: ["Analytics"]
    }];
  const handleMenu = () => {
    setOpenAnalytics(true);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);

  const theme = useTheme();
  const [open, setOpen] = React.useState(
    localStorage.getItem("sidebarOpened")
      ? localStorage.getItem("sidebarOpened") === "true"
        ? true
        : false
      : true
  );
  const [openAnalytics, setOpenAnalytics] = React.useState(false);
  const [openSwitch, setOpenSwitch] = React.useState(false);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [enterpriseAccess, setEnterpriseAccess] = useState([]);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [childEnterprises, setChildEnterprises] = useState(null);
  const [openMspInviteModal, setMspInviteModal] = useState(false);
  const handleOpenMspInviteModal = () => setMspInviteModal(true);
  const [mspReponse, setMspResponse] = useState(null);
  const [userTypes, setUserTypes] = React.useState("");
  const [userTypesforChid, setUserTypesforChild] = React.useState("");
  const [enterpriseUser, setEnterpriseUser] = useState(localStorage.getItem("enterpriseId") ? localStorage.getItem("enterpriseId") : null);
  const [enterprisesList, setEnterprisesList] = useState(localStorage.getItem("enterprisesList") ? JSON.parse(localStorage.getItem("enterprisesList")) : []);
  const [isLoading, setIsLoading] = useState(false);
  const handleCloseMspInviteModal = () => {
    setMspInviteModal(false);
  };
  const navigate = useNavigate();

  const careerPortalSSOLogin = () => {
    let mspName = localStorage.getItem("mspEnterprsieData")
      ? JSON.parse(localStorage.getItem("mspEnterprsieData"))
      : null;
    setAnchorE2(null);
      window.open(
        `${
          process.env.REACT_APP_CAREERPORTAL_BASE_URL
        }/candidate/homepage?childEnterpriseId=${localStorage.getItem("childEnterpriseId")}&enterprise_id=${localStorage.getItem("enterpriseId")}&mspName=${
          mspName?.establishmentName
        }&appOrigin=${appOrigin}`,
        "_blank",
        "noopener,noreferrer"
      );
  };
  useEffect(() => {
    if (localStorage.getItem("enterpriseId")) {
      setEnterpriseUser(localStorage.getItem("enterpriseId"));
    }
  }, [localStorage.getItem("enterpriseId")]);
  useEffect(async () => {
    AccessService.getAllEnterprises().then(async(res) => {
      setEnterprisesList(res);
      const obj = {};
      res?.map((data) => {
        data?.enterprisesUsers?.forEach((user) => {
          if (user.emailAddress === localStorage.getItem("email")) {
            obj[data.id] = user.userType;
          }
        });
      });
      setSuperAdminEntityObj(obj);
      const lastLoggedInEnterprise = res?.length > 0 ? res?.find((item) => {
        return item.enterpriseId === extractValueFromCookie("enterprise_id", document.cookie);
      }) : [];
      if (lastLoggedInEnterprise) {
        setEnterpriseUser(lastLoggedInEnterprise?.id);
      }
      localStorage.setItem("enterprisesList", JSON.stringify(res));
    }).catch((err) => {
      if (err?.statusCode == 403) {
        setActiveUser(true);
      }
    });
    await AccessService.getEnterprises().then(async(res) => {
     await AccessService.getLoggedInUserDetails(localStorage.getItem("mspEnterpriseId") ? localStorage.getItem("mspEnterpriseId") : res.id).then(
        (response) => {
            setUserTypes(response.userType);
        },
        (error) => { }
      );
      setIsWtw(res.customConfigurations);
      setActiveUser(false);
      if (res?.isManagedServiceProvider === "Y") {
        setIsEnterprsieMSP(true);
      }
      if (res.childEnterprises && res.childEnterprises.length > 0) {
        setChildEnterprises(res.childEnterprises);
      }
    }).catch((err) => {
      if (err?.statusCode == 403) {
        setActiveUser(true);
      }
    });
  }, []);

  useEffect(()=>{
    if (!location.pathname.includes("talent-console")) {
      localStorage.removeItem("searchKeyword");
      localStorage.removeItem("searchLocations");
    };
    if (location.pathname != "/enterprise/Analytics" && localStorage.getItem("analyticsTitle")) {
      localStorage.removeItem("analyticsTitle");
    }
  }, [location.pathname]);

  function removeMultipleIndexes(arr, indexesToRemove) {
    let myArray = arr.filter((_, index) => !indexesToRemove.includes(index));
    return [myArray[0], myArray[1], myArray[2]] = [myArray[1], myArray[0], myArray[2]];
  };
  const handleNavigation = (url) => {
    localStorage.removeItem("keyword");
    localStorage.removeItem("MyJobsPageNo");
    localStorage.removeItem("filterStatus");
    localStorage.removeItem("filterType");
    if (url) {
      navigate(url);
    }
  };
  const handleSelectEnterprise = (event) => {
    setIsLoading(true);
    let currentUser = enterprisesList?.find((item) => {
      return item.id === event.target.value;
    });
      setEnterpriseUser(event.target.value);
      //Added REACT_APP_KEYCLOAK_REALM condition to execute the code only in prod because custom urls not working in other environment's
      if (currentUser?.customBaseUrl != null && localStorage.getItem("enterpriseId") != event.target.value && window.location.origin != currentUser?.customBaseUrl && process.env.REACT_APP_KEYCLOAK_REALM == "dsaas") {
        let id = btoa(event.target.value);
        window.location.href = `${currentUser?.customBaseUrl}/enterprise?enterpriseId=${id}`;
      } else if (localStorage.getItem("enterpriseId") != event.target.value) {
        saveCookie("enterprise_id", event.target.value, 365);
        localStorage.setItem("enterpriseId", event.target.value);
        localStorage.setItem("enterpriseName", currentUser?.establishmentName);
        localStorage.setItem("enterpriseLogo", currentUser?.logoFileSignedUrl);
        localStorage.setItem("navbarLogo", currentUser?.navBarLogoSignedUrl);
        localStorage.removeItem("userType");
        localStorage.removeItem("mspEnterprsieData");
        localStorage.removeItem("mspEnterpriseName");
        localStorage.removeItem("availableForInterviewFrom");
        localStorage.removeItem("availableForInterviewTill");
        localStorage.removeItem("availableWeekdays");
        localStorage.removeItem("childEnterpriseId");
        localStorage.removeItem("mspEnterpriseId");
        navigate("/enterprise/jobList");
        window.location.reload(true);
      } else {
        danger(`Already on ${currentUser?.establishmentName}.`);
      }
  };
  return (
    <>
      <Grid item>
        {isLoading && <Loader />}
        {isMobile ? (
          <>
            <Grid item>
              <Grid item>
                <Main
                  open={props.open}
                  sx={{ pr: 0 }}
                  className="responsive-view-align"
                >
                  <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                    className={`zooming-sidebar ${props.open && `zooming-sidebar`
                      ? " responsive-contents"
                      : ""
                      }`}
                    sx={{ ml: 7 }}
                  >
                    <Typography className="responsive-outlet galaxy-padding-top-10">
                      <Outlet />
                    </Typography>
                  </Grid>
                </Main>
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid item className="resolution-issues">
            <Grid item className="menu-list">
              <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <Grid
                  item
                  position="fixed"
                  className="collapse-navbar"
                  open={props.open}
                >
                  <Toolbar>
                    <Button
                      // color="inherit"
                      aria-label="open drawer"
                      edge="start"
                      className="collapse-label align-start pl-0 icon-button"
                      sx={{
                        mr: 0,
                        ml: 0,
                        ...(props.open && { display: "none" }),
                      }}
                      role="switch"
                      aria-checked={props.open ? "true" : "false"}
                      tabIndex={0}
                      disableRipple="true"
                    >
                        <Grid item style={{ minWidth: "53px" }}>
                              {(localStorage.getItem("userType") == "A" ? removeMultipleIndexes(EnterpriseMenu, indexesToRemove) : EnterpriseMenu).map((list, index) => {
                                var Component = list.icon;
                                return (
                                  (list.role[0] == "All" || list.role.indexOf(userTypes) !== -1 || localStorage.getItem("childEnterpriseId") != null) ?
                                    (((list.name == "Interviews" && (enterpriseDetails?.interviewConfigurations == null || enterpriseDetails?.interviewConfigurations == "")) ||
                                    (list.name == "Configuration Control" && localStorage.getItem("childEnterpriseId") != null) || (list.url == "Career Portal" && window.location.href?.includes("uat")) || (list.name == "Talent Console" && (isWtw == null || isWtw == ""))) ? <></> : <Grid key={index}
                                      className={`icon-margin sideBar-icon ${index != 0 && "mt-20"}`}
                                      onClick={() => list.url == "Career Portal" ? careerPortalSSOLogin() : list.url == "Analytics" ? handleMenu() : handleNavigation(list.url)}
                                      onKeyPress={() => list.url == "Career Portal" ? careerPortalSSOLogin() : list.url == "Analytics" ? handleMenu() : handleNavigation(list.url)}
                                      aria-label={list.name}
                                      tabIndex={0}
                                    >
                                      <Tooltip title={list.name} position="right"
                                        trigger="mouseenter"
                                        animation="fade"
                                        duration={0}
                                        distance={0}
                                        arrow>
                                        <Typography className={`p-10 justify-center d-flex ${((list?.activeUrls?.includes(URLPath?.split('/')[2]) && !openAnalytics) || (openAnalytics && list.name == "Analytics")) ? "side-menu-bg" : "hover-list"}`}>
                                          <Component
                                            width="20px"
                                            height="20px"
                                            aria-label={list.name}
                                            className={`inputcontrasts ${((list?.activeUrls?.includes(URLPath?.split('/')[2]) && !openAnalytics) || (openAnalytics && list.name == "Analytics")) ? (list.name == "Talent Console" ? "fill-svg-icons" : "svg-icons") : `${list.name == "Talent Console" ? "dashboard" : "contrasts"}`}`}
                                          />
                                        </Typography>
                                      </Tooltip>
                                    </Grid>) : <></>
                                );
                              })}
                        </Grid>
                    </Button>
                  </Toolbar>
                </Grid>
                <Drawer
                  sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    mr: 0,
                    "& .MuiDrawer-paper": {
                      width: drawerWidth,
                      boxSizing: "border-box",
                    },
                  }}
                  variant="persistent"
                  anchor="left"
                  open={props.open}
                >
                    <Grid item className="sidebar-list">
                        <>
                        {(localStorage.getItem("userType") == "A" ? removeMultipleIndexes(EnterpriseMenu, indexesToRemove) : EnterpriseMenu).map((list, index) => {
                            var Component = list.icon;
                            return (
                              (list.role[0] == "All" || list.role.indexOf(userTypes) !== -1 || localStorage.getItem("childEnterpriseId") != null) ?
                                (((list.name == "Interviews" && (enterpriseDetails?.interviewConfigurations == null || enterpriseDetails?.interviewConfigurations == "")) ||
                                (list.name == "Configuration Control" && localStorage.getItem("childEnterpriseId") != null) || (list.url == "Career Portal" && window.location.href?.includes("uat")) || (list.name == "Talent Console" && (isWtw == null || isWtw == ""))) ? <></> : <Link key={index} tabIndex={0} to={(list.url != "Career Portal" && list.url != "Analytics") && list.url}>
                                  <ul className="mt-0 ipad-view">
                                    <li onClick={() => list.url == "Career Portal" ? careerPortalSSOLogin() : list.url == "Analytics" ? handleMenu() : handleNavigation()}
                                      onKeyPress={() => list.url == "Career Portal" ? careerPortalSSOLogin() : list.url == "Analytics" ? handleMenu() : handleNavigation()}
                                      className={`pt-10 cursor-hover d-flex align-center ipad-text-start ${((list?.activeUrls?.includes(URLPath?.split('/')[2]) && !openAnalytics) || (openAnalytics && list.name == "Analytics")) ? "active-list" : "hover-list"}`}>
                                      <Component
                                        width="20px"
                                        height="20px"
                                        aria-label="Myjobs icon"
                                        className={`inputcontrasts ${((list?.activeUrls?.includes(URLPath?.split('/')[2]) && !openAnalytics) || (openAnalytics && list.name == "Analytics")) ? (list.name == "Talent Console" ? "fill-svg-icons" : "svg-icons") : `${list.name == "Talent Console" ? "dashboard" : "contrasts"}`}`}
                                      />
                                      <label className={`pl-10 ${((list?.activeUrls?.includes(URLPath?.split('/')[2]) && !openAnalytics) || (openAnalytics && list.name == "Analytics")) ? "primary-shadow-color" : ""}`}>
                                        {list.name}
                                      </label>
                                    </li>
                                  </ul>
                                </Link>)
                                : <></>
                            );
                          })}
                        </>
                    </Grid>
                    {enterprisesList?.length > 1 && <Grid item className="px-25 pt-10 pb-10">
                    <Typography className="gray8 font-weight-500 f-14">
                      Select Enterprise
                    </Typography>
                    <Grid container lg={12} md={12} xs={12} className="rating-category select-enterprise">
                    <Select
                      className="w-100 mb-0 job-category rating-select-align form-control input-group"
                      tabIndex={0}
                      value={enterpriseUser ? enterpriseUser : "--Select--"}
                      onChange={(e) => handleSelectEnterprise(e)}
                      displayEmpty
                    >
                      {enterpriseUser == null && <MenuItem value="--Select--">--Select--</MenuItem>}
                      {enterprisesList?.map((e) => {
                        return (
                        <MenuItem onClick={() => {
                          if (localStorage.getItem("enterpriseId") == e.id) {
                            return danger(`Already on ${localStorage.getItem("enterpriseName")}.`);
                          };
                        }
                      } key={e.id} value={e.id}>{e.establishmentName}</MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                    </Grid>}
                </Drawer>
              </Box>
              <Menu
                className={
                  props.openSideBar ? "analytics-report" : "analytic-settings-report"
                }
                id="menu-appbar"
                anchorEl={anchorEl}
                disableScrollLock={true}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                KeepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={openAnalytics}
              >
                <Grid item className="mt-15">
                  <AnalyticsSideBar
                    setOpenAnalytics={setOpenAnalytics}
                    setOpen={setOpen}
                  />
                </Grid>
              </Menu>
              <Menu
                className={
                  open ? "analytics-report" : "analytic-settings-report"
                }
                id="menu-appbar"
                anchorEl={anchorEl}
                disableScrollLock={true}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                KeepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={openSwitch}
              >
                <Grid item className="mt-15 p-relative">
                  <SwitchSideBar
                    handleOpenSwitch={setOpenSwitch}
                    setOpen={setOpen}
                    childEnterprises={childEnterprises}
                    mspReponse={mspReponse}
                  />
                </Grid>
              </Menu>
            </Grid>
            <Main open={open}>
              <Grid
                item
                sx={{ ml: 38, ...(open && { marginLeft: "135px" }) }}
                className={
                  props.openSideBar
                    ? "responsive-main-section side-bar-align preview-side-align screen-left-align fit-body-expanded left-section"
                    : "responsive-main-section side-bar-align preview-side-align screen-left-align fit-body-collapsed left-section"
                }
              >
                <Outlet />
              </Grid>
            </Main>
          </Grid>
        )}
      </Grid>
    </>
  );
}
