import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Typography,
  Button,
  Input,
  Autocomplete,
  TextField,
  IconButton,
  Tooltip,
  Rating,
  Grid,
  Box,
  Modal
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import starEmpty from "../../../assets/images/star-icon-empty.svg";
import starFilled from "../../../assets/images/star-icon-filled.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../Loader";
import CandidateService from "../../../Services/Enterprise/Candidate.service";
import { Link } from "react-router-dom";
import noReviewsIcon from "../../../assets/images/NoRating.svg";

export default function ReviewModal(props) {
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [reviewDetails, setReviewDetails] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [loadMore, setLoadMore] = useState(true);
  useEffect(() => {
    if (props.openReviewModal) {
      setPageNumber(0);
      if (props.reviewDetails?.length < 10) {
        setLoadMore(false);
      } else {
        setLoadMore(true);
      }
      setReviewDetails(props.reviewDetails);
      setOpenReviewModal(true);
    }
  }, [props.openReviewModal]);
  const handleCloseReviewModal = () => {
    setLoadMore(false);
    setOpenReviewModal(false);
    props.setOpenReviewModal(false);
  };
  const fetchMoreData = () => {
    let pgNo = pageNumber + 1;
    setPageNumber(pgNo);
    CandidateService.getReviewDetails(props.candidateId, pgNo).then(
      (response) => {
        if (response.length < 10) {
          setLoadMore(false);
        } else {
          setLoadMore(true);
        }
        setReviewDetails([...reviewDetails, ...response]);
      }
    );
  };
  return (
    <Modal
      open={openReviewModal}
      onClose={handleCloseReviewModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid
        item
        className="modalStyle p-20 inputcontrasts"
        style={{ width: "700px" }}
      >
        <Grid container item spacing={1} lg={12} md={12} xs={12}>
          <Grid item lg={6} md={6} xs={6} align="left">
            <Typography
              className="f-24 font-weight-600 modalTitle heading-text-color"
              variant="h5"
            >
              Project Summary
            </Typography>
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            xs={6}
            align="right"
            className="cursor-hover sub-text-color"
          >
            <Typography className="icon-button">
              <CloseIcon
                onClick={handleCloseReviewModal}
                aria-label="Close icon"
                title="Close"
                tabIndex={0}
                onKeyPress={handleCloseReviewModal}
              />
            </Typography>
          </Grid>
        </Grid>
        {reviewDetails?.length === 0 ? (
          <Grid
            container
            item
            spacing={1}
            lg={12}
            md={12}
            xs={12}
            className="mt-15 mb-10"
          >
            <Grid item lg={12} md={12} xs={12} className="m-auto text-center">
              <img src={noReviewsIcon} alt="img" width="200px" height="200px"/>
              <Typography className="mt-15 f-16 font-weight-600 gray7">
                No ratings to show right now or they are given by other
                enterprises
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid item className="mt-5 p-5" spacing={1} lg={12} md={12} xs={12}>
            <InfiniteScroll
              dataLength={reviewDetails?.length}
              next={() => fetchMoreData()}
              hasMore={loadMore}
              loader={<Loader />}
              inverse={false}
              height={
                reviewDetails?.length > 4
                  ? 400
                  : reviewDetails?.length > 2
                  ? 300
                  : reviewDetails?.length > 1
                  ? 200
                  : 150
              }
            >
              {reviewDetails?.map((review, index) => (
                <Grid
                  className={
                    index < reviewDetails.length - 1
                      ? "mt-10 borderBottom pb-15"
                      : "mt-10"
                  }
                  key={index}
                >
                  <Grid container spacing={1} lg={12} md={12} xs={12} className="galaxy-block">
                    <Grid item lg={9.8} md={9.8} xs={9.8} align="left">
                      <Typography className="f-16 font-weight-600">
                        <Link
                          to={`/enterprise/jobs/${review.id}/matching-candidates`}
                          target="_blank"
                          className="text-decoration-none"
                        >
                          <span className="main-text-color cursor-hover f-16 font-weight-500 textEllipse">
                            {review?.jobTitle}
                          </span>
                        </Link>
                      </Typography>
                      <Typography className="f-12 font-weight-400 sub-text-color">
                        Project completed on{" "}
                        {moment(review?.offboardedDate).format("MMM DD, YYYY")}
                      </Typography>
                    </Grid>
                    <Grid item lg={2.2} md={2.2} xs={2.2}>
                      <Typography
                        variant="span"
                        className="sub-text-color f-14 align-center d-flex userName-align jobDetail-user rating-details responsive-block mt-2"
                      >
                        {review?.ratingOverall ? (
                          <Rating
                            count={5}
                            value={review?.ratingOverall}
                            name="half-rating"
                            readOnly
                            sx={{
                              "& .MuiRating-icon": {
                                width: "20px",
                              },
                            }}
                            icon={
                              <img
                                src={starFilled}
                                style={{ width: "18px", height: "18px" }}
                                alt="img"
                              />
                            }
                            emptyIcon={
                              <img
                                src={starEmpty}
                                style={{ width: "18px", height: "18px" }}
                                alt="img"
                              />
                            }
                          />
                        ) : (
                          <Typography className="f-12 mt-5 sub-text-color">
                            No rating available
                          </Typography>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  {review?.ratingRemarksByEnterprise ? (
                    <Grid
                      item
                      lg={12}
                      md={12}
                      xs={12}
                      className="mt-5"
                      align="left"
                    >
                      <Typography className="f-14 font-weight-400">
                        {`Review: ${review?.ratingRemarksByEnterprise}`}
                      </Typography>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              ))}
            </InfiniteScroll>
          </Grid>
        )}
      </Grid>
    </Modal>
  );
}
