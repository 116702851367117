import { Grid, Typography, Container, Button } from "@mui/material";
import * as React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import LaunchCarousel1 from "../../assets/images/launch-page-images/launch-carousel-1.png";
import LaunchCarousel2 from "../../assets/images/launch-page-images/launch-carousel-2.png";
import LaunchCarousel3 from "../../assets/images/launch-page-images/launch-carousel-3.png";
import LaunchCarousel4 from "../../assets/images/launch-page-images/launch-carousel-4.png";
import HomePageBanner from "../../assets/images/launch-page-images/homepage-banner.png";

export default function DirectSourceLaunch() {
    let navigate = useNavigate();

    return (
        <Grid item
            // data-aos="zoom-in-left"
            // data-aos-anchor="#example-anchor"
            // data-aos-offset="500"
            // data-aos-duration="500"
            sx={{ py: 3, px: 4 }}
            className="body-container"
        >
            <Grid item lg={12} md={12} xs={12} sx={{ py: 3, mt: 7.8 }} className="green-light-background p-relative  justify-center responsive-bottom-section direct-source-responsive">
                <Container className="d-flex justify-center">
                    <Grid item lg={10} md={10} xs={12}>
                        <Typography className="pb-15 launch-page-title2 pt-35 text-center">Discover the Power of Direct Sourcing and Revolutionize Hiring!</Typography>
                        <Typography className="pb-20 launch-page-subtext text-center">
                            Direct sourcing is now essential to finding and engaging the best contingent talent.
                            We offer a game-changing solution that provides access to millions of contingent
                            candidates, streamlines your hiring process, and helps you achieve your goals.
                        </Typography>
                        <Typography className="text-center pt-10 pb-20">
                    </Typography>
                    </Grid>
                </Container>
                <Grid className="p-absolute w-100 align-item-center">
                    <img src={HomePageBanner} alt="Direct sourcing " className="responsive-img-block" />
                </Grid>
            </Grid>
        </Grid>
    );
}
