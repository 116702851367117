import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./assets/styles/styles.scss";
import { BrowserRouter } from "react-router-dom";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import KeycloakService from "./keycloak";
import { onAuthSuccessCallback } from "./helpers/helper";
import { ModalProvider } from "./ModalContext";
let persistor = persistStore(store);

const renderApp = () => {
  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
          <ModalProvider>
            <App />
            </ModalProvider>
          </PersistGate>
        </Provider>
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
  );
};

// KeycloakService.initKeycloak(renderApp, onAuthSuccessCallback);
KeycloakService.initKeycloak(() => {
  onAuthSuccessCallback(renderApp); // Pass renderApp as the callback
});
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
