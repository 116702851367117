module.exports = {
  APP_NAME_GENERAL: "SimplifyHire",
  APP_NAME_DIRECT_HEALTH: "Direct Health",
  APP_NAME_NEURODIVERSIFY: "Neurodiversify",
  UserNotFoundException: "UserNotFoundException",
  SUCCESS: "SUCCESS",
  CODE_MISMATCH_EXCEPTION: "CodeMismatchException",
  LIMIT_EXCEEDED_EXCEPTION: "LimitExceededException",
  NOT_AUTHORIZED_EXCEPTION: "NotAuthorizedException",
  USER_NOT_CONFIRMED_EXCEPTION: "UserNotConfirmedException",
  INVALID_PARAMETER_EXCEPTION: "InvalidParameterException",
  USERNAME_EXISTS_EXCEPTION: "UsernameExistsException",
  UNEXPECTED_LAMBDA_EXCEPTION: "UnexpectedLambdaException",
  BLUK_UPLOAD_VIA_TEMPLATE_URL: "https://daas-prog-test.s3.amazonaws.com/DSAAS_Bulk_Job_Template.xlsx",
  EMAIL_REGEX:
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  ENTERPRISE_JOB_DESCRIPTION_FILE: "JOB_DESCRIPTION",
  ENTERPRISE_PROFILE_FILE: "ENTERPRISE_PROFILE",
  ENTERPRISE_BACKGROUND_FILE: "ENTERPRISE_BACKGROUND",
  ENTERPRISE_LOGO_FILE: "ENTERPRISE_LOGO",
  ENTERPRISE_OFFER_FILE: "ENTERPRISE_OFFER",
  DEBOUNCE_TIME: 300,
  MSP_EXCLUSIVE: "MSP Exclusive",
  MSP_EXCLUSIVE_Detail: "These are the candidates who have come to the platform through MSP and only you have the access to their profile",
  DEFAULT_LOGO: "https://daas-prog-test.s3.amazonaws.com/default-logo.png",
  DEFAULT_USER: "https://daas-prog-test.s3.amazonaws.com/default-user.png",
  APP_LOGO_GENERAL: "https://daas-prog-test.s3.amazonaws.com/app_logo_general.png",
  APP_LOGO_NEURODIVERSIFY: "https://daas-prog-test.s3.amazonaws.com/app_logo_neurodiversify.png",
  APP_LOGO_HEALTHCARE: "https://daas-prog-test.s3.amazonaws.com/app_logo_healthcare.png",
  BACKGROUND_IMAGE: "https://daas-prog-test.s3.amazonaws.com/background-image.png",
  APP_LOGO_GENERAL_WHITE: "https://daas-prog-test.s3.amazonaws.com/app_logo_general_white.png",
  CANDIDATE_SHOWCASE_FILE: "SHOWCASE",
  CANDIDATE_EDUCATION_FILE: "EDUCATION",
  CANDIDATE_PROFILE_FILE: "PROFILE",
  CANDIDATE_RESUME_FILE: "RESUME",
  CANDIDATE_BACKGROUND_FILE: "BACKGROUND",
};
