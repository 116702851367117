import React, { useState, useEffect } from "react";
import {
    Grid, Typography, Box, TextField, Select, Input,
    MenuItem,
    InputAdornment, Button, TextareaAutosize
} from "@mui/material";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as UploadResume } from "../../../assets/images/upload-video-icon.svg";
import { ReactComponent as InfoIcon } from "../../../assets/images/already-referred-info.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/images/delete-new-icon.svg";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ReferralService from './../../../Services/Enterprise/referral.service';
import { danger, success } from "../../../helpers/message-box";
import { handleSpaces, restrictText } from "../../../helpers/helper-data";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import ConfirmDialog from "../../../Components/shared/ConfirmDialog";
import { useSelector } from "react-redux";
import AutoCompleteCountryCode from "./AutoCompleteCountryCode";

const Constant = require("../../../helpers/constants");

const useStyles = makeStyles((theme) => ({
    customTextField: {
        "& input::placeholder": {
          fontWeight: "normal",
          color: "#667085 !important",
          opacity: '0.6',
        },
      },
    }));
function RefereeDetails(props) {
  const classes = useStyles();
    const { open, setOpen, referRecord, edit, jobId, getReferral, sortBy, page, filterData, previouslySearchedKeyword, setRefer_success, setIsLoading } = props;
    const navigate = useNavigate();
    const enterpriseDetail = useSelector((state) => state.enterprise);
    const [documentFile, setdocumentFile] = useState();
    // const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [openConfirmHideModal, setOpenConfirmHideModal] = useState(false);
    const [availability, setAvailability] = useState(0);
    const [message, setMessage] = useState("");
    const [validPhone, setValidPhone] = useState();
    const [numberError, setNumberError] = useState("");
    const [phoneCode, setPhoneCode] = useState("+1");
    const [mobileNumber, setMobileNumber] = useState("");
    const [callApiAfterFirstCall, setCallApiAfterFirstCall] = useState(false);
    const [invalidMobilePhoneError, setInvalidMobilePhoneError] = useState("");
    const [talentPoolId, setTalentPoolId] = React.useState(null);

    const handleCloseReferee = () => {
        reset();
        setAvailability(0);
        setdocumentFile("");
        setErrorMessage("");
        setEmail("");
        setOpen(false);
        setMessage("");
        setMobileNumber("");
        setInvalidMobilePhoneError("");
        setCallApiAfterFirstCall(false);
        setNumberError("");
        setPhoneCode("+1");
    };
    useEffect(() => {
        if (referRecord) {
            setValue("fullName", referRecord?.firstName + (referRecord?.lastName ? " " + referRecord?.lastName : ""));
            setValue("email", referRecord?.emailAddress);
            setValue("exp", referRecord?.overallYearsOfExperience);
            // setValue("phoneNumber", referRecord?.mobileNumber);
            if (referRecord.mobileNumber === null || referRecord.mobileNumber === "null") {
                setMobileNumber("");
               } else {
                let number = referRecord?.mobileNumber.split("-").length > 1? referRecord?.mobileNumber.split( "-")[1]: referRecord?.mobileNumber.split("-")[0];
                setMobileNumber(number);
                }
                if (referRecord.mobileNumber === null || referRecord.mobileNumber === "null") {
                    setPhoneCode("+1");
                  } else {
                    setPhoneCode(
                        referRecord?.mobileNumber.split("-").length > 1
                        ? referRecord?.mobileNumber.split("-")[0]
                        : "+1"
                    );
                  }
            setdocumentFile(referRecord?.resumeSignedUrl);
            setAvailability(referRecord?.availabilityToJoinInDays);
            setMessage(referRecord?.referralNotes);
            setValue("PortfolioLink", referRecord?.portfolioUrl);
            if (referRecord.talentPoolId && !referRecord.jobId) {
                setTalentPoolId(referRecord.talentPoolId);
            }
        }
    }, [referRecord, open]);
    const validationSchema = Yup.object().shape({
        fullName: Yup.string()
            .required("Full name is required")
            .max(128, "Exceeded maximum character length of 128"),
        email: Yup.string()
            .nullable().notRequired()
            // .required("Please enter valid email address")
            .max(128, "Exceeded maximum character length of 128")
            .matches(Constant.EMAIL_REGEX, {
                excludeEmptyString: true,
                message: "Please enter valid email address",
            }),
        PortfolioLink: Yup.string()
            .nullable().notRequired()
            // .required("Please enter valid email address")
            .max(128, "Exceeded maximum character length of 256")
            .matches(/^(|.{4,})$/, {
                excludeEmptyString: true,
                message: "The portfolio link must be at least 4 characters",
            }),
        exp: Yup.string()
            .nullable().notRequired()
            // .max(5, "Exceeded maximum character length of 5")
            .matches(/^(0*[0-9]{0,2}|0*[0-9]{0,2}(\.([1-9]|1[0-2]))|[0-9]{0,2}(\.([1-9]|1[0-2]))?)$/, {
                excludeEmptyString: true,
                message: "Please enter valid overall years of experience",
            }),
        phoneNumber: Yup.string()
            .nullable().notRequired()
            // .required("Please enter valid phone number")
            .max(16, "Exceeded maximum character length of 16")
            .matches(/^\+?\d+$/, {
                excludeEmptyString: true,
                message: "Please enter valid phone number"
            }),
    });
    const {
        register,
        setValue,
        getValues,
        reset,
        formState: { errors, isValid, isDirty },
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "onChange",
    });
    function ValidateSingleInput(event) {
        var validFileExtensions = ["pdf", "doc", "docx"];
        var file = event.target.files[0];
        if (file) {
            if (file.name.length <= 80) {
                var extension = file.name.split(".").pop();
                if (validFileExtensions.includes(extension)) {
                    setdocumentFile({
                        filename: file.name,
                        extension: extension,
                        file: file,
                    });
                } else {
                    danger("Please upload only DOC, DOCX, PDF and ZIP file formats!");
                    event.target.value = "";
                }
            } else {
                danger("Filename is too long!");
                event.target.value = "";
            }
        }
        return true;
    };
    const createReferral = () => {
        if ((mobileNumber == "" || mobileNumber == undefined) && (getValues("email") == "" || getValues("email") == undefined)) {
            danger("Phone number or Email address is required.");
            return false;
        };
        setIsLoading(true);
        var nameArray = getValues("fullName")?.split(' ');
        var formData = new FormData();
        let phoneNumber = mobileNumber
            ? phoneCode + "-" +
            (mobileNumber.split("-").length > 1
                ? mobileNumber.split("-")[1]
                : mobileNumber.split("-")[0])
            : null;
        if (edit) {
            formData.append("request-body",
                new Blob(
                    [JSON.stringify({
                        "mobileNumber": phoneNumber ? phoneNumber : null,
                        "emailAddress": getValues("email") ? getValues("email") : null,
                        "firstName": nameArray[0],
                        "lastName": nameArray?.slice(1)?.length != 0 ? nameArray?.slice(1).join(' ') : null,
                        "overallYearsOfExperience": getValues("exp") ? Number(getValues("exp")) : null,
                        "availabilityToJoinInDays": availability != null ? availability : 0,
                        "jobId": jobId ? jobId : referRecord?.jobId ? referRecord?.jobId : null,
                        "resumeUrl": referRecord?.resumeUrl ? referRecord?.resumeUrl : null,
                        "id": referRecord?.id,
                        "referralNotes": message ? message : null,
                        "referredFrom": "E",
                        "portfolioUrl": getValues("PortfolioLink") ? getValues("PortfolioLink") : null,
                        "talentPoolId": referRecord?.talentPoolId ? referRecord?.talentPoolId : null,
                    }),
                    ],
                    { type: "application/json" }
                )
            );
        } else {
            formData.append("request-body",
                new Blob(
                    [JSON.stringify({
                        "mobileNumber": phoneNumber ? phoneNumber : null,
                        "emailAddress": getValues("email") ? getValues("email") : null,
                        "firstName": nameArray[0],
                        "lastName": nameArray?.slice(1)?.length != 0 ? nameArray?.slice(1).join(' ') : null,
                        "overallYearsOfExperience": getValues("exp") ? Number(getValues("exp")) : null,
                        "availabilityToJoinInDays": availability != null ? availability : 0,
                        "resumeUrl": null,
                        "jobId": jobId ? jobId : null,
                        "referralNotes": message ? message : null,
                        "referredFrom": "E",
                        "portfolioUrl": getValues("PortfolioLink") ? getValues("PortfolioLink") : null,
                        "talentPoolId": null,
                    }),
                    ],
                    { type: "application/json" }
                )
            );
        }
        if (documentFile?.file) {
            formData.append("file", documentFile?.file ? documentFile.file : "");
        }
        ReferralService.createReferral(formData)
            .then((res) => {
                handleCloseReferee();
                setValue("fullName", "");
                setValue("email", "");
                setInvalidMobilePhoneError("");
                setCallApiAfterFirstCall(false);
                setMobileNumber("");
                setPhoneCode("+1");
                setValue("exp", "");
                setValue("phoneNumber", "");
                setValue("PortfolioLink", "");
                setMessage("");
                success(edit ? "Referral updated successfully." : "Referral submitted successfully.");
                setIsLoading(false);
                localStorage.setItem("referral-success", true);
                if (setRefer_success != undefined) {
                    setRefer_success(true);
                };
                if (edit) {
                    getReferral(page, 10, sortBy, filterData, previouslySearchedKeyword ? previouslySearchedKeyword : null);
                };
            })
            .catch(function (error) {
                setIsLoading(false);
                danger("Something went Wrong.");
            });
    };
    const uploadResume = () => {
        document.getElementById("resume-refer")?.click();
    };
    const validateEmail = (email) => {
        if (email?.length > 0 && (jobId || referRecord?.talentPoolId || referRecord?.jobId)) {
            let str = email;
            if (str.includes("+")) {
              str = str.replace(/\+/g, "%2B");
          }
            ReferralService.validateEmail(str, jobId ? jobId : referRecord?.jobId ? referRecord?.jobId : referRecord?.talentPoolId, (jobId || referRecord?.jobId) ? "jobId" : "talentPoolId")
                .then((res) => {
                    if (res) {
                        if (jobId || referRecord?.jobId) {
                            setErrorMessage("This candidate has already been referred for the position");
                        } else {
                            setErrorMessage("This candidate has already been referred for the talent pool community");
                        }
                    } else if (email == localStorage.getItem("email")) {
                        setErrorMessage("Self-Referral Not Allowed");
                    } else {
                        setErrorMessage("");
                    }
                })
                .catch(function (error) {
                });
        }
    };
    const validatePhone = (number) => {
        if (number?.length > 0 && (jobId || referRecord?.talentPoolId || referRecord?.jobId) && callApiAfterFirstCall) {
            let phoneNumber = number
          ? phoneCode + "-" +
            (number.split("-").length > 1
              ? number.split("-")[1]
              : number.split("-")[0])
          : null;
            let str = phoneNumber;
            console.log(str);
            if (str.includes("+")) {
              str = str.replace(/\+/g, "%2B");
            }
            ReferralService.validatePhone(str, jobId ? jobId : referRecord?.jobId ? referRecord?.jobId : referRecord?.talentPoolId, (jobId || referRecord?.jobId) ? "jobId" : "talentPoolId")
            .then((res) => {
                if (res) {
                    if (jobId !== null || referRecord?.jobId !== null) {
                        setInvalidMobilePhoneError("This candidate has already been referred for the position");
                    } else {
                        setInvalidMobilePhoneError("This candidate has already been referred for the talent pool community");
                    }
                } else if (number == localStorage.getItem("phone")) {
                    setInvalidMobilePhoneError("Self-Referral Not Allowed");
                } else {
                    setInvalidMobilePhoneError("");
                }
            })
            .catch(function (error) {
            });
        }
    };
    const handlePhoneCodeChange = (event, newValue) => {
        if (!callApiAfterFirstCall) {
            setCallApiAfterFirstCall(true);
        }
        if (
            mobileNumber !== "" &&
            /^\d+(?: \d+)*$/.test(
                mobileNumber.includes("-") ? mobileNumber.split("-")[1] : mobileNumber
            ) == false
        ) {
          setInvalidMobilePhoneError("Please enter valid phone number");
        } else if (
            (mobileNumber.split("-").length > 1
              ? mobileNumber.split("-")[1]
              : mobileNumber.split("-")[0]
            ).length +
              (newValue && newValue.dial_code ? newValue.dial_code.length : 2) >
            15
          ) {
            setInvalidMobilePhoneError("Exceeded maximum character length of 16");
          } else {
          setInvalidMobilePhoneError("");
        }
        setPhoneCode(newValue && newValue.dial_code ? newValue.dial_code : "+1");
      };
      const handleContactNo = (e) => {
        if (!callApiAfterFirstCall) {
            setCallApiAfterFirstCall(true);
        }
        if (e.target.value.trim() === "") {
          setInvalidMobilePhoneError("");
        } else if (
            /^\d+(?: \d+)*$/.test(e.target.value) === false
        ) {
          setInvalidMobilePhoneError("Please enter valid phone number");
        } else if (e.target.value.length + phoneCode.length > 15) {
          setInvalidMobilePhoneError("Exceeded maximum character length of 16");
        } else {
          setInvalidMobilePhoneError("");
        }
        setMobileNumber(e.target.value.trimStart());
      };
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            validatePhone(mobileNumber);
        }, Constant.DEBOUNCE_TIME);
        return () => clearTimeout(timeoutId);
      }, [mobileNumber, phoneCode]);
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            validateEmail(email);
        }, Constant.DEBOUNCE_TIME);
        return () => clearTimeout(timeoutId);
    }, [email]);
    const deleteResume = () => {
        setOpenConfirmHideModal(false);
        setdocumentFile("");
        success("Resume is deleted successfully.");
    };
    return (
        <>
            <Modal
                open={open}
                onClose={handleCloseReferee}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal-content inputcontrast">
                    <Grid className="display-flex align-center justify-between">
                        <Typography className="f-20 font-weight-600 heading-text-color">Refer a Candidate</Typography>
                        <Typography className="cursor-hover gray7 icon-button">
                            <CloseIcon
                                onClick={handleCloseReferee}
                                aria-label="close-icon"
                                tabIndex={0}
                            />
                        </Typography>
                    </Grid>
                    <Grid id="modal-description" className="pl-0">
                        <Grid container spacing={1} lg={12} md={12} xs={12} className="m-0">
                            <Grid item lg={6} md={6} xs={12} className="pl-0 m-b-5">
                                <Typography className="f-14 font-weight-400 gray7">Full Name<span>*</span></Typography>
                                <TextField
                                    type="text"
                                    id="fullName"
                                    className={`form-control input-group mb-0 inputcontrasts${errors.fullName ? "is-invalid" : ""
                                        }`}
                                    margin="normal"
                                    placeholder="Enter full name"
                                    {...register("fullName")}
                                    onKeyPress={handleSpaces}
                                />
                                <span className="danger-color error-msg">
                                    {" "}
                                    {errors.fullName && errors.fullName.message}
                                </span>
                            </Grid>
                            <Grid item lg={6} md={6} xs={12} className="m-b-5 referee-align">
                                <Typography className="f-14 font-weight-400 gray7">Email Address</Typography>
                                <TextField
                                    type="text"
                                    id="email"
                                    className={`form-control input-group mb-0 inputcontrasts${errors.email ? "is-invalid" : ""
                                        }`}
                                    margin="normal"
                                    placeholder="Enter email address"
                                    {...register("email", {
                                        onChange: (e) => {
                                            setEmail(e.target.value),
                                                e.target.value.length == "" && setErrorMessage("");
                                        }
                                    })}
                                    onKeyPress={handleSpaces}
                                />
                                <span className="danger-color error-msg">
                                    {" "}
                                    {errors.email && errors.email.message}
                                </span>
                                {errorMessage && <span className="danger-color error-msg display-flex">
                                    {" "}
                                    {/* <Box><InfoIcon width="16px" height="16px" /></Box> */}
                                    <Box className="already-referred font-weight-400">{errorMessage}</Box>
                                </span>}
                            </Grid>
                            {/* <Grid item lg={6} md={6} xs={12} className="pl-0 referral-number m-t-5">
                                <Typography className="f-14 font-weight-400 gray7">Phone Number</Typography>
                                <TextField
                                    type="text"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    className={`form-control input-group mb-0 inputcontrasts${errors.phoneNumber ? "is-invalid" : ""
                                        }`}
                                    margin="normal"
                                    placeholder="Enter phone number"
                                    {...register("phoneNumber", {
                                        onChange: (e) => {
                                            setValidPhone(e.target.value),
                                            e.target.value.length == "" && setNumberError("");
                                          }
                                    })}
                                    onKeyPress={handleSpaces}
                                />
                                <span className="danger-color error-msg">
                                    {" "}
                                    {errors.phoneNumber && errors.phoneNumber.message}
                                </span>
                                {numberError && <span className="danger-color error-msg display-flex">
                                    <Box className="already-referred font-weight-400">{numberError}</Box>
                                </span>}
                            </Grid> */}
                            <Grid
                              lg={6}
                              md={12}
                              xs={12}
                              item
                              className="m-t-5 pl-0"
                            >
                             <Box className="licence-number">
                                <p className="f-14 font-weight-400 gray7 m-b-0 mt-0">
                                  Phone Number
                                </p>
                  <Grid container spacing={1}>
                            <Grid item lg={3.8} md={3.8} xs={3.8} className="reference-input pt-0">
                            <AutoCompleteCountryCode
                        className="mb-0 w-100 select-option-section mt-1 createProfile-code-referral f-12 font-weight-400 gray9 icon-positioning-onboard"
                        defaultValue={phoneCode ? phoneCode : "+1"}
                        onChange={handlePhoneCodeChange}
                      />
                      </Grid>
                                <Grid item lg={8.2} md={8.2} xs={8.2} className="pt-0 phone-number-textfield">
                                <TextField
                                classes={{ root: classes.customTextField }}
                                  type="text"
                                  className={`form-control input-group mb-0 inputcontrasts ${
                                    invalidMobilePhoneError ? "is-invalid" : ""
                                    }`}
                                  margin="normal"
                                  placeholder="Enter phone number"
                                  onChange={handleContactNo}
                        value={
                          mobileNumber.split("-")
                            .length > 1
                            ? mobileNumber.split(
                                "-"
                              )[1]
                            : mobileNumber.split(
                                "-"
                              )[0]
                        }
                                />

</Grid>
                                </Grid>
                              </Box>
                              <span className="danger-color error-msg">
                                  {" "}
                                  {invalidMobilePhoneError}
                                </span>
                            </Grid>
                            <Grid item lg={6} md={6} xs={12} className="m-b-5 m-t-5 referee-align">
                                <Typography className="f-14 font-weight-400 gray7">Overall Experience</Typography>
                                <TextField
                                    type="text"
                                    id="experience"
                                    name="experience"
                                    className={`form-control input-group mb-0 inputcontrasts${errors.exp ? "is-invalid" : ""
                                        }`}
                                    margin="normal"
                                    placeholder="Enter overall years of experience"
                                    {...register("exp")}
                                    onKeyPress={handleSpaces}
                                />
                                <span className="danger-color error-msg">
                                    {" "}
                                    {errors.exp && errors.exp.message}
                                </span>
                            </Grid>
                            <Grid item lg={6} md={6} xs={12} className="pl-0 m-t-5">
                                <Typography className="f-14 font-weight-400 gray7">Available to Join (in days)</Typography>
                                {/* <Select
                                inputProps={{
                                    "aria-label": "With label",
                                }}
                                name="join-referee"
                                className="w-100 mb-0 m-t-5 form-control input-group inputcontrasts galaxy-select assign-msp"
                                value={availability}
                                onChange={(e) => setAvailability(e.target.value)}
                            >
                                <MenuItem value="select" className="gray7">--Select--</MenuItem>
                                <MenuItem value="0">Immediately</MenuItem>
                                <MenuItem value="15">15 days</MenuItem>
                                <MenuItem value="30">30 days</MenuItem>
                                <MenuItem value="45">45 days</MenuItem>
                                <MenuItem value="60">60+ days</MenuItem>
                            </Select> */}
                                <TextField
                                    type="number"
                                    min="0"
                                    name="join-referee"
                                    id="join-referee"
                                    className="form-control input-group mb-0 pl-10 inputcontrasts "
                                    margin="normal"
                                    placeholder="Enter day(s)"
                                    value={availability}
                                    onKeyDown={(e) =>
                                        ["e", "E", "+", "-", "."].includes(
                                            e.key
                                        ) && e.preventDefault()
                                    }
                                    pattern="[0-9]"
                                    onKeyPress={restrictText}
                                    InputProps={{
                                        inputProps: { min: 0, max: 90 },
                                    }}
                                    inputMode="numeric"
                                    onInput={(e) => e.target.value.replace(/[^A-Za-z0-9]/g, '')}
                                    onChange={(e) => {
                                        if (e.target.value > 90) {
                                            setAvailability(
                                                90
                                            );
                                        } else if (e.target.value < 1 && e.target.value != "") {
                                            setAvailability(
                                                0
                                            );
                                        } else {
                                            setAvailability(
                                                e.target.value
                                            );
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item lg={6} md={6} xs={12} className="refer-upload-field m-t-5 referee-align">
                                <Typography className="f-14 font-weight-400 gray7">Resume File</Typography>
                                <TextField
                                    type="text"
                                    id="resume"
                                    className="form-control input-group mb-0 inputcontrasts pr-0"
                                    margin="normal"
                                    placeholder="Select resume file"
                                    onClick={() => uploadResume()}
                                    value={documentFile?.filename ? documentFile?.filename : documentFile && decodeURIComponent(
                                        documentFile?.split("/").pop().split("?")[0])}
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {documentFile ? <DeleteIcon width="16px" height="16px" className="m-r-10 link-color svg-icons" onClick={() => setOpenConfirmHideModal(true)} /> :
                                                    <>
                                                        <input type="file" accept=".doc, .docx, .pdf" id="resume-refer" onChange={ValidateSingleInput} tabIndex="0" hidden />
                                                        <label htmlFor="resume-refer"></label>
                                                        <Grid className="secondary-bg-color-svg-icons border-radius-6 refer-candidate cursor-hover">
                                                            <UploadResume className="link-color svg-icons" width="14px" height="14px" />
                                                        </Grid></>}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {/* <span className="danger-color error-msg">
                                {" "}
                                {errors.resume && errors.resume.message}
                            </span> */}
                            </Grid>
                            <Grid item lg={12} md={12} xs={12} className="m-b-5 pl-0 m-t-5">
                                <Typography className="f-14 font-weight-400 gray7">Portfolio Link</Typography>
                                <TextField
                                    type="text"
                                    id="PortfolioLink"
                                    className={`form-control input-group mb-0 inputcontrasts${errors.PortfolioLink ? "is-invalid" : ""
                                        }`}
                                    margin="normal"
                                    placeholder="Enter portfolio link"
                                    {...register("PortfolioLink")}
                                    onKeyPress={handleSpaces}
                                />
                                <span className="danger-color error-msg">
                                    {" "}
                                    {errors.PortfolioLink && errors.PortfolioLink.message}
                                </span>
                            </Grid>
                            <Grid item lg={12} md={12} xs={12} className="pl-0 m-t-5">
                                <Box className="input-focus-none">
                                    <Typography className="f-14 font-weight-400 gray7">
                                        Message for Candidate
                                    </Typography>
                                    <TextareaAutosize
                                        minRows={3}
                                        maxRows={4}
                                        aria-label="maximum height"
                                        className="form-control input-group mb-0 inputcontrasts"
                                        placeholder="Enter message"
                                        role="textbox"
                                        value={message}
                                        onKeyPress={handleSpaces}
                                        onChange={(e) => setMessage(e.target.value)}
                                    />
                                    {message?.trim()?.length > 256 && (
                                        <span className="danger-color error-msg">
                                            Exceeded maximum character length of 256
                                        </span>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid id="footer" className="justify-end d-flex mt-20">
                        <Button className="btn primary-button" disabled={!isValid || errorMessage || message?.trim()?.length > 256 || invalidMobilePhoneError != ""} onClick={() => createReferral()}>Submit</Button>
                    </Grid>
                </Box>
            </Modal>
            <ConfirmDialog
                open={openConfirmHideModal}
                setOpen={setOpenConfirmHideModal}
                dialogTitle="Delete Resume"
                description={"Are you sure?"}
                handleConfirmYes={() => deleteResume()}
            />
        </>
    );
}

export default RefereeDetails;
