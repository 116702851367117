import axios from "axios";
export const getCredentialingtoken = async () => {
    if (localStorage.getItem("credentialing_token") && parseJwt(localStorage.getItem("credentialing_token"))) {
        const credentialing_token = localStorage.getItem("credentialing_token");
        return credentialing_token;
    } else {
    try {
        const clientLoginPayload = {
            "eventInfo": {
                "mode": "SYNC",
                "eventType": "credentialing-auth-event",
                "eventDestination": "credentialing-auth"
            },
            "data": {
                "payload": {
                    "type": "CLIENT_LOGIN",
                    "clientId": process.env.REACT_APP_CREDENTIALING_CLIENT_ID,
                    "clientSecret": process.env.REACT_APP_CREDENTIALING_CLIENT_SECRET_KEY
                }
            }
        };
        const response1 = await axios.post(`${process.env.REACT_APP_CREDENTIALING_EVENT_SERVICE_URL}/public/api/requests`, clientLoginPayload);
        const tokenExchangePayload = {
            "eventInfo": {
                "mode": "SYNC",
                "eventType": "credentialing-auth-event",
                "eventDestination": "credentialing-auth"
            },
            "data": {
                "metadata": {
                    "region": "us_east_1"
                },
                "payload": {
                    "type": "TOKEN_EXCHANGE",
                    "idpName": process.env.REACT_APP_CREDENTIALING_IDP_NAME,
                    "username": localStorage.getItem("email"),
                    "token": localStorage.getItem("token"),
                }
            }
        };
        const response2 = await axios.post(`${process.env.REACT_APP_CREDENTIALING_EVENT_SERVICE_URL}/api/requests`, tokenExchangePayload, {
            headers: {
                Authorization: `Bearer ${response1.data.data.access_token}`,
            },
        });
        localStorage.setItem("credentialing_token", response2.data.data.access_token);
        return response2.data.data.access_token;
    } catch (error) {
        console.error("Error fetching credentialing token:", error);
    }
}
};
 const parseJwt = (token) => {
    let base64Url = token.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    const payload = JSON.parse(jsonPayload);
    if (payload && payload.exp) {
        const expirationTime = payload.exp * 1000; // Convert Unix timestamp to milliseconds
        const currentTime = Date.now();
        if (currentTime > expirationTime) {
          console.log("Token has expired.");
          return false;
        } else {
            return true;
        }
      }
    // console.log(JSON.parse(jsonPayload));
    // return JSON.parse(jsonPayload);
  };
