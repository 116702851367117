import * as React from "react";
import {
  Typography,
  Grid,
  Container,
  Select,
  Checkbox,
  FormControlLabel,
  Button,
  MenuItem,
  MenuList,
  Box
} from "@mui/material";
import { useEffect } from "react";
import { ReactComponent as AnalyticsIcon } from "../../assets/images/analytics-black-icon.svg";
import { ReactComponent as CheckIcon } from "../../assets/images/white-tick.svg";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import MspInviteModal from "./TalentPool/MspInviteModal";
import { useState } from "react";
import { checkIsImage, saveCookie } from "../../helpers/helper-data";
import { danger } from "../../helpers/message-box";
import { useSelector } from "react-redux";
import constants from "../../helpers/constants";

export default function SwitchSideBar(props) {
  const appOrigin = useSelector((state) => state.location.origin);
  let navigate = useNavigate();
  const [option, setOption] = React.useState(null);
  const handleCloseAnalyticsSideBar = () => {
    props.handleOpenSwitch(false);
  };
  const handleCloseMenu = (event) => {
    props.handleOpenSwitch(false);
    // props.setOpen(false);
  };
  const [openMspInviteModal, setMspInviteModal] = useState(false);
  const handleOpenMspInviteModal = () => setMspInviteModal(true);
  const mspResponse = localStorage.getItem("mspEnterprsieData")
    ? JSON.parse(localStorage.getItem("mspEnterprsieData"))
    : null;
  //   const mspProfileImage = localStorage.getItem("MspEnterpriseImage") ? localStorage.getItem("MspEnterpriseImage") : null;
  const handleCloseMspInviteModal = () => {
    setMspInviteModal(false);
  };
  const handleEnterpriseChange = (value) => {
    if (localStorage.getItem("childEnterpriseId") != value.id) {
      let currentUser = (localStorage.getItem("enterprisesList") != null ? JSON.parse(localStorage.getItem("enterprisesList")) : [])?.filter((item) => {
        return item.id === value.id;
      });
      if (currentUser?.length > 0) {
        localStorage.setItem("enterpriseId", currentUser[0]?.id);
      }
      let getId = mspResponse?.id;
      let mspName = mspResponse?.establishmentName;
      // let getid = localStorage.getItem("enterpriseId");
      // let mspName =  localStorage.getItem("enterpriseName");
      localStorage.setItem("mspEnterpriseId", getId);
      localStorage.setItem("mspEnterpriseName", mspName);
      localStorage.setItem("childEnterpriseId", value?.id);
      // localStorage.setItem("enterpriseId", value?.id);
      localStorage.setItem("enterpriseName", value?.establishmentName);
      localStorage.setItem("enterpriseLogo", value?.logoFileSignedUrl);
      localStorage.setItem("navbarLogo", value?.navBarLogoSignedUrl);
      navigate("/enterprise/jobList");
      window.location.reload(true);
    } else {
      danger(`Already on ${value?.establishmentName}.`);
    }
  };
  const switchbackToMsp = () => {
    if (localStorage.getItem("childEnterpriseId")) {
      // localStorage.setItem("childEnterpriseId", F);
      localStorage.setItem("enterpriseId", localStorage.getItem("mspEnterpriseId"));
      saveCookie("enterprise_id", localStorage.getItem("mspEnterpriseId"), 365);
      localStorage.removeItem("childEnterpriseId");
      localStorage.removeItem("navbarLogo");
      localStorage.removeItem("mspEnterpriseId");
      if (localStorage.getItem("JD")) {
        localStorage.removeItem("JD");
      }
      if (localStorage.getItem("skills")) {
        localStorage.removeItem("skills");
      }
      if (localStorage.getItem("additionalDetails")) {
        localStorage.removeItem("additionalDetails");
      }
      // localStorage.removeItem("mspEnterprsieData");
      navigate("/enterprise/jobList");
      window.location.reload(true);
    } else {
      danger("Already on MSP Enterprise.");
    }
  };

  return (
    <>
      <Helmet>
        {appOrigin === "N" ?
        <title>Switch | {constants.APP_NAME_NEURODIVERSIFY}</title>
          : appOrigin === "H" ? <title>Switch | {constants.APP_NAME_DIRECT_HEALTH}</title> :
          <title>Switch | {constants.APP_NAME_GENERAL}</title>
        }
        {/* <title>Switch | SimplifyHire</title> */}
        <meta name="description" content={`Sidebar | ${constants.APP_NAME_GENERAL}`} />
        <link
          rel="simplify"
          href={process.env.REACT_APP_AWS_USER_POOL_REDIRECT_SIGN_IN}
        />
      </Helmet>
      <Grid
        item
        className="bg-white"
      // style={{
      //     width: '270px',
      //     height: '100vh',
      //     boxShadow: '0px 3.12501px 35.1564px rgba(0, 0, 0, 0.08)',
      // }}
      >
        <MenuList tabIndex={-1} className="pb-0">
          <Grid item className="pl-15 pr-20 mb-10 cursor-hover" onClick={switchbackToMsp}>
            {/* <Typography className="icon-button d-flex justify-end">
              <CloseIcon
                className="f-16 gray7 cursor-hover icon-button"
                onClick={handleCloseAnalyticsSideBar}
                onKeyPress={handleCloseAnalyticsSideBar}
                aria-label="close icon"
                tabIndex={0}
              />
            </Typography> */}
            <Typography className="d-flex align-center mt-10">
              {/* <AnalyticsIcon width="16px" height="16px" aria-label="Analytics icon" /> */}
              <Box
                className=" d-flex align-center cursor-hover"
                style={{ paddingTop: "2px" }}
                // onClick={switchbackToMsp}
              >
                {mspResponse && mspResponse.logoFileSignedUrl ? (
                  <img
                    width="30px"
                    height="30px"
                    //   onClick={switchbackToMsp}
                    style={{
                      objectFit: mspResponse.logoFileSignedUrl?.includes("gif")
                        ? "cover"
                        : "",
                    }}
                    className="default-img-border"
                    src={mspResponse.logoFileSignedUrl}
                    alt="Profile picture"
                  />
                ) : (
                  <Typography
                    className="default-img-border justify-center align-center d-flex primary-shadow-color bg-input f-14 font-weight-600 text-capitalize"
                    width="30px"
                    height="30px"
                    aria-label="Default image"
                  >
                    {mspResponse?.establishmentName?.trim().substring(0, 1)}
                  </Typography>
                )}
                <Typography className="font-weight-500 gray9 f-16 ml-10">
                  {mspResponse?.establishmentName}
                </Typography>
                {localStorage.getItem("childEnterpriseId") === null &&
                  <Typography className="align-center d-flex">
                    <CheckIcon width={"18px"} height={"18px"} className="fill-svg-icons"/>
                  </Typography>
                }
              </Box>
            </Typography>
          </Grid>
          {mspResponse?.childEnterprises?.length > 0 ? (
            <Grid
              item
              className="d-flex justify-between pt-20 pl-15 pr-20 mb-10 align-center"
            >
              <Typography className="d-flex align-center">
                {/* <AnalyticsIcon width="16px" height="16px" aria-label="Analytics icon" /> */}
                <Box
                  className="font-weight-600 gray9 f-16"
                  style={{ paddingTop: "2px" }}
                >
                  Switch to...
                </Box>
              </Typography>
            </Grid>
          ) : (
            <Grid></Grid>
          )}
          <Grid item
          // className="switch-overflow"
          >
            {mspResponse?.childEnterprises?.length > 0 &&
              mspResponse?.childEnterprises?.map((value, key) => {
                return (
                  <MenuItem
                    className="analytic-res-align p-15 sub-text-color"
                    onClick={() => handleEnterpriseChange(value)}
                    key={key}
                    value={value.id}
                    style={{
                      backgroundColor:
                        localStorage.getItem("childEnterpriseId") &&
                        localStorage.getItem("childEnterpriseId") == value.id &&
                        getComputedStyle(document.documentElement).getPropertyValue('--secondary-color'),
                      color:
                        localStorage.getItem("childEnterpriseId") &&
                        localStorage.getItem("childEnterpriseId") == value.id &&
                        getComputedStyle(document.documentElement).getPropertyValue('--primary-color'),
                    }}
                  >
                    <Typography className="d-flex justify-between align-center w-100">
                      <Typography className="f-14 font-weight-500 d-flex align-center">
                        {value.logoFileSignedUrl ? (
                          <img
                            width="30px"
                            height="30px"
                            style={{
                              objectFit: value.logoFileSignedUrl.includes("gif")
                                ? "cover"
                                : "",
                            }}
                            className="default-img-border"
                            src={
                              value?.logoFileSignedUrl && value?.logoFileSignedUrl
                            }
                            alt="Profile picture"
                          />
                        ) : (
                          <Typography
                            className="default-img-border justify-center align-center d-flex primary-shadow-color bg-input f-14 font-weight-600 text-capitalize"
                            width="30px"
                            height="30px"
                            aria-label="Default image"
                          >
                            {value?.establishmentName?.trim().substring(0, 1)}
                          </Typography>
                        )}
                        <Typography className="pl-5 break-space">
                          {value.establishmentName}
                        </Typography>
                      </Typography>
                      {localStorage.getItem("childEnterpriseId") == value.id &&
                        <Typography className="align-center d-flex">
                          <CheckIcon width={"18px"} height={"18px"} className="fill-svg-icons"/>
                        </Typography>
                      }
                    </Typography>
                  </MenuItem>
                );
              })}
          </Grid>
          {!localStorage.getItem("childEnterpriseId") && (
            <MenuItem
              // className="shadow-1 inviteViaEmail"
              onClick={handleOpenMspInviteModal}
              style={{
                color: getComputedStyle(document.documentElement).getPropertyValue('--primary-color'),
                marginTop: "15px",
                fontSize: "15px",
                padding: "15px 0px",
                justifyContent: "center",
                backgroundColor: "rgb(242, 244, 247)",
              }}
            >
              Invite via Email
            </MenuItem>
          )}
        </MenuList>
      </Grid>
      <MspInviteModal
        open={openMspInviteModal}
        close={handleCloseMspInviteModal}
      />
    </>
  );
}
