import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  Modal,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import CloseIcon from "@mui/icons-material/Close";
import playIcon from "../../../assets/images/playIcon.svg";
import profileTitleIcon from "../../../assets/images/profileTitleIcon.svg";
import matchingBreakdownSkillIcon from "../../../assets/images/matchingBreakdownSkillIcon.svg";
import industryIcon from "../../../assets/images/industryIcon.svg";
import certificationIcon from "../../../assets/images/certificationIcon.svg";
import experienceIcon from "../../../assets/images/experienceIcon.svg";
import profileEducationIcon from "../../../assets/images/profileEducationIcon.svg";
import collapseIcon from "../../../assets/images/collapseIcon.svg";
import expandIcon from "../../../assets/images/expandIcon.svg";
import ageingIcon from "../../../assets/images/ageingIcon.svg";
import { ReactComponent as NewAgeingIcon } from "../../../assets/images/newAgeingIcon.svg";
import filledProgressDot from "../../../assets/images/filledProgressDot.svg";
import semiFilledProgressDot from "../../../assets/images/semiFilledProgressDot.svg";
import unfilledProgressDot from "../../../assets/images/unfilledProgressDot.svg";
import ProgressBar from "../../../Components/shared/ProgressBar";
import { checkIsImage } from "../../../helpers/helper-data";
import filledYellow from "../../../assets/images/filledYellow.svg";
import semiFilledyellow from "../../../assets/images/semiFilledyellow.svg";
import unfilledYellow from "../../../assets/images/unfilledYellow.svg";
import filledRed from "../../../assets/images/filledRed.svg";
import unfilledRed from "../../../assets/images/unfilledRed.svg";
import semiFilledRed from "../../../assets/images/semiFilledRed.svg";
import filledGreen from "../../../assets/images/newFilledGreen.svg";
import unfilledGreen from "../../../assets/images/unfilledGreen.svg";
import semiFilledGreen from "../../../assets/images/newSemiFilledGreen.svg";
import { Tooltip, TooltipTrigger, TooltipContent } from 'react-tippy';
import { useSelector } from "react-redux";
// import semiFilledGreen from "../../../assets/images/semiFilledGreen.svg";

const MatchingScoreBreakdownModal = React.memo(function MatchingScoreBreakdownModal(props) {
  const appOrigin = useSelector((state) => state.location.origin);
  const { open3, handleClosePopover } = props;
  const [skillExpanded, setSkillExpanded] = useState(true);
  const [industryExpanded, setIndustryExpanded] = useState(true);
  const [overallExperienceExpanded, setOverallExperienceExpanded] = useState(true);
  const [profileTitleExpanded, setProfileTitleExpanded] = useState(true);
  const [educationExpanded, setEducationExpanded] = useState(true);
  const [certificationExpanded, setCertificationExpanded] = useState(true);
  const getValidMonth = (lastUsedMonth) => {
    if (lastUsedMonth) {
      let month = parseInt(lastUsedMonth);
      if (month > 12) {
        return ("0" + String(parseInt(month / 10)));
      }
    }
    return lastUsedMonth;
  };
  const getAgeingOpacity = (lastUsedYear, lastUsedMonth) => {
    if (lastUsedYear === -1 || lastUsedYear === 1970) {
      return 0.15;
    } else {
      let date = new Date();
      let yearDifference = date.getFullYear() - lastUsedYear;
      let validMonth = parseInt(getValidMonth(lastUsedMonth));
      let monthDifference = (yearDifference === 0 ? ((date.getMonth() + 1) - validMonth) : (((date.getMonth() + 1) - validMonth) >= 0 ? ((date.getMonth() + 1) - validMonth) : ((date.getMonth() + 13) - validMonth)));
      if (yearDifference < 0) {
        return 0.15;
      }
      if (yearDifference < 8) {
        return ((yearDifference / 10 + 0.2) + monthDifference / 100);
      }
    }
    return 1;
  };
  const isDetailsExist = (details) => {
    if (details?.matched?.length > 0 || details?.partialMatched?.length > 0 || (details?.unmatched?.length > 0 && (JSON.stringify(details?.unmatched[0]) !== '{}'))) {
      console.log("here");
      return true;
    }
    return false;
  };
  function formatNumber(number) {
    // Convert the input string to a number
    let parsedNumber = parseFloat(number);
    // Check if parsedNumber is NaN (Not a Number) after conversion
    if (isNaN(parsedNumber)) {
      // If parsing fails, return the input string as-is
      return number;
    }
    // Check if the number is an integer and handle the case for 4.0 specifically
    if (Number.isInteger(parsedNumber) || parsedNumber.toFixed(1) === parseInt(parsedNumber).toFixed(1)) {
      return parseInt(parsedNumber); // Output: 4
    } else {
      return parsedNumber; // Output: 4.0 or any other decimal value
    }
  }
  const handleClose = (e) => {
    handleClosePopover(e);
    setSkillExpanded(true);
    setIndustryExpanded(true);
    setOverallExperienceExpanded(true);
    setProfileTitleExpanded(true);
    setEducationExpanded(true);
    setCertificationExpanded(true);
  };
  const getProgressEmpty = (bandColor) => {
    if (bandColor === "RED_BAND") {
      return <img src={unfilledRed} />;
    } else if (bandColor === "GREEN_BAND") {
      return <img src={unfilledGreen} />;
    } else {
      return <img src={unfilledYellow} />;
    }
  };
  const getProgresssemiFilled = (bandColor) => {
    if (bandColor === "RED_BAND") {
      return <img src={semiFilledRed} />;
    } else if (bandColor === "GREEN_BAND") {
      return <img src={semiFilledGreen} />;
    } else {
      return <img src={semiFilledyellow} />;
    }
  };
  const getProgressFilled = (bandColor) => {
    if (bandColor === "RED_BAND") {
      return <img src={filledRed} />;
    } else if (bandColor === "GREEN_BAND") {
      return <img src={filledGreen} />;
    } else {
      return <img src={filledYellow} />;
    }
  };
  return (
    <Modal
      open={open3}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modal-content checklist-modal responsive-checklist-modal inputcontrasts">
        <Grid container item id="modal-title" className="mb-5">
          <Grid item lg={1} md={1} xs={1}>
            <Grid item
              className="jobProfile pl-0 p-relative"
              tabIndex={0}
            >
              {(props?.candidatePopUp?.mediaFile === "****" ||
                props?.candidatePopUp?.mediaFileSigned === null ||
                props?.candidatePopUp?.mediaFileSigned === "null" ||
                props?.candidatePopUp?.mediaFileSigned === "") && (
                  <Typography className="default-img-border justify-center align-center d-flex primary-shadow-color bg-input f-20 font-weight-700 text-capitalize"
                    width="50px" height="50px" aria-label="Default image">
                    {props?.candidatePopUp?.firstName.trim().substring(0, 1)}
                  </Typography>
                )}
              {props?.candidatePopUp?.mediaFile !== "****" &&
                props?.candidatePopUp?.mediaFileSigned !== null &&
                props?.candidatePopUp?.mediaFileSigned !== "null" &&
                props?.candidatePopUp?.mediaFileSigned !== "" &&
                !checkIsImage(props?.candidatePopUp?.mediaFileSigned) && (
                  <>
                    <video
                      width="50px"
                      height="50px"
                      className="default-img-border profile-video-thumbnail cursor-hover"
                      src={props?.candidatePopUp?.mediaFileSigned}
                      onClick={(e) => {
                        props?.handleOpenVideoPreview(
                          e,
                          props?.candidatePopUp?.mediaFileSigned,
                          props?.candidatePopUp?.firstName + " " + props?.candidatePopUp?.lastName
                        );
                      }}
                      muted
                      onKeyPress={(e) => {
                        props?.handleOpenVideoPreview(
                          e,
                          props?.candidatePopUp?.mediaFileSigned,
                          props?.candidatePopUp?.firstName + " " + props?.candidatePopUp?.lastName
                        );
                      }}
                      tabIndex={0}
                    />
                    <img
                      alt="Play Icon"
                      onClick={(e) => {
                        props?.handleOpenVideoPreview(
                          e,
                          props?.candidatePopUp?.mediaFileSigned,
                          props?.candidatePopUp?.firstName + " " + props?.candidatePopUp?.lastName
                        );
                      }}
                      className="play-icon-profile-detail cursor-hover"
                      src={playIcon}
                      tabIndex={0}
                      onKeyPress={(e) => {
                        props?.handleOpenVideoPreview(
                          e,
                          props?.candidatePopUp?.mediaFileSigned,
                          props?.candidatePopUp?.firstName + " " + props?.candidatePopUp?.lastName
                        );
                      }}
                    />
                  </>
                )}
              {props?.candidatePopUp?.mediaFile !== "****" &&
                props?.candidatePopUp?.mediaFileSigned !== null &&
                props?.candidatePopUp?.mediaFileSigned !== "null" &&
                props?.candidatePopUp?.mediaFileSigned !== "" &&
                checkIsImage(props?.candidatePopUp?.mediaFileSigned) && (
                  <img
                    className="default-img-border"
                    src={props?.candidatePopUp?.mediaFileSigned}
                    alt="img"
                    width="72px"
                    height="72px"
                  />
                )}
            </Grid>
          </Grid>
          <Grid item lg={10.8} md={10.8} xs={10.5}>
            <Typography className="matching-breakdown-responsive">
              <Box className="break-all font-weight-700 f-20 heading-text-color">
                <span>Matching Breakdown</span>
              </Box>
              <Box className="break-all f-14 gray9 job-option-align" style={{ marginTop: "-10px" }}>
                <span>{props?.candidatePopUp?.firstName}{props?.candidatePopUp?.lastName ? (" " + props?.candidatePopUp?.lastName) : ""}'s profile is matching the job by <Tooltip role="tooltip" title="Matching score" position="bottom"
                  trigger="mouseenter"
                  animation="fade"
                  arrow>
                  <Typography className="p-5 align-center" style={{ height: "50px", width: "50px", display: "inline-block", marginLeft: "5px" }}>
                    <CircularProgressbar
                      classes={{
                        root: "CircularProgressbar",
                        trail: "matchingBreakdownTrailColor",
                        path: 'CircularProgressbar-path',
                        text: 'CircularProgressbar-text',
                        background: 'CircularProgressbar-background',
                      }}
                      aria-label="Circular Progress"
                      strokeWidth={12}
                      value={
                        props?.candidatePopUp?.matchingScore
                          ? props?.candidatePopUp.matchingScore
                          : 0
                      }
                      text={`${Math.round(
                        props?.candidatePopUp?.matchingScore
                          ? props?.candidatePopUp.matchingScore
                          : 0
                      )}%`}
                      styles={buildStyles({
                        textColor: "#027A48",
                        pathColor: "#027A48",
                        trailColor: "#adc6ce",
                        textSize: "25px",
                      })}
                    />
                    {/* <span className="d-flex align-center success-color f-14 font-weight-500 ml-5">
                      {`${Math.round(
                        props?.candidatePopUp?.matchingScore
                        ? props?.candidatePopUp.matchingScore
                          : 0
                      )}%`}
                    </span> */}
                  </Typography>
                </Tooltip></span>
              </Box>
            </Typography>
          </Grid>
          {/* <Grid item lg={1.2} md={1.2} xs={1.2}></Grid> */}
          <Grid item lg={0.2} md={0.2} xs={0.5}>
            <Typography align="right" className="cursor-hover sub-text-color icon-button mt-5">
              <CloseIcon onClick={handleClose} onKeyPress={handleClose} tabIndex={0} aria-label="Close icon" />
            </Typography>
          </Grid>
          <Grid container lg={10} md={10} xs={9} className="candidateList-align">
            <Grid item lg={0.5} md={0.5} xs={0.5}></Grid>
            <Grid item lg={3.5} md={3.5} xs={3} className="f-12 font-weight-700 gray9 galaxy-font-score">Section</Grid>
            <Grid item lg={4} md={4} xs={4}></Grid>
            <Grid item lg={4} md={4} xs={2} className="f-12 font-weight-700 gray9 galaxy-font-score">Remarks</Grid>
          </Grid>
          <Grid container lg={2} md={2} xs={3} className="candidateList-align">
            <Grid item lg={2} md={2} xs={2}></Grid>
            <Grid item lg={10} md={10} xs={12} className="f-12 font-weight-700 gray9 galaxy-font-score">Matched</Grid>
          </Grid>
          <Grid item lg={11.5} md={11.5} xs={11.5} className="border-bottom-1 mt-10"></Grid>
          <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10"></Grid>
        </Grid>
        <Grid item lg={12} md={12} xs={12} style={{ maxHeight: "500px", overflowY: "overlay" }}>
          <Grid container className="mt-5">
            {(isDetailsExist(props?.candidatePopUp?.matchingPercentage?.Skill) || props?.candidatePopUp?.matchingPercentage?.Skill?.weightage > 0) ?
              <>
                <Grid container lg={10} md={10} xs={9}>
                  <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-5">
                  </Grid>
                  <Grid item lg={3.2} md={3.2} xs={3.2} className="mt-8">
                    <Typography className="f-12 font-weight-700 galaxy-font-score">{appOrigin === "H" ? "Specialization" : "Skill"}</Typography>
                  </Grid>
                  <Grid item lg={7.3} md={7.3} xs={7.3} className="mt-14">
                  </Grid>
                  <Grid item lg={1} md={1} xs={1} className="mt-16 pl-10">
                  </Grid>
                  {(isDetailsExist(props?.candidatePopUp?.matchingPercentage?.Skill) === false && props?.candidatePopUp?.matchingPercentage?.Skill?.weightage > 0) && (<>
                    <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10 galaxy-margin-top-5">
                      <CheckCircleIcon
                        className="galaxy-close-icon"
                        style={{
                          marginRight: 5,
                          width: "14px",
                          color: '#828282',
                        }}
                      />
                    </Grid>
                    <Grid item lg={3.5} md={3.5} xs={3.5} className="mt-10">
                      <Typography className="f-12 font-weight-400 galaxy-font-score exp-skill-align" style={{ color: "#828282" }}>{"Not Available"}</Typography>
                    </Grid>
                    <Grid item lg={4} md={4} xs={4} className="mt-10"></Grid>
                    <Grid item lg={4} md={4} xs={4} className="mt-10">
                      <Typography className="f-12 font-weight-700 galaxy-font-score" style={{ color: "#696871" }}>Unmatched</Typography>
                    </Grid>
                  </>)}
                  {isDetailsExist(props?.candidatePopUp?.matchingPercentage?.Skill) &&
                    <>
                      <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10"></Grid>
                      <Grid item lg={3.5} md={3.5} xs={3.5} className="mt-10">
                        <Typography className="f-12 font-weight-500 gray9 galaxy-font-score">Required</Typography>
                      </Grid>
                      <Grid item lg={0.2} md={0.2} xs={0.2}></Grid>
                      <Grid item lg={3.8} md={3.8} xs={3.8} className="mt-10">
                        <Typography className="f-12 font-weight-500 gray9 galaxy-font-score">Ageing</Typography>
                      </Grid>
                      <Grid item lg={4} md={4} xs={4} className="mt-10">
                      </Grid>
                      {props?.candidatePopUp?.matchingPercentage?.Skill?.matched?.map((skill, index) => {
                        return (<>
                          <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10"></Grid>
                          <Grid item lg={3.5} md={3.5} xs={3.5} className="mt-10">
                            <Typography style={{ background: getComputedStyle(document.documentElement).getPropertyValue('--primary-color'), borderRadius: "13px", padding: "2px 5px", color: "#FFFFFF", fontWeight: "500", fontSize: 13, display: "inline-block", }} className="galaxy-font-score">{skill.jobSkill}</Typography>
                          </Grid>
                          <Grid item lg={0.2} md={0.2} xs={0.2}></Grid>
                          <Grid item lg={0.3} md={0.3} xs={0.3} className="mt-10">
                            <NewAgeingIcon className="fill-svg-icons galaxy-close-icon galaxy-image-left" style={{ opacity: (skill.lastUsed === "0" || String(skill.lastUsed).includes("197001") || skill.lastUsed === null) ? getAgeingOpacity(-1) : getAgeingOpacity(parseInt(String(skill.lastUsed).substring(0, 4)), parseInt(String(skill.lastUsed).substring(4, 6))) }} />
                          </Grid>
                          <Grid item lg={3.5} md={3.5} xs={3.5} className="mt-10 campaign-score-align">
                            <Typography className="f-12 font-weight-400 sub-text-color galaxy-font-score galaxy-score-last-used">Last used: {(skill.lastUsed === "0" || String(skill.lastUsed).includes("197001") || skill.lastUsed === null) ? "Currently in use" : (String(skill.lastUsed).includes("-") ? String(skill.lastUsed).substring(0, 4) + "/" + getValidMonth(String(skill.lastUsed).substring(5, 7)) : String(skill.lastUsed).substring(0, 4) + "/" + getValidMonth(String(skill.lastUsed).substring(4, 6)))}</Typography>
                          </Grid>
                          <Grid item lg={4} md={4} xs={4} className="mt-10">
                            <Typography className="f-12 font-weight-700 galaxy-font-score" style={{ color: "#696871" }}>Matched</Typography>
                          </Grid></>);
                      })}
                      {props?.candidatePopUp?.matchingPercentage?.Skill?.partialMatched?.map((skill, index) => {
                        return (<>
                          <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10"></Grid>
                          <Grid item lg={3.5} md={3.5} xs={3.5} className="mt-10">
                            <Typography style={{ background: getComputedStyle(document.documentElement).getPropertyValue('--primary-color'), opacity: 0.7, borderRadius: "13px", padding: "2px 5px", color: "#FFFFFF", fontWeight: "500", fontSize: 13, display: "inline-block", }} className="galaxy-font-score">{skill.jobSkill}</Typography>
                          </Grid>
                          <Grid item lg={0.2} md={0.2} xs={0.2}></Grid>
                          <Grid item lg={0.3} md={0.3} xs={0.3} className="mt-10">
                            <NewAgeingIcon className="fill-svg-icons galaxy-close-icon galaxy-image-left" style={{ opacity: (skill.lastUsed === "0" || String(skill.lastUsed).includes("197001") || skill.lastUsed === null) ? getAgeingOpacity(-1) : getAgeingOpacity(parseInt(String(skill.lastUsed).substring(0, 4)), parseInt(String(skill.lastUsed).substring(4, 6))) }} />
                          </Grid>
                          <Grid item lg={3.5} md={3.5} xs={3.5} className="mt-10 campaign-score-align">
                            <Typography className="f-12 font-weight-400 sub-text-color galaxy-font-score galaxy-score-last-used">Last used: {(skill.lastUsed === "0" || String(skill.lastUsed).includes("197001") || skill.lastUsed === null) ? "Currently in use" : (String(skill.lastUsed).includes("-") ? String(skill.lastUsed).substring(0, 4) + "/" + getValidMonth(String(skill.lastUsed).substring(5, 7)) : String(skill.lastUsed).substring(0, 4) + "/" + getValidMonth(String(skill.lastUsed).substring(4, 6)))}</Typography>
                          </Grid>
                          <Grid item lg={4} md={4} xs={4} className="mt-10">
                            <Typography className="f-12 font-weight-700 galaxy-font-score" style={{ color: "#696871" }}>Partial Match{skill.reasons?.length > 0 ? <span className="font-weight-500"> - {skill.reasons.map((reason, index) => `${((reason?.includes(",") && !reason?.includes(", ")) ? reason.split(",").join(", ") : reason) + (index < (skill.reasons.length - 1) ? '; ' : '')}`)}</span> : ""}</Typography>
                          </Grid></>);
                      })}
                      {Array.isArray(props?.candidatePopUp?.matchingPercentage?.Skill?.unmatched) && props?.candidatePopUp?.matchingPercentage?.Skill?.unmatched?.map((skill, index) => {
                        return (<>
                          <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10"></Grid>
                          <Grid item lg={3.5} md={3.5} xs={3.5} className="mt-10">
                            <Typography style={{ borderRadius: "13px", border: "1px solid #696871", padding: "2px 5px", color: "#696871", fontWeight: "500", fontSize: 12, display: "inline-block", }} className="galaxy-font-score">{skill.jobSkill}</Typography>
                          </Grid>
                          <Grid item lg={0.2} md={0.2} xs={0.2}></Grid>
                          <Grid item lg={3.8} md={3.8} xs={3.8} className="mt-10 sub-text-color">
                            {"-"}
                          </Grid>
                          <Grid item lg={4} md={4} xs={4} className="mt-10">
                            <Typography className="f-12 font-weight-700 galaxy-font-score" style={{ color: "#696871" }}>Unmatched</Typography>
                          </Grid></>);
                      })}
                    </>}
                </Grid>
                <Grid container lg={2} md={2} xs={3}>
                  <Grid item lg={2} md={2} xs={2}></Grid>
                  <Grid item className="mt-5">
                    {(props?.candidatePopUp?.matchingPercentage?.Skill ? Math.round(props?.candidatePopUp?.matchingPercentage?.Skill.percentage) : 0) >= 20 ?
                      getProgressFilled(props?.candidatePopUp?.matchingPercentage?.Skill.bandColor)
                      :
                      ((props?.candidatePopUp?.matchingPercentage?.Skill ? Math.round(props?.candidatePopUp?.matchingPercentage?.Skill.percentage) : 0) >= 10 ?
                        getProgresssemiFilled(props?.candidatePopUp?.matchingPercentage?.Skill.bandColor)
                        :
                        getProgressEmpty(props?.candidatePopUp?.matchingPercentage?.Skill.bandColor))
                    }
                    <Box style={{ display: "inline-block", width: "2px" }}></Box>
                    {(props?.candidatePopUp?.matchingPercentage?.Skill ? Math.round(props?.candidatePopUp?.matchingPercentage?.Skill.percentage) : 0) >= 40 ?
                      getProgressFilled(props?.candidatePopUp?.matchingPercentage?.Skill.bandColor)
                      :
                      ((props?.candidatePopUp?.matchingPercentage?.Skill ? Math.round(props?.candidatePopUp?.matchingPercentage?.Skill.percentage) : 0) >= 30 ?
                        getProgresssemiFilled(props?.candidatePopUp?.matchingPercentage?.Skill.bandColor)
                        :
                        getProgressEmpty(props?.candidatePopUp?.matchingPercentage?.Skill.bandColor))
                    }
                    <Box style={{ display: "inline-block", width: "2px" }}></Box>
                    {(props?.candidatePopUp?.matchingPercentage?.Skill ? Math.round(props?.candidatePopUp?.matchingPercentage?.Skill.percentage) : 0) >= 60 ?
                      getProgressFilled(props?.candidatePopUp?.matchingPercentage?.Skill.bandColor)
                      :
                      ((props?.candidatePopUp?.matchingPercentage?.Skill ? Math.round(props?.candidatePopUp?.matchingPercentage?.Skill.percentage) : 0) >= 50 ?
                        getProgresssemiFilled(props?.candidatePopUp?.matchingPercentage?.Skill.bandColor)
                        :
                        getProgressEmpty(props?.candidatePopUp?.matchingPercentage?.Skill.bandColor))
                    }
                    <Box style={{ display: "inline-block", width: "2px" }}></Box>
                    {(props?.candidatePopUp?.matchingPercentage?.Skill ? Math.round(props?.candidatePopUp?.matchingPercentage?.Skill.percentage) : 0) >= 80 ?
                      getProgressFilled(props?.candidatePopUp?.matchingPercentage?.Skill.bandColor)
                      :
                      ((props?.candidatePopUp?.matchingPercentage?.Skill ? Math.round(props?.candidatePopUp?.matchingPercentage?.Skill.percentage) : 0) >= 70 ?
                        getProgresssemiFilled(props?.candidatePopUp?.matchingPercentage?.Skill.bandColor)
                        :
                        getProgressEmpty(props?.candidatePopUp?.matchingPercentage?.Skill.bandColor))
                    }
                    <Box style={{ display: "inline-block", width: "2px" }}></Box>
                    {(props?.candidatePopUp?.matchingPercentage?.Skill ? Math.round(props?.candidatePopUp?.matchingPercentage?.Skill.percentage) : 0) >= 100 ?
                      getProgressFilled(props?.candidatePopUp?.matchingPercentage?.Skill.bandColor)
                      :
                      ((props?.candidatePopUp?.matchingPercentage?.Skill ? Math.round(props?.candidatePopUp?.matchingPercentage?.Skill.percentage) : 0) >= 90 ?
                        getProgresssemiFilled(props?.candidatePopUp?.matchingPercentage?.Skill.bandColor)
                        :
                        getProgressEmpty(props?.candidatePopUp?.matchingPercentage?.Skill.bandColor))
                    }
                    <Typography className="f-10 font-weight-400 galaxy-font-score" style={{ color: "#696871" }}>{(props?.candidatePopUp?.matchingPercentage?.Skill ? Math.round(props?.candidatePopUp?.matchingPercentage?.Skill.percentage) : 0) + "% Matched"}</Typography>
                  </Grid>
                </Grid>
              </> :
              <>
                {props?.candidatePopUp?.matchingPercentage?.Skill ?
                  <>
                    <Grid container lg={6} md={6} xs={6}>
                      <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10">
                      </Grid>
                      <Grid item lg={11.5} md={11.5} xs={11.5} className="mt-10">
                        <Typography className="f-12 font-weight-700 galaxy-font-score" style={{ paddingLeft: "12px" }}>{appOrigin === "H" ? "Specialization" : "Skill"}</Typography>
                      </Grid>
                    </Grid>
                    <Grid item lg={5} md={5} xs={5} className="mt-10 ml-20" >
                      <Typography className="f-12 font-weight-700 galaxy-font-score" style={{ color: "#696871", textAlign: "right" }}>Disabled from matching weightage</Typography>
                    </Grid>
                  </> : <Grid container lg={6} md={6} xs={6}>
                    <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10">
                    </Grid>
                    <Grid item lg={11.5} md={11.5} xs={11.5} className="mt-10">
                      <Typography className="f-12 font-weight-700 galaxy-font-score" style={{ paddingLeft: "12px" }}>{appOrigin === "H" ? "Specialization" : "Skill"}</Typography>
                    </Grid>
                  </Grid>
                }
              </>
            }
            <Grid item lg={11.5} md={11.5} xs={11.5} className="border-bottom-1 mt-10"></Grid>
            <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10"></Grid>
            {((isDetailsExist(props?.candidatePopUp?.matchingPercentage?.Industry) || isDetailsExist(props?.candidatePopUp?.matchingPercentage?.subCategory)) && props?.candidatePopUp?.matchingPercentage?.Industry?.weightage > 0) ?
              <>
                <Grid container lg={10} md={10} xs={9}>
                  <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10">
                  </Grid>
                  <Grid item lg={11.5} md={11.5} xs={11.5} className="mt-10">
                    <Typography className="f-12 font-weight-700 galaxy-font-score">Industry</Typography>
                  </Grid>
                  {((isDetailsExist(props?.candidatePopUp?.matchingPercentage?.Industry) || isDetailsExist(props?.candidatePopUp?.matchingPercentage?.subCategory)) && props?.candidatePopUp?.matchingPercentage?.Industry?.weightage > 0) &&
                    <>
                      {props?.candidatePopUp?.matchingPercentage?.Industry?.matched?.map((industry, index) => {
                        return (<>
                          <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10 galaxy-margin-top-5">
                            <CheckCircleIcon
                              className="galaxy-close-icon"
                              style={{
                                marginRight: 5,
                                width: "14px",
                                color: getComputedStyle(document.documentElement).getPropertyValue('--primary-color'),
                              }}
                            />
                          </Grid>
                          <Grid item lg={3.5} md={3.5} xs={3.5} className="mt-10">
                            <Typography className="f-12 font-weight-400 galaxy-font-score exp-skill-align" style={{ color: getComputedStyle(document.documentElement).getPropertyValue('--primary-color') }}>{industry.Industry}</Typography>
                          </Grid>
                          <Grid item lg={4} md={4} xs={4} className="mt-10"></Grid>
                          <Grid item lg={4} md={4} xs={4} className="mt-10">
                            <Typography className="f-12 font-weight-700 galaxy-font-score" style={{ color: "#696871" }}>Matched</Typography>
                          </Grid></>);
                      })}
                      {props?.candidatePopUp?.matchingPercentage?.subCategory?.matched?.map((subCategory, index) => {
                        return (<>
                          <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10 galaxy-margin-top-5">
                            <CheckCircleIcon
                              className="galaxy-close-icon"
                              style={{
                                marginRight: 5,
                                width: "14px",
                                color: getComputedStyle(document.documentElement).getPropertyValue('--primary-color'),
                              }}
                            />
                          </Grid>
                          <Grid item lg={3.5} md={3.5} xs={3.5} className="mt-10">
                            <Typography className="f-12 font-weight-400 galaxy-font-score exp-skill-align" style={{ color: getComputedStyle(document.documentElement).getPropertyValue('--primary-color') }}>{subCategory.subCategory}</Typography>
                          </Grid>
                          <Grid item lg={4} md={4} xs={4} className="mt-10"></Grid>
                          <Grid item lg={4} md={4} xs={4} className="mt-10">
                            <Typography className="f-12 font-weight-700 galaxy-font-score" style={{ color: "#696871" }}>Matched</Typography>
                          </Grid></>);
                      })}
                      {props?.candidatePopUp?.matchingPercentage?.Industry?.partialMatched?.map((industry, index) => {
                        return (<>
                          <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10 galaxy-margin-top-5">
                            <CheckCircleIcon
                              className="galaxy-close-icon"
                              style={{
                                marginRight: 5,
                                width: "14px",
                                color: getComputedStyle(document.documentElement).getPropertyValue('--primary-color'),
                                opacity: 0.7
                              }}
                            />
                          </Grid>
                          <Grid item lg={3.5} md={3.5} xs={3.5} className="mt-10">
                            <Typography className="f-12 font-weight-400 galaxy-font-score exp-skill-align" style={{ color: getComputedStyle(document.documentElement).getPropertyValue('--primary-color'), opacity: 0.7 }}>{industry.Industry}</Typography>
                          </Grid>
                          <Grid item lg={4} md={4} xs={4} className="mt-10"></Grid>
                          <Grid item lg={4} md={4} xs={4} className="mt-10">
                            <Typography className="f-12 font-weight-700 galaxy-font-score" style={{ color: "#696871" }}>Partial Match</Typography>
                          </Grid></>);
                      })}
                      {props?.candidatePopUp?.matchingPercentage?.subCategory?.partialMatched?.map((subCategory, index) => {
                        return (<>
                          <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10 galaxy-margin-top-5">
                            <CheckCircleIcon
                              className="galaxy-close-icon"
                              style={{
                                marginRight: 5,
                                width: "14px",
                                color: getComputedStyle(document.documentElement).getPropertyValue('--primary-color'),
                                opacity: 0.7
                              }}
                            />
                          </Grid>
                          <Grid item lg={3.5} md={3.5} xs={3.5} className="mt-10">
                            <Typography className="f-12 font-weight-400 galaxy-font-score exp-skill-align" style={{ color: getComputedStyle(document.documentElement).getPropertyValue('--primary-color'), opacity: 0.7 }}>{subCategory.subCategory}</Typography>
                          </Grid>
                          <Grid item lg={4} md={4} xs={4} className="mt-10"></Grid>
                          <Grid item lg={4} md={4} xs={4} className="mt-10">
                            <Typography className="f-12 font-weight-700 galaxy-font-score" style={{ color: "#696871" }}>Partial Match</Typography>
                          </Grid></>);
                      })}
                      {Array.isArray(props?.candidatePopUp?.matchingPercentage?.Industry?.unmatched) && props?.candidatePopUp?.matchingPercentage?.Industry?.unmatched?.map((industry, index) => {
                        return (<>
                          <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10 galaxy-margin-top-5">
                            <CheckCircleIcon
                              className="galaxy-close-icon"
                              style={{
                                marginRight: 5,
                                width: "14px",
                                color: "#828282",
                              }}
                            />
                          </Grid>
                          <Grid item lg={3.5} md={3.5} xs={3.5} className="mt-10">
                            <Typography className="f-12 font-weight-400 galaxy-font-score exp-skill-align" style={{ color: "#828282" }}>{industry.Industry}</Typography>
                          </Grid>
                          <Grid item lg={4} md={4} xs={4} className="mt-10"></Grid>
                          <Grid item lg={4} md={4} xs={4} className="mt-10">
                            <Typography className="f-12 font-weight-700 galaxy-font-score" style={{ color: "#696871" }}>Unmatched</Typography>
                          </Grid></>);
                      })}
                      {Array.isArray(props?.candidatePopUp?.matchingPercentage?.subCategory?.unmatched) && props?.candidatePopUp?.matchingPercentage?.subCategory?.unmatched?.map((subCategory, index) => {
                        return (<>
                          <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10 galaxy-margin-top-5">
                            <CheckCircleIcon
                              className="galaxy-close-icon"
                              style={{
                                marginRight: 5,
                                width: "14px",
                                color: "#828282",
                              }}
                            />
                          </Grid>
                          <Grid item lg={3.5} md={3.5} xs={3.5} className="mt-10">
                            <Typography className="f-12 font-weight-400 galaxy-font-score exp-skill-align" style={{ color: "#828282" }}>{subCategory.subCategory}</Typography>
                          </Grid>
                          <Grid item lg={4} md={4} xs={4} className="mt-10"></Grid>
                          <Grid item lg={4} md={4} xs={4} className="mt-10">
                            <Typography className="f-12 font-weight-700 galaxy-font-score" style={{ color: "#696871" }}>Unmatched</Typography>
                          </Grid></>);
                      })}
                    </>}
                </Grid>
                <Grid container lg={2} md={2} xs={3}>
                  <Grid item lg={2} md={2} xs={2}></Grid>
                  <Grid item className="mt-5">
                    {((props?.candidatePopUp?.matchingPercentage?.Industry && props?.candidatePopUp?.matchingPercentage?.subCategory) ? Math.round((props?.candidatePopUp?.matchingPercentage?.Industry.percentage + props?.candidatePopUp?.matchingPercentage?.subCategory.percentage) / 2) : 0) >= 20 ?
                      getProgressFilled(props?.candidatePopUp?.matchingPercentage?.Industry.bandColor)
                      :
                      (((props?.candidatePopUp?.matchingPercentage?.Industry && props?.candidatePopUp?.matchingPercentage?.subCategory) ? Math.round((props?.candidatePopUp?.matchingPercentage?.Industry.percentage + props?.candidatePopUp?.matchingPercentage?.subCategory.percentage) / 2) : 0) >= 10 ?
                        getProgresssemiFilled(props?.candidatePopUp?.matchingPercentage?.Industry.bandColor)
                        :
                        getProgressEmpty(props?.candidatePopUp?.matchingPercentage?.Industry.bandColor))
                    }
                    <Box style={{ display: "inline-block", width: "2px" }}></Box>
                    {((props?.candidatePopUp?.matchingPercentage?.Industry && props?.candidatePopUp?.matchingPercentage?.subCategory) ? Math.round((props?.candidatePopUp?.matchingPercentage?.Industry.percentage + props?.candidatePopUp?.matchingPercentage?.subCategory.percentage) / 2) : 0) >= 40 ?
                      getProgressFilled(props?.candidatePopUp?.matchingPercentage?.Industry.bandColor)
                      :
                      (((props?.candidatePopUp?.matchingPercentage?.Industry && props?.candidatePopUp?.matchingPercentage?.subCategory) ? Math.round((props?.candidatePopUp?.matchingPercentage?.Industry.percentage + props?.candidatePopUp?.matchingPercentage?.subCategory.percentage) / 2) : 0) >= 30 ?
                        getProgresssemiFilled(props?.candidatePopUp?.matchingPercentage?.Industry.bandColor)
                        :
                        getProgressEmpty(props?.candidatePopUp?.matchingPercentage?.Industry.bandColor))
                    }
                    <Box style={{ display: "inline-block", width: "2px" }}></Box>
                    {((props?.candidatePopUp?.matchingPercentage?.Industry && props?.candidatePopUp?.matchingPercentage?.subCategory) ? Math.round((props?.candidatePopUp?.matchingPercentage?.Industry.percentage + props?.candidatePopUp?.matchingPercentage?.subCategory.percentage) / 2) : 0) >= 60 ?
                      getProgressFilled(props?.candidatePopUp?.matchingPercentage?.Industry.bandColor)
                      :
                      (((props?.candidatePopUp?.matchingPercentage?.Industry && props?.candidatePopUp?.matchingPercentage?.subCategory) ? Math.round((props?.candidatePopUp?.matchingPercentage?.Industry.percentage + props?.candidatePopUp?.matchingPercentage?.subCategory.percentage) / 2) : 0) >= 50 ?
                        getProgresssemiFilled(props?.candidatePopUp?.matchingPercentage?.Industry.bandColor)
                        :
                        getProgressEmpty(props?.candidatePopUp?.matchingPercentage?.Industry.bandColor))
                    }
                    <Box style={{ display: "inline-block", width: "2px" }}></Box>
                    {((props?.candidatePopUp?.matchingPercentage?.Industry && props?.candidatePopUp?.matchingPercentage?.subCategory) ? Math.round((props?.candidatePopUp?.matchingPercentage?.Industry.percentage + props?.candidatePopUp?.matchingPercentage?.subCategory.percentage) / 2) : 0) >= 80 ?
                      getProgressFilled(props?.candidatePopUp?.matchingPercentage?.Industry.bandColor)
                      :
                      (((props?.candidatePopUp?.matchingPercentage?.Industry && props?.candidatePopUp?.matchingPercentage?.subCategory) ? Math.round((props?.candidatePopUp?.matchingPercentage?.Industry.percentage + props?.candidatePopUp?.matchingPercentage?.subCategory.percentage) / 2) : 0) >= 70 ?
                        getProgresssemiFilled(props?.candidatePopUp?.matchingPercentage?.Industry.bandColor)
                        :
                        getProgressEmpty(props?.candidatePopUp?.matchingPercentage?.Industry.bandColor))
                    }
                    <Box style={{ display: "inline-block", width: "2px" }}></Box>
                    {((props?.candidatePopUp?.matchingPercentage?.Industry && props?.candidatePopUp?.matchingPercentage?.subCategory) ? Math.round((props?.candidatePopUp?.matchingPercentage?.Industry.percentage + props?.candidatePopUp?.matchingPercentage?.subCategory.percentage) / 2) : 0) >= 100 ?
                      getProgressFilled(props?.candidatePopUp?.matchingPercentage?.Industry.bandColor)
                      :
                      (((props?.candidatePopUp?.matchingPercentage?.Industry && props?.candidatePopUp?.matchingPercentage?.subCategory) ? Math.round((props?.candidatePopUp?.matchingPercentage?.Industry.percentage + props?.candidatePopUp?.matchingPercentage?.subCategory.percentage) / 2) : 0) >= 90 ?
                        getProgresssemiFilled(props?.candidatePopUp?.matchingPercentage?.Industry.bandColor)
                        :
                        getProgressEmpty(props?.candidatePopUp?.matchingPercentage?.Industry.bandColor))
                    }
                    <Typography className="f-10 font-weight-400 galaxy-font-score" style={{ color: "#696871" }}>{((props?.candidatePopUp?.matchingPercentage?.Industry && props?.candidatePopUp?.matchingPercentage?.subCategory) ? Math.round((props?.candidatePopUp?.matchingPercentage?.Industry.percentage + props?.candidatePopUp?.matchingPercentage?.subCategory.percentage) / 2) : 0) + "% Matched"}</Typography>
                  </Grid>
                </Grid></> :
              <>
                {props?.candidatePopUp?.matchingPercentage?.Industry ?
                  <>
                    <Grid container lg={6} md={6} xs={6}>
                      <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10">
                      </Grid>
                      <Grid item lg={11.5} md={11.5} xs={11.5} className="mt-10">
                        <Typography className="f-12 font-weight-700 galaxy-font-score" style={{ paddingLeft: "12px" }}>Industry</Typography>
                      </Grid>
                    </Grid>
                    <Grid item lg={5} md={5} xs={5} className="mt-10 ml-20" >
                      <Typography className="f-12 font-weight-700 galaxy-font-score" style={{ color: "#696871", textAlign: "right" }}>Disabled from matching weightage</Typography>
                    </Grid>
                  </>
                  : <Grid container lg={6} md={6} xs={6}>
                    <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10">
                    </Grid>
                    <Grid item lg={11.5} md={11.5} xs={11.5} className="mt-10">
                      <Typography className="f-12 font-weight-700 galaxy-font-score" style={{ paddingLeft: "12px" }}>Industry</Typography>
                    </Grid>
                  </Grid>}
              </>
            }
            <Grid item lg={11.5} md={11.5} xs={11.5} className="border-bottom-1 mt-10"></Grid>
            <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10"></Grid>
            {(isDetailsExist(props?.candidatePopUp?.matchingPercentage?.Experience) || props?.candidatePopUp?.matchingPercentage?.ProfileTitle?.weightage > 0) ?
              <>
                <Grid container lg={10} md={10} xs={9}>
                  <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10">
                  </Grid>
                  <Grid item lg={11.5} md={11.5} xs={11.5} className="mt-10">
                    <Typography className="f-12 font-weight-700 galaxy-font-score">Overall Experience</Typography>
                  </Grid>
                  <>
                    <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10 galaxy-margin-top-5">
                      <CheckCircleIcon
                        className="galaxy-close-icon"
                        style={{
                          marginRight: 5,
                          width: "14px",
                          color: `${props?.candidatePopUp?.matchingPercentage?.Experience?.unmatched?.length ? '#828282' : (props?.candidatePopUp?.matchingPercentage?.Experience?.partialMatched?.length ? getComputedStyle(document.documentElement).getPropertyValue('--primary-color') : getComputedStyle(document.documentElement).getPropertyValue('--primary-color'))}`,
                          opacity: `${props?.candidatePopUp?.matchingPercentage?.Experience?.partialMatched?.length ? 0.7 : ''}`
                        }}
                      />
                    </Grid>
                    <Grid item lg={3.5} md={3.5} xs={3.5} className="mt-10">
                      <Typography className="f-12 font-weight-400 galaxy-font-score exp-skill-align" style={{
                        color: `${props?.candidatePopUp?.matchingPercentage?.Experience?.unmatched?.length ? '#828282' : (props?.candidatePopUp?.matchingPercentage?.Experience?.partialMatched?.length ? getComputedStyle(document.documentElement).getPropertyValue('--primary-color') : getComputedStyle(document.documentElement).getPropertyValue('--primary-color'))}`,
                        opacity: `${props?.candidatePopUp?.matchingPercentage?.Experience?.partialMatched?.length ? 0.7 : ''}`
                      }}>
                        Required Experience
                      </Typography>
                    </Grid>
                    <Grid item lg={4} md={4} xs={4} className="mt-10"></Grid>
                    <Grid item lg={4} md={4} xs={4} className="mt-10">
                      <Typography className="f-12 font-weight-700 galaxy-font-score" style={{ color: "#696871" }}>
                        {props?.candidatePopUp?.matchingPercentage?.Experience?.matched?.length
                          ? props?.candidatePopUp?.matchingPercentage?.Experience?.matched[0]?.yearsOfExperience.includes("-")
  ? (
    formatNumber(props?.candidatePopUp?.matchingPercentage?.Experience?.matched[0]?.yearsOfExperience.split("-")[0])
    + (
      props?.candidatePopUp?.matchingPercentage?.Experience?.matched[0]?.yearsOfExperience.split("-")[1] !== "null"
        ? " - "
          + formatNumber(props?.candidatePopUp?.matchingPercentage?.Experience?.matched[0]?.yearsOfExperience.split("-")[1])
          + (
            parseInt(props?.candidatePopUp?.matchingPercentage?.Experience?.matched[0]?.yearsOfExperience.split("-")[1]) !== "null"
              ? (
                parseInt(props?.candidatePopUp?.matchingPercentage?.Experience?.matched[0]?.yearsOfExperience.split("-")[1]) > 1
                  ? " years"
                  : " year"
              )
              : (
                parseInt(props?.candidatePopUp?.matchingPercentage?.Experience?.matched[0]?.yearsOfExperience.split("-")[0]) > 1
                  ? " years"
                  : " year"
              )
          )
        : parseInt(props?.candidatePopUp?.matchingPercentage?.Experience?.matched[0]?.yearsOfExperience.split("-")[0]) > 1
                  ? " years"
                  : " year"
    )
  )
  : props?.candidatePopUp?.matchingPercentage?.Experience?.matched[0]?.yearsOfExperience
                          : props?.candidatePopUp?.matchingPercentage?.Experience
                            ?.partialMatched?.length
                            ? props?.candidatePopUp?.matchingPercentage?.Experience?.partialMatched[0]?.yearsOfExperience.includes("-")
  ? (
    formatNumber(props?.candidatePopUp?.matchingPercentage?.Experience?.partialMatched[0]?.yearsOfExperience.split("-")[0])
    + (
      props?.candidatePopUp?.matchingPercentage?.Experience?.partialMatched[0]?.yearsOfExperience.split("-")[1] !== "null"
        ? " - "
          + formatNumber(props?.candidatePopUp?.matchingPercentage?.Experience?.partialMatched[0]?.yearsOfExperience.split("-")[1])
          + (
            parseInt(props?.candidatePopUp?.matchingPercentage?.Experience?.partialMatched[0]?.yearsOfExperience.split("-")[1]) !== "null"
              ? (
                parseInt(props?.candidatePopUp?.matchingPercentage?.Experience?.partialMatched[0]?.yearsOfExperience.split("-")[1]) > 1
                  ? " years"
                  : " year"
              )
              : (
                parseInt(props?.candidatePopUp?.matchingPercentage?.Experience?.partialMatched[0]?.yearsOfExperience.split("-")[0]) > 1
                  ? " years"
                  : " year"
              )
          )
        :  parseInt(props?.candidatePopUp?.matchingPercentage?.Experience?.partialMatched[0]?.yearsOfExperience.split("-")[0]) > 1
                  ? " years"
                  : " year"
    )
  )
  : props?.candidatePopUp?.matchingPercentage?.Experience?.partialMatched[0]?.yearsOfExperience
                            : props?.candidatePopUp?.matchingPercentage?.Experience?.unmatched?.length
  ? (
    props?.candidatePopUp?.matchingPercentage?.Experience?.unmatched[0]?.yearsOfExperience.includes("-")
      ? (
        formatNumber(props?.candidatePopUp?.matchingPercentage?.Experience?.unmatched[0]?.yearsOfExperience.split("-")[0])
        + (
          props?.candidatePopUp?.matchingPercentage?.Experience?.unmatched[0]?.yearsOfExperience.split("-")[1] != "null"
            ? " - "
              + formatNumber(props?.candidatePopUp?.matchingPercentage?.Experience?.unmatched[0]?.yearsOfExperience.split("-")[1])
              + (
                parseInt(props?.candidatePopUp?.matchingPercentage?.Experience?.unmatched[0]?.yearsOfExperience.split("-")[1]) != "null"
                  ? (
                    parseInt(props?.candidatePopUp?.matchingPercentage?.Experience?.unmatched[0]?.yearsOfExperience.split("-")[1]) > 1
                      ? " years"
                      : " year"
                  )
                  : (
                    parseInt(props?.candidatePopUp?.matchingPercentage?.Experience?.unmatched[0]?.yearsOfExperience.split("-")[0]) > 1
                      ? " years"
                      : " year"
                  )
              )
            : parseInt(props?.candidatePopUp?.matchingPercentage?.Experience?.unmatched[0]?.yearsOfExperience.split("-")[0]) > 1
                      ? " years"
                      : " year"
        )
      )
      : props?.candidatePopUp?.matchingPercentage?.Experience?.unmatched[0]?.yearsOfExperience
  )
  : "-"}
                      </Typography>
                    </Grid>
                    <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10 galaxy-margin-top-5">
                      <CheckCircleIcon
                        className="galaxy-close-icon"
                        style={{
                          marginRight: 5,
                          width: "14px",
                          color: `${props?.candidatePopUp?.matchingPercentage?.Experience?.unmatched?.length ? '#828282' : (props?.candidatePopUp?.matchingPercentage?.Experience?.partialMatched?.length ? getComputedStyle(document.documentElement).getPropertyValue('--primary-color') : getComputedStyle(document.documentElement).getPropertyValue('--primary-color'))}`,
                          opacity: `${props?.candidatePopUp?.matchingPercentage?.Experience?.partialMatched?.length ? 0.7 : ''}`
                        }}
                      />
                    </Grid>
                    <Grid item lg={3.5} md={3.5} xs={3.5} className="mt-10">
                      <Typography className="f-12 font-weight-400 galaxy-font-score exp-skill-align" style={{
                        color: `${props?.candidatePopUp?.matchingPercentage?.Experience?.unmatched?.length ? '#828282' : (props?.candidatePopUp?.matchingPercentage?.Experience?.partialMatched?.length ? getComputedStyle(document.documentElement).getPropertyValue('--primary-color') : getComputedStyle(document.documentElement).getPropertyValue('--primary-color'))}`,
                        opacity: `${props?.candidatePopUp?.matchingPercentage?.Experience?.partialMatched?.length ? 0.7 : ''}`
                      }}>
                        Candidate Experience
                      </Typography>
                    </Grid>
                    <Grid item lg={4} md={4} xs={4} className="mt-10"></Grid>
                    <Grid item lg={4} md={4} xs={4} className="mt-10">
                      <Typography className="f-12 font-weight-700 galaxy-font-score" style={{ color: "#696871" }}>{`${props?.candidatePopUp?.matchingPercentage?.Experience?.matched?.length ? formatNumber(props?.candidatePopUp?.matchingPercentage?.Experience?.matched[0]?.candidateYearsOfExperience) : (props?.candidatePopUp?.matchingPercentage?.Experience?.partialMatched?.length ? formatNumber(props?.candidatePopUp?.matchingPercentage?.Experience?.partialMatched[0]?.candidateYearsOfExperience) : (props?.candidatePopUp?.matchingPercentage?.Experience?.unmatched?.length ? formatNumber(props?.candidatePopUp?.matchingPercentage?.Experience?.unmatched[0]?.candidateYearsOfExperience) : 0))}` + `${props?.candidatePopUp?.matchingPercentage?.Experience?.matched?.length ? (props?.candidatePopUp?.matchingPercentage?.Experience?.matched[0]?.candidateYearsOfExperience > 1 ? " years" : " year") : (props?.candidatePopUp?.matchingPercentage?.Experience?.partialMatched?.length ? (props?.candidatePopUp?.matchingPercentage?.Experience?.partialMatched[0]?.candidateYearsOfExperience > 1 ? " years" : " year") : (props?.candidatePopUp?.matchingPercentage?.Experience?.unmatched?.length ? (props?.candidatePopUp?.matchingPercentage?.Experience?.unmatched[0]?.candidateYearsOfExperience > 1 ? " years" : " year") : " year"))}`}</Typography>
                    </Grid>
                  </>
                </Grid>
                <Grid container lg={2} md={2} xs={3}>
                  <Grid item lg={2} md={2} xs={2}></Grid>
                  <Grid item className="mt-5">
                    {(props?.candidatePopUp?.matchingPercentage?.Experience?.percentage ? Math.round(props?.candidatePopUp?.matchingPercentage?.Experience.percentage) : 0) >= 20 ?
                      getProgressFilled(props?.candidatePopUp?.matchingPercentage?.Experience.bandColor)
                      :
                      ((props?.candidatePopUp?.matchingPercentage?.Experience?.percentage ? Math.round(props?.candidatePopUp?.matchingPercentage?.Experience.percentage) : 0) >= 10 ?
                        getProgresssemiFilled(props?.candidatePopUp?.matchingPercentage?.Experience.bandColor)
                        :
                        getProgressEmpty(props?.candidatePopUp?.matchingPercentage?.Experience.bandColor))
                    }
                    <Box style={{ display: "inline-block", width: "2px" }}></Box>
                    {(props?.candidatePopUp?.matchingPercentage?.Experience?.percentage ? Math.round(props?.candidatePopUp?.matchingPercentage?.Experience.percentage) : 0) >= 40 ?
                      getProgressFilled(props?.candidatePopUp?.matchingPercentage?.Experience.bandColor)
                      :
                      ((props?.candidatePopUp?.matchingPercentage?.Experience?.percentage ? Math.round(props?.candidatePopUp?.matchingPercentage?.Experience.percentage) : 0) >= 30 ?
                        getProgresssemiFilled(props?.candidatePopUp?.matchingPercentage?.Experience.bandColor)
                        :
                        getProgressEmpty(props?.candidatePopUp?.matchingPercentage?.Experience.bandColor))
                    }
                    <Box style={{ display: "inline-block", width: "2px" }}></Box>
                    {(props?.candidatePopUp?.matchingPercentage?.Experience?.percentage ? Math.round(props?.candidatePopUp?.matchingPercentage?.Experience.percentage) : 0) >= 60 ?
                      getProgressFilled(props?.candidatePopUp?.matchingPercentage?.Experience.bandColor)
                      :
                      ((props?.candidatePopUp?.matchingPercentage?.Experience?.percentage ? Math.round(props?.candidatePopUp?.matchingPercentage?.Experience.percentage) : 0) >= 50 ?
                        getProgresssemiFilled(props?.candidatePopUp?.matchingPercentage?.Experience.bandColor)
                        :
                        getProgressEmpty(props?.candidatePopUp?.matchingPercentage?.Experience.bandColor))
                    }
                    <Box style={{ display: "inline-block", width: "2px" }}></Box>
                    {(props?.candidatePopUp?.matchingPercentage?.Experience?.percentage ? Math.round(props?.candidatePopUp?.matchingPercentage?.Experience.percentage) : 0) >= 80 ?
                      getProgressFilled(props?.candidatePopUp?.matchingPercentage?.Experience.bandColor)
                      :
                      ((props?.candidatePopUp?.matchingPercentage?.Experience?.percentage ? Math.round(props?.candidatePopUp?.matchingPercentage?.Experience.percentage) : 0) >= 70 ?
                        getProgresssemiFilled(props?.candidatePopUp?.matchingPercentage?.Experience.bandColor)
                        :
                        getProgressEmpty(props?.candidatePopUp?.matchingPercentage?.Experience.bandColor))
                    }
                    <Box style={{ display: "inline-block", width: "2px" }}></Box>
                    {(props?.candidatePopUp?.matchingPercentage?.Experience?.percentage ? Math.round(props?.candidatePopUp?.matchingPercentage?.Experience.percentage) : 0) >= 100 ?
                      getProgressFilled(props?.candidatePopUp?.matchingPercentage?.Experience.bandColor)
                      :
                      ((props?.candidatePopUp?.matchingPercentage?.Experience?.percentage ? Math.round(props?.candidatePopUp?.matchingPercentage?.Experience.percentage) : 0) >= 90 ?
                        getProgresssemiFilled(props?.candidatePopUp?.matchingPercentage?.Experience.bandColor)
                        :
                        getProgressEmpty(props?.candidatePopUp?.matchingPercentage?.Experience.bandColor))
                    }
                    <Typography className="f-10 font-weight-400 galaxy-font-score" style={{ color: "#696871" }}>{(props?.candidatePopUp?.matchingPercentage?.Experience?.percentage ? Math.round(props?.candidatePopUp?.matchingPercentage?.Experience.percentage) : 0) + "% Matched"}</Typography>
                  </Grid>
                </Grid></> :
              <>
                {props?.candidatePopUp?.matchingPercentage?.ProfileTitle ?
                  <>
                    <Grid container lg={6} md={6} xs={6}>
                      <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10">
                      </Grid>
                      <Grid item lg={11.5} md={11.5} xs={11.5} className="mt-10">
                        <Typography className="f-12 font-weight-700 galaxy-font-score" style={{ paddingLeft: "12px" }}>Overall Experience</Typography>
                      </Grid>
                    </Grid>
                    <Grid item lg={5} md={5} xs={5} className="mt-10 ml-20" >
                      <Typography className="f-12 font-weight-700 galaxy-font-score" style={{ color: "#696871", textAlign: "right" }}>Disabled from matching weightage</Typography>
                    </Grid>
                  </> :
                  <Grid container lg={6} md={6} xs={6}>
                    <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10">
                    </Grid>
                    <Grid item lg={11.5} md={11.5} xs={11.5} className="mt-10">
                      <Typography className="f-12 font-weight-700 galaxy-font-score" style={{ paddingLeft: "12px" }}>Overall Experience</Typography>
                    </Grid>
                  </Grid>}
              </>}
            <Grid item lg={11.5} md={11.5} xs={11.5} className="border-bottom-1 mt-10"></Grid>
            <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10"></Grid>
            {(isDetailsExist(props?.candidatePopUp?.matchingPercentage?.ProfileTitle) || props?.candidatePopUp?.matchingPercentage?.ProfileTitle?.weightage > 0) ?
              <>
                <Grid container lg={10} md={10} xs={9}>
                  <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10">
                  </Grid>
                  <Grid item lg={11.5} md={11.5} xs={11.5} className="mt-10">
                    <Typography className="f-12 font-weight-700 galaxy-font-score">Profile Title</Typography>
                  </Grid>
                  {isDetailsExist(props?.candidatePopUp?.matchingPercentage?.ProfileTitle) &&
                    <>
                      <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10 galaxy-margin-top-5">
                        <CheckCircleIcon
                          className="galaxy-close-icon"
                          style={{
                            marginRight: 5,
                            width: "14px",
                            color: `${props?.candidatePopUp?.matchingPercentage?.ProfileTitle?.matched?.length ? getComputedStyle(document.documentElement).getPropertyValue('--primary-color') : (props?.candidatePopUp?.matchingPercentage?.ProfileTitle?.partialMatched?.length ? getComputedStyle(document.documentElement).getPropertyValue('--primary-color') : '#828282')}`,
                            opacity: `${props?.candidatePopUp?.matchingPercentage?.ProfileTitle?.partialMatched?.length ? 0.7 : ''}`
                          }}
                        />
                      </Grid>
                      <Grid item lg={3.5} md={3.5} xs={3.5} className="mt-10">
                        <Typography className="f-12 font-weight-400 galaxy-font-score galaxy-margin-left-10 exp-skill-align" style={{
                          color: `${props?.candidatePopUp?.matchingPercentage?.ProfileTitle?.matched?.length ? getComputedStyle(document.documentElement).getPropertyValue('--primary-color') : (props?.candidatePopUp?.matchingPercentage?.ProfileTitle?.partialMatched?.length ? getComputedStyle(document.documentElement).getPropertyValue('--primary-color') : '#828282')}`,
                          opacity: `${props?.candidatePopUp?.matchingPercentage?.ProfileTitle?.partialMatched?.length ? 0.7 : ''}`
                        }}>{props?.candidatePopUp?.matchingPercentage?.ProfileTitle?.matched?.length ? props?.candidatePopUp?.matchingPercentage?.ProfileTitle?.matched[0].profileTitle : props?.candidatePopUp?.matchingPercentage?.ProfileTitle?.unmatched.length ? props?.candidatePopUp?.matchingPercentage?.ProfileTitle?.unmatched[0].profileTitle : (props?.candidatePopUp?.matchingPercentage?.ProfileTitle?.partialMatched?.length ? props?.candidatePopUp?.matchingPercentage?.ProfileTitle?.partialMatched[0].profileTitle : '')}</Typography>
                      </Grid>
                      <Grid item lg={4} md={4} xs={4} className="mt-10"></Grid>
                      <Grid item lg={4} md={4} xs={4} className="mt-10">
                        <Typography className="f-12 font-weight-700 galaxy-font-score" style={{ color: "#696871" }}>{props?.candidatePopUp?.matchingPercentage?.ProfileTitle?.matched?.length ? "Matched" : (props?.candidatePopUp?.matchingPercentage?.ProfileTitle?.partialMatched?.length ? "Partial Match" : "Unmatched")}
                          {props?.candidatePopUp?.matchingPercentage?.ProfileTitle?.partialMatched.length ? <span className="f-12 font-weight-500 galaxy-font-score" style={{ color: "#696871" }}> - Worked as a {props?.candidatePopUp?.matchingPercentage?.ProfileTitle?.partialMatched[0].candidateProfileTitle}</span> : ""}
                          {props?.candidatePopUp?.matchingPercentage?.ProfileTitle?.unmatched.length ? <span className="f-12 font-weight-500 galaxy-font-score" style={{ color: "#696871" }}> - Worked as a {props?.candidatePopUp?.matchingPercentage?.ProfileTitle?.unmatched[0].candidateProfileTitle}</span> : ""}
                        </Typography>
                      </Grid>
                    </>
                  }
                </Grid>
                <Grid container lg={2} md={2} xs={3}>
                  <Grid item lg={2} md={2} xs={2}></Grid>
                  <Grid item className="mt-5">
                    {(props?.candidatePopUp?.matchingPercentage?.ProfileTitle ? Math.round(props?.candidatePopUp?.matchingPercentage?.ProfileTitle.percentage) : 0) >= 20 ?
                      getProgressFilled(props?.candidatePopUp?.matchingPercentage?.ProfileTitle.bandColor)
                      :
                      ((props?.candidatePopUp?.matchingPercentage?.ProfileTitle ? Math.round(props?.candidatePopUp?.matchingPercentage?.ProfileTitle.percentage) : 0) >= 10 ?
                        getProgresssemiFilled(props?.candidatePopUp?.matchingPercentage?.ProfileTitle.bandColor)
                        :
                        getProgressEmpty(props?.candidatePopUp?.matchingPercentage?.ProfileTitle.bandColor))
                    }
                    <Box style={{ display: "inline-block", width: "2px" }}></Box>
                    {(props?.candidatePopUp?.matchingPercentage?.ProfileTitle ? Math.round(props?.candidatePopUp?.matchingPercentage?.ProfileTitle.percentage) : 0) >= 40 ?
                      getProgressFilled(props?.candidatePopUp?.matchingPercentage?.ProfileTitle.bandColor)
                      :
                      ((props?.candidatePopUp?.matchingPercentage?.ProfileTitle ? Math.round(props?.candidatePopUp?.matchingPercentage?.ProfileTitle.percentage) : 0) >= 30 ?
                        getProgresssemiFilled(props?.candidatePopUp?.matchingPercentage?.ProfileTitle.bandColor)
                        :
                        getProgressEmpty(props?.candidatePopUp?.matchingPercentage?.ProfileTitle.bandColor))
                    }
                    <Box style={{ display: "inline-block", width: "2px" }}></Box>
                    {(props?.candidatePopUp?.matchingPercentage?.ProfileTitle ? Math.round(props?.candidatePopUp?.matchingPercentage?.ProfileTitle.percentage) : 0) >= 60 ?
                      getProgressFilled(props?.candidatePopUp?.matchingPercentage?.ProfileTitle.bandColor)
                      :
                      ((props?.candidatePopUp?.matchingPercentage?.ProfileTitle ? Math.round(props?.candidatePopUp?.matchingPercentage?.ProfileTitle.percentage) : 0) >= 50 ?
                        getProgresssemiFilled(props?.candidatePopUp?.matchingPercentage?.ProfileTitle.bandColor)
                        :
                        getProgressEmpty(props?.candidatePopUp?.matchingPercentage?.ProfileTitle.bandColor))
                    }
                    <Box style={{ display: "inline-block", width: "2px" }}></Box>
                    {(props?.candidatePopUp?.matchingPercentage?.ProfileTitle ? Math.round(props?.candidatePopUp?.matchingPercentage?.ProfileTitle.percentage) : 0) >= 80 ?
                      getProgressFilled(props?.candidatePopUp?.matchingPercentage?.ProfileTitle.bandColor)
                      :
                      ((props?.candidatePopUp?.matchingPercentage?.ProfileTitle ? Math.round(props?.candidatePopUp?.matchingPercentage?.ProfileTitle.percentage) : 0) >= 70 ?
                        getProgresssemiFilled(props?.candidatePopUp?.matchingPercentage?.ProfileTitle.bandColor)
                        :
                        getProgressEmpty(props?.candidatePopUp?.matchingPercentage?.ProfileTitle.bandColor))
                    }
                    <Box style={{ display: "inline-block", width: "2px" }}></Box>
                    {(props?.candidatePopUp?.matchingPercentage?.ProfileTitle ? Math.round(props?.candidatePopUp?.matchingPercentage?.ProfileTitle.percentage) : 0) >= 100 ?
                      getProgressFilled(props?.candidatePopUp?.matchingPercentage?.ProfileTitle.bandColor)
                      :
                      ((props?.candidatePopUp?.matchingPercentage?.ProfileTitle ? Math.round(props?.candidatePopUp?.matchingPercentage?.ProfileTitle.percentage) : 0) >= 90 ?
                        getProgresssemiFilled(props?.candidatePopUp?.matchingPercentage?.ProfileTitle.bandColor)
                        :
                        getProgressEmpty(props?.candidatePopUp?.matchingPercentage?.ProfileTitle.bandColor))
                    }
                    <Typography className="f-10 font-weight-400 galaxy-font-score" style={{ color: "#696871" }}>{(props?.candidatePopUp?.matchingPercentage?.ProfileTitle ? Math.round(props?.candidatePopUp?.matchingPercentage?.ProfileTitle.percentage) : 0) + "% Matched"}</Typography>
                  </Grid>
                </Grid></> :
              <>
                {props?.candidatePopUp?.matchingPercentage?.ProfileTitle ?
                  <>
                    <Grid container lg={6} md={6} xs={6}>
                      <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10">
                      </Grid>
                      <Grid item lg={11.5} md={11.5} xs={11.5} className="mt-10">
                        <Typography className="f-12 font-weight-700 galaxy-font-score" style={{ paddingLeft: "12px" }}>Profile Title</Typography>
                      </Grid>
                    </Grid>
                    <Grid item lg={5} md={5} xs={5} className="mt-10 ml-20" >
                      <Typography className="f-12 font-weight-700 galaxy-font-score" style={{ color: "#696871", textAlign: "right" }}>Disabled from matching weightage</Typography>
                    </Grid></> :
                  <Grid container lg={6} md={6} xs={6}>
                    <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10">
                    </Grid>
                    <Grid item lg={11.5} md={11.5} xs={11.5} className="mt-10">
                      <Typography className="f-12 font-weight-700 galaxy-font-score" style={{ paddingLeft: "12px" }}>Profile Title</Typography>
                    </Grid>
                  </Grid>}
              </>}
              {(props?.candidatePopUp?.matchingPercentage?.Education?.matched[0]?.education || props?.candidatePopUp?.matchingPercentage?.Education?.unmatched[0]?.education) && <><Grid item lg={11.5} md={11.5} xs={11.5} className="border-bottom-1 mt-10"></Grid>
            <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10"></Grid>
            {(isDetailsExist(props?.candidatePopUp?.matchingPercentage?.Education) || props?.candidatePopUp?.matchingPercentage?.Education?.weightage > 0) ?
              <>
                <Grid container lg={10} md={10} xs={9}>
                  <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10">
                  </Grid>
                  <Grid item lg={11.5} md={11.5} xs={11.5} className="mt-10">
                    <Typography className="f-12 font-weight-700 galaxy-font-score">Education</Typography>
                  </Grid>
                  <>
                    {(isDetailsExist(props?.candidatePopUp?.matchingPercentage?.Education) === false && props?.candidatePopUp?.matchingPercentage?.Education?.weightage > 0) && (<>
                      <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10 galaxy-margin-top-5">
                        <CheckCircleIcon
                          className="galaxy-close-icon"
                          style={{
                            marginRight: 5,
                            width: "14px",
                            color: '#828282',
                          }}
                        />
                      </Grid>
                      <Grid item lg={3.5} md={3.5} xs={3.5} className="mt-10">
                        <Typography className="f-12 font-weight-400 galaxy-font-score exp-skill-align" style={{ color: "#828282" }}>{"Not Available"}</Typography>
                      </Grid>
                      <Grid item lg={4} md={4} xs={4} className="mt-10"></Grid>
                      <Grid item lg={4} md={4} xs={4} className="mt-10">
                        <Typography className="f-12 font-weight-700 galaxy-font-score" style={{ color: "#696871" }}>Unmatched</Typography>
                      </Grid>
                    </>)}
                    {props?.candidatePopUp?.matchingPercentage?.Education?.matched?.map((education, index) => {
                      return (<>
                        <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10 galaxy-margin-top-5">
                          <CheckCircleIcon
                            className="galaxy-close-icon"
                            style={{
                              marginRight: 5,
                              width: "14px",
                              color: getComputedStyle(document.documentElement).getPropertyValue('--primary-color'),
                            }}
                          />
                        </Grid>
                        <Grid item lg={3.5} md={3.5} xs={3.5} className="mt-10">
                          <Typography className="f-12 font-weight-400 galaxy-font-score exp-skill-align" style={{ color: getComputedStyle(document.documentElement).getPropertyValue('--primary-color') }}>{education.education}</Typography>
                        </Grid>
                        <Grid item lg={4} md={4} xs={4} className="mt-10"></Grid>
                        <Grid item lg={4} md={4} xs={4} className="mt-10">
                          <Typography className="f-12 font-weight-700 galaxy-font-score" style={{ color: "#696871" }}>Matched</Typography>
                        </Grid>
                      </>);
                    })}
                    {props?.candidatePopUp?.matchingPercentage?.Education?.partialMatched?.map((education, index) => {
                      return (<>
                        <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10 galaxy-margin-top-5">
                          <CheckCircleIcon
                            className="galaxy-close-icon"
                            style={{
                              marginRight: 5,
                              width: "14px",
                              color: getComputedStyle(document.documentElement).getPropertyValue('--primary-color'),
                              opacity: 0.7
                            }}
                          />
                        </Grid>
                        <Grid item lg={3.5} md={3.5} xs={3.5} className="mt-10">
                          <Typography className="f-12 font-weight-400 galaxy-font-score exp-skill-align" style={{ color: getComputedStyle(document.documentElement).getPropertyValue('--primary-color'), opacity: 0.7 }}>{education.education}</Typography>
                        </Grid>
                        <Grid item lg={4} md={4} xs={4} className="mt-10"></Grid>
                        <Grid item lg={4} md={4} xs={4} className="mt-10">
                          <Typography className="f-12 font-weight-700 galaxy-font-score" style={{ color: "#696871" }}>Partial Match</Typography>
                        </Grid>
                      </>);
                    })}
                    {Array.isArray(props?.candidatePopUp?.matchingPercentage?.Education?.unmatched) && props?.candidatePopUp?.matchingPercentage?.Education?.unmatched?.map((education, index) => {
                      return (JSON.stringify(education) === '{}' ? <></> : <>
                        <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10 galaxy-margin-top-5">
                          <CheckCircleIcon
                            className="galaxy-close-icon"
                            style={{
                              marginRight: 5,
                              width: "14px",
                              color: '#828282',
                            }}
                          />
                        </Grid>
                        <Grid item lg={3.5} md={3.5} xs={3.5} className="mt-10">
                          <Typography className="f-12 font-weight-400 galaxy-font-score exp-skill-align" style={{ color: "#828282" }}>{education.education}</Typography>
                        </Grid>
                        <Grid item lg={4} md={4} xs={4} className="mt-10"></Grid>
                        <Grid item lg={4} md={4} xs={4} className="mt-10">
                          <Typography className="f-12 font-weight-700 galaxy-font-score" style={{ color: "#696871" }}>Unmatched</Typography>
                        </Grid>
                      </>);
                    })}
                  </>
                </Grid>
                <Grid container lg={2} md={2} xs={3}>
                  <Grid item lg={2} md={2} xs={2}></Grid>
                  <Grid item className="mt-5">
                    {(props?.candidatePopUp?.matchingPercentage?.Education ? Math.round(props?.candidatePopUp?.matchingPercentage?.Education.percentage) : 0) >= 20 ?
                      getProgressFilled(props?.candidatePopUp?.matchingPercentage?.Education.bandColor)
                      :
                      ((props?.candidatePopUp?.matchingPercentage?.Education ? Math.round(props?.candidatePopUp?.matchingPercentage?.Education.percentage) : 0) >= 10 ?
                        getProgresssemiFilled(props?.candidatePopUp?.matchingPercentage?.Education.bandColor)
                        :
                        getProgressEmpty(props?.candidatePopUp?.matchingPercentage?.Education.bandColor))
                    }
                    <Box style={{ display: "inline-block", width: "2px" }}></Box>
                    {(props?.candidatePopUp?.matchingPercentage?.Education ? Math.round(props?.candidatePopUp?.matchingPercentage?.Education.percentage) : 0) >= 40 ?
                      getProgressFilled(props?.candidatePopUp?.matchingPercentage?.Education.bandColor)
                      :
                      ((props?.candidatePopUp?.matchingPercentage?.Education ? Math.round(props?.candidatePopUp?.matchingPercentage?.Education.percentage) : 0) >= 30 ?
                        getProgresssemiFilled(props?.candidatePopUp?.matchingPercentage?.Education.bandColor)
                        :
                        getProgressEmpty(props?.candidatePopUp?.matchingPercentage?.Education.bandColor))
                    }
                    <Box style={{ display: "inline-block", width: "2px" }}></Box>
                    {(props?.candidatePopUp?.matchingPercentage?.Education ? Math.round(props?.candidatePopUp?.matchingPercentage?.Education.percentage) : 0) >= 60 ?
                      getProgressFilled(props?.candidatePopUp?.matchingPercentage?.Education.bandColor)
                      :
                      ((props?.candidatePopUp?.matchingPercentage?.Education ? Math.round(props?.candidatePopUp?.matchingPercentage?.Education.percentage) : 0) >= 50 ?
                        getProgresssemiFilled(props?.candidatePopUp?.matchingPercentage?.Education.bandColor)
                        :
                        getProgressEmpty(props?.candidatePopUp?.matchingPercentage?.Education.bandColor))
                    }
                    <Box style={{ display: "inline-block", width: "2px" }}></Box>
                    {(props?.candidatePopUp?.matchingPercentage?.Education ? Math.round(props?.candidatePopUp?.matchingPercentage?.Education.percentage) : 0) >= 80 ?
                      getProgressFilled(props?.candidatePopUp?.matchingPercentage?.Education.bandColor)
                      :
                      ((props?.candidatePopUp?.matchingPercentage?.Education ? Math.round(props?.candidatePopUp?.matchingPercentage?.Education.percentage) : 0) >= 70 ?
                        getProgresssemiFilled(props?.candidatePopUp?.matchingPercentage?.Education.bandColor)
                        :
                        getProgressEmpty(props?.candidatePopUp?.matchingPercentage?.Education.bandColor))
                    }
                    <Box style={{ display: "inline-block", width: "2px" }}></Box>
                    {(props?.candidatePopUp?.matchingPercentage?.Education ? Math.round(props?.candidatePopUp?.matchingPercentage?.Education.percentage) : 0) >= 100 ?
                      getProgressFilled(props?.candidatePopUp?.matchingPercentage?.Education.bandColor)
                      :
                      ((props?.candidatePopUp?.matchingPercentage?.Education ? Math.round(props?.candidatePopUp?.matchingPercentage?.Education.percentage) : 0) >= 90 ?
                        getProgresssemiFilled(props?.candidatePopUp?.matchingPercentage?.Education.bandColor)
                        :
                        getProgressEmpty(props?.candidatePopUp?.matchingPercentage?.Education.bandColor))
                    }
                    <Typography className="f-10 font-weight-400 galaxy-font-score" style={{ color: "#696871" }}>{(props?.candidatePopUp?.matchingPercentage?.Education ? Math.round(props?.candidatePopUp?.matchingPercentage?.Education.percentage) : 0) + "% Matched"}</Typography>
                  </Grid>
                </Grid>
              </> :
              <>
                {props?.candidatePopUp?.matchingPercentage?.Education ?
                  <>
                    <Grid container lg={6} md={6} xs={6}>
                      <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10">
                      </Grid>
                      <Grid item lg={11.5} md={11.5} xs={11.5} className="mt-10">
                        <Typography className="f-12 font-weight-700" style={{ paddingLeft: "12px" }}>Education</Typography>
                      </Grid>
                    </Grid>
                    <Grid item lg={5} md={5} xs={5} className="mt-10 ml-20" >
                      <Typography className="f-12 font-weight-700 galaxy-font-score" style={{ color: "#696871", textAlign: "right" }}>Disabled from matching weightage</Typography>
                    </Grid></> :
                  <Grid container lg={6} md={6} xs={6}>
                    <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10">
                    </Grid>
                    <Grid item lg={11.5} md={11.5} xs={11.5} className="mt-10">
                      <Typography className="f-12 font-weight-700 galaxy-font-score" style={{ paddingLeft: "12px" }}>Education</Typography>
                    </Grid>
                  </Grid>}
              </>
            }</>}
            <Grid item lg={11.5} md={11.5} xs={11.5} className="border-bottom-1 mt-10"></Grid>
            <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10"></Grid>
            {(isDetailsExist(props?.candidatePopUp?.matchingPercentage?.Certificate) || props?.candidatePopUp?.matchingPercentage?.Certificate?.weightage > 0) ?
              <Grid container lg={10} md={10} xs={9}>
                <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10">
                </Grid>
                <Grid item lg={11.5} md={11.5} xs={11.5} className="mt-10">
                  <Typography className="f-12 font-weight-700 galaxy-font-score">Certification</Typography>
                </Grid>
                {(isDetailsExist(props?.candidatePopUp?.matchingPercentage?.Certificate) || props?.candidatePopUp?.matchingPercentage?.Certificate?.weightage > 0) &&
                  <>
                    {(isDetailsExist(props?.candidatePopUp?.matchingPercentage?.Certificate) === false && props?.candidatePopUp?.matchingPercentage?.Certificate?.weightage > 0) && (<>
                      <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10 galaxy-margin-top-5">
                        <CheckCircleIcon
                          className="galaxy-close-icon"
                          style={{
                            marginRight: 5,
                            width: "14px",
                            color: '#828282',
                          }}
                        />
                      </Grid>
                      <Grid item lg={3.5} md={3.5} xs={3.5} className="mt-10">
                        <Typography className="f-12 font-weight-400 galaxy-font-score exp-skill-align" style={{ color: "#828282" }}>{"Not Available"}</Typography>
                      </Grid>
                      <Grid item lg={4} md={4} xs={4} className="mt-10"></Grid>
                      <Grid item lg={4} md={4} xs={4} className="mt-10">
                        <Typography className="f-12 font-weight-700 galaxy-font-score" style={{ color: "#696871" }}>Unmatched</Typography>
                      </Grid>
                    </>)}
                    {props?.candidatePopUp?.matchingPercentage?.Certificate?.matched?.map((certificate, index) => {
                      return (<>
                        <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10 galaxy-margin-top-5">
                          <CheckCircleIcon
                            className="galaxy-close-icon"
                            style={{
                              marginRight: 5,
                              width: "14px",
                              color: getComputedStyle(document.documentElement).getPropertyValue('--primary-color'),
                            }}
                          />
                        </Grid>
                        <Grid item lg={3.5} md={3.5} xs={3.5} className="mt-10">
                          <Typography className="f-12 font-weight-400 galaxy-font-score exp-skill-align" style={{ color: getComputedStyle(document.documentElement).getPropertyValue('--primary-color') }}>{certificate.certificationName}</Typography>
                        </Grid>
                        <Grid item lg={4} md={4} xs={4} className="mt-10"></Grid>
                        <Grid item lg={4} md={4} xs={4} className="mt-10">
                          <Typography className="f-12 font-weight-700 galaxy-font-score" style={{ color: "#696871" }}>Matched</Typography>
                        </Grid>
                      </>);
                    })}
                    {props?.candidatePopUp?.matchingPercentage?.Certificate?.partialMatched?.map((certificate, index) => {
                      return (<>
                        <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10 galaxy-margin-top-5">
                          <CheckCircleIcon
                            className="galaxy-close-icon"
                            style={{
                              marginRight: 5,
                              width: "14px",
                              color: getComputedStyle(document.documentElement).getPropertyValue('--primary-color'),
                              opacity: 0.7
                            }}
                          />
                        </Grid>
                        <Grid item lg={3.5} md={3.5} xs={3.5} className="mt-10">
                          <Typography className="f-12 font-weight-400 galaxy-font-score exp-skill-align" style={{ color: getComputedStyle(document.documentElement).getPropertyValue('--primary-color'), opacity: 0.7 }}>{certificate.certificationName}</Typography>
                        </Grid>
                        <Grid item lg={4} md={4} xs={4} className="mt-10"></Grid>
                        <Grid item lg={4} md={4} xs={4} className="mt-10">
                          <Typography className="f-12 font-weight-700 galaxy-font-score" style={{ color: "#696871" }}>Partial Match</Typography>
                        </Grid>
                      </>);
                    })}
                    {Array.isArray(props?.candidatePopUp?.matchingPercentage?.Certificate?.unmatched) && props?.candidatePopUp?.matchingPercentage?.Certificate?.unmatched?.map((certificate, index) => {
                      return (JSON.stringify(certificate) === '{}' ? <></> : <>
                        <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10 galaxy-margin-top-5">
                          <CheckCircleIcon
                            className="galaxy-close-icon"
                            style={{
                              marginRight: 5,
                              width: "14px",
                              color: '#828282',
                            }}
                          />
                        </Grid>
                        <Grid item lg={3.5} md={3.5} xs={3.5} className="mt-10">
                          <Typography className="f-12 font-weight-400 galaxy-font-score exp-skill-align" style={{ color: "#828282" }}>{certificate.certificationName}</Typography>
                        </Grid>
                        <Grid item lg={4} md={4} xs={4} className="mt-10"></Grid>
                        <Grid item lg={4} md={4} xs={4} className="mt-10">
                          <Typography className="f-12 font-weight-700 galaxy-font-score" style={{ color: "#696871" }}>Unmatched</Typography>
                        </Grid>
                      </>);
                    })}
                  </>
                }
              </Grid> :
              <Grid container lg={6} md={6} xs={3}>
                <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10">
                </Grid>
                <Grid item lg={11.5} md={11.5} xs={11.5} className="mt-10">
                  <Typography className="f-12 font-weight-700 galaxy-font-score" style={{ paddingLeft: "12px" }}>Certification</Typography>
                </Grid>
              </Grid>}
            {(isDetailsExist(props?.candidatePopUp?.matchingPercentage?.Certificate) || props?.candidatePopUp?.matchingPercentage?.Certificate?.weightage > 0) ?
              <>
                <Grid container lg={2} md={2} xs={3}>
                  <Grid item lg={2} md={2} xs={2}></Grid>
                  <Grid item className="mt-5">
                    {(props?.candidatePopUp?.matchingPercentage?.Certificate ? Math.round(props?.candidatePopUp?.matchingPercentage?.Certificate.percentage) : 0) >= 20 ?
                      getProgressFilled(props?.candidatePopUp?.matchingPercentage?.Certificate.bandColor)
                      :
                      ((props?.candidatePopUp?.matchingPercentage?.Certificate ? Math.round(props?.candidatePopUp?.matchingPercentage?.Certificate.percentage) : 0) >= 10 ?
                        getProgresssemiFilled(props?.candidatePopUp?.matchingPercentage?.Certificate.bandColor)
                        :
                        getProgressEmpty(props?.candidatePopUp?.matchingPercentage?.Certificate.bandColor))
                    }
                    <Box style={{ display: "inline-block", width: "2px" }}></Box>
                    {(props?.candidatePopUp?.matchingPercentage?.Certificate ? Math.round(props?.candidatePopUp?.matchingPercentage?.Certificate.percentage) : 0) >= 40 ?
                      getProgressFilled(props?.candidatePopUp?.matchingPercentage?.Certificate.bandColor)
                      :
                      ((props?.candidatePopUp?.matchingPercentage?.Certificate ? Math.round(props?.candidatePopUp?.matchingPercentage?.Certificate.percentage) : 0) >= 30 ?
                        getProgresssemiFilled(props?.candidatePopUp?.matchingPercentage?.Certificate.bandColor)
                        :
                        getProgressEmpty(props?.candidatePopUp?.matchingPercentage?.Certificate.bandColor))
                    }
                    <Box style={{ display: "inline-block", width: "2px" }}></Box>
                    {(props?.candidatePopUp?.matchingPercentage?.Certificate ? Math.round(props?.candidatePopUp?.matchingPercentage?.Certificate.percentage) : 0) >= 60 ?
                      getProgressFilled(props?.candidatePopUp?.matchingPercentage?.Certificate.bandColor)
                      :
                      ((props?.candidatePopUp?.matchingPercentage?.Certificate ? Math.round(props?.candidatePopUp?.matchingPercentage?.Certificate.percentage) : 0) >= 50 ?
                        getProgresssemiFilled(props?.candidatePopUp?.matchingPercentage?.Certificate.bandColor)
                        :
                        getProgressEmpty(props?.candidatePopUp?.matchingPercentage?.Certificate.bandColor))
                    }
                    <Box style={{ display: "inline-block", width: "2px" }}></Box>
                    {(props?.candidatePopUp?.matchingPercentage?.Certificate ? Math.round(props?.candidatePopUp?.matchingPercentage?.Certificate.percentage) : 0) >= 80 ?
                      getProgressFilled(props?.candidatePopUp?.matchingPercentage?.Certificate.bandColor)
                      :
                      ((props?.candidatePopUp?.matchingPercentage?.Certificate ? Math.round(props?.candidatePopUp?.matchingPercentage?.Certificate.percentage) : 0) >= 70 ?
                        getProgresssemiFilled(props?.candidatePopUp?.matchingPercentage?.Certificate.bandColor)
                        :
                        getProgressEmpty(props?.candidatePopUp?.matchingPercentage?.Certificate.bandColor))
                    }
                    <Box style={{ display: "inline-block", width: "2px" }}></Box>
                    {(props?.candidatePopUp?.matchingPercentage?.Certificate ? Math.round(props?.candidatePopUp?.matchingPercentage?.Certificate.percentage) : 0) >= 100 ?
                      getProgressFilled(props?.candidatePopUp?.matchingPercentage?.Certificate.bandColor)
                      :
                      ((props?.candidatePopUp?.matchingPercentage?.Certificate ? Math.round(props?.candidatePopUp?.matchingPercentage?.Certificate.percentage) : 0) >= 90 ?
                        getProgresssemiFilled(props?.candidatePopUp?.matchingPercentage?.Certificate.bandColor)
                        :
                        getProgressEmpty(props?.candidatePopUp?.matchingPercentage?.Certificate.bandColor))
                    }
                    <Typography className="f-10 font-weight-400 galaxy-font-score" style={{ color: "#696871" }}>{(props?.candidatePopUp?.matchingPercentage?.Certificate ? Math.round(props?.candidatePopUp?.matchingPercentage?.Certificate.percentage) : 0) + "% Matched"}</Typography>
                  </Grid>
                </Grid>
              </> :
              props?.candidatePopUp?.matchingPercentage?.Certificate &&
              <>
                <Grid item lg={5} md={5} xs={5} className="mt-10 ml-20">
                  <Typography className="f-12 font-weight-700 galaxy-font-score" style={{ color: "#696871", textAlign: "right" }}>Disabled from matching weightage</Typography>
                </Grid></>}
          </Grid>
        </Grid>
      </Box>
    </Modal>
    // <Modal
    //           open={open3}
    //           onClose={handleClose}
    //           aria-labelledby="modal-modal-title"
    //           aria-describedby="modal-modal-description"
    //         >
    //           <Box className="modal-content checklist-modal responsive-checklist-modal inputcontrasts">
    //             <Grid container id="modal-title" className="mb-5">
    //               <Grid item lg={1} md={1} xs={1}>
    //               <Grid
    //         className="jobProfile pl-0 p-relative"
    //         tabIndex="0"
    //       >
    //         {(props?.candidatePopUp?.mediaFileSigned === null ||
    //           props?.candidatePopUp?.mediaFileSigned === "null" ||
    //           props?.candidatePopUp?.mediaFileSigned === "") && (
    //             <Typography className="default-img-border justify-center align-center d-flex primary-shadow-color bg-input f-20 font-weight-700 text-capitalize"
    //               width="50px" height="50px" aria-label="Default image">
    //               {props?.candidatePopUp?.firstName.trim().substring(0, 1)}
    //             </Typography>
    //           )}
    //         {props?.candidatePopUp?.mediaFileSigned !== null &&
    //           props?.candidatePopUp?.mediaFileSigned !== "null" &&
    //           props?.candidatePopUp?.mediaFileSigned !== "" &&
    //           !checkIsImage(props?.candidatePopUp?.mediaFileSigned) && (
    //             <>
    //               <video
    //                 width="50px"
    //                 height="50px"
    //                 className="default-img-border profile-video-thumbnail cursor-hover"
    //                 src={props?.candidatePopUp?.mediaFileSigned}
    //                 onClick={(e) => {
    //                   props?.handleOpenVideoPreview(
    //                     e,
    //                     props?.candidatePopUp?.mediaFileSigned,
    //                     props?.candidatePopUp?.firstName + " " + props?.candidatePopUp?.lastName
    //                   );
    //                 }}
    //                 muted
    //                 onKeyPress={(e) => {
    //                   props?.handleOpenVideoPreview(
    //                     e,
    //                     props?.candidatePopUp?.mediaFileSigned,
    //                     props?.candidatePopUp?.firstName + " " + props?.candidatePopUp?.lastName
    //                   );
    //                 }}
    //                 tabIndex="0"
    //               />
    //               <img
    //                 alt="Play Icon"
    //                 onClick={(e) => {
    //                   props?.handleOpenVideoPreview(
    //                     e,
    //                     props?.candidatePopUp?.mediaFileSigned,
    //                     props?.candidatePopUp?.firstName + " " + props?.candidatePopUp?.lastName
    //                   );
    //                 }}
    //                 className="play-icon-profile-detail cursor-hover"
    //                 src={playIcon}
    //                 tabIndex="0"
    //                 onKeyPress={(e) => {
    //                   props?.handleOpenVideoPreview(
    //                     e,
    //                     props?.candidatePopUp?.mediaFileSigned,
    //                     props?.candidatePopUp?.firstName + " " + props?.candidatePopUp?.lastName
    //                   );
    //                 }}
    //               />
    //             </>
    //           )}
    //         {props?.candidatePopUp?.mediaFileSigned !== null &&
    //           props?.candidatePopUp?.mediaFileSigned !== "null" &&
    //           props?.candidatePopUp?.mediaFileSigned !== "" &&
    //           checkIsImage(props?.candidatePopUp?.mediaFileSigned) && (
    //             <img
    //               className="default-img-border"
    //               src={props?.candidatePopUp?.mediaFileSigned}
    //               alt="img"
    //               width="72px"
    //               height="72px"
    //             />
    //           )}
    //       </Grid>
    //               </Grid>
    //               <Grid item lg={9.6} md={9.6} xs={9.6}>
    //               <Typography>
    //             <Box className="break-all font-weight-700">
    //           <span>{props?.candidatePopUp?.firstName} {props?.candidatePopUp?.lastName}</span>
    //             </Box>
    //             <Box className="break-all f-14 sub-text-color">
    //           <span>{props?.candidatePopUp?.profileTitle}</span>
    //             </Box>
    //           </Typography>
    //               </Grid>
    //               <Grid item lg={1.2} md={1.2} xs={1.2}>
    //               <Tooltip role="tooltip" title="Matching score" placement="bottom">
    //                 <Typography className="mt-3" style={{ height: "44px", width: "44px" }}>
    //                 <CircularProgressbar
    //                 aria-label="Circular Progress"
    //                 strokeWidth={12}
    //                 value={
    //                   props?.candidatePopUp?.matchingScore
    //                     ? props?.candidatePopUp.matchingScore
    //                     : 0
    //                 }
    //                 text={`${Math.round(
    //                   props?.candidatePopUp?.matchingScore
    //                     ? props?.candidatePopUp.matchingScore
    //                     : 0
    //                 )}%`}
    //                 styles={buildStyles({
    //                   textColor: "#22a640",
    //                   pathColor: "#22a640",
    //                   trailColor: "#adc6ce",
    //                   textSize: "25px",
    //                 })}
    //               />
    //                 </Typography>
    //                 </Tooltip>
    //               </Grid>
    //               <Grid item lg={0.2} md={0.2} xs={0.2}>
    //                 <Typography align="right" className="cursor-hover sub-text-color icon-button mt-5">
    //                   <CloseIcon onClick={handleClose} onKeyPress={handleClose} tabIndex="0" aria-label="Close icon" />
    //                 </Typography>
    //               </Grid>
    //             </Grid>
    //             <Grid item lg={12} md={12} xs={12} style={{ maxHeight: "500px", overflowY: "overlay" }}>
    //             <Grid container className="mt-5">
    //             <Grid item lg={10.7} md={10.7} xs={10.7}></Grid>
    //             <Grid item lg={1.3} md={1.3} xs={1.3} className="f-11 font-weight-500 matchingscore-lightColor">Total Score</Grid>
    //             <Grid container lg={11.6} md={11.6} xs={11.6} className={isDetailsExist(props?.candidatePopUp?.matchingPercentage?.Skill) ? "cursor-hover" : ""} onClick={()=> (isDetailsExist(props?.candidatePopUp?.matchingPercentage?.Skill) && setSkillExpanded(!skillExpanded))}>
    //             <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10">
    //               <img src={skillIcon}/>
    //             </Grid>
    //             <Grid item lg={3.2} md={3.2} xs={3.2} className="mt-10">
    //             <Typography className="f-16 font-weight-700">Skill</Typography>
    //             </Grid>
    //             <Grid item lg={7.3} md={7.3} xs={7.3} className="mt-14">
    //             <ProgressBar
    //               parentBgColor="#8F9DDD"
    //               childBgColor=getComputedStyle(document.documentElement).getPropertyValue('--primary-color')
    //               progress={props?.candidatePopUp?.matchingPercentage?.Skill ? Math.round(props?.candidatePopUp?.matchingPercentage?.Skill.percentage) : 0}
    //               weightage={props?.candidatePopUp?.matchingPercentage?.Skill?.weightage}
    //               borderRadius={25}
    //               height={20}
    //               progresstextColor="white"
    //               aria-labelledby="Progress bar"
    //               aria-label="Progress bar"
    //             />
    //             </Grid>
    //             <Grid item lg={1} md={1} xs={1} className="mt-16 pl-10">
    //             <Typography className="f-12 font-weight-600" style={{ color: "#22A640" }}>{props?.candidatePopUp?.matchingPercentage?.Skill ? Math.round(props?.candidatePopUp?.matchingPercentage?.Skill.score) : 0}%</Typography>
    //             </Grid>
    //             </Grid>
    //             {isDetailsExist(props?.candidatePopUp?.matchingPercentage?.Skill) ?
    //             <>
    //             <Grid item lg={0.4} md={0.4} xs={0.4} className="mt-10 cursor-hover" onClick={()=>setSkillExpanded(!skillExpanded)}>
    //               {skillExpanded ? <Tooltip role="tooltip" title="Collapse" placement="bottom"><img src={collapseIcon}/></Tooltip> : <Tooltip role="tooltip" title="Expand" placement="bottom"><img src={expandIcon}/></Tooltip>}
    //             </Grid>
    //             <Grid item lg={11.5} md={11.5} xs={11.5} className="border-bottom-1 mt-10"></Grid>
    //             <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10"></Grid>
    //             {skillExpanded && <>
    //             <Grid item lg={4} md={4} xs={4} className="mt-10">
    //             <Typography className="f-12 font-weight-700">Required</Typography>
    //             </Grid>
    //             <Grid item lg={3} md={3} xs={3} className="mt-10">
    //             <Typography className="f-12 font-weight-700">Candidate Skill</Typography>
    //             </Grid>
    //             <Grid item lg={2.8} md={2.8} xs={2.8} className="mt-10">
    //             <Typography className="f-12 font-weight-700">Ageing</Typography>
    //             </Grid>
    //             <Grid item lg={2.2} md={2.2} xs={2.2} className="mt-10">
    //             <Typography className="f-12 font-weight-700">Remarks</Typography>
    //             </Grid>
    //             {props?.candidatePopUp?.matchingPercentage?.Skill?.matched?.map((skill, index) => {
    //               return (<><Grid item lg={4} md={4} xs={4} className="mt-10">
    //               <Typography style={{ background: "#E3FCEF", borderRadius: "5px", padding: "2px 10px", color: "#1f1f1f", fontWeight: "500", fontSize: 12, display: "inline-block", }}>{skill.jobSkill}</Typography>
    //               </Grid>
    //               <Grid item lg={3} md={3} xs={3} className="mt-10">
    //                 <Typography style={{ background: "#E3FCEF", borderRadius: "5px", padding: "2px 10px", color: "#1f1f1f", fontWeight: "500", fontSize: 12, display: "inline-block", }}>{skill.candidateSkill}</Typography>
    //               </Grid>
    //               <Grid item lg={0.3} md={0.3} xs={0.3} className="mt-10">
    //               <img src={ageingIcon} style={{ opacity: (skill.lastUsed === "0" || String(skill.lastUsed).includes("197001") || skill.lastUsed === null) ? getAgeingOpacity(-1) : getAgeingOpacity(parseInt(String(skill.lastUsed).substring(0, 4)), parseInt(String(skill.lastUsed).substring(4, 6))) }}/>
    //               </Grid>
    //               <Grid item lg={2.5} md={2.5} xs={2.5} className="mt-10">
    //               <Typography className="f-12 font-weight-400">Last used: {(skill.lastUsed === "0" || String(skill.lastUsed).includes("197001") || skill.lastUsed === null) ? "Currently in use" : (String(skill.lastUsed).includes("-") ? String(skill.lastUsed).substring(0, 4)+"/"+getValidMonth(String(skill.lastUsed).substring(5, 7)) : String(skill.lastUsed).substring(0, 4)+"/"+getValidMonth(String(skill.lastUsed).substring(4, 6)))}</Typography>
    //               </Grid>
    //               <Grid item lg={2.2} md={2.2} xs={2.2} className="mt-10">
    //               <Typography className="f-12 font-weight-400">Matched</Typography>
    //               </Grid></>);
    //             })}
    //             {props?.candidatePopUp?.matchingPercentage?.Skill?.partialMatched?.map((skill, index) => {
    //               return (<><Grid item lg={4} md={4} xs={4} className="mt-10">
    //               <Typography style={{ background: "#E3FCEF", borderRadius: "5px", padding: "2px 10px", color: "#1f1f1f", fontWeight: "500", fontSize: 12, display: "inline-block", }}>{skill.jobSkill}</Typography>
    //               </Grid>
    //               <Grid item lg={3} md={3} xs={3} className="mt-10">
    //               <Typography style={{ background: "#E3FCEF", borderRadius: "5px", padding: "2px 10px", color: "#1f1f1f", fontWeight: "500", fontSize: 12, display: "inline-block", }}>{skill.candidateSkill}</Typography>
    //               </Grid>
    //               <Grid item lg={0.3} md={0.3} xs={0.3} className="mt-10">
    //               <img src={ageingIcon} style={{ opacity: (skill.lastUsed === "0" || String(skill.lastUsed).includes("197001") || skill.lastUsed === null) ? getAgeingOpacity(-1) : getAgeingOpacity(parseInt(String(skill.lastUsed).substring(0, 4)), parseInt(String(skill.lastUsed).substring(4, 6))) }}/>
    //               </Grid>
    //               <Grid item lg={2.5} md={2.5} xs={2.5} className="mt-10">
    //               <Typography className="f-12 font-weight-400">Last used: {(skill.lastUsed === "0" || String(skill.lastUsed).includes("197001") || skill.lastUsed === null) ? "Currently in use" : (String(skill.lastUsed).includes("-") ? String(skill.lastUsed).substring(0, 4)+"/"+getValidMonth(String(skill.lastUsed).substring(5, 7)) : String(skill.lastUsed).substring(0, 4)+"/"+getValidMonth(String(skill.lastUsed).substring(4, 6)))}</Typography>
    //               </Grid>
    //               <Grid item lg={2.2} md={2.2} xs={2.2} className="mt-10">
    //               <Typography className="f-12 font-weight-400">Partially Matched</Typography>
    //               </Grid></>);
    //             })}
    //             {Array.isArray(props?.candidatePopUp?.matchingPercentage?.Skill?.unmatched) && props?.candidatePopUp?.matchingPercentage?.Skill?.unmatched?.map((skill, index) => {
    //               return (<><Grid item lg={4} md={4} xs={2.2} className="mt-10">
    //               <Typography style={{ background: "#EFF0F6", borderRadius: "5px", padding: "2px 10px", color: "#696871", fontWeight: "500", fontSize: 12, display: "inline-block", }}>{skill.jobSkill}</Typography>
    //               </Grid>
    //               <Grid item lg={3} md={3} xs={3} className="mt-10">
    //               <CheckCircleIcon
    //               style={{
    //                 verticalAlign: "middle",
    //                 marginRight: 5,
    //                 width: "14px",
    //                 color: "#828282"
    //               }}
    //               className="vertical-align-middle"
    //             />
    //             <span className="f-12 font-weight-400" style={{ color: "#828282" }}>Not Available</span>
    //               </Grid>
    //               <Grid item lg={2.8} md={2.8} xs={2.8} className="mt-10">
    //                 {"-"}
    //               </Grid>
    //               <Grid item lg={2.2} md={2.2} xs={2.2} className="mt-10">
    //               <Typography className="f-12 font-weight-400">Unmatched</Typography>
    //               </Grid></>);
    //             })}
    //             </>}
    //             </>
    //             :
    //             <>
    //                 <Grid item lg={11.5} md={11.5} xs={11.5} className="border-bottom-1 mt-10"></Grid>
    //                 <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10"></Grid>
    //             </>
    //             }
    //             <Grid container lg={11.6} md={11.6} xs={11.6} className={((isDetailsExist(props?.candidatePopUp?.matchingPercentage?.Industry) || isDetailsExist(props?.candidatePopUp?.matchingPercentage?.subCategory)) && props?.candidatePopUp?.matchingPercentage?.Industry?.weightage !== 0) ? "cursor-hover" : ""} onClick={()=> (((isDetailsExist(props?.candidatePopUp?.matchingPercentage?.Industry) || isDetailsExist(props?.candidatePopUp?.matchingPercentage?.subCategory)) && props?.candidatePopUp?.matchingPercentage?.Industry?.weightage !== 0) && setIndustryExpanded(!industryExpanded))}>
    //             <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-30">
    //               <img src={industryIcon}/>
    //             </Grid>
    //             <Grid item lg={3.2} md={3.2} xs={3.2} className="mt-30">
    //             <Typography className="f-16 font-weight-700">Industry</Typography>
    //             </Grid>
    //             <Grid item lg={7.3} md={7.3} xs={7.3} className="mt-34">
    //             <ProgressBar
    //               parentBgColor="#8F9DDD"
    //               childBgColor=getComputedStyle(document.documentElement).getPropertyValue('--primary-color')
    //               progress={(props?.candidatePopUp?.matchingPercentage?.Industry && props?.candidatePopUp?.matchingPercentage?.subCategory) ? Math.round((props?.candidatePopUp?.matchingPercentage?.Industry.percentage + props?.candidatePopUp?.matchingPercentage?.subCategory.percentage) / 2) : 0}
    //               weightage={props?.candidatePopUp?.matchingPercentage?.Industry?.weightage}
    //               borderRadius={25}
    //               height={20}
    //               progresstextColor="white"
    //               aria-labelledby="Progress bar"
    //               aria-label="Progress bar"
    //             />
    //             </Grid>
    //             <Grid item lg={1} md={1} xs={1} className="mt-36 pl-10">
    //             <Typography className="f-12 font-weight-600" style={{ color: "#22A640" }}>{(props?.candidatePopUp?.matchingPercentage?.Industry && props?.candidatePopUp?.matchingPercentage?.subCategory) ? Math.round(props?.candidatePopUp?.matchingPercentage?.Industry.score + props?.candidatePopUp?.matchingPercentage?.subCategory.score) : 0}%</Typography>
    //             </Grid>
    //             </Grid>
    //             {((isDetailsExist(props?.candidatePopUp?.matchingPercentage?.Industry) || isDetailsExist(props?.candidatePopUp?.matchingPercentage?.subCategory)) && props?.candidatePopUp?.matchingPercentage?.Industry?.weightage !== 0) ?
    //             <>
    //             <Grid item lg={0.4} md={0.4} xs={0.4} className="mt-30 cursor-hover" onClick={()=>setIndustryExpanded(!industryExpanded)}>
    //               {industryExpanded ? <Tooltip role="tooltip" title="Collapse" placement="bottom"><img src={collapseIcon}/></Tooltip> : <Tooltip role="tooltip" title="Expand" placement="bottom"><img src={expandIcon}/></Tooltip>}
    //             </Grid>
    //             <Grid item lg={11.5} md={11.5} xs={11.5} className="border-bottom-1 mt-10"></Grid>
    //             <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10"></Grid>
    //             {industryExpanded && <>
    //             <Grid item lg={4} md={4} xs={4} className="mt-10">
    //             <Typography className="f-12 font-weight-700">Required</Typography>
    //             </Grid>
    //             <Grid item lg={4} md={4} xs={4} className="mt-10">
    //             <Typography className="f-12 font-weight-700">Candidate Industry</Typography>
    //             </Grid>
    //             <Grid item lg={4} md={4} xs={4} className="mt-10">
    //             <Typography className="f-12 font-weight-700">Remarks</Typography>
    //             </Grid>
    //             {props?.candidatePopUp?.matchingPercentage?.Industry?.matched?.map((industry, index) => {
    //               return (<>
    //               <Grid item lg={4} md={4} xs={4} className="mt-10">
    //                 <CheckCircleIcon
    //                 style={{
    //                     verticalAlign: "middle",
    //                     marginRight: 5,
    //                     width: "14px",
    //                 }}
    //                 className="success-color vertical-align-middle"
    //                 />
    //                 <span className="f-12 font-weight-400">{industry.Industry}</span>
    //                 </Grid>
    //                 <Grid item lg={4} md={4} xs={4} className="mt-10">
    //                 <CheckCircleIcon
    //                 style={{
    //                     verticalAlign: "middle",
    //                     marginRight: 5,
    //                     width: "14px",
    //                 }}
    //                 className="success-color vertical-align-middle"
    //                 />
    //                 <span className="f-12 font-weight-400">{industry.candidateIndustry}</span>
    //                 </Grid>
    //                 <Grid item lg={4} md={4} xs={4} className="mt-10">
    //                 <span className="f-12 font-weight-400">Matched</span>
    //                 </Grid></>);
    //             })}
    //             {props?.candidatePopUp?.matchingPercentage?.subCategory?.matched?.map((subCategory, index) => {
    //               return (<>
    //               <Grid item lg={4} md={4} xs={4} className="mt-10">
    //                 <CheckCircleIcon
    //                 style={{
    //                     verticalAlign: "middle",
    //                     marginRight: 5,
    //                     width: "14px",
    //                 }}
    //                 className="success-color vertical-align-middle"
    //                 />
    //                 <span className="f-12 font-weight-400">{subCategory.subCategory}</span>
    //                 </Grid>
    //                 <Grid item lg={4} md={4} xs={4} className="mt-10">
    //                 <CheckCircleIcon
    //                 style={{
    //                     verticalAlign: "middle",
    //                     marginRight: 5,
    //                     width: "14px",
    //                 }}
    //                 className="success-color vertical-align-middle"
    //                 />
    //                 <span className="f-12 font-weight-400">{subCategory.candidateSubCategory}</span>
    //                 </Grid>
    //                 <Grid item lg={4} md={4} xs={4} className="mt-10">
    //                 <span className="f-12 font-weight-400">Matched</span>
    //                 </Grid></>);
    //             })}
    //             {props?.candidatePopUp?.matchingPercentage?.Industry?.partialMatched?.map((industry, index) => {
    //               return (<>
    //               <Grid item lg={4} md={4} xs={4} className="mt-10">
    //                 <CheckCircleIcon
    //                 style={{
    //                     verticalAlign: "middle",
    //                     marginRight: 5,
    //                     width: "14px",
    //                 }}
    //                 className="success-color vertical-align-middle"
    //                 />
    //                 <span className="f-12 font-weight-400">{industry.Industry}</span>
    //                 </Grid>
    //                 <Grid item lg={4} md={4} xs={4} className="mt-10">
    //                 <CheckCircleIcon
    //                 style={{
    //                     verticalAlign: "middle",
    //                     marginRight: 5,
    //                     width: "14px",
    //                 }}
    //                 className="success-color vertical-align-middle"
    //                 />
    //                 <span className="f-12 font-weight-400">{industry.candidateIndustry}</span>
    //                 </Grid>
    //                 <Grid item lg={4} md={4} xs={4} className="mt-10">
    //                 <span className="f-12 font-weight-400">Partially Matched</span>
    //                 </Grid></>);
    //             })}
    //             {props?.candidatePopUp?.matchingPercentage?.subCategory?.partialMatched?.map((subCategory, index) => {
    //               return (<>
    //               <Grid item lg={4} md={4} xs={4} className="mt-10">
    //                 <CheckCircleIcon
    //                 style={{
    //                     verticalAlign: "middle",
    //                     marginRight: 5,
    //                     width: "14px",
    //                 }}
    //                 className="success-color vertical-align-middle"
    //                 />
    //                 <span className="f-12 font-weight-400">{subCategory.subCategory}</span>
    //                 </Grid>
    //                 <Grid item lg={4} md={4} xs={4} className="mt-10">
    //                 <CheckCircleIcon
    //                 style={{
    //                     verticalAlign: "middle",
    //                     marginRight: 5,
    //                     width: "14px",
    //                 }}
    //                 className="success-color vertical-align-middle"
    //                 />
    //                 <span className="f-12 font-weight-400">{subCategory.candidateSubCategory}</span>
    //                 </Grid>
    //                 <Grid item lg={4} md={4} xs={4} className="mt-10">
    //                 <span className="f-12 font-weight-400">Partially Matched</span>
    //                 </Grid></>);
    //             })}
    //             {Array.isArray(props?.candidatePopUp?.matchingPercentage?.Industry?.unmatched) && props?.candidatePopUp?.matchingPercentage?.Industry?.unmatched?.map((industry, index) => {
    //               return (<>
    //               <Grid item lg={4} md={4} xs={4} className="mt-10">
    //                 <CheckCircleIcon
    //                 style={{
    //                     verticalAlign: "middle",
    //                     marginRight: 5,
    //                     width: "14px",
    //                     color: '#828282',
    //                 }}
    //                 className="vertical-align-middle"
    //                 />
    //                 <span className="f-12 font-weight-400" style={{ color: "#828282" }}>{industry.Industry}</span>
    //                 </Grid>
    //                 <Grid item lg={4} md={4} xs={4} className="mt-10">
    //                 <CheckCircleIcon
    //                 style={{
    //                     verticalAlign: "middle",
    //                     marginRight: 5,
    //                     width: "14px",
    //                     color: '#828282',
    //                 }}
    //                 className="vertical-align-middle"
    //                 />
    //                 <span className="f-12 font-weight-400" style={{ color: "#828282" }}>{industry.candidateIndustry ? industry.candidateIndustry : "Not Available"}</span>
    //                 </Grid>
    //                 <Grid item lg={4} md={4} xs={4} className="mt-10">
    //                 <span className="f-12 font-weight-400">Unmatched</span>
    //                 </Grid></>);
    //             })}
    //             {Array.isArray(props?.candidatePopUp?.matchingPercentage?.subCategory?.unmatched) && props?.candidatePopUp?.matchingPercentage?.subCategory?.unmatched?.map((subCategory, index) => {
    //               return (<>
    //               <Grid item lg={4} md={4} xs={4} className="mt-10">
    //                 <CheckCircleIcon
    //                 style={{
    //                     verticalAlign: "middle",
    //                     marginRight: 5,
    //                     width: "14px",
    //                     color: '#828282',
    //                 }}
    //                 className="vertical-align-middle"
    //                 />
    //                 <span className="f-12 font-weight-400" style={{ color: "#828282" }}>{subCategory.subCategory}</span>
    //                 </Grid>
    //                 <Grid item lg={4} md={4} xs={4} className="mt-10">
    //                 <CheckCircleIcon
    //                 style={{
    //                     verticalAlign: "middle",
    //                     marginRight: 5,
    //                     width: "14px",
    //                     color: '#828282',
    //                 }}
    //                 className="vertical-align-middle"
    //                 />
    //                 <span className="f-12 font-weight-400" style={{ color: "#828282" }}>{subCategory.candidateSubCategory ? subCategory.candidateSubCategory : "Not Available"}</span>
    //                 </Grid>
    //                 <Grid item lg={4} md={4} xs={4} className="mt-10">
    //                 <span className="f-12 font-weight-400">Unmatched</span>
    //                 </Grid></>);
    //             })}
    //             </>}
    //             </>
    //             :
    //             <>
    //                 <Grid item lg={11.5} md={11.5} xs={11.5} className="border-bottom-1 mt-10"></Grid>
    //                 <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10"></Grid>
    //             </>
    //             }
    //             <Grid container lg={11.6} md={11.6} xs={11.6} className={isDetailsExist(props?.candidatePopUp?.matchingPercentage?.Experience) ? "cursor-hover" : ""} onClick={()=> (isDetailsExist(props?.candidatePopUp?.matchingPercentage?.Experience) && setOverallExperienceExpanded(!overallExperienceExpanded))}>
    //             <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-30">
    //               <img src={experienceIcon}/>
    //             </Grid>
    //             <Grid item lg={3.2} md={3.2} xs={3.2} className="mt-30">
    //             <Typography className="f-16 font-weight-700">Overall Experience</Typography>
    //             </Grid>
    //             <Grid item lg={7.3} md={7.3} xs={7.3} className="mt-34">
    //             <ProgressBar
    //               parentBgColor="#8F9DDD"
    //               childBgColor=getComputedStyle(document.documentElement).getPropertyValue('--primary-color')
    //               progress={props?.candidatePopUp?.matchingPercentage?.Experience?.percentage ? Math.round(props?.candidatePopUp?.matchingPercentage?.Experience.percentage) : 0}
    //               weightage={props?.candidatePopUp?.matchingPercentage?.Experience?.weightage}
    //               borderRadius={25}
    //               height={20}
    //               progresstextColor="white"
    //               aria-labelledby="Progress bar"
    //               aria-label="Progress bar"
    //             />
    //             </Grid>
    //             <Grid item lg={1} md={1} xs={1} className="mt-36 pl-10">
    //             <Typography className="f-12 font-weight-600" style={{ color: "#22A640" }}>{props?.candidatePopUp?.matchingPercentage?.Experience?.score ? Math.round(props?.candidatePopUp?.matchingPercentage?.Experience.score) : 0}%</Typography>
    //             </Grid>
    //             </Grid>
    //             {isDetailsExist(props?.candidatePopUp?.matchingPercentage?.Experience) ?
    //             <>
    //             <Grid item lg={0.4} md={0.4} xs={0.4} className="mt-30 cursor-hover" onClick={()=>setOverallExperienceExpanded(!overallExperienceExpanded)}>
    //             {overallExperienceExpanded ? <Tooltip role="tooltip" title="Collapse" placement="bottom"><img src={collapseIcon}/></Tooltip> : <Tooltip role="tooltip" title="Expand" placement="bottom"><img src={expandIcon}/></Tooltip>}
    //             </Grid>
    //             <Grid item lg={11.5} md={11.5} xs={11.5} className="border-bottom-1 mt-10"></Grid>
    //             <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10"></Grid>
    //             {overallExperienceExpanded && <>
    //             <Grid item lg={4} md={4} xs={4} className="mt-10">
    //             <Typography className="f-12 font-weight-700">Required</Typography>
    //             </Grid>
    //             <Grid item lg={4} md={4} xs={4} className="mt-10">
    //             <Typography className="f-12 font-weight-700">Candidate Overall Experience</Typography>
    //             </Grid>
    //             <Grid item lg={4} md={4} xs={4} className="mt-10">
    //             <Typography className="f-12 font-weight-700">Remarks</Typography>
    //             </Grid>
    //             <Grid item lg={4} md={4} xs={4} className="mt-10">
    //             <CheckCircleIcon
    //               style={{
    //                 verticalAlign: "middle",
    //                 marginRight: 5,
    //                 width: "14px",
    //                 color: `${props?.candidatePopUp?.matchingPercentage?.Experience?.unmatched.length ? '#828282' : '#1A8533'}`,
    //               }}
    //               className="vertical-align-middle"
    //             />
    //             <span className="f-12 font-weight-400">
    //             {props?.candidatePopUp?.matchingPercentage?.Experience?.matched?.length
    //               ? (props?.candidatePopUp?.matchingPercentage?.Experience?.matched[0].yearsOfExperience.includes("-") ? (props?.candidatePopUp?.matchingPercentage?.Experience?.matched[0].yearsOfExperience.split("-")[0] + " - " + props?.candidatePopUp?.matchingPercentage?.Experience?.matched[0].yearsOfExperience.split("-")[1] + (parseInt(props?.candidatePopUp?.matchingPercentage?.Experience?.matched[0].yearsOfExperience.split("-")[1]) > 1 ? " Years" : " Year")) : props?.candidatePopUp?.matchingPercentage?.Experience?.matched[0].yearsOfExperience)
    //               : props?.candidatePopUp?.matchingPercentage?.Experience
    //                 ?.partialMatched?.length
    //                 ? (props?.candidatePopUp?.matchingPercentage?.Experience?.partialMatched[0].yearsOfExperience.includes("-") ? (props?.candidatePopUp?.matchingPercentage?.Experience?.partialMatched[0].yearsOfExperience.split("-")[0] + " - " + props?.candidatePopUp?.matchingPercentage?.Experience?.partialMatched[0].yearsOfExperience.split("-")[1] + (parseInt(props?.candidatePopUp?.matchingPercentage?.Experience?.partialMatched[0].yearsOfExperience.split("-")[1]) > 1 ? " Years" : " Year")) : props?.candidatePopUp?.matchingPercentage?.Experience?.partialMatched[0].yearsOfExperience)
    //                 : props?.candidatePopUp?.matchingPercentage?.Experience?.unmatched?.length ? (props?.candidatePopUp?.matchingPercentage?.Experience?.unmatched[0].yearsOfExperience.includes("-") ? (props?.candidatePopUp?.matchingPercentage?.Experience?.unmatched[0].yearsOfExperience.split("-")[0] + " - " + props?.candidatePopUp?.matchingPercentage?.Experience?.unmatched[0].yearsOfExperience.split("-")[1] + (parseInt(props?.candidatePopUp?.matchingPercentage?.Experience?.unmatched[0].yearsOfExperience.split("-")[1]) > 1 ? " Years" : " Year")) : props?.candidatePopUp?.matchingPercentage?.Experience?.unmatched[0].yearsOfExperience) : "-"}
    //             </span>
    //             </Grid>
    //             <Grid item lg={4} md={4} xs={4} className="mt-10">
    //             <CheckCircleIcon
    //               style={{
    //                 verticalAlign: "middle",
    //                 marginRight: 5,
    //                 width: "14px",
    //                 color: `${props?.candidatePopUp?.matchingPercentage?.Experience?.unmatched?.length ? '#828282' : '#1A8533'}`,
    //               }}
    //               className="vertical-align-middle"
    //             />
    //             <span className="f-12 font-weight-400">{`${props?.candidatePopUp?.overallYearsOfExperience}` + `${props?.candidatePopUp?.overallYearsOfExperience > 1 ? " Years" : " Year"}`}</span>
    //             </Grid>
    //             <Grid item lg={4} md={4} xs={4} className="mt-10">
    //             <span className="f-12 font-weight-400">
    //             {props?.candidatePopUp?.matchingPercentage?.Experience?.matched?.length
    //               ? "Matched"
    //               : props?.candidatePopUp?.matchingPercentage?.Experience
    //                 ?.partialMatched?.length
    //                 ? "Partially Matched"
    //                 : "Unmatched"}
    //             </span>
    //             </Grid>
    //             </>}
    //             </>
    //             :
    //             <>
    //                 <Grid item lg={11.5} md={11.5} xs={11.5} className="border-bottom-1 mt-10"></Grid>
    //                 <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10"></Grid>
    //             </>
    //             }
    //             <Grid container lg={11.6} md={11.6} xs={11.6} className={isDetailsExist(props?.candidatePopUp?.matchingPercentage?.ProfileTitle) ? "cursor-hover" : ""} onClick={()=> (isDetailsExist(props?.candidatePopUp?.matchingPercentage?.ProfileTitle) && setProfileTitleExpanded(!profileTitleExpanded))}>
    //             <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-30">
    //               <img src={profileTitleIcon}/>
    //             </Grid>
    //             <Grid item lg={3.2} md={3.2} xs={3.2} className="mt-30">
    //             <Typography className="f-16 font-weight-700">Profile Title</Typography>
    //             </Grid>
    //             <Grid item lg={7.3} md={7.3} xs={7.3} className="mt-34">
    //             <ProgressBar
    //               parentBgColor="#8F9DDD"
    //               childBgColor=getComputedStyle(document.documentElement).getPropertyValue('--primary-color')
    //               progress={props?.candidatePopUp?.matchingPercentage?.ProfileTitle ? Math.round(props?.candidatePopUp?.matchingPercentage?.ProfileTitle.percentage) : 0}
    //               weightage={props?.candidatePopUp?.matchingPercentage?.ProfileTitle?.weightage}
    //               borderRadius={25}
    //               height={20}
    //               progresstextColor="white"
    //               aria-labelledby="Progress bar"
    //               aria-label="Progress bar"
    //             />
    //             </Grid>
    //             <Grid item lg={1} md={1} xs={1} className="mt-36 pl-10">
    //             <Typography className="f-12 font-weight-600" style={{ color: "#22A640" }}>{props?.candidatePopUp?.matchingPercentage?.ProfileTitle ? Math.round(props?.candidatePopUp?.matchingPercentage?.ProfileTitle.score) : 0}%</Typography>
    //             </Grid>
    //             </Grid>
    //             {isDetailsExist(props?.candidatePopUp?.matchingPercentage?.ProfileTitle) ?
    //             <>
    //             <Grid item lg={0.4} md={0.4} xs={0.4} className="mt-30 cursor-hover" onClick={()=>setProfileTitleExpanded(!profileTitleExpanded)}>
    //               {profileTitleExpanded ? <Tooltip role="tooltip" title="Collapse" placement="bottom"><img src={collapseIcon}/></Tooltip> : <Tooltip role="tooltip" title="Expand" placement="bottom"><img src={expandIcon}/></Tooltip>}
    //             </Grid>
    //             <Grid item lg={11.5} md={11.5} xs={11.5} className="border-bottom-1 mt-10"></Grid>
    //             <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10"></Grid>
    //             {profileTitleExpanded && <>
    //             <Grid item lg={4} md={4} xs={4} className="mt-10">
    //             <Typography className="f-12 font-weight-700">Required</Typography>
    //             </Grid>
    //             <Grid item lg={4} md={4} xs={4} className="mt-10">
    //             <Typography className="f-12 font-weight-700">Candidate Profile Title</Typography>
    //             </Grid>
    //             <Grid item lg={4} md={4} xs={4} className="mt-10">
    //             <Typography className="f-12 font-weight-700">Remarks</Typography>
    //             </Grid>
    //             <Grid item lg={4} md={4} xs={4} className="mt-10">
    //             <CheckCircleIcon
    //               style={{
    //                 verticalAlign: "middle",
    //                 marginRight: 5,
    //                 width: "14px",
    //                 color: `${props?.candidatePopUp?.matchingPercentage?.ProfileTitle?.matched?.length ? '#1A8533' : '#828282'}`,
    //               }}
    //               className="vertical-align-middle"
    //             />
    //             <span className="f-12 font-weight-400">{props?.candidatePopUp?.matchingPercentage?.ProfileTitle?.matched?.length ? props?.candidatePopUp?.matchingPercentage?.ProfileTitle?.matched[0].profileTitle : props?.candidatePopUp?.matchingPercentage?.ProfileTitle?.unmatched.length ? props?.candidatePopUp?.matchingPercentage?.ProfileTitle?.unmatched[0].profileTitle : ""}</span>
    //             </Grid>
    //             <Grid item lg={4} md={4} xs={4} className="mt-10">
    //             <CheckCircleIcon
    //               style={{
    //                 verticalAlign: "middle",
    //                 marginRight: 5,
    //                 width: "14px",
    //                 color: `${props?.candidatePopUp?.matchingPercentage?.ProfileTitle?.matched?.length ? '#1A8533' : '#828282'}`,
    //               }}
    //               className="vertical-align-middle"
    //             />
    //             <span className="f-12 font-weight-400">{props?.candidatePopUp?.profileTitle}</span>
    //             </Grid>
    //             <Grid item lg={4} md={4} xs={4} className="mt-10">
    //             <span className="f-12 font-weight-400">{props?.candidatePopUp?.matchingPercentage?.ProfileTitle?.matched?.length ? "Matched" : "Unmatched"}</span>
    //             </Grid>
    //             </>}
    //             </>
    //             :
    //             <>
    //                 <Grid item lg={11.5} md={11.5} xs={11.5} className="border-bottom-1 mt-10"></Grid>
    //                 <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10"></Grid>
    //             </>
    //             }
    //             <Grid container lg={11.6} md={11.6} xs={11.6} className={(isDetailsExist(props?.candidatePopUp?.matchingPercentage?.Education) || props?.candidatePopUp?.matchingPercentage?.Education?.weightage > 0) ? "cursor-hover" : ""} onClick={()=> ((isDetailsExist(props?.candidatePopUp?.matchingPercentage?.Education) || props?.candidatePopUp?.matchingPercentage?.Education?.weightage > 0) && setEducationExpanded(!educationExpanded))}>
    //             <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-30">
    //               <img src={profileEducationIcon}/>
    //             </Grid>
    //             <Grid item lg={3.2} md={3.2} xs={3.2} className="mt-30">
    //             <Typography className="f-16 font-weight-700">Education</Typography>
    //             </Grid>
    //             <Grid item lg={7.3} md={7.3} xs={7.3} className="mt-34">
    //             <ProgressBar
    //               parentBgColor="#8F9DDD"
    //               childBgColor=getComputedStyle(document.documentElement).getPropertyValue('--primary-color')
    //               progress={props?.candidatePopUp?.matchingPercentage?.Education ? Math.round(props?.candidatePopUp?.matchingPercentage?.Education.percentage) : 0}
    //               weightage={props?.candidatePopUp?.matchingPercentage?.Education?.weightage}
    //               borderRadius={25}
    //               height={20}
    //               progresstextColor="white"
    //               aria-labelledby="Progress bar"
    //               aria-label="Progress bar"
    //             />
    //             </Grid>
    //             <Grid item lg={1} md={1} xs={1} className="mt-36 pl-10">
    //             <Typography className="f-12 font-weight-600" style={{ color: "#22A640" }}>{props?.candidatePopUp?.matchingPercentage?.Education ? Math.round(props?.candidatePopUp?.matchingPercentage?.Education.score) : 0}%</Typography>
    //             </Grid>
    //             </Grid>
    //             {(isDetailsExist(props?.candidatePopUp?.matchingPercentage?.Education) || props?.candidatePopUp?.matchingPercentage?.Education?.weightage > 0) ?
    //             <>
    //             <Grid item lg={0.4} md={0.4} xs={0.4} className="mt-30 cursor-hover" onClick={()=>setEducationExpanded(!educationExpanded)}>
    //               {educationExpanded ? <Tooltip role="tooltip" title="Collapse" placement="bottom"><img src={collapseIcon}/></Tooltip> : <Tooltip role="tooltip" title="Expand" placement="bottom"><img src={expandIcon}/></Tooltip>}
    //             </Grid>
    //             <Grid item lg={11.5} md={11.5} xs={11.5} className="border-bottom-1 mt-10"></Grid>
    //             <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10"></Grid>
    //             {educationExpanded && <>
    //             <Grid item lg={4} md={4} xs={4} className="mt-10">
    //             <Typography className="f-12 font-weight-700">Required</Typography>
    //             </Grid>
    //             <Grid item lg={4} md={4} xs={4} className="mt-10">
    //             <Typography className="f-12 font-weight-700">Candidate Education</Typography>
    //             </Grid>
    //             <Grid item lg={4} md={4} xs={4} className="mt-10">
    //             <Typography className="f-12 font-weight-700">Remarks</Typography>
    //             </Grid>
    //             {(isDetailsExist(props?.candidatePopUp?.matchingPercentage?.Education) === false && props?.candidatePopUp?.matchingPercentage?.Education?.weightage > 0) && (<>
    //               <Grid item lg={4} md={4} xs={4} className="mt-10">
    //                 <CheckCircleIcon
    //                 style={{
    //                     verticalAlign: "middle",
    //                     marginRight: 5,
    //                     width: "14px",
    //                     color: '#828282',
    //                 }}
    //                 className="vertical-align-middle"
    //                 />
    //                 <span className="f-12 font-weight-400" style={{ color: "#828282" }}>{"Not Available"}</span>
    //                 </Grid>
    //                 <Grid item lg={4} md={4} xs={4} className="mt-10">
    //                 <CheckCircleIcon
    //                 style={{
    //                     verticalAlign: "middle",
    //                     marginRight: 5,
    //                     width: "14px",
    //                     color: '#828282',
    //                 }}
    //                 className="vertical-align-middle"
    //                 />
    //                 <span className="f-12 font-weight-400" style={{ color: "#828282" }}>{"Not Available"}</span>
    //                 </Grid>
    //                 <Grid item lg={4} md={4} xs={4} className="mt-10">
    //                 <span className="f-12 font-weight-400">Unmatched</span>
    //                 </Grid></>)}
    //             {props?.candidatePopUp?.matchingPercentage?.Education?.matched?.map((education, index) => {
    //               return (<>
    //               <Grid item lg={4} md={4} xs={4} className="mt-10">
    //                 <CheckCircleIcon
    //                 style={{
    //                     verticalAlign: "middle",
    //                     marginRight: 5,
    //                     width: "14px",
    //                 }}
    //                 className="success-color vertical-align-middle"
    //                 />
    //                 <span className="f-12 font-weight-400">{education.education}</span>
    //                 </Grid>
    //                 <Grid item lg={4} md={4} xs={4} className="mt-10">
    //                 <CheckCircleIcon
    //                 style={{
    //                     verticalAlign: "middle",
    //                     marginRight: 5,
    //                     width: "14px",
    //                 }}
    //                 className="success-color vertical-align-middle"
    //                 />
    //                 <span className="f-12 font-weight-400">{education.candidateEducation}</span>
    //                 </Grid>
    //                 <Grid item lg={4} md={4} xs={4} className="mt-10">
    //                 <span className="f-12 font-weight-400">Matched</span>
    //                 </Grid></>);
    //             })}
    //             {props?.candidatePopUp?.matchingPercentage?.Education?.partialMatched?.map((education, index) => {
    //               return (<>
    //               <Grid item lg={4} md={4} xs={4} className="mt-10">
    //                 <CheckCircleIcon
    //                 style={{
    //                     verticalAlign: "middle",
    //                     marginRight: 5,
    //                     width: "14px",
    //                 }}
    //                 className="success-color vertical-align-middle"
    //                 />
    //                 <span className="f-12 font-weight-400">{education.education}</span>
    //                 </Grid>
    //                 <Grid item lg={4} md={4} xs={4} className="mt-10">
    //                 <CheckCircleIcon
    //                 style={{
    //                     verticalAlign: "middle",
    //                     marginRight: 5,
    //                     width: "14px",
    //                 }}
    //                 className="success-color vertical-align-middle"
    //                 />
    //                 <span className="f-12 font-weight-400">{Education.candidateEducation}</span>
    //                 </Grid>
    //                 <Grid item lg={4} md={4} xs={4} className="mt-10">
    //                 <span className="f-12 font-weight-400">Partially Matched</span>
    //                 </Grid></>);
    //             })}
    //             {Array.isArray(props?.candidatePopUp?.matchingPercentage?.Education?.unmatched) && props?.candidatePopUp?.matchingPercentage?.Education?.unmatched?.map((education, index) => {
    //               return (JSON.stringify(education) === '{}' ? <></> : <>
    //               <Grid item lg={4} md={4} xs={4} className="mt-10">
    //                 <CheckCircleIcon
    //                 style={{
    //                     verticalAlign: "middle",
    //                     marginRight: 5,
    //                     width: "14px",
    //                     color: '#828282',
    //                 }}
    //                 className="vertical-align-middle"
    //                 />
    //                 <span className="f-12 font-weight-400" style={{ color: "#828282" }}>{education.education}</span>
    //                 </Grid>
    //                 <Grid item lg={4} md={4} xs={4} className="mt-10">
    //                 <CheckCircleIcon
    //                 style={{
    //                     verticalAlign: "middle",
    //                     marginRight: 5,
    //                     width: "14px",
    //                     color: '#828282',
    //                 }}
    //                 className="vertical-align-middle"
    //                 />
    //                 <span className="f-12 font-weight-400" style={{ color: "#828282" }}>{education.candidateEducation ? education.candidateEducation : "Not Available"}</span>
    //                 </Grid>
    //                 <Grid item lg={4} md={4} xs={4} className="mt-10">
    //                 <span className="f-12 font-weight-400">Unmatched</span>
    //                 </Grid></>);
    //             })}
    //             </>
    //             }
    //             </>
    //             :
    //             <>
    //                 <Grid item lg={11.5} md={11.5} xs={11.5} className="border-bottom-1 mt-10"></Grid>
    //                 <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10"></Grid>
    //             </>
    //             }
    //             <Grid container lg={11.6} md={11.6} xs={11.6} className={(isDetailsExist(props?.candidatePopUp?.matchingPercentage?.Certificate) || props?.candidatePopUp?.matchingPercentage?.Certificate?.weightage > 0) ? "cursor-hover" : ""} onClick={()=> ((isDetailsExist(props?.candidatePopUp?.matchingPercentage?.Certificate) || props?.candidatePopUp?.matchingPercentage?.Certificate?.weightage > 0) && setCertificationExpanded(!certificationExpanded))}>
    //             <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-30">
    //               <img src={certificationIcon}/>
    //             </Grid>
    //             <Grid item lg={3.2} md={3.2} xs={3.2} className="mt-30">
    //             <Typography className="f-16 font-weight-700">Certification</Typography>
    //             </Grid>
    //             <Grid item lg={7.3} md={7.3} xs={7.3} className="mt-34">
    //             <ProgressBar
    //               parentBgColor="#8F9DDD"
    //               childBgColor=getComputedStyle(document.documentElement).getPropertyValue('--primary-color')
    //               progress={props?.candidatePopUp?.matchingPercentage?.Certificate ? Math.round(props?.candidatePopUp?.matchingPercentage?.Certificate.percentage) : 0}
    //               weightage={props?.candidatePopUp?.matchingPercentage?.Certificate?.weightage}
    //               borderRadius={25}
    //               height={20}
    //               progresstextColor="white"
    //               aria-labelledby="Progress bar"
    //               aria-label="Progress bar"
    //             />
    //             </Grid>
    //             <Grid item lg={1} md={1} xs={1} className="mt-36 pl-10">
    //             <Typography className="f-12 font-weight-600" style={{ color: "#22A640" }}>{props?.candidatePopUp?.matchingPercentage?.Certificate ? Math.round(props?.candidatePopUp?.matchingPercentage?.Certificate.score) : 0}%</Typography>
    //             </Grid>
    //             </Grid>
    //             {(isDetailsExist(props?.candidatePopUp?.matchingPercentage?.Certificate) || props?.candidatePopUp?.matchingPercentage?.Certificate?.weightage > 0) &&
    //             <>
    //             <Grid item lg={0.4} md={0.4} xs={0.4} className="mt-30 cursor-hover" onClick={()=>setCertificationExpanded(!certificationExpanded)}>
    //               {certificationExpanded ? <Tooltip role="tooltip" title="Collapse" placement="bottom"><img src={collapseIcon}/></Tooltip> : <Tooltip role="tooltip" title="Expand" placement="bottom"><img src={expandIcon}/></Tooltip>}
    //             </Grid>
    //             {certificationExpanded && <>
    //             <Grid item lg={11.5} md={11.5} xs={11.5} className="border-bottom-1 mt-10"></Grid>
    //             <Grid item lg={0.5} md={0.5} xs={0.5} className="mt-10"></Grid>
    //             <Grid item lg={4} md={4} xs={4} className="mt-10">
    //             <Typography className="f-12 font-weight-700">Required</Typography>
    //             </Grid>
    //             <Grid item lg={4} md={4} xs={4} className="mt-10">
    //             <Typography className="f-12 font-weight-700">Candidate Certification</Typography>
    //             </Grid>
    //             <Grid item lg={4} md={4} xs={4} className="mt-10">
    //             <Typography className="f-12 font-weight-700">Remarks</Typography>
    //             </Grid>
    //             {(isDetailsExist(props?.candidatePopUp?.matchingPercentage?.Certificate) === false && props?.candidatePopUp?.matchingPercentage?.Certificate?.weightage > 0) && (<>
    //               <Grid item lg={4} md={4} xs={4} className="mt-10">
    //                 <CheckCircleIcon
    //                 style={{
    //                     verticalAlign: "middle",
    //                     marginRight: 5,
    //                     width: "14px",
    //                     color: '#828282',
    //                 }}
    //                 className="vertical-align-middle"
    //                 />
    //                 <span className="f-12 font-weight-400" style={{ color: "#828282" }}>{"Not Available"}</span>
    //                 </Grid>
    //                 <Grid item lg={4} md={4} xs={4} className="mt-10">
    //                 <CheckCircleIcon
    //                 style={{
    //                     verticalAlign: "middle",
    //                     marginRight: 5,
    //                     width: "14px",
    //                     color: '#828282',
    //                 }}
    //                 className="vertical-align-middle"
    //                 />
    //                 <span className="f-12 font-weight-400" style={{ color: "#828282" }}>{"Not Available"}</span>
    //                 </Grid>
    //                 <Grid item lg={4} md={4} xs={4} className="mt-10">
    //                 <span className="f-12 font-weight-400">Unmatched</span>
    //                 </Grid></>)}
    //             {props?.candidatePopUp?.matchingPercentage?.Certificate?.matched?.map((certificate, index) => {
    //               return (<>
    //               <Grid item lg={4} md={4} xs={4} className="mt-10">
    //                 <CheckCircleIcon
    //                 style={{
    //                     verticalAlign: "middle",
    //                     marginRight: 5,
    //                     width: "14px",
    //                 }}
    //                 className="success-color vertical-align-middle"
    //                 />
    //                 <span className="f-12 font-weight-400">{certificate.jobSkill}</span>
    //                 </Grid>
    //                 <Grid item lg={4} md={4} xs={4} className="mt-10">
    //                 <CheckCircleIcon
    //                 style={{
    //                     verticalAlign: "middle",
    //                     marginRight: 5,
    //                     width: "14px",
    //                 }}
    //                 className="success-color vertical-align-middle"
    //                 />
    //                 <span className="f-12 font-weight-400">{certificate.candidateSkill}</span>
    //                 </Grid>
    //                 <Grid item lg={4} md={4} xs={4} className="mt-10">
    //                 <span className="f-12 font-weight-400">Matched</span>
    //                 </Grid></>);
    //             })}
    //             {props?.candidatePopUp?.matchingPercentage?.Certificate?.partialMatched?.map((certificate, index) => {
    //               return (<>
    //               <Grid item lg={4} md={4} xs={4} className="mt-10">
    //                 <CheckCircleIcon
    //                 style={{
    //                     verticalAlign: "middle",
    //                     marginRight: 5,
    //                     width: "14px",
    //                 }}
    //                 className="success-color vertical-align-middle"
    //                 />
    //                 <span className="f-12 font-weight-400">{certificate.jobSkill}</span>
    //                 </Grid>
    //                 <Grid item lg={4} md={4} xs={4} className="mt-10">
    //                 <CheckCircleIcon
    //                 style={{
    //                     verticalAlign: "middle",
    //                     marginRight: 5,
    //                     width: "14px",
    //                 }}
    //                 className="success-color vertical-align-middle"
    //                 />
    //                 <span className="f-12 font-weight-400">{certificate.candidateSkill}</span>
    //                 </Grid>
    //                 <Grid item lg={4} md={4} xs={4} className="mt-10">
    //                 <span className="f-12 font-weight-400">Partially Matched</span>
    //                 </Grid></>);
    //             })}
    //             {Array.isArray(props?.candidatePopUp?.matchingPercentage?.Certificate?.unmatched) && props?.candidatePopUp?.matchingPercentage?.Certificate?.unmatched?.map((certificate, index) => {
    //               return (JSON.stringify(certificate) === '{}' ? <></> : <>
    //               <Grid item lg={4} md={4} xs={4} className="mt-10">
    //                 <CheckCircleIcon
    //                 style={{
    //                     verticalAlign: "middle",
    //                     marginRight: 5,
    //                     width: "14px",
    //                     color: '#828282',
    //                 }}
    //                 className="vertical-align-middle"
    //                 />
    //                 <span className="f-12 font-weight-400" style={{ color: "#828282" }}>{certificate.jobSkill}</span>
    //                 </Grid>
    //                 <Grid item lg={4} md={4} xs={4} className="mt-10">
    //                 <CheckCircleIcon
    //                 style={{
    //                     verticalAlign: "middle",
    //                     marginRight: 5,
    //                     width: "14px",
    //                     color: '#828282',
    //                 }}
    //                 className="vertical-align-middle"
    //                 />
    //                 <span className="f-12 font-weight-400" style={{ color: "#828282" }}>{certificate.candidateSkill ? certificate.candidateSkill : "Not Available"}</span>
    //                 </Grid>
    //                 <Grid item lg={4} md={4} xs={4} className="mt-10">
    //                 <span className="f-12 font-weight-400">Unmatched</span>
    //                 </Grid></>);
    //             })}
    //             </>
    //             }
    //             </>}
    //             </Grid>
    //             </Grid>
    //           </Box>
    //         </Modal>
  );
});
export default MatchingScoreBreakdownModal;
